import React,{useState, useEffect} from "react";
import { CheckIcon } from "@heroicons/react/outline";
import Modal from 'react-responsive-modal'
import { getPlans } from "../../services/loggedInApp";
import { PlanUpdatedSuccessfully } from "../NotificationToast";
import { ToastContainer } from "react-toastify";
import PlansSlider from "./PlansSlider";


function PlanCard({ plan_name, price, select, planDuration, planId, planCurrency, planDescription,  planRegion, appID, deleteFunc, editFunc}) {


  const [isOpenModal, setIsOpenModal] = useState(false);
  const [deleteModelOpen, setDeleteModelOpen] = useState(false);

  const [planID, setPlanID] = useState('');
  const [planName, setPlanName] = useState('');
  const [regionUID, setRegionUID] = useState('');
  const [currency, setCurrency] = useState('');
  const [countryAmount, setCountryAmount] = useState('');
  const [planInfo, setPlanInfo] = useState('');

  const onCloseModel = () => {
    setDeleteModelOpen(false);
    setIsOpenModal(false);
    setPlanName('')
    setRegionUID('')
    setCurrency('')
    setCountryAmount('')
    setPlanInfo('')
  }

  const openModal = (plId) => {
    setPlanID(plId);
    setDeleteModelOpen(true)
  }
  

  return (
    <div className={ select === 0 ? "border-t-4 border-2 border-gray-400 w-auto rounded-xl py-10 px-7 shadow-lg" : "border-2 w-auto rounded-xl py-10 px-7 shadow-md" } >
      <div className="border-b-2">
        <h1 className="font-semibold text-3xl text-center">{plan_name}</h1>
        <h2 className="text-center my-4">
          <span className=" flex items-end justify-center text-4xl font-medium"><p className="text-base mb-1 mr-1">{planCurrency} </p>{price} <p className="text-base mb-1 ml-1">{`/month`}</p></span>
        </h2>   
        <div className="flex justify-center items-center mb-2">
          <button className="bg-white border font-semibold border-gray-800 text-black w-24 mr-4 h-8 rounded-md" onClick={() => {setIsOpenModal(true); setPlanID(planId)}}>Edit</button>
          <button className="bg-gray-800 text-white w-24 h-8 rounded-md" onClick={() => {openModal(planId)}}>Delete</button>
        </div>
      </div>
      <ul className="my-5">
        <li className="flex my-2">
          <span> <CheckIcon className="w-5 h-5 mr-3" /> </span>
         {planDescription}
        </li>
        <li className="flex my-2">
          <span> <CheckIcon className="w-5 h-5 mr-3" /> </span>
          FREE for 15 days
        </li>
      </ul>
      {select === 0 ? (
        <button className="border-2 w-full py-2 rounded-lg border-black font-semibold">
          Your current plan
        </button>
      ) : (
        <button className="w-full rounded-lg bg-black text-white py-2 font-semibold">
          Upgrade
        </button>
      )}
      <Modal open={isOpenModal} onClose={onCloseModel}  classNames={{overlay: 'customOverlay',modal: 'customModal'}}> 
        <div className="">
          <div className="flex flex-col">
            <label className="font-semibold mb-2 ">Plan Name</label>
            <input type="text" className="rounded-md mb-4" onChange={(e) => {setPlanName(e.target.value)}}/>
          </div>

          <div className="flex flex-col">
            <label className="font-semibold mb-2 ">Currency</label>
            <input type="text" className="rounded-md mb-4" onChange={(e) => {setCurrency(e.target.value)}}/>
          </div>

          <div className="flex flex-col">
            <label className="font-semibold mb-2 ">Amount</label>
            <input type="number" className="rounded-md mb-4" onChange={(e) => {setCountryAmount(e.target.value)}}/>
          </div>

          <div className="flex flex-col">
            <label className="font-semibold mb-2 ">Region</label>
            <input type="text" className="rounded-md mb-4" onChange={(e) => {setRegionUID(e.target.value)}}/>
          </div>


          <div className="flex flex-col">
            <label className="font-semibold mb-2 ">Plan Description</label>
            <textarea rows='5' type="text" className="rounded-md mb-4 border-2 " onChange={(e) => {setPlanInfo(e.target.value)}}/>
          </div>
          <div className="flex justify-center items-center">
            <button className="bg-gray-800 text-white py-2 px-5 rounded-md" onClick={() => {editFunc(planID, planName, regionUID, currency, countryAmount, planInfo); setIsOpenModal(false)}}>Submit</button>
          </div>
        </div>
      </Modal>
      <Modal open={deleteModelOpen} onClose={onCloseModel}  classNames={{overlay: 'customOverlay',modal: 'customModal'}}> 
          <div>
              <label>Are you sure you want to Delete?</label>
              <div className="mt-8 flex justify-center items-center">
                <button onClick={() => {onCloseModel()}} className="bg-gray-700 text-white font-bold rounded-md w-24 h-8 mr-4 ">Cancel</button>
                <button onClick={() => {deleteFunc(planID); setDeleteModelOpen(false)}} className="bg-red-600 text-white font-bold rounded-md w-24 h-8">Yes</button>
              </div>
          </div> 
      </Modal>
      <ToastContainer />
    </div>
  );
}

export default PlanCard;
