import React from "react"

import { useLocation } from 'react-router-dom';

const RedirectUser = (props) => {

  const search = useLocation().search;
  const access_token = new URLSearchParams(search).get('token')
  const refresh_token = new URLSearchParams(search).get('reToken')
  const email = new URLSearchParams(search).get('email')


  if (access_token && refresh_token) {

    // const user_email=query.get(')
    localStorage.setItem("token", access_token);
    localStorage.setItem("refresh_token", refresh_token);
    localStorage.setItem("user_email", email);
    sessionStorage.setItem("login","login");
    const redirectHref = "/apps";
    window.location.href = redirectHref;
  }
  else {
    const redirectHreff = 'https://trypadlock.com/loginpage';
    window.location.href = redirectHreff;
  }
  return (
    <>
      Redirecting.....
    </>
  )
}
export default RedirectUser;

export const findToken = () => {
  if (localStorage.getItem("token") != null) {
      return true;
  }
  return false;
};