import React, {useState, useEffect} from "react";
import PaymentHistorySection from "../BillsAndPayments/PaymentHistorySection";
import PlanCard from './PlanCard'
import Modal from 'react-responsive-modal'
import { useHistory, useLocation } from "react-router";
import { getPlans } from "../../services/loggedInApp";
import { PlanUpdatedSuccessfully, PlanDeletedSuccessfully } from "../NotificationToast";
import { ToastContainer } from "react-toastify";


function PlansBody({ active, appId }) {
  let location = useLocation();
  let locState = location.state;

  const [priceData, setPriceData] = useState([]);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [planName, setPlanName] = useState('');
  const [regionUID, setRegionUID] = useState('');
  const [currency, setCurrency] = useState('');
  const [countryAmount, setCountryAmount] = useState('');
  const [planInfo, setPlanInfo] = useState([]);


  const onCloseModel = () => {
    console.log('model')
    setIsOpenModal(false);
    setPlanName('')
    setRegionUID('')
    setCurrency('')
    setCountryAmount('')
    setPlanInfo('')
  }

  const get_plans = () => {
    getPlans(locState.appId).then((res) => {
      if(res.error){
        console.log(res.error)
      } else {
        setPriceData(res.data.data)
      }
    })
  }

  useEffect(() => {
    get_plans();
  }, [])


  const createPlans = () => {
      fetch("https://api.trypadlock.com/plans/", {
          method: "POST",
          headers: {
              authorization: "Jwt " + localStorage.getItem("token"),
              "Content-Type": "application/json",
          },
          body: JSON.stringify({
              appid: locState.appId,
              regionId: locState.regionId,
              name: planName,
              region: regionUID,
              currencys: currency,
              country_amount: countryAmount,
              info: [planInfo],
          }),
      })
          .then((response) => response.json())
          .then((data) => {
              if (data.status == "plan got saved") {
                  get_plans();
                  setIsOpenModal(false);
              } else {
                  console.log("Error creating Plan");
              }
          });
  };

  const deletePlan = (planID) => {
      fetch("https://api.trypadlock.com/plans/", {
          method: "PUT",
          headers: {
              authorization: "Jwt " + localStorage.getItem("token"),
              "Content-Type": "application/json",
          },
          body: JSON.stringify({
              id: planID,
              status: "false",
          }),
      })
          .then((response) => response.json())
          .then((data) => {
              console.log("deatDEE", data);
              if (data.status == "data updated successfully") {
                  // setDeleteModelOpen(false);
                  PlanDeletedSuccessfully();
                  get_plans();
              } else {
                  console.log("error delting");
              }
          });
  };

  const editPlan = (planID, planName, regionUID, currency, countryAmount, planInfo) => {
      fetch("https://api.trypadlock.com/plans/", {
          method: "PUT",
          headers: {
              authorization: "Jwt " + localStorage.getItem("token"),
              "Content-Type": "application/json",
          },
          body: JSON.stringify({
              id: planID,
              name: planName,
              region: regionUID,
              currencys: currency,
              country_amount: countryAmount,
              info: planInfo,
          }),
      })
          .then((response) => response.json())
          .then((data) => {
              if (data.status == "data updated successfully") {
                  // setIsOpenModal(false);
                  get_plans();
                  PlanUpdatedSuccessfully();
              } else {
                  console.log("error updating");
              }
              console.log("datataedit", data);
          });
  };

  return (
      <div className="">
          {active === 0 ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-7 my-16">
                  {priceData.map((plan, idx) =>
                      plan.regionId == locState.regionId ? (
                          <PlanCard
                              key={idx}
                              plan_name={plan.name}
                              price={plan.price}
                              planId={plan.id}
                              planCurrency={plan.currency}
                              planDescription={plan.info}
                              planRegion={plan.region}
                              appID={locState.appId}
                              deleteFunc={deletePlan}
                              editFunc={editPlan}
                          />
                      ) : null
                  )}
                  <div className="border-2 border-gray-400 w-auto rounded-xl py-10 px-7 shadow-lg flex justify-center items-center h-auto">
                      <button
                          onClick={() => {
                              setIsOpenModal(true);
                          }}
                          className="w-full h-full text-black font-medium rounded-md text-base  px-7 py-3">
                          {" "}
                          <p className="text-5xl text-black font-light">+</p>Add Plans
                      </button>
                  </div>
              </div>
          ) : (
              ""
          )}
          {active === 1 ? (
              <div className="my-5">
                  <PaymentHistorySection
                      plans={priceData.filter((plan) => plan.regionId === locState.regionId)}
                  />
              </div>
          ) : (
              ""
          )}
          <Modal
              open={isOpenModal}
              onClose={onCloseModel}
              classNames={{ overlay: "customOverlay", modal: "customModal" }}>
              <div className="">
                  <div className="flex flex-col">
                      <label className="font-semibold mb-2 ">Plan Name</label>
                      <input
                          type="text"
                          className="rounded-md mb-4"
                          onChange={(e) => {
                              setPlanName(e.target.value);
                          }}
                      />
                  </div>

                  <div className="flex flex-col">
                      <label className="font-semibold mb-2 ">Currency</label>
                      <input
                          type="text"
                          className="rounded-md mb-4"
                          onChange={(e) => {
                              setCurrency(e.target.value);
                          }}
                      />
                  </div>

                  <div className="flex flex-col">
                      <label className="font-semibold mb-2 ">Amount</label>
                      <input
                          type="number"
                          className="rounded-md mb-4"
                          onChange={(e) => {
                              setCountryAmount(e.target.value);
                          }}
                      />
                  </div>

                  <div className="flex flex-col">
                      <label className="font-semibold mb-2 ">Region</label>
                      <input
                          type="text"
                          className="rounded-md mb-4"
                          onChange={(e) => {
                              setRegionUID(e.target.value);
                          }}
                      />
                  </div>

                  <div className="flex flex-col">
                      <label className="font-semibold mb-2 ">Plan Description</label>
                      <textarea
                          rows="5"
                          type="text"
                          className="rounded-md mb-4 border-2 "
                          onChange={(e) => {
                              setPlanInfo(e.target.value);
                          }}
                      />
                  </div>
                  <div className="flex justify-center items-center">
                      <button
                          className="bg-gray-800 text-white py-2 px-5 rounded-md"
                          onClick={() => {
                              createPlans();
                          }}>
                          Submit
                      </button>
                  </div>
              </div>
          </Modal>
          <ToastContainer />
      </div>
  );
}

export default PlansBody;
