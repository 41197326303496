import React, { useState, useEffect } from 'react'
import ProfileNav from './ProfileNav'
import user from '../../assets/user.png';
import { getUserDetails } from '../../services/loggedInApp';

export default function UserProfile() {

    const[userInfo , setUserInfo] = useState({});

    const userDetail = () => {
        getUserDetails().then((res) => {
            setUserInfo(res.data);
        })
    }

    useEffect(() => {
        userDetail();
    }, [])

    return (
        <>
        <ProfileNav />
        <div className='p-12 pr-20 pl-20 smt:p-0'>
            <div className='border shadow-md p-10 smt:p-0 smt:pb-10 smt:pt-8 flex justify-center align-middle items-center flex-col'>
                <div className='flex flex-col justify-center items-center mb-10'>
                    <h1 className='mb-8 text-4xl font-bold'>Profile</h1>
                    <div className='rounded-full w-32 h-32 border flex items-center justify-center'>
                        <img src={user} alt='User Profile Photo' className='w-20 h-20' />
                    </div>
                        <p className='text-2xl font-semibold'>{userInfo.first_name} {userInfo.last_name}</p>
                </div>

                <div className=' p-8 grid grid-cols-2 gap-4 w-full mb-8'>
                    <div className='flex flex-col rounded-md   '>
                        <label className='mb-2 text-lg'>User Name</label>
                        <input disabled className='rounded-md bg-white' value={userInfo.username}/>
                    </div>
                    <div className='flex flex-col rounded-md ' >
                        <label className='mb-2 text-lg'>First Name</label>
                        <input  disabled className='rounded-md bg-white' value={userInfo.first_name}/>
                    </div>
                    <div className='flex flex-col rounded-md ' >
                        <label className='mb-2 text-lg'>Last Name</label>
                        <input  disabled className='rounded-md bg-white' value={userInfo.last_name}/>
                    </div>
                    <div className='flex flex-col rounded-md ' >
                        <label className='mb-2 text-lg'>Email</label>
                        <input  disabled className='rounded-md bg-white' value={userInfo.email}/>
                    </div>
                    
                </div>
            </div>
        </div>
        </>
    )
}
