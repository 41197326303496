import React from "react";

export default function GTable(data, arr) {
    const array = arr.map((header, idx) => {
        return (
            <td
                key={new Date().getTime() + "_" + idx}
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {header}
            </td>
        );
    });

    const NoUsers = () => {
        return (
            <tr>
                <td colSpan={arr.length} className="flex justify-center items-center m-10">
                    <span className="loader"></span>
                    <span className="text-lg ml-2">Loading...</span>
                </td>
            </tr>
        );
    };

    return (
        <div className="flex flex-col">
            <div className="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-50">
                                <tr>{array}</tr>
                            </thead>
                            <tbody>{data == "" ? <NoUsers /> : data}</tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}
