import React, { useState, useEffect, useContext } from "react";
import {
  SearchIcon,
  ArrowSmDownIcon,
  ArrowSmUpIcon,
} from "@heroicons/react/solid";
import { OfficeBuildingIcon } from "@heroicons/react/outline";
import ProfileNav from "../Profile/ProfileNav";
import { api_url, getToken, refreshTokenn } from "../../services/auth";
import { useHistory } from "react-router";
import "../../styles/modal.css";
// import '../../styles/setPlanModal.css'
import Modal from "react-responsive-modal";
import { ToastContainer, toast } from "react-toastify";
import {
  DeletedApp,
  ServerError,
  AppAddedinCV,
  ErrorinCV,
} from "../NotificationToast";
import NPagination from "../Pagination/NPagination";
import {
  getUserApps,
  getUserDataRevenue,
  getUserDetails,
} from "../../services/loggedInApp";
import SetPlanModal from "../setPlanModal/SetPlanModal";
import { Context } from "../../services/context";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function User() {
  const history = useHistory();
  const context = useContext(Context);
  const [uData, setuData] = useState({});
  const [apps, setapps] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [appId, setAppID] = useState("");
  const [appUuid, setAppUuid] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [open, setOpen] = useState(false);
  const [renewPlan, setRenewPlan] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onOpenModal2 = () => setIsOpen(true);
  const onCloseModal = () => {
    setOpen(false);
  };
  const onCloseModal2 = () => {
    setIsOpen(false);
  };

  setTimeout(() => {
    if (apps == []) {
      setapps("");
    }
  }, 5000);

  const userDetail = () => {
    getUserDetails().then((res) => {
      if (res == "" || res == undefined) {
        console.log("Error Fetching User details");
      } else {
        setUserInfo(res.data);
        if (!localStorage.getItem("user_email") && res.data["email"]) {
          localStorage.setItem("user_email", res.data["email"]);
        }
      }
    });
  };

  const userDataRevenuee = () => {
    getUserDataRevenue().then((res) => {
      if (res == "" || res == undefined) {
        console.log("Error Fetching Revenue");
      } else {
        setuData(res.data);
      }
    });
  };

  const userApps = () => {
    getUserApps().then((res) => {
      if (res == "" || res == undefined) {
        console.log("Error Fetching Apps");
      } else {
        setapps(res.data.data);
        context.setApps(res.data.data);
      }
    });
  };

  useEffect(() => {
    userDetail();
    userDataRevenuee();
    userApps();
  }, []);

  function deleteApps(e, element) {
    e.preventDefault();
    const filtered = apps.filter((app) => app.id !== element);
    fetch(`https://api.trypadlock.com/loginapp?id=${element}&status=false`, {
      method: "DELETE",
      headers: {
        authorization: "Jwt " + getToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          refreshTokenn();
        } else {
          setapps(filtered);
          context.setApps(filtered);
          onCloseModal();
          DeletedApp();
        }
      })
      .catch((err) => console.log(err));
  }

  const stats = [
    {
      id: 1,
      name: "This month total revenue",
      stat:
        uData.thismonthRevune == undefined ? (
          <p className="loader"></p>
        ) : (
          uData.thismonthRevune
        ),
      change: "122",
      changeType: "increase",
    },
    {
      id: 2,
      name: "Today active users",
      stat:
        uData.activeToday == undefined ? (
          <p className="loader"></p>
        ) : (
          uData.activeToday
        ),
      change: "5.4%",
      changeType: "increase",
    },
    {
      id: 3,
      name: "Today new signups",
      stat:
        uData.createdToday == undefined ? (
          <p className="loader"></p>
        ) : (
          uData.createdToday
        ),
      change: "3.2%",
      changeType: "decrease",
    },
    {
      id: 4,
      name: "Last month total revenue",
      stat:
        uData.lastmonthRevune == undefined ? (
          <p className="loader"></p>
        ) : (
          uData.lastmonthRevune
        ),
      change: "3.2%",
      changeType: "decrease",
    },
    {
      id: 5,
      name: "Yesterday active users",
      stat:
        uData.activeYesterday == undefined ? (
          <p className="loader"></p>
        ) : (
          uData.activeYesterday
        ),
      change: "3.2%",
      changeType: "decrease",
    },
    {
      id: 6,
      name: "Yesterday new signups",
      stat:
        uData.createdYesterday == undefined ? (
          <p className="loader"></p>
        ) : (
          uData.createdYesterday
        ),
      change: "3.2%",
      changeType: "decrease",
    },
    {
      id: 7,
      name: "Weekly active users",
      stat:
        uData.activeWeekly == undefined ? (
          <p className="loader"></p>
        ) : (
          uData.activeWeekly
        ),
      change: "3.2%",
      changeType: "decrease",
    },
  ];

  const usersperPage = 10;
  const pagesVisited = pageNumber * usersperPage;
  const pageCount = Math.ceil(
    apps == undefined ? "" : apps.length / usersperPage
  );

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const handleNavigation = (e, appName, appId, app_uuid) => {
    e.preventDefault();
    history.push({
      pathname: `/${appName}`,
      state: { appName, appid: appId, appuuid: app_uuid },
    });
  };

  const NoUsers = () => {
    return (
      <tr className="flex m-10">
        <td className="loader"></td>
        <td className="text-lg ml-2">Loading...</td>
      </tr>
    );
  };

  const displayApps =
    apps == "" ? (
      <tr>
        <td colSpan={3} className="text-lg m-10">
          No Apps Found
        </td>
      </tr>
    ) : (
      apps
        .slice(pagesVisited, pagesVisited + usersperPage)
        .map((app, appidx) => {
          return (
            <tr
              key={app.id + "_apps"}
              className={appidx % 2 === 0 ? "bg-white" : "bg-gray-50"}
            >
              <td
                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer hover:underline"
                onClick={(e) =>
                  handleNavigation(e, app.name, app.id, app.app_uuid)
                }
              >
                {app.name}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {new Date(app.created).toDateString()}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {app.app_uuid}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {app.id}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <button
                  className="text-black hover:underline"
                  onClick={(e) => onOpenModal2(e, app.id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          );
        })
    );

  return (
    <>
      <ProfileNav />
      <div className="pr-20 pl-20 pt-10 pb-10 smt:p-2">
        <div className="pb-5  border-b-none border-gray-200 flex items-center justify-between">
          <div className="flex flex-row justify-center items-end align-middle text-center">
            <h3 className="text-lg leading-6 font-medium text-gray-900 mr-2 sm:mb-2">
              {userInfo.first_name} {userInfo.last_name}
            </h3>
            <h3 className="text-md font-medium bg-gray-200 text-gray-900 inline-flex items-baseline px-2.5 py-0.5 rounded-full sm:mb-2">
              Earned credits
            </h3>
          </div>
          {/* <div className="flex flex-row justify-center items-end align-middle text-center">
            <h3 className="text-md font-medium bg-black text-white inline-flex items-baseline px-2.5 py-0.5 rounded-md sm:mb-2 cursor-pointer" onClick={() => onOpenModal()}>Set Plan</h3>
          </div> */}
          {/* {
          renewPlan ?
          <SetPlanModal open={open} onCloseModal={onCloseModal} onOpenModal={onOpenModal} renew="tr" setRenewPlan={setRenewPlan} />
          :
          <SetPlanModal open={open} onCloseModal={onCloseModal} onOpenModal={onOpenModal}  />
         } */}

          {/* <div className="sm:mt-0 sm:ml-4">
          <div className="flex rounded-md shadow-sm">
            <div className="relative flex-grow focus-within:z-10">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                type="text"
                name="desktop-search-candidate"
                id="desktop-search-candidate"
                className="focus:ring-indigo-500 focus:border-indigo-500 w-full rounded-none rounded-l-md pl-10 sm:block sm:text-sm border-gray-300"
                placeholder="Search candidates"
              />
            </div>
          </div>
        </div> */}
        </div>
        {/* <div className="w-[100%] h-[30vh]  flex flex-col items-center justify-center ">
            <div className="w-9 h-9 sm:w-20 sm:h-20 rounded-full border-[1px] border-gray-400 mb-2 flex items-center justify-center">
             <OfficeBuildingIcon className=" h-[50%] w-[50%] " />
            </div>
              <h1 className="font-bold text-lg">cs64</h1>
            <div className="sm:ml-20 mt-2 flex sm:flex-row flex-col items-center  justify-center ">
              <button className="px-6 text-xs sm:text-sm mb-2 sm:mb-0 rounded-sm text-white py-1 bg-gray-600 sm:mr-4">
                Edit Organization
              </button>
              <button className="px-6 text-xs sm:text-sm rounded-sm text-white py-1 bg-gray-600 ">
               Trial Expired on May 20th 2022
              </button>
              <h1 className=" text-blue-600 font-medium text-sm underline cursor-pointer ml-2 "  onClick={() => {
                setRenewPlan(true)
                onOpenModal()
               }}>Renew</h1>
            </div>
          </div> */}
        <div>
          <dl className="mt-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-2">
            {stats.map((item) => (
              <div
                key={item.id}
                className="relative bg-white border border-gray-100 pt-5 px-4 pb-1 sm:pt-6 sm:px-6 shadow overflow-hidden"
              >
                <dt>
                  <p className="text-sm font-medium text-gray-500 truncate">
                    {item.name}
                  </p>
                </dt>
                <dd className="pb-6 flex justify-between items-baseline sm:pb-7">
                  <span className="text-2xl font-semibold text-gray-900">
                    {item.stat}
                  </span>
                  {/* <p
                    className={classNames(
                      item.changeType === 'increase' ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600',
                      'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                    )}
                  >
                    {item.changeType === 'increase' ? (
                      <ArrowSmUpIcon className="self-center flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                    ) : (
                      <ArrowSmDownIcon className="self-center flex-shrink-0 h-5 w-5 text-red-500" aria-hidden="true" />
                    )}

                    <span className="sr-only">{item.changeType === 'increase' ? 'Increased' : 'Decreased'} by</span>
                    {item.change}
                  </p> */}
                </dd>
              </div>
            ))}
          </dl>
        </div>
        <div className="flex flex-col mt-14">
          <div className="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        APP/WEBSITE
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        CREATED DATE
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        APP UUID
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        APP ID
                      </th>
                      <th scope="col" className="relative px-6 py-3">
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{apps == [] ? <NoUsers /> : displayApps}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <NPagination pageCount={pageCount} onPageChange={changePage} />

        <Modal
          open={isOpen}
          onClose={onCloseModal2}
          classNames={{ overlay: "customOverlay", modal: "customModal" }}
        >
          <p>Do you want delete it?</p>
          <div className="flex justify-center items-center mt-4">
            <button
              className="px-2 py-1 m-2 bg-gray-300 hover:bg-gray-400 text-black hover:text-white rounded-md border-b-2 border-gray-400"
              onClick={onCloseModal}
            >
              Cancel
            </button>
            <button
              className="px-2 py-1 m-2 bg-red-400 hover:bg-red-500 text-black hover:text-white rounded-md border-b-2 border-red-700"
              onClick={(e) => deleteApps(e, appId)}
            >
              Delete
            </button>
          </div>
        </Modal>
      </div>
      <ToastContainer className="mt-28" />
    </>
  );
}
