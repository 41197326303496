import React, { useState, useRef } from "react";
import Navbar from "../Navbar/Navbar";
import ProfileNav from "../Profile/ProfileNav";
import documentationData from "./documentationData";

const DocumentationPage = ({ isLoggedin }) => {
  const [expandedApi, setExpandedApi] = useState(null);
  const categoryRefs = useRef({});

  const handleExpand = (apiName) => {
    setExpandedApi(expandedApi === apiName ? null : apiName);
  };

  const handleSidebarClick = (category) => {
    categoryRefs.current[category]?.scrollIntoView({ behavior: "smooth" });
  };

  const groupByCategory = (data) => {
    return data.reduce((acc, item) => {
      if (!acc[item.category]) {
        acc[item.category] = [];
      }
      acc[item.category].push(item);
      return acc;
    }, {});
  };

  const groupedData = groupByCategory(documentationData);
  const categories = Object.keys(groupedData);

  return (
    <>
      {isLoggedin ? <ProfileNav /> : <Navbar />}

      <div className="flex ">
        <div className="w-1/5 bg-gray-200 p-4 rounded sticky top-0 h-screen overflow-y-auto">
          <h2 className="text-lg font-bold mb-4">Categories</h2>
          <ul>
            {categories.map((category) => (
              <li key={category} className="mb-1">
                <button
                  onClick={() => handleSidebarClick(category)}
                  className="text-blue-500 text-sm hover:underline"
                >
                  {category}
                </button>
              </li>
            ))}
          </ul>
        </div>

        <div className="w-4/5 p-10 bg-[#f5f5f5] rounded">
          <h1 className="mb-4 text-2xl font-bold">API Documentation</h1>
          {categories.map((category) => (
            <div
              key={category}
              ref={(el) => (categoryRefs.current[category] = el)}
              className="mb-8"
            >
              <h2 className="text-xl mb-4 font-bold">{category}</h2>
              {groupedData[category].map((api) => (
                <div key={api.name} className="mb-4">
                  <div
                    className="flex justify-between items-center bg-gray-700 text-white p-4 rounded cursor-pointer"
                    onClick={() => handleExpand(api.name)}
                  >
                    <div>
                      <div>{api.name}</div>
                      <div>
                        <span>
                          {api.method} {api.endpoint}
                        </span>
                      </div>
                    </div>

                    <button className="bg-gray-500 p-2 rounded">
                      {expandedApi === api.name ? "Collapse" : "Expand"}
                    </button>
                  </div>
                  {expandedApi === api.name && (
                    <div className="bg-gray-100 p-4 rounded mt-2">
                      <h2 className="text-xl mb-2">Path Parameters</h2>
                      <div className="mb-4">
                        {api.parameters.map((param) => (
                          <div key={param.name} className="mb-2">
                            <span className="font-bold">{param.name}</span> -{" "}
                            {param.description} ({param.type})
                          </div>
                        ))}
                      </div>

                      <h2 className="text-xl mb-2">Request Example</h2>

                      <pre className="bg-gray-200 p-4 whitespace-pre-wrap break-words rounded">
                        <code>{api.requestExample}</code>
                      </pre>

                      <h2 className="text-xl mb-2">Response Example</h2>
                      <pre className="bg-gray-200 p-2 rounded">
                        {JSON.stringify(api.responseExample, null, 2)}
                      </pre>

                      <h2 className="text-xl mb-2">Error Responses</h2>

                      <ul className="bg-gray-200 p-2 rounded">
                        {api.errorResponses.map((error, idx) => (
                          <li key={idx}>
                            <strong>{error.code}:</strong> {error.message}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DocumentationPage;
