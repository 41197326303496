import React from "react";
import ProfileNav from "../components/Profile/ProfileNav";
import RegionTable from "../components/RegionTable";
import "../styles/EditScreen.css";
import { SearchIcon } from "@heroicons/react/solid";
import { ArrowLeftIcon } from "@heroicons/react/outline";

export default function EditScreen() {
  return (
    <div>
    <div className="editScreen pb-10">
      <ProfileNav />
      <div className="m-14">
        <button className="shadow-lg border-2 border-gray-100 py-3 px-5 font-semibold inline-flex rounded">
          <ArrowLeftIcon className="w-6 h-6 mr-3" />
          Back
        </button>
        <div className="text-center">
          <h1 className="font-extrabold text-5xl mb-7">Fangate</h1>
          <h2 className="font-semibold text-3xl">Region</h2>
          <div className="flex float-right">
            <input
              type="text"
              placeholder="Search"
              className="shadow border-2 border-black px-4 py-2 w-80"
            />
            <SearchIcon className=" w-8 h-9.5 bg-black text-white border_radius_right" />
          </div>
        </div>
        <div className="mt-24 mx-16">
          <h2 className="text-2xl font-semibold mb-8">
            Select your preferred region
          </h2>
          <RegionTable />
        </div>
        <div>
          <button className="float-right mt-8 mr-20 w-60 h-10 bg-gray-400 rounded text-white font-semibold ">
            Continue
          </button>
        </div>
      </div>
    </div>
    </div>
  );
}
