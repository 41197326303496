import React, { useEffect, useState } from "react";
import { transport } from "../services/api";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { XCircle } from "heroicons-react";

const PaymentComplete = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (searchParams.get("invoicetoken")) {
            transport("stripe/payment_status", {
                method: "PUT",
                data: {
                    token: searchParams.get("invoicetoken"),
                },
            })
                .then((res) => {
                    if (res.status === 200) {
                        history.replace("/");
                    }
                })
                .catch(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, []);
    return (
        <div className="w-screen text-black h-screen flex flex-col gap-6 items-center justify-center">
            {loading ? (
                <CircularProgress size="48px" color="inherit" />
            ) : (
                <XCircle size="48px" color="red" />
            )}
            {loading ? (
                <h2 className="text-4xl">&nbsp;&nbsp;&nbsp;Processing...</h2>
            ) : (
                <h2 className="text-4xl">Something gone wrong!</h2>
            )}
        </div>
    );
};

export default PaymentComplete;
