import React,{useState} from "react";
import Popup from '../Featurespop';
import './ExpandedFeatures.css';
import { CheckIcon } from '@heroicons/react/solid';
function ExpandedFeature(){
    const [isOpen,setIsOpen]=useState(false);
    return (
        <div>
        <a href="#" onClick={()=> setIsOpen(true)}>See all features</a>
<Popup trigger={isOpen} setTrigger={setIsOpen}>
    <div className="Popcontainer">
      <div className="headingP">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Basic</div>
      <div className="newrate">$40/user/month</div>
      <div className="featuress">What you'll get</div>
 <div className="expandedFeature">
         <span>
    <CheckIcon className="inline self-center flex-shrink-0 h-5 w-5 text-green-500" />Pariatur quod similque
           </span>  &nbsp; &nbsp;
           <span>
    <CheckIcon className="inline self-center flex-shrink-0 h-5 w-5 text-green-500" />Sapiente libero dolorios modi nostrn
           </span>  &nbsp; &nbsp; 
           <span>
    <CheckIcon className="inline self-center flex-shrink-0 h-5 w-5 text-green-500" />Itaque cupiditate adipisci quibusdam
           </span>  
         </div>
         <div className="expandedFeature">
         <span>
    <CheckIcon className="inline self-center flex-shrink-0 h-5 w-5 text-green-500" />Pariatur quod similque
           </span>  &nbsp; &nbsp;
           <span>
    <CheckIcon className="inline self-center flex-shrink-0 h-5 w-5 text-green-500" />Sapiente libero dolorios modi nostrn
           </span>  &nbsp; &nbsp;
           <span>
    <CheckIcon className="inline self-center flex-shrink-0 h-5 w-5 text-green-500" />Itaque cupiditate adipisci quibusdam
           </span>
         </div>
         <div className="expandedFeature">
         <span>
    <CheckIcon className="inline self-center flex-shrink-0 h-5 w-5 text-green-500" />Pariatur quod similque
           </span>  &nbsp; &nbsp;
           <span>
    <CheckIcon className="inline self-center flex-shrink-0 h-5 w-5 text-green-500" />Sapiente libero dolorios modi nostrn
           </span> &nbsp; &nbsp; 
           <span>
    <CheckIcon className="inline self-center flex-shrink-0 h-5 w-5 text-green-500" />Itaque cupiditate adipisci quibusdam
           </span>
         </div>

         {/* <div className="expandedFeature">
         <span><img className="image" src={tick} alt="" />
        </span>
        <span className="text">Pariatur quod similque</span>&nbsp;&nbsp;&nbsp;
        <span><img className="image" src={tick} alt="" />
        </span>
        <span className="text">Sapiente libero dolorios modi nostrn</span>&nbsp;&nbsp;&nbsp;
        <span><img className="image" src={tick} alt="" />
        </span>
        <span className="text">Itaque cupiditate adipisci quibusdam</span>&nbsp;&nbsp;&nbsp;
         </div>


         <div className="expandedFeature">
         <span><img className="image" src={tick} alt="" />
        </span>
        <span className="text">Pariatur quod similque</span>&nbsp;&nbsp;&nbsp;
        <span><img className="image" src={tick} alt="" />
        </span>
        <span className="text">Sapiente libero dolorios modi nostrn</span>&nbsp;&nbsp;&nbsp;
        
         </div> */}



      </div>
   </Popup>
   </div>
   );
};
export default ExpandedFeature;