import React, { useEffect, useState } from 'react'
import Modal from 'react-responsive-modal';
import '../../styles/setPlanModal.css'
import { CheckIcon } from '@heroicons/react/solid';
import AdditionInfoModal from './AdditionInfoModal';
import SeeAllTheFeature from './SeeAllTheFeature';

export default function SetPlanModal({open, onCloseModal,onOpenModal,renew, setRenewPlan}
    ) {
    const [additionalInformation, setAdditionalInformation] = useState(false)
    const [seeFeature, setSeeFeature] = useState(false);
    const [price, setPrice] = useState()
    const [planName, setPlanName] = useState('')
    const [plansData, setPlansData] = useState([])
    const [planId, setplanId] = useState()
   



    const plans = [
        {
            id: 1,
            plan: "Basic",
            price: 12,
            feature: [
                'Pariatur quod similique',
                'Sapiente libero doloribus modi nostrum',
                'Vel ipsa esse repudiandae excepturi',
                'Itaque cupiditate adipisci quibusdam',
                'Itaque cupiditate adipisci quibusdam',
                'Itaque cupiditate adipisci quibusdam'
            ]
        },
        {
            id: 1,
            plan: "Standard",
            price: 24,
            feature: [
                'Pariatur quod similique',
                'Sapiente libero doloribus modi nostrum',
                'Vel ipsa esse repudiandae excepturi',
                'Itaque cupiditate adipisci quibusdam',
                'Itaque cupiditate adipisci quibusdam',
                'Itaque cupiditate adipisci quibusdam'
            ]
        },
        {
            id: 1,
            plan: "Premium",
            price: 36,
            feature: [
                'Pariatur quod similique',
                'Sapiente libero doloribus modi nostrum',
                'Vel ipsa esse repudiandae excepturi',
                'Itaque cupiditate adipisci quibusdam',
                'Itaque cupiditate adipisci quibusdam',
                'Itaque cupiditate adipisci quibusdam'
            ]
        },
        
    ]

    const  feature= [
        'Pariatur quod similique',
        'Sapiente libero doloribus modi nostrum',
        'Vel ipsa esse repudiandae excepturi',
        'Itaque cupiditate adipisci quibusdam',
        'Itaque cupiditate adipisci quibusdam',
        'Itaque cupiditate adipisci quibusdam'
    ]

    const showAdditionalInformation = (price,planName,id) => {
        setPrice(price)
        setPlanName(planName)
        setplanId(id)
        setAdditionalInformation(true)
        onCloseModal()
    }
    const showSeeAllFeatures= (price,planName, id) => {
        setPrice(price)
        setPlanName(planName)
        setplanId(id)
        setSeeFeature(true)
        // onCloseModal()
    }

    const getPlans = ( ) => {
        fetch("https://api.trypadlock.com/plans/?appid=2", {
            method: "GET",
            headers: {
                authorization: "Jwt " + localStorage.getItem("token"),
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data.data);
                setPlansData(data.data);
                console.log(plansData);
            })
            .catch((err) => console.log("err", err));
      };

          
      useEffect(()=>{
            getPlans()
      },[])
      return (
          <>
              <Modal
                  open={open}
                  onClose={onCloseModal}
                  center
                  classNames={{ overlay: "customOverlay", modal: "setPlanModal" }}>
                  <div>
                      <div className=" border-gray-200 sm:flex sm:items-center sm:justify-between -mt-2">
                          <div className="flex flex-row justify-center items-end align-middle text-center mr-10">
                              <h1>Select Plan</h1>
                          </div>
                          <div className=" flex flex-row justify-center items-end align-middle text-center mr-10">
                              <h3
                                  className="text-sm font-medium bg-black text-white inline-flex items-baseline px-5 py-2 rounded-md sm:mb-2 "
                                  onClick={() => onOpenModal()}>
                                  Custom Plan
                              </h3>
                          </div>
                      </div>
                      <div className="grid md:grid-cols-2 md:grid-rows-2 lg:grid-cols-3 lg:grid-rows-1 mt-4 place-items-center gap-x-4">
                          {plansData?.map((plans, index) => (
                              <div
                                  key={"plans_" + index}
                                  class=" w-[100%] rounded overflow-hidden shadow-lg m-4">
                                  <div className="flex items-center justify-between flex-col sm:flex-row pl-6 pr-8">
                                      <h1 className="font-bold text-lg">{plans.name}</h1>
                                      <div>
                                          <h1 className="font-semibold inline-block text-sm">
                                              ${plans.price}
                                              <h6 className="text-xs inline sm:hidden">
                                                  /user/permonth
                                              </h6>
                                          </h1>
                                          <span className="text-xs hidden sm:inline-block">
                                              /user/permonth
                                          </span>
                                      </div>
                                  </div>
                                  <div className="grid place-items-center">
                                      <button
                                          className="bg-[#10B981] sm:px-10 py-2 rounded-lg w-[90%] text-white  font-semibold outline-none"
                                          onClick={() =>
                                              showAdditionalInformation(
                                                  plans.price,
                                                  plans.name,
                                                  plans.id
                                              )
                                          }>
                                          Choose Plan
                                      </button>
                                  </div>
                                  <div className="px-2 sm:px-6 py-4">
                                      <div className=" text-sm mb-2">Features</div>
                                      {plans.features.length > 0
                                          ? plans.features?.map((feature, idx) => (
                                                <p
                                                    key={feature + "_" + idx}
                                                    class="text-gray-900 text-xs font-bold mt-3 ">
                                                    <CheckIcon className="inline self-center flex-shrink-0 h-5 w-5 text-green-500" />
                                                    {feature.description}
                                                </p>
                                            ))
                                          : feature.map((feature, idx) => (
                                                <p
                                                    key={feature + "_" + idx}
                                                    class="text-gray-900 text-xs font-bold mt-3 ">
                                                    <CheckIcon className="inline self-center flex-shrink-0 h-5 w-5 text-green-500" />
                                                    {feature}
                                                </p>
                                            ))}
                                  </div>
                                  <h1
                                      className="text-center text-blue-600 text-sm underline cursor-pointer mb-2"
                                      onClick={() =>
                                          showSeeAllFeatures(plans.price, plans.name, plans.id)
                                      }>
                                      See all features
                                  </h1>
                              </div>
                          ))}
                      </div>
                  </div>
              </Modal>
              {additionalInformation && (
                  <AdditionInfoModal
                      setAdditionalInformation={setAdditionalInformation}
                      price={price}
                      planName={planName}
                      renewed={renew}
                      setRenewPlan={setRenewPlan}
                      id={planId}
                  />
              )}
              {seeFeature && (
                  <SeeAllTheFeature
                      setSeeFeature={setSeeFeature}
                      sendTopay="false"
                      price={price}
                      planName={planName}
                      id={planId}
                  />
              )}
          </>
      );
}
