import { CashIcon } from "@heroicons/react/solid";
import { User } from "heroicons-react";
import React from "react";
import { FaWpforms } from "react-icons/fa";

function ReferralSteps() {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-3 my-8">
      <div className="p-6">
        <User className="mx-auto w-16 h-16 p-4 rounded-full bg-blue-100 shadow-lg" />
        <h1 className="text-3xl text-center font-bold my-5">Install</h1>
        <h3 className="text-center text-lg font-light my-2 text-gray-600">
          Install padlock app
        </h3>
      </div>
      <div className="p-6">
        <FaWpforms className="mx-auto w-16 h-16 p-4 rounded-full bg-blue-100 shadow-lg" />
        <h1 className="text-3xl text-center font-bold my-5">Register</h1>
        <h3 className="text-center text-lg font-light my-2 text-gray-600">
          Register into the app if you are new user
        </h3>
      </div>
      <div className="p-6">
        <CashIcon className="mx-auto w-16 h-16 p-4 rounded-full bg-blue-100 shadow-lg" />
        <h1 className="text-3xl text-center font-bold my-5">
          Choose different plan
        </h1>
        <h3 className="text-center text-lg font-light my-2 text-gray-600">
          You can earn credits by choosing different credit deals
        </h3>
      </div>
    </div>
  );
}

export default ReferralSteps;
