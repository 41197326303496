import React from "react";
import ProfileNav from "../components/Profile/ProfileNav";
import NotificationCard from "../components/Notifications/NotificationCard";

function NotificationScreen() {
  const notifications = [
    { title: "You have earned credits for referring Venkatesh", price: "$15" },
    { title: "You have earned credits for referring Basavaraju", price: "$15" },
    {
      title: "You have earned credits for referring Shravan Kumar",
      price: "$15",
    },
    { title: "You have earned credits for referring Aravand", price: "$15" },
  ];
  return (
    <div className="notificationScreen">
      <ProfileNav />
      <div>
        <h1 className="text-5xl text-center font-extrabold my-9">
          Notifications
        </h1>
        <div className="mx-auto max-w-4xl my-10">
          {notifications.map((notification) => {
            return (
              <NotificationCard
                title={notification.title}
                price={notification.price}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default NotificationScreen;
