import React, { useState } from "react";
import ProfileNav from "../components/Profile/ProfileNav";
import SettingSlider from "../components/Settings/SettingSlider";
import SettingBody from "../components/Settings/SettingBody";

function SettingsScreen() {
  const [active, setActive] = useState(0);
  return (
    <div>
      <ProfileNav />
      <div className="mx-auto my-10">
        <h1 className="text-5xl font-extrabold text-center">Settings</h1>
        <SettingSlider active={active} setActive={setActive} />
        <SettingBody active={active} setActive={setActive} />
      </div>
    </div>
  );
}

export default SettingsScreen;
