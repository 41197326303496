import React, { useContext } from "react";
import { useState } from "react";
import PlansBody from "../components/Plans/PlansBody";
import PlansSlider from "../components/Plans/PlansSlider";
import ProfileNav from "../components/Profile/ProfileNav";
import { useHistory, useLocation } from "react-router";
import Modal from "react-responsive-modal";
import { useEffect } from "react";
import { getUserApps } from "../services/loggedInApp";
import { Context } from "../services/context";

function PlansAndPayments(props) {
    const [active, setActive] = useState(0);
    let location = useLocation();
    const [locState, setLocState] = useState(location.state ?? {});
    const history = useHistory();
    const context = useContext(Context);
    useEffect(() => {
        const lState = localStorage.getItem("loc");
        if (!locState && !lState) {
            history.replace("/apps");
        } else {
            if (!locState) {
                location.state = JSON.parse(lState);
                setLocState(location.state);
            }
            if (location.pathname === `/${locState.appName}/payment-history`) {
                setActive(1);
            }
        }
        getUserApps().then((res) => {
            if (res == "" || res == undefined) {
                console.log("Error Fetching Apps");
            } else {
                context.setApps(res.data.data);
            }
        });
    }, []);
    return (
        <div>
            <ProfileNav />
            <div className="mx-auto max-w-[100vw] lg:max-w-4xl my-10">
                <div className="flex justify-between items-center mb-12">
                    <button
                        className="bg-gray-800 text-white rounded-md p-1 px-4"
                        onClick={() => {
                            history.goBack();
                        }}>
                        Back
                    </button>
                    <p className="text-center text-4xl font-semibold mr-10">{locState?.appName}</p>
                    <div></div>
                </div>
                <h1 className="text-center font-extrabold text-2xl mb-4">Plan & Payment</h1>
                <div className="flex justify-end">
                    <PlansSlider active={active} history={history} setActive={setActive} />
                </div>
                {locState?.appId && <PlansBody active={active} appId={locState?.appId} />}
            </div>
        </div>
    );
}

export default PlansAndPayments;
