const documentationData = [
  //workahub login
  {
    name: "Login to Workahub",
    method: "POST",
    category: "workahub login",
    endpoint: "/users/login",
    parameters: [
      {
        name: "email",
        description: "The user's email address.",
        type: "string",
      },
      {
        name: "org_name",
        description: "The organization name.",
        type: "string",
      },
      {
        name: "password",
        description: "The user's password.",
        type: "string",
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/login\' -H \'Content-Type: application/json\' -d \'{"email": "user@example.com", "password": "userpassword", "org_name": "organization"}\'',

    responseExample: {
      access_token: "string",
    },
    errorResponses: [
      { code: 400, message: "Invalid credentials found" },
      { code: 400, message: "User is not active" },
      { code: 400, message: "Please verify your email" },
      { code: 400, message: "User not found for organization" },
      { code: 400, message: "Application does not exist" },
    ],
  },
  {
    name: "Login to Workahub Client",
    method: "POST",
    category: "workahub login",
    endpoint: "/users/login_client",
    parameters: [
      {
        name: "username",
        description: "The user's email address.",
        type: "string",
      },
      {
        name: "organization",
        description: "The organization name.",
        type: "string",
      },
      {
        name: "password",
        description: "The user's password.",
        type: "string",
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/login_client\' -H \'Content-Type: application/json\' -d \'{"username": "user@example.com", "password": "userpassword", "organization": "organization"}\'',

    responseExample: {
      user_roles: "array of roles",
      user_id: "string",
      username: "string",
    },
    errorResponses: [
      { code: 400, message: "Invalid credentials found" },
      { code: 400, message: "User is not active" },
      { code: 400, message: "User details not found" },
      { code: 400, message: "User is not associated with the app" },
      { code: 400, message: "User or organization is not found" },
      { code: 400, message: "Application is not found" },
    ],
  },
  {
    name: "Login to Workahub Mobile",
    method: "POST",
    category: "workahub login",
    endpoint: "/users/login_mobile",
    parameters: [
      {
        name: "email",
        description: "The user's email address.",
        type: "string",
      },
      {
        name: "org_name",
        description: "The organization name.",
        type: "string",
      },
      {
        name: "password",
        description: "The user's password.",
        type: "string",
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/login_mobile\' -H \'Content-Type: application/json\' -d \'{"email": "user@example.com", "password": "userpassword", "org_name": "organization"}\'',

    responseExample: {
      access_token: "string",
    },
    errorResponses: [
      { code: 400, message: "Invalid credentials found" },
      { code: 400, message: "User is not active" },
      { code: 400, message: "Please verify your email" },
      { code: 400, message: "User not found with this app" },
      { code: 400, message: "User not found for organization" },
      { code: 400, message: "Application does not exist" },
    ],
  },
  //loginapp
  {
    name: " loginapp Create Application",
    method: "POST",
    category: "loginapp",
    endpoint: "/loginapp/",
    parameters: [
      {
        name: "name",
        description: "The name of the application.",
        type: "string",
      },
      {
        name: "redirect_url",
        description: "The URL to redirect to, if applicable.",
        type: "string",
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/loginapp/' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN' -H 'Content-Type: application/json' -d '{\"name\": \"exampleApp\", \"redirect_url\": \"https://example.com\"}'",

    responseExample: {
      Status: "data saved",
    },
    errorResponses: [
      { code: 400, message: "Please enter a valid URL" },
      { code: 404, message: "Application already found" },
    ],
  },
  {
    name: "Get User Applications",
    method: "GET",
    category: "loginapp",
    endpoint: "/loginapp/",
    parameters: [],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/loginapp/' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",

    responseExample: {
      data: [
        {
          id: "string",
          name: "string",
          userid: "string",
          status: true,
          app_data: {
            redirect_url: "string",
          },
          app_uuid: "string",
        },
      ],
    },
    errorResponses: [{ code: 404, message: "Applications not found" }],
  },
  {
    name: "Update Application Status",
    method: "DELETE",
    category: "loginapp",
    endpoint: "/loginapp/",
    parameters: [
      {
        name: "id",
        description: "The ID of the application to update.",
        type: "string",
      },
      {
        name: "status",
        description: "The new status of the application.",
        type: "boolean",
      },
    ],
    requestExample:
      "curl -X DELETE 'https://api.trypadlock.com/loginapp/' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN' -H 'Content-Type: application/json' -d '{\"id\": \"application_id\", \"status\": true}'",

    responseExample: {
      data: "data updated successfully",
    },
    errorResponses: [{ code: 404, message: "Applications not found" }],
  },
  {
    name: "Get User List for Applications",
    method: "GET",
    category: "loginapp",
    endpoint: "/loginapp/userlist",
    parameters: [],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/loginapp/userlist' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",

    responseExample: {
      data: [
        {
          user_id: "string",
          username: "string",
          email: "string",
          first_name: "string",
          last_name: "string",
        },
      ],
    },
    errorResponses: [{ code: 404, message: "Applications not found" }],
  },
  {
    name: "Get Application Users",
    method: "GET",
    category: "loginapp",
    endpoint: "/loginapp/applist",
    parameters: [
      {
        name: "appid",
        description: "The ID of the application to retrieve users for.",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/loginapp/applist?appid=12345' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",

    responseExample: [
      {
        user_id: "string",
        username: "string",
        email: "string",
        first_name: "string",
        last_name: "string",
      },
    ],
    errorResponses: [{ code: 404, message: "applications not found" }],
  },
  {
    name: "loginapp Update User Profile",
    method: "PUT",
    category: "loginapp",
    endpoint: "/loginapp/profile",
    parameters: [
      {
        name: "first_name",
        description: "The first name of the user.",
        type: "string",
        required: false,
      },
      {
        name: "last_name",
        description: "The last name of the user.",
        type: "string",
        required: false,
      },
      {
        name: "password",
        description: "The new password for the user.",
        type: "string",
        required: false,
      },
      {
        name: "avatar",
        description: "The avatar image file to be uploaded.",
        type: "file",
        required: false,
      },
    ],
    requestExample:
      "curl -X PUT 'https://api.trypadlock.com/loginapp/profile' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN' -H 'Content-Type: application/json' -d '{\"first_name\": \"NewFirstName\", \"last_name\": \"NewLastName\"}'",

    responseExample: {
      Status: "data updated successfully",
    },
    errorResponses: [{ code: 404, message: "user does not exist" }],
  },
  {
    name: "Get loginApp User Profile",
    method: "GET",
    category: "loginapp",
    endpoint: "/loginapp/profile",
    parameters: [],
    requestExample: ``,
    responseExample: {
      email: "user@example.com",
      username: "username",
      first_name: "John",
      last_name: "Doe",
      url: "avatar.jpg",
    },
    errorResponses: [{ code: 404, message: "user does not exist" }],
  },
  {
    name: "Get Price List",
    method: "GET",
    category: "loginapp",
    endpoint: "/loginapp/pricelist",
    parameters: [
      {
        name: "appid",
        description: "The application ID for which to fetch the price list.",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/loginapp/profile' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",

    responseExample: {
      data: [
        {
          id: 1,
          name: "Plan A",
          region: "US",
          regionId: "us-east-1",
          currency: "USD",
          price: 99.99,
          info: "Basic Plan",
        },
        {
          id: 2,
          name: "Plan B",
          region: "EU",
          regionId: "eu-west-1",
          currency: "EUR",
          price: 79.99,
          info: "Standard Plan",
        },
      ],
    },
    errorResponses: [{ code: 404, message: "No price list found" }],
  },
  {
    name: "loginApp Get Organization List",
    method: "GET",
    category: "loginapp",
    endpoint: "/loginapp/orgList",
    parameters: [
      {
        name: "appid",
        description:
          "The application ID for which to fetch the organization list.",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/loginapp/orgList?appid=application_id' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",

    responseExample: {
      data: [
        {
          id: 1,
          name: "Org A",
          org_uuid: "org-uuid-123",
          fixed: true,
          count: 25,
          url: "file.jpg",
          created: "2024-07-01T12:00:00Z",
          status: true,
        },
        {
          id: 2,
          name: "Org B",
          org_uuid: "org-uuid-456",
          fixed: false,
          count: 10,
          url: "None",
          created: "2024-07-02T15:30:00Z",
          status: true,
        },
      ],
    },
    errorResponses: [{ code: 404, message: "No organizations found" }],
  },
  {
    name: "Get Organization User List",
    method: "GET",
    category: "loginapp",
    endpoint: "/loginapp/orgUserList",
    parameters: [
      {
        name: "appid",
        description:
          "The application ID for which to fetch the organization user list.",
        type: "string",
        required: true,
      },
      {
        name: "orgid",
        description: "The organization ID for which to fetch the user list.",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/loginapp/orgUserList?appid=application_id&orgid=organization_id' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",

    responseExample: {
      data: [
        {
          user_id: "user-uuid-123",
          username: "johndoe",
          email: "johndoe@example.com",
          first_name: "John",
          last_name: "Doe",
          last_login: "2024-07-10T09:00:00Z",
          created: "2024-01-15T08:45:00Z",
          roles: ["admin", "user"],
        },
        {
          user_id: "user-uuid-456",
          username: "janedoe",
          email: "janedoe@example.com",
          first_name: "Jane",
          last_name: "Doe",
          last_login: "2024-07-12T10:30:00Z",
          created: "2024-02-20T11:00:00Z",
          roles: ["user"],
        },
      ],
    },
    errorResponses: [{ code: 400, message: "Organization not found" }],
  },
  {
    name: "Delete User",
    method: "DELETE",
    category: "loginapp",
    endpoint: "/loginapp/delete_user/:id",
    parameters: [
      {
        name: "id",
        description: "The unique identifier (UUID) of the user to be deleted.",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      "curl -X DELETE 'https://api.trypadlock.com/loginapp/delete_user/user-uuid-123' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",

    responseExample: {
      Status: "succefully deleted the user",
    },
    errorResponses: [
      { code: 404, message: "User not found" },
      { code: 400, message: "User already deleted" },
    ],
  },
  {
    name: "Get Organization Owner Details",
    method: "GET",
    category: "loginapp",
    endpoint: "/loginapp/owner_org",
    parameters: [
      {
        name: "org_uuid",
        description: "The unique identifier (UUID) of the organization.",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/loginapp/owner_org?org_uuid=org-uuid-123' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",

    responseExample: {
      users: {
        id: 1,
        email: "user@example.com",
        username: "username",
        created: "2024-07-26T12:00:00Z",
      },
      org_created_date: "2024-07-26T12:00:00Z",
    },
    errorResponses: [{ code: 404, message: "Organization not found" }],
  },
  {
    name: "Get User Data for Application",
    method: "GET",
    category: "loginapp",
    endpoint: "/loginapp/userdata-app",
    parameters: [
      {
        name: "appid",
        description: "The unique identifier (ID) of the application.",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/loginapp/userdata-app?appid=application_id' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",

    responseExample: {
      thismonthRevune: 1500,
      lastmonthRevune: 1200,
      activeToday: 25,
      activeYesterday: 20,
      activeWeekly: 100,
      lastWeek: 30,
      thismonth: 60,
      lastmonth: 45,
      lasttwomonth: 80,
      thisyear: 500,
      lastyear: 450,
      createdToday: 5,
      createdYesterday: 3,
      createdWeekly: 20,
    },
    errorResponses: [
      { code: 400, message: "Invalid or missing application ID" },
      { code: 404, message: "No data found for the specified application" },
    ],
  },
  {
    name: "loginApp User Login",
    method: "POST",
    category: "loginapp",
    endpoint: "/loginapp/login",
    parameters: [
      {
        name: "email",
        description:
          "The email address of the user, which is case-insensitive.",
        type: "string",
        required: true,
      },
      {
        name: "password",
        description: "The password of the user.",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/loginapp/login\' -H \'Content-Type: application/json\' -d \'{"email": "user@example.com", "password": "userpassword"}\'',

    responseExample: {
      token: " token",
      refresh_token: "refresh token",
    },
    errorResponses: [
      { code: 400, message: "Invalid credentials" },
      { code: 404, message: "Email does not exist" },
    ],
  },
  {
    name: "Retention Analytics",
    method: "GET",
    category: "loginapp",
    endpoint: "/loginapp/retentionanalytics",
    parameters: [
      {
        name: "interval",
        description:
          "The time interval for which to retrieve retention data. Possible values are 'day', 'week', and 'month'.",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/loginapp/retentionanalytics?interval=week' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",

    responseExample: {
      users_count: [5, 10, 7, 8, 6, 12, 15],
    },
    errorResponses: [
      { code: 400, message: "Invalid interval parameter" },
      { code: 401, message: "Unauthorized access" },
    ],
  },
  {
    name: "Organization Analytics",
    method: "GET",
    category: "loginapp",
    endpoint: "/loginapp/organalytics",
    parameters: [
      {
        name: "org_uuid",
        description:
          "The UUID of the organization for which to retrieve user analytics.",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/loginapp/organalytics?org_uuid=org-uuid-123' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",

    responseExample: {
      months: [7, 6, 5, 4],
      users: [20, 15, 10, 5],
    },
    errorResponses: [
      { code: 400, message: "Invalid organization UUID" },
      { code: 401, message: "Unauthorized access" },
      { code: 404, message: "Organization not found" },
    ],
  },
  {
    name: "Organization Data",
    method: "GET",
    category: "loginapp",
    endpoint: "/loginapp/orgdata",
    parameters: [
      {
        name: "org_uuid",
        description: "The UUID of the organization for which to retrieve data.",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/loginapp/orgdata?org_uuid=org-uuid-123' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",

    responseExample: {
      thismonthRevune: 1000,
      lastmonthRevune: 1200,
      activeToday: 50,
      activeYesterday: 45,
      activeWeekly: 300,
      lastWeek: 200,
      thismonth: 800,
      lastmonth: 600,
      lasttwomonth: 500,
      thisyear: 4000,
      lastyear: 3500,
      createdToday: 10,
      createdYesterday: 5,
      createdWeekly: 70,
    },
    errorResponses: [
      { code: 400, message: "Invalid organization UUID" },
      { code: 401, message: "Unauthorized access" },
      { code: 404, message: "Organization not found" },
    ],
  },
  {
    name: "User Analytics",
    method: "GET",
    category: "loginapp",
    endpoint: "/loginapp/useranalytics",
    parameters: [],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/loginapp/useranalytics' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",

    responseExample: {
      months: [6, 5, 4, 3],
      users: [150, 120, 100, 80],
    },
    errorResponses: [
      { code: 401, message: "Unauthorized access" },
      { code: 500, message: "Internal server error" },
    ],
  },
  {
    name: "User Data",
    method: "GET",
    category: "loginapp",
    endpoint: "/loginapp/userdata",
    parameters: [],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/loginapp/userdata' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",

    responseExample: {
      thismonthRevune: 5000,
      lastmonthRevune: 4500,
      activeToday: 25,
      activeYesterday: 20,
      activeWeekly: 150,
      lastWeek: 80,
      thismonth: 200,
      lastmonth: 180,
      lasttwomonth: 160,
      thisyear: 2500,
      lastyear: 2400,
      createdToday: 10,
      createdYesterday: 8,
      createdWeekly: 50,
    },
    errorResponses: [
      { code: 401, message: "Unauthorized access" },
      { code: 500, message: "Internal server error" },
    ],
  },
  {
    name: "User Signup",
    method: "POST",
    category: "loginapp",
    endpoint: "/loginapp/signup",
    parameters: [
      {
        name: "username",
        type: "body",
        description: "Username for the new user.",
        required: true,
      },
      {
        name: "email",
        type: "body",
        description: "Email address for the new user.",
        required: true,
      },
      {
        name: "password",
        type: "body",
        description: "Password for the new user.",
        required: true,
      },
      {
        name: "appname",
        type: "body",
        description: "Name of the application to associate with the user.",
        required: true,
      },
      {
        name: "first_name",
        type: "body",
        description: "First name of the user.",
        required: false,
      },
      {
        name: "last_name",
        type: "body",
        description: "Last name of the user.",
        required: false,
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/loginapp/signup\' -H \'Content-Type: application/json\' -d \'{"username": "newuser", "email": "newuser@example.com", "password": "password123", "appname": "myapp", "first_name": "John", "last_name": "Doe"\'}\'',
    responseExample: {
      token: "your_access_token_here",
      refresh_token: "your_refresh_token_here",
    },
    errorResponses: [
      {
        code: 400,
        message: "user already exists",
      },
      {
        code: 404,
        message: "application not found",
      },
    ],
  },
  //workahub_profile
  {
    name: "Get User Profile Image",
    method: "GET",
    category: "workahub profile",
    endpoint: "/workahub_profile/users",
    parameters: [],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/workahub_profile/users' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",
    responseExample: {
      url: "https://padlockstorage.s3.amazonaws.com/path/to/image.jpg",
    },
    errorResponses: [
      { code: 400, message: "user data doesnot exists", url: "" },
      { code: 404, message: "user doesnot exists", url: "" },
    ],
  },
  {
    name: "Get Admin Profile Image",
    method: "GET",
    category: "workahub profile",
    endpoint: "/workahub_profile/admins",
    parameters: [
      {
        name: "user_uuid",
        description:
          "The UUID of the user whose profile image is to be retrieved.",
        type: "string",
        required: true,
        in: "query",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/workahub_profile/admins?user_uuid=exampleUserUUID' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",
    responseExample: {
      url: "https://padlockstorage.s3.amazonaws.com/path/to/image.jpg",
    },
    errorResponses: [
      { code: 200, message: "user data doesnot exists" },
      { code: 200, message: "user is not a admin" },
      { code: 200, message: "userss doesnot exists" },
    ],
  },
  {
    name: "Upload User Profile Image",
    method: "POST",
    category: "workahub profile",
    endpoint: "/workahub_profile/",
    parameters: [
      {
        name: "avatar",
        description: "The avatar image file to be uploaded.",
        type: "file",
        required: true,
        in: "formData",
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/workahub_profile/' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN' -F 'avatar=@path/to/avatar.jpg'",
    responseExample: {
      Status: "data updated succesfully",
    },
    errorResponses: [
      { code: 404, message: "file doesn't exists" },
      { code: 404, message: "user doesnot exists" },
    ],
  },
  //app
  {
    name: "Update or Add App Data",
    method: "POST",
    category: " app route",
    endpoint: "/app/app_data",
    parameters: [
      {
        name: "app_uuid",
        description: "The UUID of the application.",
        type: "string",
        required: true,
        in: "body",
      },
      {
        name: "name",
        description: "The name of the data to be added or updated.",
        type: "string",
        required: true,
        in: "body",
      },
      {
        name: "main_key",
        description: "The main key of the data to be added or updated.",
        type: "string",
        required: true,
        in: "body",
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/app/app_data\' -H \'Authorization: Bearer YOUR_ACCESS_TOKEN\' -H \'Content-Type: application/json\' -d \'{"app_uuid": "exampleAppUUID", "name": "exampleName", "main_key": "exampleKey"}\'',
    responseExample: {
      status: "app got updated with the value and key",
    },
    errorResponses: [],
  },
  {
    name: "Delete App Data",
    method: "DELETE",
    category: " app route",
    endpoint: "/app/app_data",
    parameters: [
      {
        name: "app_uuid",
        description: "The UUID of the application.",
        type: "string",
        required: true,
        in: "body",
      },
      {
        name: "name",
        description: "The name of the data to be deleted.",
        type: "string",
        required: true,
        in: "body",
      },
    ],
    requestExample:
      "curl -X DELETE 'https://api.trypadlock.com/app/app_data' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN' -H 'Content-Type: application/json' -d '{\"app_uuid\": \"exampleAppUUID\", \"name\": \"exampleName\"}'",
    responseExample: {
      status: "key and value of app got deleted",
    },
    errorResponses: [{ code: 404, message: "key and value not found" }],
  },
  {
    name: "Get App Data",
    method: "GET",
    category: " app route",
    endpoint: "/app/app_data",
    parameters: [
      {
        name: "app_uuid",
        description: "The UUID of the application.",
        type: "string",
        required: true,
        in: "query",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/app/app_data?app_uuid=exampleAppUUID' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",
    responseExample: {
      status: {
        exampleKey1: "exampleValue1",
        exampleKey2: "exampleValue2",
      },
    },
    errorResponses: [{ code: 200, message: "key and value not found" }],
  },
  //screenshot
  {
    name: "Get Screenshot Timing",
    method: "GET",
    category: "screenshot ",
    endpoint: "/screenshot",
    parameters: [
      {
        name: "user_uuid",
        description: "The UUID of the user.",
        type: "string",
        required: false,
        in: "query",
      },
      {
        name: "team_uuid",
        description: "The UUID of the team.",
        type: "string",
        required: false,
        in: "query",
      },
      {
        name: "type",
        description: "The type of screenshot (User or Team).",
        type: "string",
        required: true,
        in: "query",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/screenshot?user_uuid=exampleUserUUID&team_uuid=exampleTeamUUID&type=User' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",
    responseExample: {
      data: "10",
      status: false,
    },
    errorResponses: [],
  },
  {
    name: "Save Screenshot Timing",
    method: "POST",
    category: "screenshot ",
    endpoint: "/screenshot",
    parameters: [
      {
        name: "time",
        description: "The screenshot time.",
        type: "string",
        required: true,
        in: "body",
      },
      {
        name: "user_uuid",
        description: "The UUID of the user.",
        type: "string",
        required: false,
        in: "body",
      },
      {
        name: "team_uuid",
        description: "The UUID of the team.",
        type: "string",
        required: false,
        in: "body",
      },
      {
        name: "type",
        description: "The type of screenshot (User, Team, Org).",
        type: "string",
        required: true,
        in: "body",
      },
      {
        name: "feature_id",
        description: "The ID of the feature.",
        type: "string",
        required: true,
        in: "body",
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/screenshot\' -H \'Authorization: Bearer YOUR_ACCESS_TOKEN\' -H \'Content-Type: application/json\' -d \'{"time": "10", "user_uuid": "exampleUserUUID", "team_uuid": "exampleTeamUUID", "type": "User", "feature_id": "exampleFeatureID"}\'',
    responseExample: {
      status: "data saved succesfully",
    },
    errorResponses: [{ code: 200, message: "data already exists" }],
  },
  {
    name: "Update Screenshot Timing",
    method: "PATCH",
    category: "screenshot ",
    endpoint: "/screenshot",
    parameters: [
      {
        name: "time",
        description: "The screenshot time to be updated.",
        type: "string",
        required: true,
        in: "query",
      },
      {
        name: "id",
        description: "The ID of the screenshot timing.",
        type: "string",
        required: true,
        in: "query",
      },
    ],
    requestExample:
      "curl -X PATCH 'https://api.trypadlock.com/screenshot?time=15&id=exampleID' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",
    responseExample: {
      status: "data updated succesfully",
    },
    errorResponses: [{ code: 200, message: "data not found" }],
  },
  {
    name: "Get Screenshot Device Timing",
    method: "GET",
    category: "screenshot ",
    endpoint: "/screenshot/device",
    parameters: [
      {
        name: "team_uuid",
        description: "The UUID of the team.",
        type: "string",
        required: true,
        in: "query",
      },
      {
        name: "user_uuid",
        description: "The UUID of the user.",
        type: "string",
        required: true,
        in: "query",
      },
      {
        name: "org_uuid",
        description: "The UUID of the organization.",
        type: "string",
        required: true,
        in: "query",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/screenshot/device?team_uuid=exampleTeamUUID&user_uuid=exampleUserUUID&org_uuid=exampleOrgUUID'",
    responseExample: {
      info: [
        {
          type_feature: "User",
          team_uuid: "exampleTeamUUID",
          org_uuid: "exampleOrgUUID",
          user_uuid: "exampleUserUUID",
          screenshotsTime: "10",
        },
        {
          type_feature: "Org",
          team_uuid: "exampleTeamUUID",
          org_uuid: "exampleOrgUUID",
          user_uuid: "exampleUserUUID",
          screenshotsTime: "10",
        },
      ],
    },
    errorResponses: [],
  },
  //org
  {
    name: "Update Organization",
    method: "PUT",
    category: "organisation ",
    endpoint: "/org/",
    parameters: [
      {
        name: "org_uuid",
        description: "The UUID of the organization.",
        type: "string",
        required: true,
        in: "body",
      },
      {
        name: "name",
        description: "The new name of the organization (optional).",
        type: "string",
        required: false,
        in: "body",
      },
      {
        name: "fixed",
        description: "The fixed status of the organization (optional).",
        type: "boolean",
        required: false,
        in: "body",
      },
      {
        name: "appid",
        description: "The new app ID of the organization (optional).",
        type: "string",
        required: false,
        in: "body",
      },
      {
        name: "status",
        description: "The new status of the organization (optional).",
        type: "boolean",
        required: false,
        in: "body",
      },
    ],
    requestExample:
      'curl -X PUT \'https://api.trypadlock.com/org/\' -H \'Authorization: Bearer YOUR_ACCESS_TOKEN\' -H \'Content-Type: application/json\' -d \'{"org_uuid": "exampleOrgUUID", "name": "New Organization Name", "fixed": true, "appid": "newAppID", "status": false}\'',
    responseExample: {
      status: "data updated successfully",
    },
    errorResponses: [{ code: 404, message: "no data found" }],
  },
  {
    name: "Get Organization Retentions",
    method: "GET",
    category: "organisation ",
    endpoint: "/org/org_retentions",
    parameters: [
      {
        name: "offset",
        description: "The offset for pagination.",
        type: "number",
        required: true,
        in: "query",
      },
      {
        name: "appid",
        description: "The app ID to filter organizations (optional).",
        type: "number",
        required: false,
        in: "query",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/org/org_retentions?offset=0&appid=2' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",
    responseExample: {
      orgs: [
        {
          id: 1,
          name: "Organization Name",
          org_uuid: "exampleOrgUUID",
          status: true,
          created_at: "2024-07-26T00:00:00.000Z",
          subscription: "Basic",
          users_count: 10,
          last_login: "2024-07-25T00:00:00.000Z",
        },
      ],
    },
    errorResponses: [{ code: 400, message: "please pass the offset" }],
  },
  {
    name: "Create Organization",
    method: "POST",
    category: "organisation ",
    endpoint: "/org",
    parameters: [
      {
        name: "name",
        description: "The name of the organization.",
        type: "string",
        required: true,
        in: "body",
      },
      {
        name: "appid",
        description: "The app ID of the organization.",
        type: "number",
        required: true,
        in: "body",
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/org' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN' -H 'Content-Type: application/json' -d '{\"name\": \"New Organization\", \"appid\": 2}'",
    responseExample: {
      status: "org got created",
    },
    errorResponses: [
      { code: 400, message: "org cannot have puncations" },
      { code: 400, message: "org cannot have spaces" },
      { code: 400, message: "org already exists" },
    ],
  },
  {
    name: "Upload Organization Icon",
    method: "POST",
    category: "organisation ",
    endpoint: "/org/orgIcon",
    parameters: [
      {
        name: "org_uuid",
        description: "The UUID of the organization.",
        type: "string",
        required: true,
        in: "body",
      },
      {
        name: "org_avatar",
        description: "The avatar image file of the organization.",
        type: "file",
        required: true,
        in: "form-data",
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/org/orgIcon' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN' -F 'org_uuid=exampleOrgUUID' -F 'org_avatar=@path/to/image.png'",
    responseExample: {
      status: "picture has been uploaded",
    },
    errorResponses: [{ code: 400, message: "no picture found" }],
  },
  {
    name: "Add or Update Organization Data",
    method: "POST",
    category: "organisation ",
    endpoint: "/org/org_data",
    parameters: [
      {
        name: "org_uuid",
        description: "The UUID of the organization.",
        type: "string",
        required: true,
        in: "body",
      },
      {
        name: "name",
        description: "The name of the data field to add or update.",
        type: "string",
        required: true,
        in: "body",
      },
      {
        name: "main_key",
        description: "The value of the data field to add or update.",
        type: "string",
        required: true,
        in: "body",
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/org/org_data\' -H \'Authorization: Bearer YOUR_ACCESS_TOKEN\' -H \'Content-Type: application/json\' -d \'{"org_uuid": "exampleOrgUUID", "name": "exampleKey", "main_key": "exampleValue"}\'',
    responseExample: {
      status: "org got added with the value and key",
    },
    errorResponses: [{ code: 404, message: "organization not found" }],
  },
  {
    name: "Delete Organization Data",
    method: "DELETE",
    category: "organisation ",
    endpoint: "/org/org_data",
    parameters: [
      {
        name: "org_uuid",
        description: "The UUID of the organization.",
        type: "string",
        required: true,
        in: "body",
      },
      {
        name: "name",
        description: "The name of the data field to delete.",
        type: "string",
        required: true,
        in: "body",
      },
    ],
    requestExample:
      "curl -X DELETE 'https://api.trypadlock.com/org/org_data' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN' -H 'Content-Type: application/json' -d '{\"org_uuid\": \"exampleOrgUUID\", \"name\": \"exampleKey\"}'",
    responseExample: {
      status: "org value and key got deleted",
    },
    errorResponses: [{ code: 404, message: "organization not found" }],
  },
  {
    name: "Get Organization Data",
    method: "GET",
    category: "organisation ",
    endpoint: "/org/org_data",
    parameters: [
      {
        name: "org_uuid",
        description: "The UUID of the organization.",
        type: "string",
        required: true,
        in: "query",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/org/org_data?org_uuid=exampleOrgUUID' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",
    responseExample: {
      status: {
        key1: "value1",
        key2: "value2",
        phone: "123-456-7890",
      },
    },
    errorResponses: [
      { code: 404, message: "organization not found" },
      { code: 200, message: "key and value not found" },
    ],
  },
  {
    name: "Get Organization",
    method: "GET",
    category: "organisation ",
    endpoint: "/org/get_org",
    parameters: [],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/org/get_org' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",
    responseExample: {
      url: "https://api.joinworkahub.com",
      name: "Example Organization Name",
    },
    errorResponses: [{ code: 404, message: "organization not found" }],
  },
  {
    name: "Get Active Organizations Count",
    method: "GET",
    category: "organisation ",
    endpoint: "/org/get_active_org",
    parameters: [
      {
        name: "appid",
        type: "query",
        description: "The application ID to filter organizations.",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/org/get_active_org?appid=YOUR_APP_ID' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",
    responseExample: {
      number: 10,
    },
    errorResponses: [{ code: 404, message: "no data found" }],
  },
  {
    name: "Extend Organization Trial Period",
    method: "POST",
    category: "organisation ",
    endpoint: "/org/extend_trial",
    parameters: [
      {
        name: "org_uuid",
        type: "body",
        description:
          "The unique identifier of the organization whose trial period is being extended.",
        required: true,
      },
      {
        name: "date",
        type: "body",
        description: "The new end date for the trial period.",
        required: true,
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/org/extend_trial' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN' -H 'Content-Type: application/json' -d '{\"org_uuid\": \"ORG_UUID\", \"date\": \"YYYY-MM-DD\"}'",
    responseExample: {
      status: "successfully extended",
    },
    errorResponses: [{ code: 404, message: "No user found" }],
  },
  {
    name: "Get Organization Retention Data",
    method: "GET",
    category: "organisation ",
    endpoint: "/org/org_retention",
    parameters: [
      {
        name: "rowscount",
        type: "query",
        description:
          "The number of rows to retrieve from the `payment_histories` table.",
        required: true,
      },
      {
        name: "rowstart",
        type: "query",
        description:
          "The offset from where to start retrieving rows from the `payment_histories` table.",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/org/org_retention?rowscount=10&rowstart=0'",
    responseExample: {
      data: [
        {
          org_uuid: "ORG_UUID",
          users_count: 100,
          last_login: "2024-07-22T12:00:00Z",
          org_name: "Organization Name",
          subscription: "Premium Plan",
        },
        // more entries
      ],
    },
    errorResponses: [{ code: 404, message: "No data found" }],
  },
  {
    name: "Get Organization Payments",
    method: "GET",
    category: "organisation ",
    endpoint: "/org/org_payments",
    parameters: [
      {
        name: "offset",
        type: "query",
        description: "The page offset for paginated results.",
        required: false,
      },
      {
        name: "appid",
        type: "query",
        description: "Application ID to filter organizations.",
        required: false,
      },
      {
        name: "status",
        type: "query",
        description: "Payment status to filter records.",
        required: false,
      },
      {
        name: "org_uuid",
        type: "query",
        description: "Specific organization UUID to filter records.",
        required: false,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/org/org_payments?offset=0&appid=2&status=true' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",
    responseExample: {
      orgs: [
        {
          username: "user@example.com",
          email: "user@example.com",
          invoce: 12345,
          status: "paid",
          currency: "USD",
          price: 100,
          date: "2024-07-22T12:00:00Z",
          due_date: "08-22-2024",
          users: 10,
          org_name: "Organization Name",
          features: ["Feature1", "Feature2"],
          plan: "Basic Plan",
        },
        // more entries
      ],
    },
    errorResponses: [{ code: 404, message: "No data found" }],
  },
  {
    name: "Get Organization Data Labels",
    method: "GET",
    category: "organisation ",
    endpoint: "/org/org_data_labels",
    parameters: [
      {
        name: "name",
        type: "query",
        description: "The name of the organization to fetch data labels.",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/org/org_data_labels?name=OrganizationName'",
    responseExample: {
      team_name: "Team Name",
      project_name: "Project Name",
      category_name: "Category Name",
      task_name: "Task Name",
    },

    errorResponses: [{ code: 404, message: "No data found" }],
  },
  // profile
  {
    name: "Update User Profile",
    method: "PUT",
    category: "profile ",
    endpoint: "/profile",
    parameters: [
      {
        name: "user_uuid",
        type: "body",
        description: "UUID of the user.",
        required: true,
      },
      {
        name: "first_name",
        type: "body",
        description: "First name of the user.",
        required: false,
      },
      {
        name: "last_name",
        type: "body",
        description: "Last name of the user.",
        required: false,
      },
      {
        name: "password",
        type: "body",
        description: "Password for the user.",
        required: false,
      },
      {
        name: "avatar",
        type: "file",
        description: "Avatar image file.",
        required: false,
      },
    ],
    requestExample:
      "curl -X PUT 'https://api.trypadlock.com/profile' -H 'Authorization: Bearer <token>' -F 'user_uuid=<user_uuid>' -F 'first_name=John' -F 'last_name=Doe' -F 'password=password123' -F 'avatar=@/path/to/avatar.jpg'",
    responseExample: {
      Status: "data updated successfully",
    },
    errorResponses: [
      {
        code: 404,
        message: "user does not exist",
      },
    ],
  },
  {
    name: "Get User Profile",
    method: "GET",
    category: "profile ",
    endpoint: "/profile",
    parameters: [
      {
        name: "user_uuid",
        type: "query",
        description: "UUID of the user.",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/profile?user_uuid=<user_uuid>' -H 'Authorization: Bearer <token>'",
    responseExample: {
      email: "user@example.com",
      username: "username",
      first_name: "John",
      last_name: "Doe",
      url: "https://worksnaps-internal.s3.amazonaws.com/<user_uuid>/<image>.jpg",
    },
    errorResponses: [
      {
        code: 404,
        message: "user does not exist",
      },
    ],
  },
  {
    name: "Update User Roles",
    method: "PUT",
    category: "profile ",
    endpoint: "/profile/profileworkahub",
    parameters: [
      {
        name: "user_uuid",
        type: "body",
        description: "UUID of the user.",
        required: true,
      },
      {
        name: "roles",
        type: "body",
        description: "New roles to assign to the user.",
        required: true,
      },
    ],
    requestExample:
      "curl -X PUT 'https://api.trypadlock.com/profile/profileworkahub' -H 'Authorization: Bearer <token>' -H 'Content-Type: application/json' -d '{\"user_uuid\":\"<user_uuid>\", \"roles\":\"admin\"}'",
    responseExample: {
      status: "roles changed successfully",
    },
    errorResponses: [
      {
        code: 404,
        message: "user not found",
      },
    ],
  },
  {
    name: "Update User Data",
    method: "POST",
    category: "profile ",
    endpoint: "/profile/user_data",
    parameters: [
      {
        name: "user_uuid",
        type: "body",
        description: "UUID of the user.",
        required: true,
      },
      {
        name: "name",
        type: "body",
        description: "The key to be added or updated in user data.",
        required: true,
      },
      {
        name: "main_key",
        type: "body",
        description: "The value to be added or updated in user data.",
        required: true,
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/profile/user_data\' -H \'Authorization: Bearer <token>\' -H \'Content-Type: application/json\' -d \'{"user_uuid":"<user_uuid>", "name":"<key>", "main_key":"<value>"}\'',
    responseExample: {
      status: "user got added with the value and key",
    },
    errorResponses: [
      {
        code: 404,
        message: "user not found",
      },
    ],
  },
  {
    name: "Delete User Data",
    method: "DELETE",
    category: "profile ",
    endpoint: "/profile/user_data",
    parameters: [
      {
        name: "user_uuid",
        type: "body",
        description: "UUID of the user.",
        required: true,
      },
      {
        name: "name",
        type: "body",
        description: "The key to be deleted from user data.",
        required: true,
      },
    ],
    requestExample:
      "curl -X DELETE 'https://api.trypadlock.com/profile/user_data' -H 'Authorization: Bearer <token>' -H 'Content-Type: application/json' -d '{\"user_uuid\":\"<user_uuid>\", \"name\":\"<key>\"}'",
    responseExample: {
      status: "user key and value got deleted",
    },
    errorResponses: [
      {
        code: 404,
        message: "key not found",
      },
      {
        code: 404,
        message: "user not found",
      },
    ],
  },
  {
    name: "Get User Data",
    method: "GET",
    category: "profile ",
    endpoint: "/profile/user_data",
    parameters: [
      {
        name: "user_uuid",
        type: "query",
        description: "UUID of the user.",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/profile/user_data?user_uuid=<user_uuid>' -H 'Authorization: Bearer <token>'",
    responseExample: {
      status: {
        first_name: "John",
        last_name: "Doe",
        email: "john.doe@example.com",
      },
    },
    errorResponses: [
      {
        code: 404,
        message: "user not found",
      },
    ],
  },
  {
    name: "Get Users by Application Name",
    method: "GET",
    category: "profile ",
    endpoint: "/profile/app_data_user",
    parameters: [
      {
        name: "name",
        type: "query",
        description: "Name of the application.",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/profile/app_data_user?name=<app_name>' -H 'Authorization: Bearer <token>'",
    responseExample: {
      status: [
        {
          id: 1,
          email: "john.doe@example.com",
          user_uuid: "123e4567-e89b-12d3-a456-426614174000",
          createdAt: "2023-07-25T14:48:00.000Z",
          updatedAt: "2023-07-25T14:48:00.000Z",
        },
        {
          id: 2,
          email: "jane.doe@example.com",
          user_uuid: "123e4567-e89b-12d3-a456-426614174001",
          createdAt: "2023-07-25T14:48:00.000Z",
          updatedAt: "2023-07-25T14:48:00.000Z",
        },
      ],
    },
    errorResponses: [
      {
        code: 404,
        message: "Application not found",
      },
      {
        code: 500,
        message: "Internal Server Error",
      },
    ],
  },
  //plans
  {
    name: "Create Payment Plan",
    method: "POST",
    category: "plans ",
    endpoint: "/plans",
    parameters: [
      {
        name: "name",
        type: "body",
        description: "Name of the payment plan.",
        required: true,
      },
      {
        name: "appid",
        type: "body",
        description: "ID of the application.",
        required: true,
      },
      {
        name: "regionId",
        type: "body",
        description: "ID of the region.",
        required: true,
      },
      {
        name: "region",
        type: "body",
        description: "Region of the payment plan.",
        required: true,
      },
      {
        name: "currencys",
        type: "body",
        description: "Currency of the payment plan.",
        required: true,
      },
      {
        name: "country_amount",
        type: "body",
        description: "Amount in the country currency.",
        required: true,
      },
      {
        name: "info",
        type: "body",
        description: "Additional information about the payment plan.",
        required: true,
      },
      {
        name: "features",
        type: "body",
        description: "Comma-separated list of feature IDs.",
        required: true,
      },
    ],
    requestExample: {
      name: "Basic Plan",
      appid: "123",
      regionId: "456",
      region: "US",
      currencys: "USD",
      country_amount: "10.00",
      info: "Basic plan with essential features.",
      features: "1,2,3",
    },
    responseExample: {
      status: "plan got saved",
    },
    errorResponses: [
      {
        code: 400,
        message: "data already exists",
      },
    ],
  },
  {
    name: "Get Features by Plan ID",
    method: "GET",
    category: "plans ",
    endpoint: "/plans/features_plan",
    parameters: [
      {
        name: "planid",
        type: "query",
        description: "ID of the payment plan.",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/plans/features_plan?planid=123' -H 'Authorization: Bearer <token>'",
    responseExample: {
      data: [
        {
          id: 1,
          name: "Feature A",
          description: "Description of Feature A",
        },
        {
          id: 2,
          name: "Feature B",
          description: "Description of Feature B",
        },
      ],
    },
    errorResponses: [],
  },
  {
    name: "Get List of Payment Plans by App ID",
    method: "GET",
    category: "plans ",
    endpoint: "/plans/plans_list",
    parameters: [
      {
        name: "appid",
        type: "query",
        description: "ID of the application.",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/plans/plans_list?appid=123' -H 'Authorization: Bearer <token>'",
    responseExample: {
      data: [
        {
          id: 1,
          name: "Basic Plan",
          currency: "USD",
          price: "10.00",
        },
        {
          id: 2,
          name: "Pro Plan",
          currency: "USD",
          price: "20.00",
        },
      ],
    },
    errorResponses: [],
  },
  {
    name: "Get Detailed List of Payment Plans by App ID",
    method: "GET",
    category: "plans ",
    endpoint: "/plans",
    parameters: [
      {
        name: "appid",
        type: "query",
        description: "ID of the application.",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/plans?appid=123' -H 'Authorization: Bearer <token>'",
    responseExample: {
      data: [
        {
          id: 1,
          name: "Basic Plan",
          currency: "USD",
          price: "10.00",
          features: [
            {
              name: "Feature A",
              description: "Description of Feature A",
            },
            {
              name: "Feature B",
              description: "Description of Feature B",
            },
          ],
        },
        {
          id: 2,
          name: "Pro Plan",
          currency: "USD",
          price: "20.00",
          features: [
            {
              name: "Feature C",
              description: "Description of Feature C",
            },
            {
              name: "Feature D",
              description: "Description of Feature D",
            },
          ],
        },
      ],
    },
    errorResponses: [],
  },
  {
    name: "Get Detailed Payment Plan by Plan ID",
    method: "GET",
    category: "plans ",
    endpoint: "/plans/plan_detailed",
    parameters: [
      {
        name: "planid",
        type: "query",
        description: "ID of the payment plan.",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/plans/plan_detailed?planid=123' -H 'Authorization: Bearer <token>'",
    responseExample: {
      data: {
        id: 1,
        name: "Basic Plan",
        currency: "USD",
        price: "10.00",
        features: [
          {
            name: "Feature A",
            description: "Description of Feature A",
          },
          {
            name: "Feature B",
            description: "Description of Feature B",
          },
        ],
      },
    },
    errorResponses: [],
  },
  {
    name: "Update Payment Plan",
    method: "PUT",
    category: "plans ",
    endpoint: "/plans",
    parameters: [
      {
        name: "id",
        type: "body",
        description: "ID of the payment plan.",
        required: true,
      },
      {
        name: "name",
        type: "body",
        description: "Name of the payment plan.",
        required: false,
      },
      {
        name: "regionId",
        type: "body",
        description: "ID of the region.",
        required: false,
      },
      {
        name: "appid",
        type: "body",
        description: "ID of the application.",
        required: false,
      },
      {
        name: "currencys",
        type: "body",
        description: "Currency of the payment plan.",
        required: false,
      },
      {
        name: "status",
        type: "body",
        description: "Status of the payment plan.",
        required: false,
      },
      {
        name: "region",
        type: "body",
        description: "Region of the payment plan.",
        required: false,
      },
      {
        name: "country_amount",
        type: "body",
        description: "Amount in the country currency.",
        required: false,
      },
      {
        name: "info",
        type: "body",
        description: "Additional information about the payment plan.",
        required: false,
      },
    ],
    requestExample: {
      id: 1,
      name: "Updated Basic Plan",
      regionId: "789",
      appid: "123",
      currencys: "USD",
      status: true,
      region: "US",
      country_amount: "12.00",
      info: "Updated basic plan with additional features.",
    },
    responseExample: {
      status: "data updated successfully",
    },
    errorResponses: [
      {
        code: 404,
        message: "no data found",
      },
    ],
  },
  {
    name: "Add Payment Plan to Organization",
    method: "PUT",
    category: "plans ",
    endpoint: "/plans/orgAdd",
    parameters: [
      {
        name: "id",
        type: "body",
        description: "ID of the payment plan.",
        required: true,
      },
      {
        name: "org_uuid",
        type: "body",
        description: "UUID of the organization.",
        required: true,
      },
    ],
    requestExample: {
      id: 1,
      org_uuid: "123e4567-e89b-12d3-a456-426614174000",
    },
    responseExample: {
      status: "Plan is added to the org",
    },
    errorResponses: [
      {
        code: 404,
        message: "no data found",
      },
    ],
  },
  {
    name: "Get Payment Plan for Organization",
    method: "GET",
    category: "plans ",
    endpoint: "/plans/orgAdd",
    parameters: [
      {
        name: "org_uuid",
        type: "query",
        description: "UUID of the organization.",
        required: true,
      },
    ],
    authentication: "Required",
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/plans/orgAdd?org_uuid=123e4567-e89b-12d3-a456-426614174000' -H 'Authorization: Bearer <token>'",
    responseExample: {
      plan: {
        id: 1,
        name: "Basic Plan",
        currency: "USD",
        price: "10.00",
      },
    },
    errorResponses: [
      {
        code: 404,
        message: "No plan is present or organization not found",
      },
    ],
  },
  //refresh
  {
    name: "Refresh Token",
    method: "POST",
    category: "refresh token ",
    endpoint: "/refresh",
    parameters: [
      {
        name: "refresh",
        type: "body",
        description: "The refresh token provided by the client.",
        required: true,
      },
    ],
    authentication: "None",
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/' -H 'Content-Type: application/json' -d '{\"refresh\": \"<refresh_token>\"}'",
    responseExample: {
      token: "<access_token>",
    },
    errorResponses: [
      {
        code: 401,
        message: "The token got expired or invalid token",
      },
      {
        code: 401,
        message: "Not Authorized",
      },
      {
        code: 401,
        message: "Token Not Provided",
      },
    ],
  },
  //premium
  {
    name: "Update Organization Premium Price",
    method: "POST",
    category: "premium ",
    endpoint: "/premium",
    parameters: [
      {
        name: "orgName",
        type: "body",
        description:
          "The name of the organization for which the premium price is being updated.",
        required: true,
      },
      {
        name: "amount",
        type: "body",
        description:
          "The new premium price amount to be set for the organization.",
        required: true,
      },
    ],
    authentication: "None",
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/premium' -H 'Content-Type: application/json' -d '{\"orgName\": \"Acme Corp\", \"amount\": 199.99}'",
    responseExample: {
      status: "data updated successfully",
    },
    errorResponses: [
      {
        code: 404,
        message: "Organization not found",
      },
      {
        code: 500,
        message: "Internal server error",
      },
    ],
  },
  //padlock graph
  {
    name: "Client Login Graph",
    method: "GET",
    category: "padlock graph ",
    endpoint: "/graph/client_graph",
    parameters: [
      {
        name: "start_date",
        type: "query",
        description: "Start date of the date range (format: YYYY-MM-DD).",
        required: true,
      },
      {
        name: "end_date",
        type: "query",
        description: "End date of the date range (format: YYYY-MM-DD).",
        required: true,
      },
    ],
    authentication: "isAuthenticated",
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/graph/client_graph?start_date=2024-01-01&end_date=2024-01-07'",
    responseExample: {
      data: [
        { date: "2024-01-01", number_of_users: 5 },
        { date: "2024-01-02", number_of_users: 10 },
      ],
    },
    errorResponses: [
      {
        code: 404,
        message: "No data found",
      },
    ],
  },
  {
    name: "Organization Login Graph",
    method: "GET",
    category: "padlock graph ",
    endpoint: "/graph/org_graph",
    parameters: [
      {
        name: "start_date",
        type: "query",
        description: "Start date of the date range (format: YYYY-MM-DD).",
        required: true,
      },
      {
        name: "end_date",
        type: "query",
        description: "End date of the date range (format: YYYY-MM-DD).",
        required: true,
      },
      {
        name: "org_uuid",
        type: "query",
        description: "UUID of the organization.",
        required: true,
      },
    ],
    authentication: "isAuthenticated",
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/graph/org_graph?start_date=2024-01-01&end_date=2024-01-07&org_uuid=abc123'",
    responseExample: {
      data: [
        { date: "2024-01-01", number_of_users: 3 },
        { date: "2024-01-02", number_of_users: 7 },
      ],
    },
    errorResponses: [
      {
        code: 404,
        message: "No data found",
      },
    ],
  },
  {
    name: "Extend Trial Period",
    method: "POST",
    category: "padlock graph ",
    endpoint: "/graph/extend_trail",
    parameters: [
      {
        name: "org_uuid",
        type: "body",
        description: "UUID of the organization.",
        required: true,
      },
      {
        name: "end_date",
        type: "body",
        description: "New end date for the trial period (format: YYYY-MM-DD).",
        required: true,
      },
    ],
    authentication: "isAuthenticated",
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/graph/extend_trail\' -H \'Content-Type: application/json\' -d \'{"org_uuid": "abc123", "end_date": "2024-01-15"}\'',
    responseExample: {
      status: "data got updated",
    },
    errorResponses: [
      {
        code: 404,
        message: "Organization not found",
      },
    ],
  },
  //level
  {
    name: "Add Level",
    method: "POST",
    category: "level ",
    endpoint: "/level",
    parameters: [
      {
        name: "email",
        type: "body",
        description: "Email of the user.",
        required: true,
      },
      {
        name: "type",
        type: "body",
        description: "Type of the level.",
        required: true,
      },
    ],
    authentication: "isAuthenticated",
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/level\' -H \'Content-Type: application/json\' -d \'{"email": "user@example.com", "type": "premium"}\'',
    responseExample: {
      status: "successfully added",
      code: 200,
    },
    errorResponses: [
      {
        code: 400,
        message: "No data found",
      },
    ],
  },
  {
    name: "Get Level",
    method: "GET",
    category: "level ",
    endpoint: "/level",
    parameters: [
      {
        name: "email",
        type: "query",
        description: "Email of the user.",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/level?email=user@example.com'",
    responseExample: {
      status: "successfully retrieved",
      code: 200,
    },
    errorResponses: [
      {
        code: 400,
        message: "No data found",
      },
    ],
  },
  //microsoft login
  {
    name: "Google Signup",
    method: "POST",
    category: "microsoft login ",
    endpoint: "/microsoft/google_signup",
    parameters: [
      {
        name: "token",
        type: "body",
        description: "Google OAuth token.",
        required: true,
      },
      {
        name: "org_name",
        type: "body",
        description: "Organization name.",
        required: true,
      },
      {
        name: "time_zone",
        type: "body",
        description: "Time zone of the user.",
        required: true,
      },
      {
        name: "languages",
        type: "body",
        description: "Preferred language (default is 'en').",
        required: false,
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/microsoft/google_signup\' -H \'Content-Type: application/json\' -d \'{"token": "google_oauth_token", "org_name": "Example Org", "time_zone": "GMT", "languages": "en"}\'',
    responseExample: {
      access_token: "jwt_token",
    },
    errorResponses: [
      {
        code: 404,
        message: "User already exists",
      },
    ],
  },
  {
    name: "Microsoft Data Sign",
    method: "POST",
    category: "microsoft login ",
    endpoint: "/microsoft/microsoft_data_sign",
    parameters: [
      {
        name: "token",
        type: "body",
        description: "Microsoft OAuth token.",
        required: true,
      },
      {
        name: "password",
        type: "body",
        description: "User's password.",
        required: true,
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/microsoft/microsoft_data_sign\' -H \'Content-Type: application/json\' -d \'{"token": "microsoft_oauth_token", "password": "user_password"}\'',
    responseExample: {
      access_token: "jwt_token",
    },
    errorResponses: [
      {
        code: 404,
        message: "User not found",
      },
    ],
  },
  {
    name: "Microsoft Client Sign",
    method: "GET",
    category: "microsoft login ",
    endpoint: "/microsoft/microsoft_client_sign/:token",
    parameters: [
      {
        name: "token",
        type: "path",
        description: "Microsoft OAuth token.",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/microsoft/microsoft_client_sign/microsoft_oauth_token'",
    responseExample: {
      list: ["Org1", "Org2"],
      email: "user@example.com",
    },
    errorResponses: [
      {
        code: 404,
        message: "User not found",
      },
    ],
  },
  {
    name: "Microsoft Org Sign",
    method: "POST",
    category: "microsoft login ",
    endpoint: "/microsoft/org_sign",
    parameters: [
      {
        name: "email",
        type: "body",
        description: "User's email.",
        required: true,
      },
      {
        name: "org",
        type: "body",
        description: "Organization name.",
        required: true,
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/microsoft/org_sign\' -H \'Content-Type: application/json\' -d \'{"email": "user@example.com", "org": "Example Org"}\'',
    responseExample: {
      user_roles: "admin",
      user_id: "user_uuid",
      username: "user_name",
      org: "Example Org",
    },
    errorResponses: [
      {
        code: 404,
        message: "User not found",
      },
    ],
  },
  {
    name: "Microsoft Auth Redirect",
    method: "GET",
    category: "microsoft login ",
    endpoint: "/microsoft",
    parameters: [
      {
        name: "domain_name",
        type: "query",
        description: "Domain name to determine the URL link for registration.",
        required: false,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/microsoft?domain_name=samsTaponShoulder'",
    responseExample:
      "Redirects to the appropriate URL for Microsoft authentication.",
    errorResponses: [
      {
        code: 500,
        message: "Internal Server Error",
      },
    ],
  },
  {
    name: "Microsoft Redirect Callback",
    method: "GET",
    category: "microsoft login ",
    endpoint: "/microsoft/redirect",
    parameters: [
      {
        name: "code",
        type: "query",
        description:
          "Authorization code returned from Microsoft after successful login.",
        required: true,
      },
      {
        name: "domain_name",
        type: "query",
        description:
          "Domain name used to determine the URL link for registration.",
        required: false,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/microsoft/redirect?code=AUTH_CODE&domain_name=samsTaponShoulder'",
    responseExample: {
      access_token: "YOUR_JWT_ACCESS_TOKEN",
      org: "Organization Name",
    },
    errorResponses: [
      {
        code: 400,
        message: "Bad Request - Missing or invalid authorization code",
      },
      {
        code: 404,
        message: "Not Found - User not found",
      },
      {
        code: 500,
        message: "Internal Server Error",
      },
    ],
  },
  //forgotPassword
  {
    name: "Change Password",
    method: "POST",
    category: "forgot password ",
    endpoint: "/password/forgot_password",
    parameters: [
      {
        name: "password",
        type: "body",
        description: "Current password of the user.",
        required: true,
      },
      {
        name: "new_password",
        type: "body",
        description: "New password to set for the user.",
        required: true,
      },
      {
        name: "uu_id",
        type: "body",
        description: "Unique user identifier.",
        required: true,
      },
    ],
    requestExample:
      'curl -X POST -H \'Content-Type: application/json\' -d \'{"password": "current_password", "new_password": "new_password", "uu_id": "user_uuid"}\' \'https://api.trypadlock.com/password/forgot_password\'',
    responseExample: {
      status: "succefully changed the password",
    },
    errorResponses: [
      {
        code: 400,
        message: "Bad Request - Incorrect current password",
      },
      {
        code: 404,
        message: "Not Found - User not found",
      },
    ],
  },
  {
    name: "Reset Password with Token",
    method: "POST",
    category: "forgot password ",
    endpoint: "/password/forgot_password_first",
    parameters: [
      {
        name: "token",
        type: "body",
        description: "JWT token used to verify the user identity.",
        required: true,
      },
      {
        name: "password",
        type: "body",
        description: "New password to set for the user.",
        required: true,
      },
    ],
    requestExample:
      'curl -X POST -H \'Content-Type: application/json\' -d \'{"token": "jwt_token", "password": "new_password"}\' \'https://api.trypadlock.com/password/forgot_password_first\'',
    responseExample: { status: "succefully changed the password" },
    errorResponses: [
      { code: 401, message: "Unauthorized - Invalid or expired token" },
      { code: 404, message: "Not Found - User not found" },
    ],
  },
  //google login
  {
    name: "Google Sign Up",
    method: "POST",
    category: "google login ",
    endpoint: "/users/google_signup",
    parameters: [
      {
        name: "token",
        type: "body",
        description: "Google ID token used to verify the user's identity.",
        required: true,
      },
      {
        name: "org_name",
        type: "body",
        description: "Name of the organization the user is signing up for.",
        required: true,
      },
      {
        name: "time_zone",
        type: "body",
        description: "User's time zone.",
        required: true,
      },
    ],
    requestExample:
      'curl -X POST -H \'Content-Type: application/json\' -d \'{"token": "google_id_token", "org_name": "organization_name", "time_zone": "timezone"}\' \'https://api.trypadlock.com/users/google_signup\'',
    responseExample: {
      access_token: "jwt_access_token",
    },
    errorResponses: [
      {
        code: 400,
        message: "Bad Request - Error verifying the Google token",
      },
      {
        code: 409,
        message: "Conflict - User already exists",
      },
    ],
  },
  {
    name: "Google Data Sign",
    method: "POST",
    category: "google login ",
    endpoint: "/users/google_data_sign",
    parameters: [
      {
        name: "token",
        type: "body",
        description: "Google ID token used to verify the user's identity.",
        required: true,
      },
    ],
    requestExample:
      "curl -X POST -H 'Content-Type: application/json' -d '{\"token\": \"google_id_token\"}' 'https://api.trypadlock.com/users/google_data_sign'",
    responseExample: {
      access_token: "jwt_access_token",
      org: "organization_name",
    },
    errorResponses: [
      {
        code: 400,
        message: "Bad Request - Error verifying the Google token",
      },
      {
        code: 404,
        message: "Not Found - User not found",
      },
    ],
  },
  {
    name: "Google Client Sign",
    method: "GET",
    category: "google login ",
    endpoint: "/users/google_client_sign/:token",
    parameters: [
      {
        name: "token",
        type: "path",
        description: "Google ID token used to verify the user's identity.",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/google_client_sign/google_id_token'",
    responseExample: {
      list: ["org_name_1", "org_name_2"],
      email: "user@example.com",
    },
    errorResponses: [
      {
        code: 400,
        message: "Bad Request - Error verifying the Google token",
      },
      {
        code: 404,
        message: "Not Found - User not found",
      },
    ],
  },
  {
    name: "Organization Sign",
    method: "POST",
    category: "google login ",
    endpoint: "/users/org_sign",
    parameters: [
      {
        name: "email",
        type: "body",
        description: "Email of the user",
        required: true,
      },
      {
        name: "org",
        type: "body",
        description: "Organization name",
        required: true,
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/org_sign\' -H \'Content-Type: application/json\' -d \'{"email": "user@example.com", "org": "organization_name"}\'',
    responseExample: {
      user_roles: "role_of_user",
      user_id: "user_uuid",
      username: "username",
      org: "organization_name",
    },
    errorResponses: [
      {
        code: 404,
        message: "Not Found - User not found",
      },
    ],
  },
  //pricing
  {
    name: "Get Prices",
    method: "GET",
    category: "pricing ",
    endpoint: "/users/prices",
    parameters: [
      {
        name: "accept-language",
        type: "header",
        description: "Language and country code from the client's browser",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/prices' -H 'accept-language: en-US'",
    responseExample: {
      Status: 10,
    },
    errorResponses: [
      {
        code: 200,
        message: "Default price returned if no specific country price is found",
      },
    ],
  },
  {
    name: "Get Payment Plans",
    method: "GET",
    category: "pricing ",
    endpoint: "/users/paymentplan",
    parameters: [
      {
        name: "user",
        type: "query",
        description: "Number of users for the payment plan calculation",
        required: false,
      },
      {
        name: "accept-language",
        type: "header",
        description: "Language and country code from the client's browser",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/paymentplan?user=5' -H 'accept-language: en-US'",
    responseExample: [
      {
        price: 10,
        name: "Standard Plan",
        cost: 50,
      },
      {
        price: 20,
        name: "Premium Plan",
        cost: 100,
      },
    ],
    errorResponses: [
      {
        code: 404,
        message: "No payment plans found for the specified region",
      },
    ],
  },
  {
    name: "Add Features to Payment Plan",
    method: "POST",
    category: "pricing ",
    endpoint: "/users/add_features",
    parameters: [
      {
        name: "id",
        type: "body",
        description: "The ID of the payment plan to update",
        required: true,
      },
      {
        name: "datas",
        type: "body",
        description:
          "Comma-separated list of features to add to the payment plan",
        required: true,
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/users/add_features' -H 'Content-Type: application/json' -d '{\"id\": 1, \"datas\": \"Feature1,Feature2,Feature3\"}'",
    responseExample: {
      Status: "data saved successfully",
    },
    errorResponses: [
      {
        code: 404,
        message: "Payment plan not found",
      },
    ],
  },
  {
    name: "Get Payment Plans by Region",
    method: "GET",
    category: "pricing ",
    endpoint: "/users/prices_list",
    parameters: [
      {
        name: "ip_address",
        type: "query",
        description:
          "The IP address used to determine the geographical location",
        required: false,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/prices_list?ip_address=8.8.8.8'",
    responseExample: {
      Status: [
        {
          id: 1,
          name: "Basic Plan",
          price: 10,
          region: "US",
        },
        {
          id: 2,
          name: "Premium Plan",
          price: 20,
          region: "US",
        },
      ],
    },
    errorResponses: [
      {
        code: 404,
        message: "Data not found",
      },
    ],
  },
  {
    name: "Calculate User Cost",
    method: "GET",
    category: "pricing ",
    endpoint: "/users/user_per_cost",
    parameters: [
      {
        name: "user",
        type: "query",
        description: "The number of users to calculate the total cost for",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/user_per_cost?user=10'",
    responseExample: {
      price: 70,
      numberofuser: 10,
      priceperuser: 7,
    },
    errorResponses: [],
  },
  {
    name: "Retrieve Last Payment Date",
    method: "GET",
    category: "pricing ",
    endpoint: "/users/last_payment",
    parameters: [
      {
        name: "email",
        type: "query",
        description:
          "The email of the user whose last payment date is to be retrieved",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/last_payment?email=user@example.com'",
    responseExample: {
      date: "2024-07-27T15:00:00Z",
    },
    errorResponses: [
      {
        code: 404,
        message: "Payment record not found",
      },
    ],
  },
  //workahub registration
  {
    name: "Register Workahub User",
    method: "POST",
    category: "workahub registration ",
    endpoint: "/users/workahubregister",
    parameters: [
      {
        name: "token",
        type: "body",
        description: "JWT token for validating the registration request",
        required: true,
      },
      {
        name: "subcription",
        type: "body",
        description: "Subscription plan name",
        required: true,
      },
      {
        name: "org_name",
        type: "body",
        description: "Name of the organization",
        required: true,
      },
      {
        name: "email",
        type: "body",
        description: "Email address of the user",
        required: true,
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/workahubregister\' -H \'Content-Type: application/json\' -d \'{"token":"<jwt-token>","subcription":"basic","org_name":"MyOrg","email":"user@example.com"}\'',
    responseExamples: {
      success: {
        orgs: "<organization-uuid>",
      },
      error: {
        error: "org already exists",
      },
    },
    errorResponses: [
      {
        code: 500,
        message: "Link Expired! Please Register again",
      },
      {
        code: 404,
        message: "No Subscriptions",
      },
      {
        code: 404,
        message: "No Token Passed",
      },
      {
        code: 404,
        message: "Refer does not exist",
      },
    ],
  },
  {
    name: "Users Register Workahub User",
    method: "POST",
    category: "workahub registration ",
    endpoint: "/users/workahubreg",
    parameters: [
      {
        name: "token",
        type: "body",
        description: "JWT token for validating the registration request",
        required: true,
      },
      {
        name: "orgs",
        type: "body",
        description: "Organization name",
        required: true,
      },
      {
        name: "subcription",
        type: "body",
        description: "Subscription plan name",
        required: true,
      },
      {
        name: "uuid",
        type: "body",
        description: "Unique identifier for the organization",
        required: true,
      },
      {
        name: "first_name",
        type: "body",
        description: "First name of the user",
        required: true,
      },
      {
        name: "last_name",
        type: "body",
        description: "Last name of the user",
        required: true,
      },
      {
        name: "username",
        type: "body",
        description: "Username for the user",
        required: true,
      },
      {
        name: "password",
        type: "body",
        description: "Password for the user",
        required: true,
      },
      {
        name: "phone",
        type: "body",
        description: "Phone number of the user",
        required: true,
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/workahubreg\' -H \'Content-Type: application/json\' -d \'{"token":"<jwt-token>","orgs":"MyOrg","subcription":"basic","uuid":"<organization-uuid>","first_name":"John","last_name":"Doe","username":"johndoe","password":"password123","phone":"1234567890"}\'',
    responseExamples: {
      success: {
        access_token: "<new-jwt-token>",
      },
      error: {
        Status: "No Token Passed",
      },
    },
    errorResponses: [
      {
        code: 500,
        message: "Link Expired! Please Register again",
      },
      {
        code: 400,
        message: "User already exists",
      },
    ],
  },
  //features
  {
    name: "Check Feature Emails",
    method: "POST",
    category: "feature ",
    endpoint: "/users/feature_emails",
    parameters: [
      {
        name: "org_name",
        description: "The organization name.",
        type: "string",
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/users/feature_emails' -H 'Content-Type: application/json' -d '{\"org_name\": \"organization\"}'",
    responseExample: {
      status: true,
    },
    errorResponses: [
      { code: 400, message: "Organization not found" },
      { code: 400, message: "Feature not available for organization" },
    ],
  },
  {
    name: "Add Features",
    method: "POST",
    category: "feature ",
    endpoint: "/users/addfeatures",
    parameters: [
      {
        name: "feature",
        description: "The feature to be added.",
        type: "string",
      },
      {
        name: "email",
        description: "The admin's email address.",
        type: "string",
      },
      {
        name: "useremail",
        description:
          "The user's email address to whom the feature will be added.",
        type: "string",
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/addfeatures\' -H \'Content-Type: application/json\' -d \'{"feature": "feature_name", "email": "admin@example.com", "useremail": "user@example.com"}\'',
    responseExample: {
      Status: "Text Extraction is Added For User",
    },
    errorResponses: [
      { code: 400, message: "Admin not Found" },
      { code: 400, message: "No Feature Found" },
      { code: 400, message: "User Not Found" },
      { code: 400, message: "No App_Pricing data Found" },
      { code: 400, message: "User Details Not Found" },
      { code: 400, message: "Admin is not active" },
      {
        code: 400,
        message: "User Already Has The Text Extraction Feature Added",
      },
    ],
  },
  {
    name: "Remove Features",
    method: "POST",
    category: "feature ",
    endpoint: "/users/removefeatures",
    parameters: [
      {
        name: "feature",
        description: "The feature to be removed.",
        type: "string",
      },
      {
        name: "email",
        description: "The admin's email address.",
        type: "string",
      },
      {
        name: "useremail",
        description:
          "The user's email address from whom the feature will be removed.",
        type: "string",
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/removefeatures\' -H \'Content-Type: application/json\' -d \'{"feature": "feature_name", "email": "admin@example.com", "useremail": "user@example.com"}\'',
    responseExample: {
      Status: "Feature is Removed For User",
    },
    errorResponses: [
      { code: 400, message: "Admin not Found" },
      { code: 400, message: "Feature Not Found" },
      { code: 400, message: "User Not Found" },
      { code: 400, message: "No App_Pricing data Found" },
      { code: 400, message: "User Details Not Found" },
      {
        code: 400,
        message: "User Don't have the respective Feature Enabled",
      },
    ],
  },
  {
    name: "List Market Features",
    method: "POST",
    category: "feature ",
    endpoint: "/users/listmarketfeatures",
    parameters: [
      {
        name: "email",
        description: "The admin's email address.",
        type: "string",
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/users/listmarketfeatures' -H 'Content-Type: application/json' -d '{\"email\": \"admin@example.com\"}'",
    responseExample: {
      Feature: "array of market features",
    },
    errorResponses: [{ code: 400, message: "No market feature details found" }],
  },
  {
    name: "List Features",
    method: "POST",
    category: "feature ",
    endpoint: "/users/listfeatures",
    parameters: [
      {
        name: "email",
        description: "The admin's email address.",
        type: "string",
      },
      {
        name: "useremail",
        description: "The user's email address to list features for.",
        type: "string",
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/listfeatures\' -H \'Content-Type: application/json\' -d \'{"email": "admin@example.com", "useremail": "user@example.com"}\'',
    responseExample: {
      data: "object containing feature names",
    },
    errorResponses: [
      { code: 400, message: "Admin Does Not Exist" },
      { code: 400, message: "User Does Not Exist" },
      { code: 400, message: "FeatureEnable Does Not Exist" },
    ],
  },
  {
    name: "List Features for app",
    method: "GET",
    category: "feature ",
    endpoint: "/users/list_features",
    parameters: [
      {
        name: "appname",
        description:
          "The name of the application to list features for. Defaults to 'Workahub'.",
        type: "string",
        required: false,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/list_features?appname=Workahub'",
    responseExample: {
      features: "array of feature objects",
    },
    errorResponses: [{ code: 404, message: "No features found" }],
  },
  {
    name: "Get Client Features",
    method: "GET",
    category: "feature ",
    endpoint: "/users/client_feature/:org_uuid",
    parameters: [
      {
        name: "org_uuid",
        description: "The UUID of the organization.",
        type: "string",
        required: true,
      },
      {
        name: "team",
        description: "The ID of the team (optional).",
        type: "string",
        required: false,
      },
      {
        name: "userid",
        description: "The ID of the user.",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/client_feature/12345?team=team1&userid=user123'",
    responseExample: {
      datas: [
        {
          type: "Org",
          status: "yes",
          name: "Feature Name",
        },
        {
          type: "Team",
          status: "no",
          name: "Another Feature Name",
        },
        {
          type: "User",
          status: "yes",
          name: "User-Specific Feature",
        },
      ],
    },
    errorResponses: [
      { code: 400, message: "No data found" },
      { code: 404, message: "No data is present" },
    ],
  },
  {
    name: "Get Client Features v1",
    method: "GET",
    category: "feature ",
    endpoint: "/users/v1/client_feature/",
    parameters: [
      {
        name: "org_uuid",
        description: "The UUID of the organization.",
        type: "string",
        required: true,
      },
      {
        name: "team",
        description: "The name of the team (optional).",
        type: "string",
        required: false,
      },
      {
        name: "userid",
        description: "The ID of the user.",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/v1/client_feature/?org_uuid=12345&team=team1&userid=user123'",
    responseExample: {
      datas: [
        {
          type: "Org",
          stat: "yes",
          name: "Feature Name",
        },
        {
          type: "Team",
          stat: "no",
          name: "Another Feature Name",
        },
        {
          type: "User",
          stat: "yes",
          name: "User-Specific Feature",
        },
      ],
    },
    errorResponses: [
      { code: 400, message: "No data found" },
      { code: 404, message: "No data is present" },
    ],
  },
  {
    name: "Get Client Features (v2)",
    method: "GET",
    category: "feature ",
    endpoint: "/users/v2/client_feature/",
    parameters: [
      {
        name: "org_uuid",
        description: "The UUID of the organization.",
        type: "string",
        required: true,
      },
      {
        name: "team",
        description: "The UUID of the team (optional).",
        type: "string",
        required: false,
      },
      {
        name: "userid",
        description: "The ID of the user.",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/v2/client_feature/?org_uuid=12345&team=team-uuid&userid=user123'",
    responseExample: {
      datas: [
        {
          type: "Org",
          stat: "yes",
          name: "Feature Name",
        },
        {
          type: "Team",
          stat: "no",
          name: "Another Feature Name",
        },
        {
          type: "User",
          stat: "yes",
          name: "User-Specific Feature",
        },
      ],
    },
    errorResponses: [
      { code: 400, message: "No data found" },
      { code: 404, message: "No data is present" },
    ],
  },
  {
    name: "users Create Feature",
    method: "POST",
    category: "feature ",
    endpoint: "/users/create_feature",
    parameters: [
      {
        name: "app",
        description:
          "The name of the application where the feature will be added.",
        type: "string",
        required: true,
      },
      {
        name: "name",
        description: "The name of the feature.",
        type: "string",
        required: true,
      },
      {
        name: "description",
        description: "A brief description of the feature.",
        type: "string",
        required: true,
      },
      {
        name: "feature_price",
        description: "The price of the feature (optional).",
        type: "number",
        required: false,
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/create_feature\' -H \'Content-Type: application/json\' -d \'{"app": "workahub", "name": "Advanced Analytics", "description": "Provides advanced analytics features.", "feature_price": 99.99}\'',
    responseExample: {
      feature: "Advanced Analytics",
      id: 12345,
    },
    errorResponses: [
      { code: 404, message: "Not enough data given." },
      { code: 409, message: "The feature with given name already exists." },
      { code: 404, message: "The app with given name not found." },
    ],
  },
  {
    name: "Assign Feature",
    method: "PUT",
    category: "feature ",
    endpoint: "/users/assign_feature",
    parameters: [
      {
        name: "org_uuid",
        description:
          "The UUID of the organization to which the feature will be assigned or removed.",
        type: "string",
        required: true,
      },
      {
        name: "feature",
        description:
          "The feature object containing the feature ID and other details.",
        type: "object",
        required: true,
        properties: [
          {
            name: "id",
            description: "The ID of the feature.",
            type: "number",
            required: true,
          },
          {
            name: "name",
            description:
              "The name of the feature (will be added if the feature is being added).",
            type: "string",
            required: false,
          },
          {
            name: "description",
            description:
              "A brief description of the feature (will be added if the feature is being added).",
            type: "string",
            required: false,
          },
        ],
      },
      {
        name: "currency",
        description: "The currency used by the organization (optional).",
        type: "string",
        required: false,
      },
    ],
    requestExample:
      'curl -X PUT \'https://api.trypadlock.com/users/assign_feature\' -H \'Content-Type: application/json\' -d \'{"org_uuid": "1234-5678-91011", "feature": {"id": 1, "name": "Advanced Analytics"}, "currency": "USD"}\'',
    responseExample: {
      message: "feature added", // or "feature removed" based on the operation performed
    },
    errorResponses: [{ code: 404, message: "Given feature not found." }],
  },
  {
    name: "Edit Feature Price",
    method: "PUT",
    category: "feature ",
    endpoint: "/users/edit_feature_price",
    parameters: [
      {
        name: "org_uuid",
        description:
          "The UUID of the organization whose feature price is to be edited.",
        type: "string",
        required: true,
      },
      {
        name: "feature_id",
        description: "The ID of the feature whose price is to be updated.",
        type: "number",
        required: true,
      },
      {
        name: "price",
        description: "The new price for the feature.",
        type: "number",
        required: true,
      },
    ],
    requestExample:
      'curl -X PUT \'https://api.trypadlock.com/users/edit_feature_price\' -H \'Content-Type: application/json\' -d \'{"org_uuid": "1234-5678-91011", "feature_id": 1, "price": 99.99}\'',
    responseExample: {
      message: "price updated successfully",
    },
    errorResponses: [{ code: 404, message: "Given feature not found." }],
  },
  {
    name: "Feature Listing",
    method: "GET",
    category: "feature ",
    endpoint: "/users/feature_listing",
    parameters: [],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/feature_listing' -H 'Authorization: Bearer <your-token>'",
    responseExample: {
      status: [
        {
          user: "username1",
          data: [
            {
              name: "Feature1",
            },
            {
              name: "Feature2",
            },
          ],
        },
        {
          user: "username2",
          data: [
            {
              name: "Feature2",
            },
            {
              name: "Feature3",
            },
          ],
        },
      ],
    },
    errorResponses: [{ code: 401, message: "Unauthorized" }],
  },
  //features
  {
    name: "Add or Update Feature",
    method: "POST",
    category: "feature ",
    endpoint: "/features/featureStart",
    parameters: [
      {
        name: "feature",
        type: "string",
        description: "ID of the feature to be added or updated.",
      },
      {
        name: "type",
        type: "string",
        description: "Type of the feature (Org, Team, User).",
      },
      {
        name: "userid",
        type: "string",
        description:
          "Comma-separated list of user IDs (for Org or Team type features).",
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/features/featureStart\' -H \'Authorization: Bearer <your-token>\' -H \'Content-Type: application/json\' -d \'{"feature": "feature-id", "type": "Org", "userid": "user1,user2"}\'',
    responseExample: {
      status: "Data saved Successfully",
    },
    errorResponses: [{ code: 400, message: "Feature already exists" }],
  },
  {
    name: "Disable Feature",
    method: "PUT",
    category: "feature ",
    endpoint: "/features/featureStop",
    parameters: [
      {
        name: "id",
        type: "string",
        description: "ID of the feature to be disabled.",
      },
    ],
    requestExample:
      "curl -X PUT 'https://api.trypadlock.com/features/featureStop?id=feature-id' -H 'Authorization: Bearer <your-token>'",
    responseExample: {
      Status: "Disabled Successfully",
    },
    errorResponses: [{ code: 404, message: "Data not found" }],
  },
  {
    name: "Delete Feature start",
    method: "DELETE",
    category: "feature ",
    endpoint: "/features/featureStart",
    parameters: [
      {
        name: "featureid",
        type: "string",
        description: "ID of the feature to be deleted.",
      },
    ],
    requestExample:
      "curl -X DELETE 'https://api.trypadlock.com/features/featureStart?featureid=feature-id' -H 'Authorization: Bearer <your-token>'",
    responseExample: {
      status: "Data removed Successfully",
    },
    errorResponses: [{ code: 404, message: "No Data Found" }],
  },
  {
    name: "Enable Feature",
    method: "PUT",
    category: "feature ",
    endpoint: "/features/featureEnable",
    parameters: [
      {
        name: "id",
        type: "string",
        description: "ID of the feature to be enabled.",
      },
    ],
    requestExample:
      "curl -X PUT 'https://api.trypadlock.com/features/featureEnable?id=feature-id' -H 'Authorization: Bearer <your-token>'",
    responseExample: {
      Status: "Enabled Successfully",
    },
    errorResponses: [{ code: 404, message: "Data not found" }],
  },
  {
    name: "Feature List",
    method: "GET",
    category: "feature ",
    endpoint: "/features/featureList",
    parameters: [
      {
        name: "type",
        type: "query",
        description: "Type of feature listing (Org, User, Team)",
        required: true,
        example: "Org",
      },
      {
        name: "userid",
        type: "query",
        description: "User ID for user-specific features",
        required: false,
        example: "12345",
      },
      {
        name: "teamid",
        type: "query",
        description: "Team ID for team-specific features",
        required: false,
        example: "54321",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/features/featureList?type=Org' -H 'Authorization: Bearer <your-token>'",
    responseExample: {
      features: [
        {
          featureid: 1,
          id: 101,
          feature: "Feature Name",
          description: "Feature Description",
          status: true,
          data: true,
          userexcluded: ["user1", "user2"],
        },
        {
          featureid: 2,
          id: 102,
          feature: "Another Feature",
          description: "Another Description",
          status: false,
          data: true,
          userexcluded: [],
        },
      ],
    },
    errorResponses: [{ code: 404, message: "No data found" }],
  },
  {
    name: "Feature Market",
    method: "GET",
    category: "feature ",
    endpoint: "/features/featureMarket",
    parameters: [
      {
        name: "type",
        type: "query",
        description: "Type of feature market (e.g., Org, User, Team)",
        required: true,
        example: "User",
      },
      {
        name: "userid",
        type: "query",
        description: "User ID for user-specific features",
        required: false,
        example: "12345",
      },
      {
        name: "teamid",
        type: "query",
        description: "Team ID for team-specific features",
        required: false,
        example: "54321",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/features/featureMarket?type=User&userid=12345' -H 'Authorization: Bearer <your-token>'",
    responseExample: {
      feature: [
        {
          id: 1,
          name: "Feature Name",
          description: "Feature Description",
          status: true,
        },
        {
          id: 2,
          name: "Another Feature",
          description: "Another Description",
          status: false,
        },
      ],
    },
    errorResponses: [{ code: 404, message: "No data found" }],
  },
  {
    name: "Feature Unavailable",
    method: "GET",
    category: "feature ",
    endpoint: "/features/featureUnavailable",
    parameters: [
      {
        name: "type",
        type: "query",
        description: "Type of feature (e.g., Org, User, Team)",
        required: true,
        example: "User",
      },
      {
        name: "userid",
        type: "query",
        description: "User ID for user-specific features",
        required: false,
        example: "12345",
      },
      {
        name: "teamid",
        type: "query",
        description: "Team ID for team-specific features",
        required: false,
        example: "54321",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/features/featureUnavailable?type=User&userid=12345' -H 'Authorization: Bearer <your-token>'",
    responseExample: {
      feature: [
        {
          id: 1,
          name: "Feature Name",
          description: "Feature Description",
        },
        {
          id: 2,
          name: "Another Feature",
          description: "Another Description",
        },
      ],
    },
    errorResponses: [{ code: 404, message: "No data found" }],
  },
  {
    name: "Add Feature Market",
    method: "POST",
    category: "feature ",
    endpoint: "/features/featureMarket",
    parameters: [
      {
        name: "featureid",
        type: "body",
        description: "ID of the feature to add",
        required: true,
        example: 1,
      },
      {
        name: "type",
        type: "body",
        description: "Type of feature market (e.g., Org, User, Team)",
        required: true,
        example: "User",
      },
      {
        name: "userid",
        type: "body",
        description: "User ID for user-specific features",
        required: false,
        example: "12345",
      },
      {
        name: "teamid",
        type: "body",
        description: "Team ID for team-specific features",
        required: false,
        example: "54321",
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/features/featureMarket\' -H \'Authorization: Bearer <your-token>\' -H \'Content-Type: application/json\' -d \'{"featureid":1,"type":"User","userid":"12345"}\'',
    responseExample: {
      status: "Data saved Successfully",
    },
    errorResponses: [{ code: 400, message: "Data already exists" }],
  },
  {
    name: "Update Feature Market",
    method: "PUT",
    category: "feature ",
    endpoint: "/features/featureMarket",
    parameters: [
      {
        name: "featureid",
        type: "body",
        description: "ID of the feature to update",
        required: true,
        example: 1,
      },
    ],
    requestExample:
      "curl -X PUT 'https://api.trypadlock.com/features/featureMarket' -H 'Authorization: Bearer <your-token>' -H 'Content-Type: application/json' -d '{\"featureid\":1}'",
    responseExample: {
      status: "Data saved Successfully",
    },
    errorResponses: [{ code: 400, message: "Data already exists" }],
  },
  {
    name: "Delete Feature Market",
    method: "DELETE",
    category: "feature ",
    endpoint: "/features/featureMarket",
    parameters: [
      {
        name: "featureid",
        type: "body",
        description: "ID of the feature to delete",
        required: true,
        example: 1,
      },
    ],
    requestExample:
      "curl -X DELETE 'https://api.trypadlock.com/features/featureMarket' -H 'Authorization: Bearer <your-token>' -H 'Content-Type: application/json' -d '{\"featureid\":1}'",
    responseExample: {
      status: "Data removed Successfully",
    },
    errorResponses: [{ code: 404, message: "No Data Found" }],
  },
  {
    name: "Feature Override",
    method: "GET",
    category: "feature ",
    endpoint: "/features/featureOveride",
    parameters: [
      {
        name: "featureid",
        type: "string",
        required: true,
        description: "Comma-separated list of feature IDs",
      },
      {
        name: "type",
        type: "string",
        required: true,
        description: "Type of the feature ('Org', 'Team', or 'User')",
      },
      {
        name: "status",
        type: "string",
        required: true,
        description: "Comma-separated list of feature statuses",
      },
      {
        name: "team_uuid",
        type: "string",
        required: false,
        description: "UUID of the team",
      },
      {
        name: "user_uuid",
        type: "string",
        required: false,
        description: "UUID of the user",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/features/featureOveride?featureid=1,2&type=Org&status=true' -H 'Authorization: Bearer <your-token>'",
    responseExample: {
      Status: [
        {
          id: "feature1",
          users: ["user1", "user2"],
          teams: ["team1", "team2"],
          org: ["Organization Name"],
        },
        {
          id: "feature2",
          users: ["user3"],
          teams: ["team3"],
          org: [],
        },
      ],
    },
    errorResponses: [
      { code: 400, message: "Invalid request parameters" },
      { code: 404, message: "Feature or related data not found" },
    ],
  },
  //manager
  {
    name: "Invite Manager",
    method: "POST",
    category: "manager ",
    endpoint: "/manager/invite",
    parameters: [
      {
        name: "useremail",
        type: "string",
        description: "Email address of the user to be invited.",
        required: true,
      },
      {
        name: "teams",
        type: "string",
        description:
          "Comma-separated list of team UUIDs to which the manager will be assigned.",
        required: true,
      },
      {
        name: "role",
        type: "string",
        description: "Role of the user.",
        required: true,
      },
      {
        name: "domain_name",
        type: "string",
        description: "Domain name for the email invitation. Optional.",
        required: false,
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/manager/invite\' -H \'Authorization: Bearer <your-token>\' -H \'Content-Type: application/json\' -d \'{"useremail": "example@example.com", "teams": "team1,team2", "role": "manager", "domain_name": "samsTaponShoulder"}\'',
    responseExample: {
      Status: "Email Sent Successfully",
    },
    errorResponses: [
      { code: 400, message: "Bad Request" },
      { code: 401, message: "Unauthorized" },
      { code: 404, message: "User or team not found" },
      { code: 500, message: "Internal Server Error" },
    ],
    description:
      "This endpoint allows an admin to invite a new manager by sending an invitation email. It includes checking if the user already exists, generating a JWT token for the invitation, and sending an email through AWS SES. If the user does not exist, the user is added to the verification and observer tables, and a log entry is created.",
  },
  {
    name: "Manager Register",
    method: "POST",
    category: "manager ",
    endpoint: "/manager/register",
    parameters: [
      {
        name: "token",
        type: "string",
        required: true,
        description: "JWT token used for verifying the invitation link.",
      },
      {
        name: "first_name",
        type: "string",
        required: true,
        description: "First name of the user.",
      },
      {
        name: "last_name",
        type: "string",
        required: true,
        description: "Last name of the user.",
      },
      {
        name: "username",
        type: "string",
        required: true,
        description: "Username of the user.",
      },
      {
        name: "password",
        type: "string",
        required: true,
        description: "Password for the user.",
      },
      {
        name: "ip_address",
        type: "string",
        required: false,
        description: "IP address of the user, used to determine timezone.",
      },
    ],
    requestExample:
      'curl -X POST https://api.trypadlock.com/manager/register -H "Content-Type: application/json" -d \'{"token": "<token>", "first_name": "John", "last_name": "Doe", "username": "johndoe", "password": "password123", "ip_address": "192.168.1.1"}\'',
    responseExample: {
      access_token: "jwt_token_here",
    },
    errorResponses: [
      { code: 400, message: "Token Not Found" },
      { code: 400, message: "Link Expired! Please contact to your admin." },
      { code: 400, message: "Application not exists" },
      { code: 302, message: "User already exists in this org" },
    ],
    description:
      "Registers a new manager after verifying the invitation token. It creates a user with the provided details, manages team memberships, and updates pricing information. If successful, it returns a JWT token.",
  },
  //link generator
  {
    name: "Email Magic Link Generator",
    method: "POST",
    category: "link generator ",
    endpoint: "/generator/email_magiclink",
    parameters: [
      { name: "org_name", type: "string", required: true },
      { name: "email", type: "string", required: true },
      { name: "application", type: "string", required: true },
      { name: "domain_name", type: "string", required: false },
    ],
    requestExample: `
        curl -X POST 'https://api.trypadlock.com/generator/email_magiclink' 
        -H 'Content-Type: application/json' 
        -d '{
          "org_name": "example_org",
          "email": "user@example.com",
          "application": "example_app",
          "domain_name": "samsTaponShoulder"
        }'`,
    responseExample: {
      status: "email sent",
    },
    errorResponses: [
      { code: 404, message: "Organization not found" },
      { code: 404, message: "User not found" },
      { code: 404, message: "Application not found" },
      { code: 404, message: "User application details not found" },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "Email Register Magic Link Generator",
    method: "POST",
    category: "link generator ",
    endpoint: "/generator/email_register_magiclink",
    parameters: [
      { name: "org_name", type: "string", required: true },
      { name: "email", type: "string", required: true },
      { name: "application", type: "string", required: true },
      { name: "ip_address", type: "string", required: false },
      { name: "domain_name", type: "string", required: false },
    ],
    requestExample: `
    curl -X POST 'https://api.trypadlock.com/generator/email_register_magiclink' 
    -H 'Content-Type: application/json' 
    -d '{
      "org_name": "example_org",
      "email": "user@example.com",
      "application": "example_app",
      "ip_address": "192.168.1.1",
      "domain_name": "samsTaponShoulder"
    }'`,
    responseExample: {
      status: "email sent",
    },
    errorResponses: [{ code: 500, message: "Internal Server Error" }],
  },
  //team
  {
    name: "Add Team Member",
    method: "PUT",
    category: "team ",
    endpoint: "/team/AddTeamMember/:org/:teamid",
    parameters: [
      { name: "org", type: "string", required: true, source: "path" },
      { name: "teamid", type: "string", required: true, source: "path" },
      { name: "team", type: "string", required: true, source: "query" },
      {
        name: "userid",
        type: "string",
        required: true,
        source: "query",
        description: "Comma-separated user IDs",
      },
    ],
    requestExample: `
    curl -X PUT 'https://api.trypadlock.com/team/AddTeamMember/org_uuid/team_uuid?team=team_name&userid=user1,user2'`,
    responseExample: {
      status: "Successfully added the user to team", // or "No users are found", "No Team is found"
    },
    errorResponses: [
      { code: 400, message: "No users are found" },
      { code: 404, message: "No Team is found" },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "Create Team",
    method: "POST",
    category: "team ",
    endpoint: "/team/addTeam",
    parameters: [
      { name: "name", type: "string", required: true, source: "body" },
      { name: "org_uuid", type: "string", required: true, source: "body" },
    ],
    requestExample: `
    curl -X POST 'https://api.trypadlock.com/team/addTeam' \\
    -H 'Content-Type: application/json' \\
    -d '{
      "name": "team_name",
      "org_uuid": "org_uuid"
    }'`,
    responseExample: {
      status: "Team created Successfully",
      uu_id: "generated_team_uuid",
    },
    errorResponses: [
      { code: 400, message: "Team is already found" },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "List Teams for Organization",
    method: "GET",
    category: "team ",
    endpoint: "/team/listTeam/:org",
    parameters: [
      { name: "org", type: "string", required: true, source: "path" },
      { name: "observer", type: "string", required: false, source: "query" },
    ],
    requestExample: `
    curl -X GET 'https://api.trypadlock.com/team/listTeam/org_uuid?observer=true'`,
    responseExample: {
      info: [
        {
          team: {
            id: 1,
            name: "team_name",
            org_uuid: "org_uuid",
            status: true,
          },
          count: 10,
        },
      ],
    },
    errorResponses: [
      { code: 404, message: "no team is found for org" },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "List Members in a Team",
    method: "GET",
    category: "team ",
    endpoint: "/team/listMember/:team",
    parameters: [
      { name: "team", type: "string", required: true, source: "path" },
    ],
    requestExample: `
    curl -X GET 'https://api.trypadlock.com/team/listMember/team_uuid'`,
    responseExample: {
      info: [
        {
          role: "role_name",
          user_uuid: "user_uuid",
          username: "username",
          email: "user@example.com",
        },
      ],
    },
    errorResponses: [
      { code: 404, message: "no team is found for org" },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "Delete Team",
    method: "DELETE",
    category: "team ",
    endpoint: "/team/teamDelete",
    parameters: [
      { name: "org", type: "string", required: true, source: "query" },
      { name: "teamid", type: "string", required: true, source: "query" },
    ],
    requestExample: `
    curl -X DELETE 'https://api.trypadlock.com/team/teamDelete?org=org_uuid&teamid=team_uuid'`,
    responseExample: {
      status: "Successfully deleted team",
    },
    errorResponses: [
      { code: 400, message: "team is already inactive" },
      { code: 404, message: "no team is found for org" },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "Remove User from Team",
    method: "DELETE",
    category: "team ",
    endpoint: "/team/DeleteTeamMember/:teamid/:userid",
    parameters: [
      { name: "teamid", type: "string", required: true, source: "path" },
      { name: "userid", type: "string", required: true, source: "path" },
    ],
    requestExample: `
    curl -X DELETE 'https://api.trypadlock.com/team/DeleteTeamMember/team_uuid/user_uuid'`,
    responseExample: {
      status: "User Removed successfully",
    },
    errorResponses: [
      { code: 404, message: "no team is found for org" },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "Remove Multiple Users from Team",
    method: "DELETE",
    category: "team ",
    endpoint: "/team/DeleteTeamMembers",
    parameters: [
      { name: "teamid", type: "string", required: true, source: "body" },
      {
        name: "userid",
        type: "string",
        required: true,
        source: "body",
        description: "Comma-separated user IDs",
      },
    ],
    requestExample: `
    curl -X DELETE 'https://api.trypadlock.com/team/DeleteTeamMembers' 
    -H 'Content-Type: application/json' 
    -d '{
      "teamid": "team_uuid",
      "userid": "user1,user2"
    }'`,
    responseExample: {
      status: "User Removed successfully",
    },
    errorResponses: [
      { code: 404, message: "no team is found for org" },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "List Inactive Teams for Organization",
    method: "GET",
    category: "team ",
    endpoint: "/team/listinactive/:org",
    parameters: [
      { name: "org", type: "string", required: true, source: "path" },
    ],
    requestExample: `
    curl -X GET 'https://api.trypadlock.com/team/listinactive/org_uuid'`,
    responseExample: {
      info: [
        {
          team: {
            id: 1,
            name: "team_name",
            org_uuid: "org_uuid",
            status: false,
          },
          count: 5,
        },
      ],
    },
    errorResponses: [
      { code: 404, message: "no team is found for org" },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "Reactivate Team",
    method: "POST",
    category: "team ",
    endpoint: "/team/teamAdd",
    parameters: [
      { name: "org", type: "string", required: true, source: "body" },
      { name: "teamid", type: "string", required: true, source: "body" },
    ],
    requestExample: `
    curl -X POST 'https://api.trypadlock.com/team/teamAdd' 
    -H 'Content-Type: application/json' 
    -d '{
      "org": "org_uuid",
      "teamid": "team_uuid"
    }'`,
    responseExample: {
      status: "Successfully reactivated team",
    },
    errorResponses: [
      { code: 400, message: "team is already active" },
      { code: 404, message: "no team is found for org" },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "List Teams for a User",
    method: "GET",
    category: "team ",
    endpoint: "/team/clientlist/:userid",
    parameters: [
      { name: "userid", type: "string", required: true, source: "path" },
    ],
    requestExample: `
    curl -X GET 'https://api.trypadlock.com/team/clientlist/user_uuid'`,
    responseExample: [
      {
        name: "team_name",
        id: 1,
      },
    ],
    errorResponses: [
      { code: 404, message: "no team is found for user" },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "List All Active Teams",
    method: "GET",
    category: "team ",
    endpoint: "/team/clientlist/",
    parameters: [],
    requestExample: `
    curl -X GET 'https://api.trypadlock.com/team/clientlist/'`,
    responseExample: [
      {
        name: "team_name",
        id: 1,
      },
    ],
    errorResponses: [
      { code: 404, message: "no team is found for user" },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "Get Team by Organization and Team Name",
    method: "POST",
    category: "team ",
    endpoint: "/team/getTeam",
    parameters: [
      { name: "org_name", type: "string", required: true, source: "body" },
      { name: "name", type: "string", required: true, source: "body" },
    ],
    requestExample: `
    curl -X POST 'https://api.trypadlock.com/team/getTeam' 
    -H 'Content-Type: application/json' 
    -d '{
      "org_name": "organization_name",
      "name": "team_name"
    }'`,
    responseExample: {
      name: "team_uuid",
    },
    errorResponses: [
      { code: 404, message: "Team not found" },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "List Teams Based on User",
    method: "GET",
    category: "team ",
    endpoint: "/team/teamBasedUser",
    parameters: [
      { name: "user_id", type: "string", required: true, source: "query" },
    ],
    requestExample: `
    curl -X GET 'https://api.trypadlock.com/team/teamBasedUser?user_id=user_uuid'`,
    responseExample: {
      data: [
        {
          id: 1,
          team_uuid: "team_uuid",
          name: "team_name",
        },
      ],
    },
    errorResponses: [
      { code: 404, message: "Teams not found for user" },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  //execute email
  {
    name: "Send Email with Download Links",
    method: "POST",
    category: "execute email ",
    endpoint: "/users/exe_email",
    parameters: [
      {
        name: "email",
        type: "string",
        required: true,
        source: "body",
        description: "Comma-separated list of email addresses",
      },
    ],
    requestExample: `
      curl -X POST 'https://api.trypadlock.com/users/exe_email' 
      -H 'Content-Type: application/json' 
      -d '{
        "email": "user1@example.com,user2@example.com"
      }'`,
    responseExample: {
      status: "successfully subscribed",
    },
    errorResponses: [
      { code: 500, message: "email not sent" },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  //observer
  {
    name: "Invite User",
    method: "POST",
    category: "observer ",
    endpoint: "/observer/invite",
    authentication: true,
    parameters: [
      {
        name: "useremail",
        type: "string",
        required: true,
        source: "body",
        description: "Email address of the user to be invited",
      },
      {
        name: "type",
        type: "string",
        required: true,
        source: "body",
        description: "Type of the user",
      },
      {
        name: "teams",
        type: "string",
        required: true,
        source: "body",
        description: "Comma-separated list of team UUIDs",
      },
      {
        name: "role",
        type: "string",
        required: true,
        source: "body",
        description: "Role of the user",
      },
      {
        name: "domain_name",
        type: "string",
        required: false,
        source: "body",
        description: "Domain name (optional)",
      },
    ],
    requestExample: `
      curl -X POST 'https://api.trypadlock.com/observer/invite' 
      -H 'Content-Type: application/json' 
      -H 'Authorization: Bearer <token>' 
      -d '{
        "useremail": "newuser@example.com",
        "type": "observer",
        "teams": "team1_uuid,team2_uuid",
        "role": "observer",
        "domain_name": "joinworkahub"
      }'`,
    responseExample: {
      Status: "Email Sent Successfully",
    },
    errorResponses: [
      { code: 400, message: "Bad Request" },
      { code: 401, message: "Unauthorized" },
      { code: 404, message: "User already exists" },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "Register User",
    method: "POST",
    category: "observer ",
    endpoint: "/observer/register",
    parameters: [
      {
        name: "token",
        type: "string",
        required: true,
        source: "body",
        description: "JWT token for verification",
      },
      {
        name: "first_name",
        type: "string",
        required: true,
        source: "body",
        description: "First name of the user",
      },
      {
        name: "last_name",
        type: "string",
        required: true,
        source: "body",
        description: "Last name of the user",
      },
      {
        name: "username",
        type: "string",
        required: true,
        source: "body",
        description: "Username for the user",
      },
      {
        name: "orgname",
        type: "string",
        required: true,
        source: "body",
        description: "Organization name",
      },
      {
        name: "password",
        type: "string",
        required: true,
        source: "body",
        description: "Password for the user",
      },
      {
        name: "ip_address",
        type: "string",
        required: false,
        source: "body",
        description: "IP address for timezone detection",
      },
    ],
    requestExample: `
      curl -X POST 'https://api.trypadlock.com/observer/register' 
      -H 'Content-Type: application/json' 
      -d '{
        "token": "your_jwt_token",
        "first_name": "John",
        "last_name": "Doe",
        "username": "johndoe",
        "orgname": "exampleOrg",
        "password": "password123",
        "ip_address": "192.168.1.1"
      }'`,
    responseExample: {
      status: "success",
      access_token: "your_jwt_access_token",
    },
    errorResponses: [
      { code: 400, message: "Token Not Found" },
      { code: 400, message: "Link Expired! Please contact to your admin." },
      { code: 400, message: "Application not exists" },
      { code: 302, message: "User already exists in this org" },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  //billing
  {
    name: "Get User Billing Type and Renewal Interval",
    method: "GET",
    category: "billing ",
    endpoint: "/users/type_interval",
    parameters: [
      {
        name: "email",
        type: "string",
        required: true,
        source: "query",
        description: "The email address of the user",
      },
    ],
    requestExample: `
      curl -X GET 'https://api.trypadlock.com/users/type_interval?email=user@example.com'
      -H 'Content-Type: application/json'`,
    responseExamples: [
      {
        status: 200,
        body: {
          data: "yearly",
          status: true,
          renewal: "2024-07-29T00:00:00.000Z",
        },
      },
      {
        status: 200,
        body: {
          data: "monthly",
          status: "no_plan",
          renewal: "2024-08-28T00:00:00.000Z",
        },
      },
      {
        status: 404,
        body: {
          error: "user details not found",
        },
      },
      {
        status: 404,
        body: {
          error: "user not found",
        },
      },
    ],
    errorResponses: [
      {
        code: 404,
        message: "user details not found",
      },
      {
        code: 404,
        message: "user not found",
      },
    ],
  },
  {
    name: "Update User Billing Type",
    method: "POST",
    category: "billing ",
    endpoint: "/users/type_interval",
    parameters: [
      {
        name: "email",
        type: "string",
        required: true,
        source: "body",
        description: "The email address of the user",
      },
      {
        name: "type",
        type: "string",
        required: true,
        source: "body",
        description: "The new billing type to update for the user",
      },
    ],
    requestExample: `
      curl -X POST 'https://api.trypadlock.com/users/type_interval'
      -H 'Content-Type: application/json'
      -d '{"email": "user@example.com", "type": "monthly"}'`,
    responseExamples: [
      {
        status: 200,
        body: {
          status: "type updated",
        },
      },
      {
        status: 404,
        body: {
          error: "user details not found",
        },
      },
      {
        status: 404,
        body: {
          error: "user not found",
        },
      },
    ],
    errorResponses: [
      {
        code: 404,
        message: "user details not found",
      },
      {
        code: 404,
        message: "user not found",
      },
    ],
  },
  {
    name: "Create Invoice",
    method: "POST",
    category: "billing ",
    endpoint: "/users/create_invoice",
    parameters: [
      {
        name: "org_uuid",
        type: "string",
        required: true,
        source: "body",
        description: "The unique identifier for the organization",
      },
      {
        name: "currency",
        type: "string",
        required: true,
        source: "body",
        description: "The currency in which the invoice is generated",
      },
      {
        name: "price",
        type: "number",
        required: true,
        source: "body",
        description: "The price per user",
      },
      {
        name: "regionid",
        type: "string",
        required: true,
        source: "body",
        description: "The region identifier",
      },
      {
        name: "features",
        type: "array",
        required: false,
        source: "body",
        description: "The list of features associated with the organization",
      },
      {
        name: "appname",
        type: "string",
        required: true,
        source: "body",
        description: "The name of the application",
      },
    ],
    requestExample: `
      curl -X POST 'https://api.trypadlock.com/users/create_invoice'
      -H 'Content-Type: application/json'
      -d '{
          "org_uuid": "org-uuid-example",
          "currency": "USD",
          "price": 10,
          "regionid": "region-123",
          "features": ["feature1", "feature2"],
          "appname": "MyApp"
        }'`,
    responseExamples: [
      {
        status: 200,
        body: {
          status: "Invoice created successfully",
          id: 123,
        },
      },
    ],
    errorResponses: [
      {
        code: 400,
        message: "Bad Request - Missing or invalid parameters",
      },
      {
        code: 404,
        message: "Organization or user not found",
      },
    ],
  },
  //price
  {
    name: "Create or Update Monetary Credit",
    method: "POST",
    category: "price ",
    endpoint: "/price/monetarycreditcreate",
    parameters: [
      {
        name: "credit_money",
        type: "number",
        required: true,
        source: "body",
        description: "The amount of money to be added or updated.",
      },
      {
        name: "org_name",
        type: "string",
        required: true,
        source: "body",
        description:
          "The name of the organization for which the monetary credit is being created or updated.",
      },
    ],
    requestExample: `
      curl -X POST 'https://api.trypadlock.com/price/monetarycreditcreate' 
      -H 'Content-Type: application/json' 
      -d '{
        "credit_money": 100,
        "org_name": "ExampleOrg"
      }'`,
    responseExample: {
      status: "Money created", // or "Money updated"
    },
    errorResponses: [{ code: 500, message: "Internal Server Error" }],
  },
  {
    name: "Get Plan Details",
    method: "GET",
    category: "price ",
    endpoint: "/price/plan_details",
    parameters: [
      {
        name: "email",
        type: "string",
        required: true,
        source: "body",
        description:
          "The email address of the user whose plan details are being requested.",
      },
    ],
    requestExample: `
      curl -X GET 'https://api.trypadlock.com/price/plan_details' 
      -H 'Content-Type: application/json' 
      -d '{
        "email": "user@example.com"
      }'`,
    responseExample: {
      plan_triel_period: 5, // Number of remaining trial days
      current_plan: {
        // Details of the current plan
      },
      no_of_users: 10, // Number of users associated with the email
    },
    errorResponses: [
      { code: 500, message: "Internal Server Error" },
      { code: 404, message: "User not available" },
      { code: 404, message: "price does not exist" },
    ],
  },
  {
    name: "Get Payment History",
    method: "GET",
    category: "price ",
    endpoint: "/price/payment_history",
    authentication: "required",
    parameters: [
      {
        name: "processed",
        type: "boolean",
        required: false,
        source: "query",
        description:
          "If true, fetches processed payment history; if false or not provided, fetches unprocessed payment history.",
      },
      {
        name: "rowscount",
        type: "integer",
        required: true,
        source: "query",
        description: "The number of rows to return in the result.",
      },
      {
        name: "rowstart",
        type: "integer",
        required: true,
        source: "query",
        description: "The starting index for pagination.",
      },
    ],
    requestExample: `
      curl -X GET 'https://api.trypadlock.com/price/payment_history?processed=true&rowscount=10&rowstart=0' 
      -H 'Authorization: Bearer <your-token>'`,
    responseExample: {
      data: [
        {
          payment_id: 1,
          username: "JohnDoe",
          email: "john.doe@example.com",
          payment_mode: "Credit",
          payment_date: "2024-07-26T00:00:00.000Z",
          next_due_date: "2024-08-26T00:00:00.000Z",
          totalprice: 99.99,
          currency: "USD",
          plan_name: "Premium",
          invoice_number: "INV-12345",
        },
      ],
    },
    errorResponses: [{ code: 500, message: "Internal Server Error" }],
  },
  {
    name: "Get Price History",
    method: "GET",
    category: "price ",
    endpoint: "/price/price_history",
    authentication: "required",
    parameters: [
      {
        name: "org_name",
        type: "string",
        required: true,
        source: "body",
        description:
          "The name of the organization for which to retrieve the price history.",
      },
    ],
    requestExample: `
      curl -X GET 'https://api.trypadlock.com/price/price_history' 
      -H 'Authorization: Bearer <your-token>' 
      -H 'Content-Type: application/json' 
      -d '{"org_name": "ExampleOrg"}'`,
    responseExample: [
      {
        id: 1,
        org_name: "ExampleOrg",
        available_money: 500,
        transaction_amount: 100,
        transaction_reason: "Added to wallet",
        created_at: "2024-07-25T00:00:00.000Z",
        updated_at: "2024-07-25T00:00:00.000Z",
      },
    ],
    errorResponses: [
      { code: 404, message: "Data not found" },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "Delete Price History",
    method: "DELETE",
    category: "price ",
    endpoint: "/price/delete_history",
    authentication: "required",
    parameters: [
      {
        name: "id",
        type: "integer",
        required: true,
        source: "body",
        description: "The ID of the price history record to delete.",
      },
    ],
    requestExample: `
      curl -X DELETE 'https://api.trypadlock.com/price/delete_history' 
      -H 'Authorization: Bearer <your-token>' 
      -H 'Content-Type: application/json' 
      -d '{"id": 123}'`,
    responseExample: {
      message: "History deleted",
    },
    errorResponses: [
      { code: 400, message: "Bad Request: Invalid ID" },
      { code: 404, message: "Not Found: History record does not exist" },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "Get Monetary Credit Balance",
    method: "GET",
    category: "price ",
    endpoint: "/price/monetarycredit_balance",
    authentication: "optional",
    parameters: [],
    requestExample: `
      curl -X GET 'https://api.trypadlock.com/price/monetarycredit_balance'`,
    responseExample: [
      {
        id: 1,
        org_name: "ExampleOrg",
        credit_money: 1000,
      },
      {
        id: 2,
        org_name: "AnotherOrg",
        credit_money: 500,
      },
    ],
    errorResponses: [{ code: 500, message: "Internal Server Error" }],
  },
  {
    name: "Get Price Overview",
    method: "GET",
    category: "price ",
    endpoint: "/price/overview",
    authentication: "required",
    parameters: [
      {
        name: "org_name",
        in: "body",
        description:
          "The name of the organization to retrieve the overview for",
        required: true,
        schema: {
          type: "string",
        },
      },
    ],
    requestExample: `
      curl -X GET 'https://api.trypadlock.com/price/overview' \
      -H 'Content-Type: application/json' \
      -d '{"org_name": "ExampleOrg"}'`,
    responseExample: {
      org: "ExampleOrg",
      username: "exampleUser",
      membership_start: "2024-01-01T00:00:00Z",
      subscription_name: "Pro Plan",
      members: 10,
      subcription_price: 1000,
      free_days: 30,
      amount: 500,
    },
    errorResponses: [
      { code: 404, message: "Price does not exist" },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "Set Price",
    method: "POST",
    category: "price ",
    endpoint: "/price/set_price",
    authentication: "required",
    parameters: [
      {
        name: "org_name",
        in: "body",
        description:
          "The name of the organization to set or update the price for",
        required: true,
        schema: {
          type: "string",
        },
      },
      {
        name: "price_per_user",
        in: "body",
        description: "The price per user for the organization",
        required: true,
        schema: {
          type: "number",
          format: "float",
        },
      },
      {
        name: "members",
        in: "body",
        description: "The number of members in the organization",
        required: true,
        schema: {
          type: "integer",
        },
      },
    ],
    requestExample: `
      curl -X POST 'https://api.trypadlock.com/price/set_price' \
      -H 'Content-Type: application/json' \
      -d '{
            "org_name": "ExampleOrg",
            "price_per_user": 20.5,
            "members": 15
          }'`,
    responseExample: "Price Set",
    errorResponses: [
      { code: 400, message: "Bad Request" },
      { code: 404, message: "Organization Not Found" },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "Subscription Payment",
    method: "POST",
    category: "price ",
    endpoint: "/price/subcription_payment",
    authentication: "required",
    parameters: [
      {
        name: "org_name",
        in: "body",
        description: "The name of the organization subscribing to the plan",
        required: true,
        schema: {
          type: "string",
        },
      },
    ],
    requestExample: `
      curl -X POST 'https://api.trypadlock.com/price/subcription_payment' \
      -H 'Content-Type: application/json' \
      -d '{
            "org_name": "ExampleOrg"
          }'`,
    responseExample: { status: "Plan Subscribed" },
    errorResponses: [
      { code: 400, message: "Bad Request" },
      { code: 404, message: "Organization Not Found" },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "Stripe Payment",
    method: "POST",
    category: "price ",
    endpoint: "/price/stripe_payment",
    authentication: "required",
    parameters: [
      {
        name: "number",
        in: "body",
        description: "Credit card number",
        required: true,
        schema: {
          type: "string",
        },
      },
      {
        name: "exp_month",
        in: "body",
        description: "Credit card expiration month",
        required: true,
        schema: {
          type: "integer",
        },
      },
      {
        name: "exp_year",
        in: "body",
        description: "Credit card expiration year",
        required: true,
        schema: {
          type: "integer",
        },
      },
      {
        name: "cvc",
        in: "body",
        description: "Credit card CVC code",
        required: true,
        schema: {
          type: "string",
        },
      },
    ],
    requestExample: `
      curl -X POST 'https://api.trypadlock.com/price/stripe_payment' \
      -H 'Content-Type: application/json' \
      -d '{
            "number": "4242424242424242",
            "exp_month": 12,
            "exp_year": 2024,
            "cvc": "123"
          }'`,
    responseExample: { message: "Payment successful" },
    errorResponses: [
      { code: 400, message: "Bad Request" },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "Create Feature",
    method: "POST",
    category: "price ",
    endpoint: "/price/features_create",
    authentication: "required",
    parameters: [
      {
        name: "name",
        in: "body",
        description: "Name of the feature",
        required: true,
        schema: {
          type: "string",
        },
      },
      {
        name: "feature_price",
        in: "body",
        description: "Price of the feature",
        required: true,
        schema: {
          type: "number",
          format: "float",
        },
      },
      {
        name: "description",
        in: "body",
        description: "Description of the feature",
        required: false,
        schema: {
          type: "string",
        },
      },
    ],
    requestExample: `
      curl -X POST 'https://api.trypadlock.com/price/features_create' \
      -H 'Content-Type: application/json' \
      -d '{
            "name": "Advanced Analytics",
            "feature_price": 29.99,
            "description": "Provides advanced analytics and reporting tools."
          }'`,
    responseExample: { message: "Feature created" },
    errorResponses: [
      { code: 400, message: "Bad Request" },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "Get Feature List",
    method: "GET",
    category: "price ",
    endpoint: "/price/feature_list",
    authentication: "optional",
    parameters: [],
    requestExample: `
      curl -X GET 'https://api.trypadlock.com/price/feature_list'`,
    responseExample: [
      {
        id: 1,
        name: "Advanced Analytics",
        feature_price: 29.99,
        description: "Provides advanced analytics and reporting tools.",
      },
      {
        id: 2,
        name: "Premium Support",
        feature_price: 49.99,
        description: "24/7 premium support with dedicated account manager.",
      },
    ],
    errorResponses: [{ code: 500, message: "Internal Server Error" }],
  },
  {
    name: "Update Feature",
    method: "PUT",
    category: "price ",
    endpoint: "/price/feature_update",
    authentication: "required",
    parameters: [
      {
        name: "name",
        type: "string",
        description: "The current name of the feature to be updated.",
        required: true,
      },
      {
        name: "update_name",
        type: "string",
        description: "The new name for the feature.",
        required: false,
      },
      {
        name: "feature_price",
        type: "number",
        description: "The new price for the feature.",
        required: false,
      },
      {
        name: "description",
        type: "string",
        description: "The new description for the feature.",
        required: false,
      },
    ],
    requestExample: `
      curl -X PUT 'https://api.trypadlock.com/price/feature_update' \
      -H 'Content-Type: application/json' \
      -d '{
        "name": "Advanced Analytics",
        "update_name": "Advanced Analytics Pro",
        "feature_price": 39.99,
        "description": "Provides advanced analytics and reporting tools with additional features."
      }'`,
    responseExample: {
      status: "Feature Updated",
    },
    errorResponses: [
      { code: 400, message: "Bad Request: Missing or invalid parameters." },
      { code: 404, message: "Feature not found." },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "Delete Feature",
    method: "DELETE",
    category: "price ",
    endpoint: "/price/delete_feature",
    authentication: "required",
    parameters: [
      {
        name: "name",
        type: "string",
        description: "The name of the feature to be deleted.",
        required: true,
      },
    ],
    requestExample: `
      curl -X DELETE 'https://api.trypadlock.com/price/delete_feature' \
      -H 'Content-Type: application/json' \
      -d '{
        "name": "Advanced Analytics"
      }'`,
    responseExample: {
      status: "Feature Deleted",
    },
    errorResponses: [
      { code: 400, message: "Bad Request: Missing or invalid parameters." },
      { code: 404, message: "Feature not found." },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "Add Feature",
    method: "POST",
    category: "price ",
    endpoint: "/price/feature_add",
    authentication: "required",
    parameters: [
      {
        name: "feature_name",
        type: "string",
        description: "The name of the feature to be added.",
        required: true,
      },
      {
        name: "org_name",
        type: "string",
        description:
          "The name of the organization to which the feature is being added.",
        required: true,
      },
    ],
    requestExample: `
      curl -X POST 'https://api.trypadlock.com/price/feature_add' \
      -H 'Content-Type: application/json' \
      -d '{
        "feature_name": "Advanced Analytics",
        "org_name": "Tech Innovations"
      }'`,
    responseExample: {
      status: "Feature added",
    },
    errorResponses: [
      { code: 400, message: "Bad Request: Missing or invalid parameters." },
      { code: 404, message: "Feature or organization not found." },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "Get Free Trial Details",
    method: "GET",
    category: "price ",
    endpoint: "/price/freetrail",
    authentication: "required",
    parameters: [
      {
        name: "email",
        type: "string",
        description: "The email address of the user.",
        required: true,
      },
    ],
    requestExample: `
      curl -X GET 'https://api.trypadlock.com/price/freetrail?email=user@example.com'`,
    responseExample: {
      plan_triel_period: 15,
      current_plan: {
        name: "Premium Plan",
        price: 100,
      },
      no_of_users: 5,
    },
    errorResponses: [
      { code: 400, message: "Bad Request: Missing or invalid parameters." },
      { code: 404, message: "User or data not found." },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "Get Invoice Details",
    method: "GET",
    category: "price ",
    endpoint: "/price/invoice",
    authentication: "required",
    parameters: [],
    requestExample: `
      curl -X GET 'https://api.trypadlock.com/price/invoice'`,
    responseExample: {
      data: {
        subscription: "Premium",
        users: 10,
        billed_on: "2024-07-15T00:00:00.000Z",
        valid_till: "2024-09-15T00:00:00.000Z",
        price: 200,
        invoice_number: "INV-12345",
        order_number: "ORD-67890",
        payment_by: "Credit Card",
      },
    },
    errorResponses: [
      { code: 404, message: "Invoice not found." },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  //workahub registration
  {
    name: "Admin Deactivate User",
    method: "POST",
    category: "workahub registration admin ",
    endpoint: "/users/admin_deactivate",
    authentication: "required",
    parameters: [
      {
        name: "email",
        type: "string",
        description: "The email address of the user to be deactivated.",
      },
    ],
    requestExample: `
      curl -X POST 'https://api.trypadlock.com/users/admin_deactivate' \
      -H 'Content-Type: application/json' \
      -d '{"email": "user@example.com"}'`,
    responseExample: {
      status: "admin deactivated",
    },
    errorResponses: [
      { code: 404, message: "User not found" },
      { code: 404, message: "App pricing not found" },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "Check User Email",
    method: "GET",
    category: "workahub registration admin ",
    endpoint: "/users/check_email",
    authentication: "optional",
    parameters: [
      {
        name: "email",
        type: "string",
        description: "The email address of the user to check.",
        required: true,
      },
      {
        name: "appid",
        type: "string",
        description: "The application ID to check the user's association with.",
        required: true,
      },
    ],
    requestExample: `
      curl -X GET 'https://api.trypadlock.com/users/check_email?email=user@example.com&appid=123'`,
    responseExample: {
      status: true,
    },
    errorResponses: [{ code: 500, message: "Internal Server Error" }],
  },
  //workahub dashboard
  {
    name: "Get Emails List",
    method: "GET",
    category: "workahub dashboard ",
    endpoint: "/users/emails_list",
    authentication: "required",
    parameters: [
      {
        name: "org",
        type: "string",
        description:
          "The organization name for which to retrieve the list of emails.",
        required: true,
      },
    ],
    requestExample: `
      curl -X GET 'https://api.trypadlock.com/users/emails_list?org=exampleOrg'`,
    responseExample: [
      {
        id: 1,
        email: "example1@example.com",
        update: "2024-07-27T10:00:00.000Z",
        type: "admin",
        status: "verified",
        roles: ["admin", "user"],
      },
      {
        id: 2,
        email: "example2@example.com",
        update: "2024-07-27T10:05:00.000Z",
        type: "user",
        status: "pending",
        roles: ["user"],
      },
    ],
    errorResponses: [{ code: 500, message: "Internal Server Error" }],
  },
  {
    name: "Update User Role",
    method: "PUT",
    category: "workahub dashboard ",
    endpoint: "/users/update_role",
    authentication: "required",
    parameters: [
      {
        name: "email",
        type: "string",
        description: "The email of the user whose role needs to be updated.",
        required: true,
      },
      {
        name: "role",
        type: "string",
        description:
          "The new role to be assigned to the user. Valid values are 'Admin', 'Manager', 'Regular', 'Observer'.",
        required: true,
      },
    ],
    requestExample: `
      curl -X PUT 'https://api.trypadlock.com/users/update_role?email=example@example.com&role=Admin'`,
    responseExample: {
      status: "Role Updated Successfully",
    },
    errorResponses: [
      { code: 404, message: "No record exists with the provided email" },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "Update Email in Verifyemail",
    method: "PUT",
    category: "workahub dashboard ",
    endpoint: "/users/emails_list",
    authentication: "required",
    parameters: [
      {
        name: "email",
        type: "string",
        description:
          "The new email address to be updated in the Verifyemail record.",
        required: true,
      },
      {
        name: "id",
        type: "integer",
        description: "The ID of the Verifyemail record to be updated.",
        required: true,
      },
    ],
    requestExample: `
      curl -X PUT 'https://api.trypadlock.com/users/emails_list?email=newemail@example.com&id=123'`,
    responseExample: {
      status: "successfully updated",
    },
    errorResponses: [
      { code: 404, message: "User not found" },
      { code: 404, message: "No record exists with the provided ID" },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "Delete Email from Verifyemail",
    method: "DELETE",
    category: "workahub dashboard ",
    endpoint: "/users/emails_list",
    authentication: "required",
    parameters: [
      {
        name: "email",
        type: "string",
        description: "The email address associated with the user.",
        required: true,
      },
      {
        name: "id",
        type: "integer",
        description: "The ID of the Verifyemail record to be deleted.",
        required: true,
      },
    ],
    requestExample: `
      curl -X DELETE 'https://api.trypadlock.com/users/emails_list?email=user@example.com&id=123'`,
    responseExample: {
      status: "successfully deleted",
    },
    errorResponses: [
      { code: 404, message: "User not found" },
      { code: 404, message: "Verifyemail record not found" },
      { code: 404, message: "Userdetail not found" },
      { code: 403, message: "Unauthorized action" },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "Dashboard Flow Status",
    method: "GET",
    category: "workahub dashboard ",
    endpoint: "/users/dashboard_flow",
    authentication: "required",
    parameters: [
      {
        name: "email",
        type: "string",
        description:
          "The email address of the user to check the dashboard flow status for.",
        required: true,
      },
    ],
    requestExample: `
      curl -X GET 'https://api.trypadlock.com/users/dashboard_flow?email=user@example.com'`,
    responseExample: {
      user: true,
      invite: true,
      payment: true,
      banner: true,
    },
    errorResponses: [
      { code: 404, message: "User not found" },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "Reactivate User",
    method: "POST",
    category: "workahub dashboard ",
    endpoint: "/users/reactivate_user",
    authentication: "required",
    parameters: [
      {
        name: "email",
        type: "string",
        description: "The email address of the user to reactivate.",
        required: true,
      },
    ],
    requestExample: `
      curl -X POST 'https://api.trypadlock.com/users/reactivate_user' \
      -H 'Content-Type: application/json' \
      -H 'Authorization: Bearer <token>' \
      -d '{"email": "user@example.com"}'`,
    responseExample: {
      Status: "User reactivated successfully",
    },
    errorResponses: [
      { code: 404, message: "User not found" },
      { code: 404, message: "App pricing record not found" },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  //timezone
  {
    name: "Get Time Zones",
    method: "GET",
    category: "timezone ",
    endpoint: "/users/time_zone",
    authentication: "none",
    parameters: [],
    requestExample: `
      curl -X GET 'https://api.trypadlock.com/users/time_zone'`,
    responseExample: `
      [
        {
          "id": 1,
          "time": "GMT+1",
          "times": "Europe/London"
        },
        {
          "id": 2,
          "time": "GMT+2",
          "times": "Europe/Berlin"
        }
      ]`,
    errorResponses: [
      { code: 404, message: "No data found" },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "Add Time Zone for Admin User",
    method: "POST",
    category: "timezone ",
    endpoint: "/users/admin_timezone",
    authentication: "required",
    parameters: [
      {
        name: "email",
        in: "body",
        required: true,
        type: "string",
        description: "Email address of the user.",
      },
      {
        name: "times",
        in: "body",
        required: true,
        type: "string",
        description: "Display time of the time zone to be added.",
      },
    ],
    requestExample: `
      curl -X POST 'https://api.trypadlock.com/users/admin_timezone' \
      -H 'Content-Type: application/json' \
      -d '{
        "email": "user@example.com",
        "times": "GMT+1"
      }'`,
    responseExample: {
      status: "time zone is added",
    },
    errorResponses: [
      { code: 400, message: "Bad Request" },
      { code: 404, message: "User not found" },
      { code: 404, message: "User detail not found" },
      { code: 404, message: "Time zone not found" },
      { code: 403, message: "User is not an admin" },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "Get Time Zones for Admin User",
    method: "GET",
    category: "timezone ",
    endpoint: "/users/admin_timezone",
    authentication: "required",
    parameters: [
      {
        name: "email",
        in: "query",
        required: true,
        type: "string",
        description: "Email address of the user.",
      },
    ],
    requestExample: `
      curl -X GET 'https://api.trypadlock.com/users/admin_timezone?email=user@example.com'`,
    responseExample: [
      {
        id: 1,
        time: "GMT+1",
      },
      {
        id: 2,
        time: "GMT-5",
      },
    ],
    errorResponses: [
      { code: 400, message: "Bad Request" },
      { code: 404, message: "No user data found" },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  //address
  {
    name: "Post Billing Address for User",
    method: "POST",
    category: "address ",
    endpoint: "/users/billing_address_post",
    authentication: "required",
    parameters: [
      {
        name: "email",
        in: "body",
        required: true,
        type: "string",
        description: "Email address of the user.",
      },
      {
        name: "name",
        in: "body",
        required: true,
        type: "string",
        description: "Name of the person or business.",
      },
      {
        name: "bussinessname",
        in: "body",
        required: true,
        type: "string",
        description: "Business name.",
      },
      {
        name: "address",
        in: "body",
        required: true,
        type: "string",
        description: "Billing address.",
      },
      {
        name: "city",
        in: "body",
        required: true,
        type: "string",
        description: "City of the billing address.",
      },
      {
        name: "state",
        in: "body",
        required: true,
        type: "string",
        description: "State of the billing address.",
      },
      {
        name: "country",
        in: "body",
        required: true,
        type: "string",
        description: "Country of the billing address.",
      },
      {
        name: "zip",
        in: "body",
        required: true,
        type: "string",
        description: "ZIP code of the billing address.",
      },
    ],
    requestExample: `
      curl -X POST 'https://api.trypadlock.com/users/billing_address_post' \
      -H 'Content-Type: application/json' \
      -d '{
        "email": "user@example.com",
        "name": "John Doe",
        "bussinessname": "John's Business",
        "address": "123 Main St",
        "city": "Hometown",
        "state": "State",
        "country": "Country",
        "zip": "12345"
      }'`,
    responseExample: {
      Status: "data saved",
    },
    errorResponses: [
      { code: 400, message: "Bad Request" },
      { code: 404, message: "User not found" },
      { code: 409, message: "Address already exists" },
      { code: 422, message: "Validation Error" },
    ],
  },
  {
    name: "Patch Billing Address for User",
    method: "PATCH",
    category: "address ",
    endpoint: "/users/billing_address_post",
    authentication: "required",
    parameters: [
      {
        name: "email",
        in: "body",
        required: true,
        type: "string",
        description: "Email address of the user.",
      },
      {
        name: "name",
        in: "body",
        required: true,
        type: "string",
        description: "Name of the person or business.",
      },
      {
        name: "bussinessname",
        in: "body",
        required: true,
        type: "string",
        description: "Business name.",
      },
      {
        name: "address",
        in: "body",
        required: true,
        type: "string",
        description: "Billing address.",
      },
      {
        name: "city",
        in: "body",
        required: true,
        type: "string",
        description: "City of the billing address.",
      },
      {
        name: "state",
        in: "body",
        required: true,
        type: "string",
        description: "State of the billing address.",
      },
      {
        name: "country",
        in: "body",
        required: true,
        type: "string",
        description: "Country of the billing address.",
      },
      {
        name: "zip",
        in: "body",
        required: true,
        type: "string",
        description: "ZIP code of the billing address.",
      },
    ],
    requestExample: `
      curl -X PATCH 'https://api.trypadlock.com/users/billing_address_post' \
      -H 'Content-Type: application/json' \
      -d '{
        "email": "user@example.com",
        "name": "John Doe",
        "bussinessname": "John's Business",
        "address": "123 Main St",
        "city": "Hometown",
        "state": "State",
        "country": "Country",
        "zip": "12345"
      }'`,
    responseExample: {
      Status: "data saved",
    },
    errorResponses: [
      { code: 400, message: "Bad Request" },
      { code: 404, message: "User not found" },
      { code: 409, message: "Address already exists" },
      { code: 422, message: "Validation Error" },
    ],
  },
  {
    name: "Get User Address",
    method: "GET",
    category: "address ",
    endpoint: "/users/getaddress/:email",
    authentication: "required",
    parameters: [
      {
        name: "email",
        in: "path",
        required: true,
        type: "string",
        description:
          "Email address of the user whose address is to be retrieved.",
      },
    ],
    requestExample: `
      curl -X GET 'https://api.trypadlock.com/users/getaddress/user@example.com'`,
    responseExample: {
      name: "John Doe",
      bussinessname: "John's Business",
      address: "123 Main St",
      city: "Hometown",
      state: "State",
      country: "Country",
      zip: "12345",
    },
    errorResponses: [
      { code: 404, message: "User or address not found" },
      { code: 401, message: "Unauthorized" },
      { code: 403, message: "Forbidden" },
    ],
  },
  {
    name: "Update User Address",
    method: "POST",
    category: "address ",
    endpoint: "/users/update_address",
    authentication: "required",
    parameters: [
      {
        name: "email",
        in: "body",
        required: true,
        type: "string",
        description:
          "Email address of the user whose address is to be updated.",
      },
      {
        name: "name",
        in: "body",
        required: false,
        type: "string",
        description:
          "Name for the address. If not provided, the existing name will be retained.",
      },
      {
        name: "bussinessname",
        in: "body",
        required: false,
        type: "string",
        description:
          "Business name for the address. If not provided, the existing business name will be retained.",
      },
      {
        name: "address",
        in: "body",
        required: false,
        type: "string",
        description:
          "Street address. If not provided, the existing address will be retained.",
      },
      {
        name: "city",
        in: "body",
        required: false,
        type: "string",
        description:
          "City of the address. If not provided, the existing city will be retained.",
      },
      {
        name: "state",
        in: "body",
        required: false,
        type: "string",
        description:
          "State of the address. If not provided, the existing state will be retained.",
      },
      {
        name: "country",
        in: "body",
        required: false,
        type: "string",
        description:
          "Country of the address. If not provided, the existing country will be retained.",
      },
      {
        name: "zip",
        in: "body",
        required: false,
        type: "string",
        description:
          "ZIP code of the address. If not provided, the existing ZIP code will be retained.",
      },
    ],
    requestExample: `
      curl -X POST 'https://api.trypadlock.com/users/update_address' \
      -H 'Content-Type: application/json' \
      -d '{
        "email": "user@example.com",
        "name": "John Doe",
        "bussinessname": "John's Business",
        "address": "123 Main St",
        "city": "Hometown",
        "state": "State",
        "country": "Country",
        "zip": "12345"
      }'`,
    responseExample: {
      status: "data updated successfully",
    },
    errorResponses: [
      { code: 404, message: "User or address not found" },
      { code: 401, message: "Unauthorized" },
      { code: 403, message: "Forbidden" },
      { code: 400, message: "Bad Request - Missing or invalid parameters" },
    ],
  },
  //workahub invoice
  {
    name: "Generate Invoice",
    method: "POST",
    category: "workahub invoice ",
    endpoint: "/users/generate_invoice",
    authentication: "required",
    authorization: "admin or Admin",
    parameters: [],
    requestExample: `
      curl -X POST 'https://api.trypadlock.com/users/generate_invoice' \
      -H 'Authorization: Bearer <token>'`,
    responseExample: [
      {
        id: 1,
        number_of_user: 50,
        price: 5000,
        payment_status: "paid",
        duedate: "2024-08-30T00:00:00.000Z",
        time: "2024-07-30T00:00:00.000Z",
      },
    ],
    errorResponses: [
      { code: 403, message: "Forbidden - User is not authorized" },
      {
        code: 500,
        message: "Internal Server Error - Unable to retrieve app pricing",
      },
    ],
  },
  {
    name: "Get User History",
    method: "GET",
    category: "workahub invoice ",
    endpoint: "/users/history",
    authentication: "required",
    authorization: "admin",
    parameters: [
      {
        name: "email",
        type: "query",
        required: true,
        description:
          "The email address of the user whose history is to be retrieved.",
      },
    ],
    requestExample: `
      curl -X GET 'https://api.trypadlock.com/users/history?email=user@example.com' \
      -H 'Authorization: Bearer <token>'`,
    responseExample: [
      {
        id: 1,
        balance: 1000,
        price: 500,
        time: "2024-07-01T00:00:00.000Z",
        team: "Team Alpha",
      },
    ],
    errorResponses: [
      { code: 403, message: "Forbidden - User is not authorized" },
      { code: 404, message: "Not Found - User or related data not found" },
      { code: 500, message: "Internal Server Error - Unable to retrieve data" },
    ],
  },
  {
    name: "Generate PDF Invoice",
    method: "POST",
    category: "workahub invoice ",
    endpoint: "/users/pdf_generator",
    authentication: "required",
    authorization: "admin",
    parameters: [
      {
        name: "id",
        type: "body",
        required: true,
        description:
          "The ID of the app pricing record to generate the invoice for.",
      },
    ],
    requestExample: `
      curl -X POST 'https://api.trypadlock.com/users/pdf_generator' \
      -H 'Authorization: Bearer <token>' \
      -H 'Content-Type: application/json' \
      -d '{"id": 1}'`,
    responseExample: `
      PDF file will be downloaded with the name 'MM-DD-YYYY.pdf'`,
    errorResponses: [
      { code: 403, message: "Forbidden - User is not authorized" },
      { code: 404, message: "Not Found - Data not found for the given ID" },
      {
        code: 500,
        message: "Internal Server Error - Unable to generate the PDF",
      },
    ],
  },
  {
    name: "Generate Bitwork PDF Invoice",
    method: "POST",
    category: "workahub invoice ",
    endpoint: "/users/pdf_bitwork_generator",
    authentication: "required",
    authorization: "social",
    parameters: [
      {
        name: "id",
        type: "body",
        required: true,
        description:
          "The ID of the app pricing record to generate the invoice for.",
      },
    ],
    requestExample: `
      curl -X POST 'https://api.trypadlock.com/users/pdf_bitwork_generator' \
      -H 'Authorization: Bearer <token>' \
      -H 'Content-Type: application/json' \
      -d '{"id": 1}'`,
    responseExample: {},
    errorResponses: [
      { code: 400, message: "Bad Request - Address not found for app" },
      { code: 404, message: "Not Found - Data not found for app" },
      {
        code: 500,
        message: "Internal Server Error - Unable to generate the PDF",
      },
    ],
  },
  {
    name: "Generate Workahub PDF Invoice",
    method: "POST",
    category: "workahub invoice ",
    endpoint: "/users/pdf_workahub_generator",
    authentication: "optional",
    parameters: [
      {
        name: "invoice",
        type: "body",
        required: true,
        description: "Invoice number for the PDF.",
      },
      {
        name: "billing_date",
        type: "body",
        required: true,
        description: "Billing date for the invoice.",
      },
      {
        name: "due_date",
        type: "body",
        required: true,
        description: "Due date for the invoice.",
      },
      {
        name: "company_city",
        type: "body",
        required: true,
        description: "City of the company issuing the invoice.",
      },
      {
        name: "company_name",
        type: "body",
        required: true,
        description: "Name of the company issuing the invoice.",
      },
      {
        name: "company_address_1",
        type: "body",
        required: true,
        description: "Address line 1 of the company issuing the invoice.",
      },
      {
        name: "company_address_2",
        type: "body",
        required: false,
        description: "Address line 2 of the company issuing the invoice.",
      },
      {
        name: "company_email",
        type: "body",
        required: true,
        description: "Email address of the company issuing the invoice.",
      },
      {
        name: "company_phone",
        type: "body",
        required: true,
        description: "Phone number of the company issuing the invoice.",
      },
      {
        name: "org_phone",
        type: "body",
        required: true,
        description: "Phone number of the organization.",
      },
      {
        name: "org_email",
        type: "body",
        required: true,
        description: "Email address of the organization.",
      },
      {
        name: "org_city",
        type: "body",
        required: true,
        description: "City of the organization.",
      },
      {
        name: "org_names",
        type: "body",
        required: true,
        description: "Name of the organization.",
      },
      {
        name: "org_address1",
        type: "body",
        required: true,
        description: "Address line 1 of the organization.",
      },
      {
        name: "org_address2",
        type: "body",
        required: false,
        description: "Address line 2 of the organization.",
      },
      {
        name: "sub_total",
        type: "body",
        required: true,
        description: "Subtotal amount for the invoice.",
      },
      {
        name: "discount",
        type: "body",
        required: true,
        description: "Discount applied to the subtotal.",
      },
      {
        name: "total",
        type: "body",
        required: true,
        description: "Total amount after discount.",
      },
      {
        name: "percentage",
        type: "body",
        required: true,
        description: "Percentage of discount applied.",
      },
      {
        name: "datas",
        type: "body",
        required: true,
        description: "Additional data to include in the invoice.",
      },
    ],
    requestExample: `
      curl -X POST 'https://api.trypadlock.com/users/pdf_workahub_generator' \
      -H 'Content-Type: application/json' \
      -d '{
        "invoice": "INV123456",
        "billing_date": "2024-07-15",
        "due_date": "2024-08-15",
        "company_city": "New York",
        "company_name": "Tech Solutions Inc.",
        "company_address_1": "123 Tech St",
        "company_address_2": "Suite 100",
        "company_email": "info@techsolutions.com",
        "company_phone": "+1234567890",
        "org_phone": "+0987654321",
        "org_email": "contact@org.com",
        "org_city": "San Francisco",
        "org_names": "Org Co.",
        "org_address1": "456 Org Ave",
        "org_address2": "Floor 2",
        "sub_total": "1000.00",
        "discount": "100.00",
        "total": "900.00",
        "percentage": "10%",
        "datas": []
      }`,
    responseExample: {},
    errorResponses: [
      { code: 400, message: "Bad Request - Missing or invalid data" },
      {
        code: 500,
        message: "Internal Server Error - Unable to generate the PDF",
      },
    ],
  },
  {
    name: "Send Invoice Email",
    method: "POST",
    category: "workahub invoice ",
    endpoint: "/users/email_invoice",
    authentication: "optional",
    parameters: [
      {
        name: "emails",
        type: "body",
        required: true,
        description:
          "Comma-separated email addresses to which the invoice will be sent.",
      },
      {
        name: "invoice_number",
        type: "body",
        required: true,
        description: "Invoice number to include in the email.",
      },
      {
        name: "invoice_date",
        type: "body",
        required: true,
        description: "Date of the invoice.",
      },
      {
        name: "due_amount",
        type: "body",
        required: true,
        description: "Amount due for the invoice.",
      },
      {
        name: "due_date",
        type: "body",
        required: true,
        description: "Due date for the invoice.",
      },
      {
        name: "org_name",
        type: "body",
        required: true,
        description: "Name of the organization issuing the invoice.",
      },
      {
        name: "client_name",
        type: "body",
        required: true,
        description: "Name of the client receiving the invoice.",
      },
      {
        name: "month_year",
        type: "body",
        required: true,
        description: "Month and year for the invoice.",
      },
    ],
    requestExample: `
      curl -X POST 'https://api.trypadlock.com/users/email_invoice' \
      -H 'Content-Type: application/json' \
      -d '{
        "emails": "example1@example.com,example2@example.com",
        "invoice_number": "INV123456",
        "invoice_date": "2024-07-15",
        "due_amount": "900.00",
        "due_date": "2024-08-15",
        "org_name": "Tech Solutions Inc.",
        "client_name": "John Doe",
        "month_year": "July 2024"
      }`,
    responseExample: {
      message: "email is sent",
    },
    errorResponses: [
      { code: 400, message: "Bad Request - Missing or invalid data" },
      { code: 500, message: "Internal Server Error - Unable to send email" },
    ],
  },
  //users

  {
    name: "users Create Application",
    method: "POST",
    category: "users ",
    endpoint: "/users/create_app",
    parameters: [
      {
        name: "org_name",
        type: "string",
        required: true,
      },
      {
        name: "project_name",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/create_app\' -H \'Content-Type: application/json\' -d \'{"org_name": "Example Org", "project_name": "Example Project"}\'',
    responseExample: {
      status: "Created",
    },
    errorResponses: [
      {
        code: 400,
        message: "Bad Request",
      },
    ],
  },
  {
    name: "Create Org User",
    method: "POST",
    category: "users ",
    endpoint: "/users/OrgUser",
    parameters: [
      {
        name: "email",
        type: "string",
        required: true,
      },
      {
        name: "appid",
        type: "string",
        required: true,
      },
      {
        name: "org_uuid",
        type: "string",
        required: true,
      },
      {
        name: "first_name",
        type: "string",
        required: true,
      },
      {
        name: "last_name",
        type: "string",
        required: true,
      },
      {
        name: "username",
        type: "string",
        required: true,
      },
      {
        name: "password",
        type: "string",
        required: true,
      },
      {
        name: "roles",
        type: "string",
        required: true,
      },
      {
        name: "time_zone",
        type: "string",
        required: false,
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/OrgUser\' -H \'Content-Type: application/json\' -d \'{"email": "user@example.com", "appid": "2", "org_uuid": "org-uuid", "first_name": "John", "last_name": "Doe", "username": "johndoe", "password": "password123", "roles": "manager", "time_zone": "Asia/Kolkata"}\'',
    responseExample: {
      status: "user got created",
    },
    errorResponses: [
      {
        code: 400,
        message: "Email already exists or invalid email",
      },
    ],
  },
  {
    name: "Get Users Profile",
    method: "GET",
    category: "users ",
    endpoint: "/users/profile",
    parameters: [],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/profile' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",
    responseExample: {
      id: "string",
      email: "string",
      username: "string",
      org_uuid: "string",
      user_uuid: "string",
      password: "string",
    },
    errorResponses: [
      {
        code: 401,
        message: "Unauthorized",
      },
    ],
  },
  {
    name: "Verification Email",
    method: "POST",
    category: "users ",
    endpoint: "/users/verification",
    parameters: [
      {
        name: "email",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/users/verification' -H 'Content-Type: application/json' -d '{\"email\": \"user@example.com\"}'",
    responseExample: {
      sent: true,
      msg: "Mail Sent!",
      token: "string",
    },
    errorResponses: [
      {
        code: 400,
        message: "Bad Request",
      },
    ],
  },
  {
    name: "Activate User Account",
    method: "GET",
    category: "users ",
    endpoint: "/users/activate/:token",
    parameters: [
      {
        name: "token",
        type: "path",
        description: "JWT token for activation",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/activate/YOUR_JWT_TOKEN'",
    responseExample: null,
    errorResponses: [
      { code: 302, message: "Redirect to signup or set password page" },
      { code: 400, message: "Expired or invalid token" },
    ],
  },
  {
    name: "Get Organization List",
    method: "GET",
    category: "users ",
    endpoint: "/users/organization_list",
    parameters: [
      {
        name: "email",
        type: "body",
        description: "Email to find organizations",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/organization_list' -H 'Content-Type: application/json' -d '{\"email\": \"user@example.com\"}'",
    responseExample: {
      data: [
        {
          id: "string",
          email: "string",
        },
      ],
    },
    errorResponses: [
      { code: 404, message: "Organization not found" },
      { code: 500, message: "Error retrieving organizations" },
    ],
  },
  {
    name: "Create Subscription",
    method: "POST",
    category: "users ",
    endpoint: "/users/subcription_create",
    parameters: [
      {
        name: "name",
        type: "body",
        description: "Name of the subscription",
      },
      {
        name: "price",
        type: "body",
        description: "Price of the subscription",
      },
      {
        name: "no_of_users",
        type: "body",
        description: "Number of users for the subscription",
      },
      {
        name: "free_days",
        type: "body",
        description: "Number of free days",
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/subcription_create\' -H \'Content-Type: application/json\' -d \'{"name": "Pro", "price": 29.99, "no_of_users": 10, "free_days": 7}\'',
    responseExample: {
      message: "created",
    },
    errorResponses: [
      { code: 400, message: "Invalid request body" },
      { code: 500, message: "Error creating subscription" },
    ],
  },
  {
    name: "Update Subscription",
    method: "PUT",
    category: "users ",
    endpoint: "/users/subscription_update",
    parameters: [
      {
        name: "subscription_name",
        type: "body",
        description: "New name of the subscription",
      },
      {
        name: "price",
        type: "body",
        description: "New price of the subscription",
      },
      {
        name: "no_of_users",
        type: "body",
        description: "New number of users for the subscription",
      },
      {
        name: "free_days",
        type: "body",
        description: "New number of free days",
      },
      {
        name: "name",
        type: "body",
        description: "Current name of the subscription to update",
      },
    ],
    requestExample:
      'curl -X PUT \'https://api.trypadlock.com/users/subscription_update\' -H \'Content-Type: application/json\' -d \'{"subscription_name": "Pro Plus", "price": 39.99, "no_of_users": 15, "free_days": 14, "name": "Pro"}\'',
    responseExample: {
      message: "Updated",
    },
    errorResponses: [
      { code: 400, message: "Invalid request body" },
      { code: 404, message: "Subscription not found" },
      { code: 500, message: "Error updating subscription" },
    ],
  },
  {
    name: "Get Subscriptions List",
    method: "GET",
    category: "users ",
    endpoint: "/users/subcriptions_list",
    parameters: [],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/subcriptions_list'",
    responseExample: {
      data: [
        {
          id: "string",
          name: "string",
          price: "number",
          no_of_users: "number",
          free_days: "number",
        },
      ],
    },
    errorResponses: [{ code: 500, message: "Error retrieving subscriptions" }],
  },
  {
    name: "Get Subscription Details",
    method: "GET",
    category: "users ",
    endpoint: "/users/subcription_details",
    parameters: [
      {
        name: "name",
        type: "string",
        description: "The name of the subscription to retrieve details for.",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/subcription_details' -H 'Content-Type: application/json' -d '{\"name\": \"example_subscription_name\"}'",
    responseExample: {
      data: {
        id: "string",
        name: "string",
        status: true,
        additional_info: "string",
      },
    },
    errorResponses: [
      {
        code: 404,
        message: "Subscription not found",
      },
    ],
  },
  {
    name: "Delete Subscription",
    method: "DELETE",
    category: "users ",
    endpoint: "/users/delete_subscription",
    parameters: [
      {
        name: "subscription_name",
        type: "string",
        description: "The name of the subscription to delete.",
      },
    ],
    requestExample:
      "curl -X DELETE 'https://api.trypadlock.com/users/delete_subscription' -H 'Content-Type: application/json' -d '{\"subscription_name\": \"example_subscription_name\"}'",
    responseExample: {
      message: "Subscription deleted",
    },
    errorResponses: [
      {
        code: 404,
        message: "Subscription not found",
      },
    ],
  },
  {
    name: "Delete Account",
    method: "DELETE",
    category: "users ",
    endpoint: "/users/delete_account",
    parameters: [
      {
        name: "org_name",
        type: "string",
        description:
          "The name of the organization whose account is to be deleted.",
      },
    ],
    requestExample:
      "curl -X DELETE 'https://api.trypadlock.com/users/delete_account' -H 'Content-Type: application/json' -d '{\"org_name\": \"example_org_name\"}'",
    responseExample: {
      message: "Account deleted",
    },
    errorResponses: [
      {
        code: 404,
        message: "Organization not found",
      },
    ],
  },
  {
    name: "Login OTP",
    method: "POST",
    category: "users ",
    endpoint: "/users/login_otp",
    parameters: [
      {
        name: "email",
        type: "string",
        description: "The email address of the user requesting OTP.",
      },
      {
        name: "app",
        type: "string",
        description: "The application name for which OTP is being requested.",
      },
      {
        name: "data",
        type: "string",
        description: "Additional data or string required for OTP generation.",
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/login_otp\' -H \'Content-Type: application/json\' -d \'{"email": "user@example.com", "app": "example_app", "data": "some_data"}\'',
    responseExample: {
      status: "email sent successfully",
    },
    errorResponses: [
      {
        code: 400,
        message: "Invalid request data",
      },
      {
        code: 404,
        message: "Application or user not found",
      },
    ],
  },
  {
    name: "Verify Login OTP by Email",
    method: "POST",
    category: "users ",
    endpoint: "/users/login_otp_verify",
    parameters: [
      { name: "app", type: "string", required: true },
      { name: "email", type: "string", required: true },
      { name: "otp", type: "string", required: true },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/login_otp_verify\' -H \'Content-Type: application/json\' -d \'{"app": "exampleApp", "email": "user@example.com", "otp": "123456"}\'',
    responseExample: {
      token: "string",
    },
    errorResponses: [
      { code: 400, message: "Incorrect OTP" },
      { code: 400, message: "app not found" },
      { code: 400, message: "Email not found" },
    ],
  },
  {
    name: "Verify Login OTP by Phone",
    method: "POST",
    category: "users ",
    endpoint: "/users/login_otp_phoneverify",
    parameters: [
      { name: "phone", type: "string", required: true },
      { name: "app", type: "string", required: true },
      { name: "otp", type: "string", required: true },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/login_otp_phoneverify\' -H \'Content-Type: application/json\' -d \'{"phone": "1234567890", "app": "exampleApp", "otp": "123456"}\'',
    responseExample: {
      token: "string",
    },
    errorResponses: [
      { code: 400, message: "Incorrect OTP" },
      { code: 400, message: "app not found" },
    ],
  },
  {
    name: "Create Application",
    method: "POST",
    category: "users ",
    endpoint: "/users/createapp",
    parameters: [
      { name: "name", type: "string", required: true },
      { name: "email", type: "string", required: true },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/createapp\' -H \'Content-Type: application/json\' -d \'{"name": "exampleApp", "email": "user@example.com"}\'',
    responseExample: {
      status: "app is created",
    },
    errorResponses: [
      { code: 200, message: "app not created" },
      { code: 200, message: "User not found" },
      { code: 200, message: "app exist" },
    ],
  },
  {
    name: "Delete Application",
    method: "DELETE",
    category: "users ",
    endpoint: "/users/delete_app",
    parameters: [
      { name: "name", type: "string", required: true },
      { name: "email", type: "string", required: true },
    ],
    requestExample:
      'curl -X DELETE \'https://api.trypadlock.com/users/delete_app\' -H \'Content-Type: application/json\' -d \'{"name": "exampleApp", "email": "admin@example.com"}\'',
    responseExample: {
      status: "App deleted",
    },
    errorResponses: [
      { code: 400, message: "user not exist" },
      { code: 400, message: "app not exist" },
    ],
  },
  {
    name: "Add Application to User",
    method: "POST",
    category: "users ",
    endpoint: "/users/application_add",
    parameters: [
      { name: "user", type: "string", required: true },
      { name: "appid", type: "string", required: true },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/application_add\' -H \'Content-Type: application/json\' -d \'{"user": "userId", "appid": "appId"}\'',
    responseExample: {
      status: "App added to the user",
    },
    errorResponses: [
      { code: 400, message: "user not exist" },
      { code: 400, message: "app not exist" },
      { code: 400, message: "App already exists with user" },
    ],
  },
  {
    name: "Send OTP via SMS",
    method: "POST",
    category: "users ",
    endpoint: "/users/login_sms",
    parameters: [
      {
        name: "phone",
        type: "string",
        description: "Phone number to which OTP is sent",
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/users/login_sms' -H 'Content-Type: application/json' -d '{\"phone\": \"1234567890\"}'",
    responseExample: {
      error: "app not exist",
    },
    errorResponses: [
      {
        code: 500,
        message: "Error sending OTP",
      },
    ],
  },
  {
    name: "Phone Login",
    method: "POST",
    category: "users ",
    endpoint: "/users/phone_login",
    parameters: [
      {
        name: "phone",
        type: "string",
        description: "Phone number of the user",
      },
      {
        name: "app",
        type: "string",
        description: "Application name",
      },
      {
        name: "data",
        type: "string",
        description: "Optional additional data to include in the OTP message",
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/phone_login\' -H \'Content-Type: application/json\' -d \'{"phone": "1234567890", "app": "exampleApp", "data": "extra info"}\'',
    responseExample: {
      status: "sms sent successfully",
    },
    errorResponses: [
      {
        code: 404,
        message: "Application not found",
      },
      {
        code: 404,
        message: "User not found",
      },
      {
        code: 400,
        message: "User data not saved",
      },
    ],
  },
  {
    name: "Update App Pricing",
    method: "POST",
    category: "users ",
    endpoint: "/users/app_pricing",
    parameters: [
      {
        name: "name",
        type: "string",
        description: "Application name for which the pricing is updated",
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/users/app_pricing' -H 'Content-Type: application/json' -d '{\"name\": \"exampleApp\"}'",
    responseExample: {
      status: "Updated the payment",
    },
    errorResponses: [
      {
        code: 404,
        message: "App not found",
      },
      {
        code: 404,
        message: "No users for application",
      },
    ],
  },
  {
    name: "Process App Payment",
    method: "POST",
    category: "users ",
    endpoint: "/users/app_price_payment",
    parameters: [
      {
        name: "email",
        type: "string",
        description: "User email address",
      },
      {
        name: "stripeToken",
        type: "object",
        description: "Stripe token object containing payment details",
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/app_price_payment\' -H \'Content-Type: application/json\' -d \'{"email": "user@example.com", "stripeToken": {"id": "tok_visa"}}\'',
    responseExample: {
      status: "Payment successful",
    },
    errorResponses: [
      {
        code: 404,
        message: "User not found",
      },
      {
        code: 404,
        message: "Application not found",
      },
      {
        code: 400,
        message: "No wallet exists",
      },
    ],
  },
  {
    name: "Get PDF Invoice",
    method: "GET",
    category: "users ",
    endpoint: "/users/pdf_invoice/:email/:id",
    parameters: [
      {
        name: "email",
        type: "string",
        description: "User's email address",
      },
      {
        name: "id",
        type: "string",
        description: "ID for the invoice",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/pdf_invoice/user@example.com/123' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",
    responseExample: {
      id: "string",
      name: "string",
      totalprice: "number",
      numberuser: "number",
      paymentstatus: "string",
      type: "string",
      duedate: "string",
      subcription: "string",
      created: "string",
    },
    errorResponses: [
      { code: 404, message: "User not found" },
      { code: 404, message: "Application not found" },
      { code: 404, message: "Data not found for app" },
    ],
  },
  {
    name: "Get Invoice App",
    method: "GET",
    category: "users ",
    endpoint: "/users/invoce_app/:email/:id",
    parameters: [
      {
        name: "email",
        type: "string",
        description: "User's email address",
      },
      {
        name: "id",
        type: "string",
        description: "ID for the invoice",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/invoce_app/user@example.com/123' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",
    responseExample: {
      app_name: "string",
      totalprice: "number",
      numberuser: "number",
      paymentstatus: "string",
    },
    errorResponses: [
      { code: 404, message: "User not found" },
      { code: 404, message: "Application not found" },
      { code: 404, message: "Data not found for app" },
    ],
  },
  {
    name: "Workahub Manual Payment",
    method: "POST",
    category: "users ",
    endpoint: "/users/workahub_manual_payment",
    parameters: [
      { name: "email", type: "string", required: true },
      { name: "name", type: "string", required: true },
      { name: "amount", type: "number", required: true },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/workahub_manual_payment\' -H \'Content-Type: application/json\' -d \'{"email":"user@example.com", "name":"AppName", "amount":10}\'',
    responseExample: { Status: "Successfully created" },
    errorResponses: [
      { code: 404, message: "User not found" },
      { code: 404, message: "Application not found" },
      { code: 404, message: "No User found" },
    ],
  },
  {
    name: "Invoice User",
    method: "POST",
    category: "users ",
    endpoint: "/users/invoice_user",
    parameters: [{ name: "email", type: "string", required: true }],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/users/invoice_user' -H 'Content-Type: application/json' -d '{\"email\":\"user@example.com\"}'",
    responseExample: [
      {
        email: "user@example.com",
        number_of_user: 5,
        app_name: "AppName",
        price: 100,
        region: "US",
        currency: "USD",
        payment_status: "paid",
        time: "2024-07-29T12:00:00Z",
      },
    ],
    errorResponses: [
      { code: 404, message: "User not found" },
      { code: 404, message: "No apps are found" },
      { code: 404, message: "app not found" },
    ],
  },
  {
    name: "Invite Referral",
    method: "POST",
    category: "users ",
    endpoint: "/users/invite_referal",
    parameters: [
      { name: "email", type: "string", required: true },
      { name: "emailer", type: "string", required: true },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/invite_referal\' -H \'Content-Type: application/json\' -d \'{"email":"referral@example.com", "emailer":"user@example.com"}\'',
    responseExample: { Status: "Email Sent Successfully" },
    errorResponses: [
      { code: 404, message: "User not found" },
      { code: 404, message: "Application doesn't exist" },
      { code: 404, message: "Referal not found" },
    ],
  },
  {
    name: "Workahub App Pricing",
    method: "POST",
    category: "users ",
    endpoint: "/users/workahub/app_pricing",
    parameters: [{ name: "email", type: "string", required: true }],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/users/workahub/app_pricing' -H 'Content-Type: application/json' -d '{\"email\":\"user@example.com\"}'",
    responseExample: { Status: "Successfully added" },
    errorResponses: [
      { code: 404, message: "User not found" },
      { code: 404, message: "Userdetail not found" },
      { code: 404, message: "Subcriptions not found" },
    ],
  },
  {
    name: "Invite User by Email",
    method: "POST",
    category: "users ",
    endpoint: "/users/invite_email",
    parameters: [
      {
        name: "useremail",
        type: "string",
        description: "Email address of the user to invite",
      },
      {
        name: "type",
        type: "string",
        description: "Type of invitation",
      },
      {
        name: "role",
        type: "string",
        description: "Role of the user being invited",
      },
      {
        name: "domain_name",
        type: "string",
        description: "Domain name for customizing invitation",
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/invite_email\' -H \'Authorization: Bearer YOUR_ACCESS_TOKEN\' -H \'Content-Type: application/json\' -d \'{"useremail": "user@example.com", "type": "invite", "role": "user", "domain_name": "exampleDomain"}\'',
    responseExample: {
      Status: "Email Sent Successfully",
    },
    errorResponses: [
      {
        code: 400,
        message: "Invalid request parameters",
      },
      {
        code: 401,
        message: "Unauthorized access",
      },
    ],
  },
  {
    name: "Upgrade Subscription",
    method: "POST",
    category: "users ",
    endpoint: "/users/upgrade_subcription",
    parameters: [
      {
        name: "plan_name",
        type: "string",
        description: "Name of the subscription plan to upgrade to",
      },
      {
        name: "email",
        type: "string",
        description: "Email address of the user",
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/users/upgrade_subcription' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN' -H 'Content-Type: application/json' -d '{\"plan_name\": \"premium\", \"email\": \"user@example.com\"}'",
    responseExample: "Subcription updated",
    errorResponses: [
      {
        code: 400,
        message: "Invalid request parameters",
      },
      {
        code: 404,
        message: "User or subscription not found",
      },
    ],
  },
  {
    name: "Check User Count Against Subscription",
    method: "POST",
    category: "users ",
    endpoint: "/users/user_count_check",
    parameters: [
      {
        name: "email",
        type: "string",
        description: "Email address of the user",
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/users/user_count_check' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN' -H 'Content-Type: application/json' -d '{\"email\": \"user@example.com\"}'",
    responseExample: {
      Status: "Number of Users are more than plan",
    },
    errorResponses: [
      {
        code: 400,
        message: "Invalid request parameters",
      },
      {
        code: 404,
        message: "User or subscription not found",
      },
    ],
  },
  {
    name: "Employee Registration",
    method: "POST",
    category: "users ",
    endpoint: "/users/employee_register",
    parameters: [
      {
        name: "token",
        type: "string",
        description: "JWT token for verification",
      },
      {
        name: "first_name",
        type: "string",
        description: "Employee's first name",
      },
      {
        name: "last_name",
        type: "string",
        description: "Employee's last name",
      },
      { name: "username", type: "string", description: "Employee's username" },
      { name: "password", type: "string", description: "Employee's password" },
      { name: "languages", type: "string", description: "Preferred language" },
      {
        name: "ip_address",
        type: "string",
        description: "IP address for timezone detection",
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/employee_register\' -H \'Content-Type: application/json\' -d \'{"token":"YOUR_TOKEN", "first_name":"John", "last_name":"Doe", "username":"johndoe", "password":"YOUR_PASSWORD", "languages":"en", "ip_address":"YOUR_IP_ADDRESS"}\'',
    responseExample: {
      access_token: "string",
    },
    errorResponses: [
      { code: 400, message: "Token Not Found" },
      { code: 400, message: "Link Expired! Please contact to your admin." },
      { code: 302, message: "User already exists in this org" },
      { code: 400, message: "Application not exists" },
    ],
  },
  {
    name: "Invite Email Users",
    method: "POST",
    category: "users ",
    endpoint: "/users/invite_email_users",
    parameters: [
      {
        name: "email",
        type: "string",
        description: "Email of the user to invite",
      },
      {
        name: "app",
        type: "string",
        description: "Application name for which the user is invited",
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/invite_email_users\' -H \'Content-Type: application/json\' -d \'{"email":"user@example.com", "app":"workahub"}\'',
    responseExample: {
      Status: "string",
    },
    errorResponses: [
      { code: 404, message: "Application doesn't exist" },
      { code: 404, message: "User already exists" },
    ],
  },
  {
    name: "Register Fangate User",
    method: "POST",
    category: "users ",
    endpoint: "/users/register_fangate",
    parameters: [
      {
        name: "email",
        type: "string",
        required: true,
      },
      {
        name: "username",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/register_fangate\' -H \'Content-Type: application/json\' -d \'{"email": "user@example.com", "username": "username"}\'',
    responseExample: {
      Status: "Email Sent Successfully",
    },
    errorResponses: [
      {
        code: 400,
        message: "Account Already Exists with the email",
      },
      {
        code: 404,
        message: "Application doesn't exist",
      },
      {
        code: 500,
        message: "User Data Not Saved",
      },
    ],
  },
  {
    name: "Fangate Verification (GET)",
    method: "GET",
    category: "users ",
    endpoint: "/users/fangate_verification",
    parameters: [
      {
        name: "email",
        type: "string",
        required: true,
      },
      {
        name: "otp",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/fangate_verification?email=user@example.com&otp=123456'",
    responseExample: {
      token: "string",
    },
    errorResponses: [
      {
        code: 400,
        message: "Incorrect OTP",
      },
      {
        code: 400,
        message: "Email not found",
      },
    ],
  },
  {
    name: "Fangate Verification (POST)",
    method: "POST",
    category: "users ",
    endpoint: "/users/fangate_verification",
    parameters: [
      {
        name: "email",
        type: "string",
        required: true,
      },
      {
        name: "otp",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/fangate_verification\' -H \'Content-Type: application/json\' -d \'{"email": "user@example.com", "otp": "123456"}\'',
    responseExample: {
      token: "string",
    },
    errorResponses: [
      {
        code: 400,
        message: "Incorrect OTP",
      },
      {
        code: 400,
        message: "Email not found",
      },
    ],
  },
  {
    name: "Login Fangate User",
    method: "POST",
    category: "users ",
    endpoint: "/users/login_fangate",
    parameters: [
      {
        name: "email",
        type: "string",
        required: true,
      },
      {
        name: "password",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/login_fangate\' -H \'Content-Type: application/json\' -d \'{"email": "user@example.com", "password": "password"}\'',
    responseExample: {
      token: "string",
    },
    errorResponses: [
      {
        code: 400,
        message: "Wrong password",
      },
      {
        code: 400,
        message: "User not found with the app",
      },
      {
        code: 400,
        message: "User not found for organization",
      },
      {
        code: 400,
        message: "Userdetail not found",
      },
    ],
  },
  {
    name: "Check Client Organization",
    method: "POST",
    category: "users ",
    endpoint: "/users/client_org",
    parameters: [
      {
        name: "email",
        type: "string",
        description: "Email address of the user.",
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/users/client_org' -H 'Content-Type: application/json' -d '{\"email\": \"example@example.com\"}'",
    responseExample: {
      name: "string",
      datajson: "string",
    },
    errorResponses: [
      { code: 404, message: "No user found" },
      { code: 404, message: "No FeatureEnable Found" },
      { code: 404, message: "No organization Found" },
    ],
  },
  {
    name: "Get Countries",
    method: "GET",
    category: "users ",
    endpoint: "/users/country",
    parameters: [],
    requestExample: "curl -X GET 'https://api.trypadlock.com/users/country'",
    responseExample: [
      {
        id: "string",
        country: "string",
      },
    ],
    errorResponses: [{ code: 404, message: "No Country Found" }],
  },
  {
    name: "Get Zipcodes by Country",
    method: "POST",
    category: "users ",
    endpoint: "/users/zipcode",
    parameters: [
      {
        name: "countryid",
        type: "string",
        description: "Country ID for fetching zip codes.",
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/users/zipcode' -H 'Content-Type: application/json' -d '{\"countryid\": \"US\"}'",
    responseExample: ["string"],
    errorResponses: [{ code: 404, message: "No Zip Found" }],
  },
  {
    name: "Get Subscriptions",
    method: "GET",
    category: "users ",
    endpoint: "/users/subcriptions",
    parameters: [],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/subcriptions'",
    responseExample: [
      {
        name: "string",
        number_of_user: "integer",
        price: "number",
        currency: "string",
      },
    ],
    errorResponses: [{ code: 404, message: "no data found" }],
  },
  {
    name: "Check Organization Existence",
    method: "POST",
    category: "users ",
    endpoint: "/users/org",
    parameters: [
      {
        name: "organization",
        type: "string",
        description: "Name of the organization to check.",
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/users/org' -H 'Content-Type: application/json' -d '{\"organization\": \"ExampleOrg\"}'",
    responseExample: {
      Status: "string",
    },
    errorResponses: [],
  },
  {
    name: "Get Organizations by Email",
    method: "POST",
    category: "users ",
    endpoint: "/users/organization_require",
    parameters: [
      {
        name: "email",
        type: "string",
        description: "Email address to fetch organizations.",
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/users/organization_require' -H 'Content-Type: application/json' -d '{\"email\": \"example@example.com\"}'",
    responseExample: {
      list: ["string"],
    },
    errorResponses: [{ code: 400, message: "No Org Found" }],
  },
  {
    name: "Check Username Existence",
    method: "POST",
    category: "users ",
    endpoint: "/users/username_check",
    parameters: [
      {
        name: "username",
        type: "string",
        description: "Username to check for existence.",
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/users/username_check' -H 'Content-Type: application/json' -d '{\"username\": \"exampleUser\"}'",
    responseExample: {
      Status: "string",
    },
    errorResponses: [],
  },
  {
    name: "Check Payment Status",
    method: "POST",
    category: "users ",
    endpoint: "/users/paymentcheck",
    parameters: [
      {
        name: "email",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/users/paymentcheck' -H 'Content-Type: application/json' -d '{\"email\": \"user@example.com\"}'",
    responseExample: {
      Status: "payment due",
    },
    errorResponses: [
      {
        code: 404,
        message: "User or details not found",
      },
    ],
  },
  {
    name: "User Login",
    method: "POST",
    category: "users ",
    endpoint: "/users/password",
    parameters: [
      {
        name: "email",
        type: "string",
        required: true,
      },
      {
        name: "password",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/password\' -H \'Content-Type: application/json\' -d \'{"email": "user@example.com", "password": "password123"}\'',
    responseExample: {
      found: true,
      msg: "Successfully Loggedin",
      access_token: "your_jwt_token_here",
    },
    errorResponses: [
      {
        code: 400,
        message: "Invalid Password or User",
      },
    ],
  },
  {
    name: "Deactivate Admin",
    method: "POST",
    category: "users ",
    endpoint: "/users/admindeactivate",
    parameters: [
      {
        name: "token",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/users/admindeactivate' -H 'Content-Type: application/json' -d '{\"token\": \"your_jwt_token_here\"}'",
    responseExample: {
      Status: "User successfully deactivated",
    },
    errorResponses: [
      {
        code: 400,
        message: "Token Not Found or Invalid",
      },
    ],
  },
  {
    name: "Deactivate User",
    method: "POST",
    category: "users ",
    endpoint: "/users/userdeactivate",
    parameters: [
      {
        name: "email",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/users/userdeactivate' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN' -H 'Content-Type: application/json' -d '{\"email\": \"user@example.com\"}'",
    responseExample: {
      Status: "User deactivated successfully",
    },
    errorResponses: [
      {
        code: 400,
        message: "User not found or not authorized",
      },
    ],
  },
  {
    name: "Email Login Check",
    method: "POST",
    category: "users ",
    endpoint: "/users/email_login",
    parameters: [
      {
        name: "email",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/users/email_login' -H 'Content-Type: application/json' -d '{\"email\": \"user@example.com\"}'",
    responseExample: {
      found: true,
      msg: "user found",
    },
    errorResponses: [
      {
        code: 400,
        message: "User not found",
      },
    ],
  },
  {
    name: "Organization Login Check",
    method: "POST",
    category: "users ",
    endpoint: "/users/org_login",
    parameters: [
      {
        name: "email",
        type: "string",
        required: true,
      },
      {
        name: "org_name",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/org_login\' -H \'Content-Type: application/json\' -d \'{"email": "user@example.com", "org_name": "OrgName"}\'',
    responseExample: {
      found: true,
      msg: "org found",
    },
    errorResponses: [
      {
        code: 400,
        message: "Organization not found",
      },
    ],
  },
  {
    name: "Activate User",
    method: "POST",
    category: "users ",
    endpoint: "/users/useractivate",
    parameters: [
      {
        name: "email",
        type: "string",
        required: true,
      },
      {
        name: "superuser",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/useractivate\' -H \'Content-Type: application/json\' -d \'{"email": "user@example.com", "superuser": "superuser@example.com"}\'',
    responseExample: {
      Status: "User Successfully Activated",
    },
    errorResponses: [
      {
        code: 400,
        message: "User not found or Superuser not valid",
      },
    ],
  },
  {
    name: "Activate User by Admin",
    method: "POST",
    category: "users ",
    endpoint: "/users/useracti",
    parameters: [
      {
        name: "email",
        type: "string",
        required: true,
      },
      {
        name: "admin",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/useracti\' -H \'Content-Type: application/json\' -d \'{"email": "user@example.com", "admin": "admin@example.com"}\'',
    responseExample: {
      Status: "User Successfully Activated",
    },
    errorResponses: [
      {
        code: 400,
        message: "Admin not found or User not found",
      },
    ],
  },
  {
    name: "Forgot Password",
    method: "POST",
    category: "users ",
    endpoint: "/users/forgot_password",
    parameters: [
      {
        name: "email",
        type: "string",
        description:
          "The email address of the user requesting a password reset.",
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/users/forgot_password' -H 'Content-Type: application/json' -d '{\"email\": \"user@example.com\"}'",
    responseExample: {
      status: "string",
    },
    errorResponses: [
      {
        code: 404,
        message: "User not found",
      },
      {
        code: 500,
        message: "Email not sent",
      },
    ],
  },
  {
    name: "Get Password Reset Link",
    method: "GET",
    category: "users ",
    endpoint: "/users/get_link/:email",
    parameters: [
      {
        name: "email",
        type: "string",
        description:
          "The email address of the user for which to generate a password reset link.",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/get_link/user@example.com'",
    responseExample: {
      redirect: "string",
    },
    errorResponses: [
      {
        code: 404,
        message: "User not found",
      },
    ],
  },
  {
    name: "Reset Password",
    method: "PUT",
    category: "users ",
    endpoint: "/users/reset_password",
    parameters: [
      {
        name: "email",
        type: "string",
        description: "The email address of the user resetting their password.",
      },
      {
        name: "password",
        type: "string",
        description: "The new password for the user.",
      },
      {
        name: "confirm_password",
        type: "string",
        description: "The confirmation of the new password.",
      },
    ],
    requestExample:
      'curl -X PUT \'https://api.trypadlock.com/users/reset_password\' -H \'Content-Type: application/json\' -d \'{"email": "user@example.com", "password": "newpassword", "confirm_password": "newpassword"}\'',
    responseExample: {
      message: "string",
    },
    errorResponses: [
      {
        code: 400,
        message: "Password and confirm password do not match",
      },
      {
        code: 404,
        message: "User not found",
      },
    ],
  },
  {
    name: "Get Market Feature for Client",
    method: "GET",
    category: "users ",
    endpoint: "/users/client_marketfeature/:uuid",
    parameters: [
      {
        name: "uuid",
        type: "string",
        description: "The UUID of the user to fetch market feature data.",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/client_marketfeature/uuid123'",
    responseExample: {
      Feature: "string",
    },
    errorResponses: [
      {
        code: 404,
        message: "No market feature data found",
      },
    ],
  },
  {
    name: "Add Balance",
    method: "POST",
    category: "users ",
    endpoint: "/users/balance_add",
    parameters: [
      {
        name: "price",
        type: "number",
        description: "The amount of money to add to the user's wallet.",
      },
      {
        name: "email",
        type: "string",
        description: "The email address of the user to add balance to.",
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/users/balance_add' -H 'Content-Type: application/json' -d '{\"price\": 100, \"email\": \"user@example.com\"}'",
    responseExample: {
      status: "string",
    },
    errorResponses: [
      {
        code: 404,
        message: "User not found",
      },
      {
        code: 403,
        message: "User is not a manager",
      },
      {
        code: 400,
        message: "User is not active",
      },
      {
        code: 404,
        message: "No monetary credit data found",
      },
    ],
  },
  {
    name: "Check User Balance",
    method: "POST",
    category: "users ",
    endpoint: "/users/balance_check",
    parameters: [
      {
        name: "email",
        type: "string",
        description: "Email of the user to check balance for",
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/users/balance_check' -H 'Content-Type: application/json' -d '{\"email\": \"user@example.com\"}'",
    responseExample: {
      fund: true,
    },
    errorResponses: [
      { code: 404, message: "User not found" },
      { code: 404, message: "User details not found" },
      { code: 404, message: "No data found money" },
      { code: 404, message: "User is not active" },
      { code: 404, message: "User is not a manager" },
    ],
  },
  {
    name: "Change Referral Percentage",
    method: "POST",
    category: "users ",
    endpoint: "/users/changeper_refer",
    parameters: [
      {
        name: "email",
        type: "string",
        description:
          "Email of the user whose referral percentage needs to be updated",
      },
      {
        name: "per",
        type: "number",
        description: "New referral percentage",
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/users/changeper_refer' -H 'Content-Type: application/json' -d '{\"email\": \"user@example.com\", \"per\": 10}'",
    responseExample: {
      status: "percentage is updated",
    },
    errorResponses: [
      { code: 404, message: "User not found" },
      { code: 404, message: "User details not found" },
      { code: 404, message: "No data found in referral" },
      { code: 404, message: "User is not active" },
      { code: 404, message: "User is not a manager" },
    ],
  },
  {
    name: "Get Referral List",
    method: "GET",
    category: "users ",
    endpoint: "/users/referlist/:email",
    parameters: [
      {
        name: "email",
        type: "string",
        description: "Email of the user whose referral list is to be fetched",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/referlist/user@example.com'",
    responseExample: [
      {
        email: "referral@example.com",
        credit: 50,
      },
    ],
    errorResponses: [
      { code: 404, message: "User not found" },
      { code: 404, message: "No referrals found" },
    ],
  },
  {
    name: "Get User Referral Code",
    method: "GET",
    category: "users ",
    endpoint: "/users/usercode/:email",
    parameters: [
      {
        name: "email",
        type: "string",
        description: "Email of the user whose referral code is to be fetched",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/usercode/user@example.com'",
    responseExample: {
      referals: "REF123456",
    },
    errorResponses: [
      { code: 404, message: "User not found" },
      { code: 404, message: "User details not found" },
      { code: 404, message: "User is not active" },
      { code: 404, message: "User is not a manager" },
    ],
  },
  {
    name: "Check Client Fund Status",
    method: "GET",
    category: "users ",
    endpoint: "/users/check_client/:uuid",
    parameters: [
      {
        name: "uuid",
        type: "string",
        description: "UUID of the user to check fund status for",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/check_client/123e4567-e89b-12d3-a456-426614174000'",
    responseExample: {
      fund: true,
    },
    errorResponses: [
      { code: 404, message: "User not found" },
      { code: 404, message: "Organization not found" },
      { code: 404, message: "No data found money" },
    ],
  },
  {
    name: "Create Payment Type",
    method: "POST",
    category: "users ",
    endpoint: "/users/payment_type",
    parameters: [
      { name: "email", type: "string", description: "User's email address" },
      { name: "type", type: "string", description: "Payment type to be set" },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/payment_type\' -H \'Content-Type: application/json\' -d \'{"email": "user@example.com", "type": "credit_card"}\'',
    responseExample: {
      status: "data successfully updated",
    },
    errorResponses: [
      { code: 404, message: "User or user details not found" },
      { code: 400, message: "User is not active or not a manager" },
    ],
  },
  {
    name: "Get Payment Type",
    method: "POST",
    category: "users ",
    endpoint: "/users/get_type",
    parameters: [
      { name: "email", type: "string", description: "User's email address" },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/users/get_type' -H 'Content-Type: application/json' -d '{\"email\": \"user@example.com\"}'",
    responseExample: {
      payment_type: "credit_card",
    },
    errorResponses: [
      { code: 404, message: "User or user details not found" },
      { code: 400, message: "User is not active or not a manager" },
    ],
  },
  {
    name: "Create Card",
    method: "POST",
    category: "users ",
    endpoint: "/users/card_create",
    parameters: [
      { name: "email", type: "string", description: "User's email address" },
      {
        name: "stripeToken",
        type: "object",
        description: "Stripe token for the card",
      },
      { name: "org_uuid", type: "string", description: "Organization UUID" },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/card_create\' -H \'Content-Type: application/json\' -d \'{"email": "user@example.com", "stripeToken": {"id": "token_id", "card": {"id": "card_id", "last4": "1234", "brand": "Visa", "exp_month": 12, "exp_year": 2025}}, "org_uuid": "org-uuid"}\'',
    responseExample: {
      cards: "card got created",
    },
    errorResponses: [
      { code: 404, message: "User, customer, or organization not found" },
      { code: 400, message: "Card creation failed" },
    ],
  },
  {
    name: "Update Card",
    method: "POST",
    category: "users ",
    endpoint: "/users/updatecard",
    parameters: [
      { name: "email", type: "string", description: "User's email address" },
      {
        name: "card",
        type: "string",
        description: "Card ID to be set as default",
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/updatecard\' -H \'Content-Type: application/json\' -d \'{"email": "user@example.com", "card": "card_id"}\'',
    responseExample: {
      custo: "successfully card changed",
    },
    errorResponses: [
      { code: 404, message: "User or customer not found" },
      { code: 400, message: "Card update failed" },
    ],
  },
  {
    name: "Get Default Card",
    method: "POST",
    category: "users ",
    endpoint: "/users/defaultcard",
    parameters: [
      { name: "email", type: "string", description: "User's email address" },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/users/defaultcard' -H 'Content-Type: application/json' -d '{\"email\": \"user@example.com\"}'",
    responseExample: {
      card: "card_id",
    },
    errorResponses: [
      { code: 404, message: "User or customer not found" },
      { code: 400, message: "Failed to retrieve default card" },
    ],
  },
  {
    name: "List Cards",
    method: "POST",
    category: "users ",
    endpoint: "/users/list_card",
    parameters: [
      { name: "email", type: "string", description: "User's email address" },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/users/list_card' -H 'Content-Type: application/json' -d '{\"email\": \"user@example.com\"}'",
    responseExample: [
      {
        last: "1234",
        card_id: "card_id",
        customer: "customer_id",
      },
    ],
    errorResponses: [{ code: 404, message: "User or customer not found" }],
  },
  {
    name: "Get Price of Person",
    method: "POST",
    category: "users ",
    endpoint: "/users/priceofperson",
    parameters: [
      { name: "email", type: "string", description: "User's email address" },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/users/priceofperson' -H 'Content-Type: application/json' -d '{\"email\": \"user@example.com\"}'",
    responseExample: {
      amount: 100,
    },
    errorResponses: [
      { code: 404, message: "User or user details not found" },
      { code: 400, message: "User is not active or not a manager" },
    ],
  },
  {
    name: "Create Payment Link",
    method: "GET",
    category: "users ",
    endpoint: "/users/pay",
    parameters: [],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/pay' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",
    responseExample: {
      url: "string",
    },
    errorResponses: [
      { code: 403, message: "User is not an admin" },
      { code: 404, message: "No data found" },
    ],
  },
  {
    name: "Handle Payment Success",
    method: "GET",
    category: "users ",
    endpoint: "/users/success_payment",
    parameters: [
      { name: "org_uuid", type: "query", required: true },
      { name: "PayerID", type: "query", required: true },
      { name: "paymentId", type: "query", required: true },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/success_payment?org_uuid=YOUR_ORG_UUID&PayerID=YOUR_PAYER_ID&paymentId=YOUR_PAYMENT_ID' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",
    responseExample: {
      message: "Success",
    },
    errorResponses: [
      { code: 404, message: "No data found" },
      { code: 500, message: "Payment execution error" },
    ],
  },
  {
    name: "Handle Payment Cancellation",
    method: "GET",
    category: "users ",
    endpoint: "/users/cancel",
    parameters: [],
    requestExample: "curl -X GET 'https://api.trypadlock.com/users/cancel'",
    responseExample: {
      message: "Cancelled",
    },
    errorResponses: [],
  },
  {
    name: "users Create Subscription",
    method: "POST",
    category: "users ",
    endpoint: "/users/subscription_create",
    parameters: [{ name: "email", type: "body", required: true }],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/users/subscription_create' -H 'Content-Type: application/json' -d '{\"email\": \"user@example.com\"}'",
    responseExample: {
      subscription: "succefully created subscription",
    },
    errorResponses: [
      { code: 404, message: "User or pricing data not found" },
      { code: 400, message: "Subscription creation error" },
    ],
  },
  {
    name: "Check Client Update",
    method: "GET",
    category: "users ",
    endpoint: "/users/update_client/:ver/:os",
    parameters: [
      { name: "ver", type: "path", required: true },
      { name: "os", type: "path", required: true },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/update_client/1.0/windows'",
    responseExample: {
      status: true,
    },
    errorResponses: [{ code: 404, message: "Client data not found" }],
  },
  {
    name: "Retrieve Card Details",
    method: "POST",
    category: "users ",
    endpoint: "/users/carddetails",
    parameters: [{ name: "email", type: "body", required: true }],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/users/carddetails' -H 'Content-Type: application/json' -d '{\"email\": \"user@example.com\"}'",
    responseExample: {
      card: "string",
    },
    errorResponses: [
      { code: 404, message: "Customer not found" },
      { code: 400, message: "Error retrieving card details" },
    ],
  },
  {
    name: "Get Current Plan",
    method: "GET",
    category: "users ",
    endpoint: "/users/current_plan/:email",
    parameters: [
      {
        name: "email",
        type: "string",
        in: "path",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/current_plan/user@example.com'",
    responseExample: {
      payment_type: "string",
      status: "string",
      manager: "string",
      userdetail: "string",
    },
    errorResponses: [{ code: 404, message: "User or user details not found" }],
  },
  {
    name: "Get Number of Days for Trial",
    method: "GET",
    category: "users ",
    endpoint: "/users/number_days",
    parameters: [
      {
        name: "org_uuid",
        type: "string",
        in: "query",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/number_days?org_uuid=12345' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",
    responseExample: {
      status: "string",
      expireDate: "string",
      free: true,
    },
    errorResponses: [
      { code: 404, message: "Organization not found" },
      { code: 302, message: "Not under the trial period" },
    ],
  },
  {
    name: "Credit Padlock",
    method: "POST",
    category: "users ",
    endpoint: "/users/credit_padlock",
    parameters: [
      {
        name: "email",
        type: "string",
        in: "body",
        required: true,
      },
      {
        name: "name",
        type: "string",
        in: "body",
        required: true,
      },
      {
        name: "app",
        type: "string",
        in: "body",
        required: true,
      },
      {
        name: "desc",
        type: "string",
        in: "body",
        required: false,
      },
      {
        name: "credit",
        type: "number",
        in: "body",
        required: true,
      },
      {
        name: "region",
        type: "string",
        in: "body",
        required: false,
      },
      {
        name: "currency",
        type: "string",
        in: "body",
        required: false,
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/credit_padlock\' -H \'Content-Type: application/json\' -d \'{"email": "user@example.com", "name": "Test", "app": "AppName", "desc": "Description", "credit": 100, "region": "US", "currency": "USD"}\'',
    responseExample: {
      status: "string",
      creditapp: "string",
    },
    errorResponses: [
      { code: 404, message: "User or application not found" },
      { code: 409, message: "Data already exists" },
    ],
  },
  {
    name: "Get Credit Padlock",
    method: "GET",
    category: "users ",
    endpoint: "/users/credit_padlock",
    parameters: [
      {
        name: "email",
        type: "string",
        in: "query",
        required: true,
      },
      {
        name: "app",
        type: "string",
        in: "query",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/credit_padlock?email=user@example.com&app=AppName'",
    responseExample: [
      {
        id: "string",
        name: "string",
        description: "string",
        credits: "number",
        currency: "string",
      },
    ],
    errorResponses: [
      { code: 404, message: "User or user details not available" },
    ],
  },
  {
    name: "Delete Credit Padlock",
    method: "DELETE",
    category: "users ",
    endpoint: "/users/credit_padlock",
    parameters: [
      {
        name: "email",
        type: "string",
        in: "query",
        required: true,
      },
      {
        name: "app",
        type: "string",
        in: "query",
        required: true,
      },
      {
        name: "name",
        type: "string",
        in: "query",
        required: true,
      },
    ],
    requestExample:
      "curl -X DELETE 'https://api.trypadlock.com/users/credit_padlock?email=user@example.com&app=AppName&name=Test'",
    responseExample: {
      data: "string",
      credit: "string",
    },
    errorResponses: [
      { code: 404, message: "User, application, or credit not found" },
    ],
  },
  {
    name: "Subscribe Email",
    method: "POST",
    category: "users ",
    endpoint: "/users/subscribe_email",
    parameters: [
      {
        name: "email",
        type: "string",
        in: "body",
        required: true,
      },
      {
        name: "app",
        type: "string",
        in: "body",
        required: true,
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/subscribe_email\' -H \'Content-Type: application/json\' -d \'{"email": "user@example.com", "app": "AppName"}\'',
    responseExample: {
      status: "string",
      app: "string",
    },
    errorResponses: [
      { code: 404, message: "Application or subscription not found" },
      { code: 500, message: "Email not sent" },
    ],
  },
  {
    name: "Get Subscribed Emails",
    method: "GET",
    category: "users ",
    endpoint: "/users/subscribe_email",
    parameters: [
      {
        name: "app",
        type: "query",
        description: "Application name to filter the subscribed emails",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/subscribe_email?app=exampleApp' ",
    responseExample: ["email1@example.com", "email2@example.com"],
    errorResponses: [{ code: 404, message: "No subscribed emails found" }],
  },
  {
    name: "Create or Check Region",
    method: "POST",
    category: "users ",
    endpoint: "/users/regions",
    parameters: [
      {
        name: "region",
        type: "body",
        description: "Region name to create or check",
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/users/regions'  -H 'Content-Type: application/json' -d '{\"region\": \"NewRegion\"}'",
    responseExample: {
      status: "data saved successfully",
    },
    errorResponses: [{ code: 400, message: "Bad request" }],
  },
  {
    name: "Get Regions",
    method: "GET",
    category: "users ",
    endpoint: "/users/regions",
    parameters: [],
    requestExample: "curl -X GET 'https://api.trypadlock.com/users/regions' ",
    responseExample: [
      {
        id: "1",
        region: "RegionName",
      },
    ],
    errorResponses: [{ code: 404, message: "No regions found" }],
  },
  {
    name: "Update User Profile Image",
    method: "POST",
    category: "users ",
    endpoint: "/users/profile_update/:email",
    parameters: [
      {
        name: "email",
        type: "path",
        description: "User's email address",
      },
      {
        name: "avatar",
        type: "file",
        description: "User's profile image file",
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/users/profile_update/user@example.com'  -F 'avatar=@/path/to/image.jpg'",
    responseExample: {
      Status: "file uploaded successfully",
    },
    errorResponses: [
      { code: 404, message: "User does not exist" },
      { code: 500, message: "File upload failed" },
    ],
  },
  {
    name: "Get User Profile Image URL",
    method: "GET",
    category: "users ",
    endpoint: "/users/profile_update",
    parameters: [
      {
        name: "email",
        type: "query",
        description: "User's email address",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/profile_update?email=user@example.com' ",
    responseExample: {
      url: "https://s3.amazonaws.com/padlockstorage/user_uuid/image.jpg",
      email: "user@example.com",
      username: "username",
      timezone: "timezone",
      phone: "123-456-7890",
    },
    errorResponses: [
      { code: 404, message: "User does not exist" },
      { code: 404, message: "User details do not exist" },
    ],
  },
  {
    name: "Update User Profile Details",
    method: "POST",
    category: "users ",
    endpoint: "/users/profile_updates",
    parameters: [
      {
        name: "email",
        type: "body",
        description: "User's email address",
      },
      {
        name: "username",
        type: "body",
        description: "New username",
      },
      {
        name: "phone",
        type: "body",
        description: "New phone number",
      },
      {
        name: "timezone",
        type: "body",
        description: "New timezone",
      },
      {
        name: "password",
        type: "body",
        description: "New password",
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/profile_updates\'  -H \'Content-Type: application/json\' -d \'{"email": "user@example.com", "username": "newUsername", "phone": "987-654-3210", "timezone": "GMT+1", "password": "newPassword"}\'',
    responseExample: {
      status: "user details updated successfully",
    },
    errorResponses: [
      { code: 404, message: "User does not exist" },
      { code: 400, message: "Invalid request data" },
    ],
  },
  {
    name: "Get User Phone Number",
    method: "GET",
    category: "users ",
    endpoint: "/users/phone_profile",
    parameters: [
      {
        name: "uu_id",
        type: "query",
        description: "User UUID",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/phone_profile?uu_id=user_uuid' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",
    responseExample: {
      phone_number: "123-456-7890",
    },
    errorResponses: [{ code: 404, message: "User not found" }],
  },
  {
    name: "Update Phone Number",
    method: "PUT",
    category: "users ",
    endpoint: "/users/phone_profile",
    parameters: [
      {
        name: "uu_id",
        type: "query",
        description: "User UUID",
      },
      {
        name: "phone",
        type: "query",
        description: "New phone number",
      },
    ],
    requestExample:
      "curl -X PUT 'https://api.trypadlock.com/users/phone_profile?uu_id=USER_UUID&phone=NEW_PHONE' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",
    responseExample: {
      status: "phone number updated",
    },
    errorResponses: [
      {
        code: 404,
        message: "User not found",
      },
    ],
  },
  {
    name: "Get Organization Credentials",
    method: "GET",
    category: "users ",
    endpoint: "/users/credentails/:org",
    parameters: [
      {
        name: "org",
        type: "path",
        description: "Organization name",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/credentails/ORG_NAME' ",
    responseExample: {
      key: "string",
      secret_key: "string",
      url: "string",
      region: "string",
      bucket: "string",
    },
    errorResponses: [
      {
        code: 400,
        message: "Organization is not active",
      },
    ],
  },
  {
    name: "Delete Card",
    method: "DELETE",
    category: "users ",
    endpoint: "/users/delete_card",
    parameters: [
      {
        name: "email",
        type: "body",
        description: "User email",
      },
      {
        name: "last",
        type: "body",
        description: "Last 4 digits of the card",
      },
    ],
    requestExample:
      'curl -X DELETE \'https://api.trypadlock.com/users/delete_card\' -d \'{"email": "USER_EMAIL", "last": "LAST_4_DIGITS"}\'',
    responseExample: {
      status: "card deleted successfully",
    },
    errorResponses: [
      {
        code: 404,
        message: "Customer or user not found",
      },
      {
        code: 400,
        message: "Card did not delete",
      },
    ],
  },
  {
    name: "Card Update ",
    method: "POST",
    category: "users ",
    endpoint: "/users/cardupdate",
    parameters: [
      {
        name: "exp_date",
        type: "body",
        description: "Expiration month of the card",
      },
      {
        name: "exp_year",
        type: "body",
        description: "Expiration year of the card",
      },
      {
        name: "email",
        type: "body",
        description: "User email",
      },
      {
        name: "last4",
        type: "body",
        description: "Last 4 digits of the card",
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/cardupdate\' -d \'{"exp_date": "MONTH", "exp_year": "YEAR", "email": "USER_EMAIL", "last4": "LAST_4_DIGITS"}\'',
    responseExample: {
      status: "card successfully updated",
    },
    errorResponses: [
      {
        code: 404,
        message: "User does not exist",
      },
      {
        code: 400,
        message: "Card did not get updated",
      },
    ],
  },
  {
    name: "Get All Cards",
    method: "GET",
    category: "users ",
    endpoint: "/users/cardsall",
    parameters: [
      {
        name: "email",
        type: "query",
        description: "User email",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/cardsall?email=USER_EMAIL' ",
    responseExample: [
      {
        last: "string",
        brand: "string",
        exp_month: "string",
        exp_year: "string",
      },
    ],
    errorResponses: [
      {
        code: 404,
        message: "User or customer not found",
      },
    ],
  },
  {
    name: "Get AWS Data",
    method: "GET",
    category: "users ",
    endpoint: "/users/data/:org",
    parameters: [
      {
        name: "org",
        type: "path",
        description: "Organization name",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/data/ORG_NAME' ",
    responseExample: {
      key: "string",
      secret_key: "string",
      token: "string",
      url: "string",
      region: "string",
      bucket: "string",
    },
    errorResponses: [
      {
        code: 404,
        message: "No data found",
      },
    ],
  },
  {
    name: "Get Django Data",
    method: "GET",
    category: "users ",
    endpoint: "/users/django_data/:org",
    parameters: [
      {
        name: "org",
        type: "path",
        description: "Organization name",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/django_data/ORG_NAME' ",
    responseExample: {
      key: "string",
      secret_key: "string",
      token: "string",
      url: "string",
      region: "string",
      bucket: "string",
      status: "boolean",
      minioUrl: "string",
      redisUrl: "string",
      postgresPort: "string",
      postgresHost: "string",
      postgresPassword: "string",
      postgresUser: "string",
      postgresName: "string",
    },
    errorResponses: [
      {
        code: 404,
        message: "No data found",
      },
    ],
  },
  {
    name: "Get Client Data",
    method: "GET",
    category: "users ",
    endpoint: "/users/client_data/:org",
    parameters: [
      {
        name: "org",
        type: "path",
        description: "The organization name",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/client_data/org_name' ",
    responseExample: {
      key: "string",
      secret_key: "string",
      token: "string",
      url: "string",
      region: "string",
      bucket: "string",
      status: true,
      minioUrl: "string",
    },
    errorResponses: [{ code: 404, message: "Organization not found" }],
  },
  {
    name: "Get Frontend Data",
    method: "GET",
    category: "users ",
    endpoint: "/users/frontendData/:org",
    parameters: [
      {
        name: "org",
        type: "path",
        description: "The organization name",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/frontendData/org_name'",
    responseExample: {
      url: "string",
    },
    errorResponses: [{ code: 404, message: "Organization not found" }],
  },
  {
    name: "Post Email",
    method: "POST",
    category: "users ",
    endpoint: "/users/email_post",
    parameters: [
      {
        name: "email",
        type: "body",
        description: "The recipient's email address",
      },
      {
        name: "useremail",
        type: "body",
        description: "The sender's email address",
      },
      {
        name: "type",
        type: "body",
        description: "Type of email",
      },
      {
        name: "role",
        type: "body",
        description: "Role of the recipient",
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/users/email_post\'  -H \'Content-Type: application/json\' -d \'{"email":"recipient@example.com", "useremail":"sender@example.com", "type":"invite", "role":"manager"}\'',
    responseExample: {
      Status: "string",
    },
    errorResponses: [
      { code: 400, message: "Bad Request" },
      { code: 404, message: "User not found" },
    ],
  },
  {
    name: "Get Observer List",
    method: "GET",
    category: "users ",
    endpoint: "/users/observerList",
    parameters: [],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/observerList' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",
    responseExample: {
      users: [
        {
          roles: "string",
          user_data: {
            status: "string",
          },
          user: {
            username: "string",
            email: "string",
            user_uuid: "string",
          },
        },
      ],
    },
    errorResponses: [{ code: 401, message: "Unauthorized" }],
  },
  {
    name: "Get Manager List",
    method: "GET",
    category: "users ",
    endpoint: "/users/managerList",
    parameters: [],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/users/managerList' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",
    responseExample: {
      users: [
        {
          roles: "string",
          user_data: {
            status: "string",
          },
          user: {
            username: "string",
            email: "string",
            user_uuid: "string",
          },
        },
      ],
    },
    errorResponses: [{ code: 401, message: "Unauthorized" }],
  },
  {
    name: "Get User Profile",
    method: "GET",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/profile",
    parameters: [],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/padlockAdmin/profile' -H 'Authorization: Bearer <access_token>'",
    responseExample: {
      email: "user@example.com",
      user_uuid: "uuid-string",
      username: "username",
      first_name: "John",
      last_name: "Doe",
      url: "https://padlockstorage.s3.amazonaws.com/uuid-string/image.png?AWSAccessKeyId=...",
      phone_number: "+1234567890",
      org_uuid: "org-uuid-string",
    },
    errorResponses: [
      { code: 404, message: "user does not exist" },
      { code: 404, message: "user details do not exist" },
      { code: 404, message: "image does not exist" },
    ],
  },
  {
    name: "Get User List",
    method: "GET",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/userList",
    parameters: [
      {
        name: "orgName",
        description: "The name of the organization.",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/padlockAdmin/userList?orgName=exampleOrg' -H 'Authorization: Bearer <access_token>'",
    responseExample: {
      data: [
        {
          id: 1,
          email: "user@example.com",
          username: "username",
          first_name: "John",
          last_name: "Doe",
          url: "https://padlockstorage.s3.amazonaws.com/uuid-string/image.png?AWSAccessKeyId=...",
          role: "admin",
          org_name: "exampleOrg",
          created_date: "2023-01-01T00:00:00.000Z",
          last_active: "2023-09-01T00:00:00.000Z",
          user_uuid: "uuid-string",
        },
      ],
    },
    errorResponses: [
      { code: 200, response: { data: [] } },
      { code: 500, message: "Internal Server Error" },
    ],
  },

  {
    name: "Get Organization List",
    method: "GET",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/orgList",
    parameters: [
      {
        name: "appid",
        description: "The application ID associated with the organizations.",
        type: "string",
        required: true,
      },
      {
        name: "page",
        description: "The page number for pagination.",
        type: "integer",
        required: false,
        default: 1,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/padlockAdmin/orgList?appid=exampleAppId&page=1' -H 'Authorization: Bearer <access_token>'",
    responseExample: {
      data: [
        {
          id: 1,
          name: "Organization Name",
          org_uuid: "uuid-string",
          fixed: true,
          count: 10,
          url: "https://padlockstorage.s3.amazonaws.com/org-file.png?AWSAccessKeyId=...",
          created: "2023-01-01T00:00:00.000Z",
          status: true,
        },
      ],
      currentPage: 1,
      totalPages: 3,
    },
    errorResponses: [
      {
        code: 200,
        message: "No organizations found",
        response: { data: [], currentPage: 1, totalPages: 0 },
      },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "Get Organization User List",
    method: "GET",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/orgUserList",
    parameters: [
      {
        name: "appid",
        description: "The application ID associated with the organization.",
        type: "string",
        required: true,
      },
      {
        name: "orgid",
        description: "The ID of the organization.",
        type: "integer",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/padlockAdmin/orgUserList?appid=exampleAppId&orgid=1' -H 'Authorization: Bearer <access_token>'",
    responseExample: {
      data: [
        {
          user_id: "uuid-string",
          username: "username",
          email: "user@example.com",
          first_name: "John",
          last_name: "Doe",
          last_login: "2023-09-01T00:00:00.000Z",
          created: "2023-01-01T00:00:00.000Z",
          roles: "admin",
        },
        {
          user_id: "uuid-string-2",
          username: "user2",
          email: "user2@example.com",
          first_name: "Jane",
          last_name: "Doe",
          last_login: "2023-09-02T00:00:00.000Z",
          created: "2023-01-02T00:00:00.000Z",
          roles: "user",
        },
      ],
    },
    errorResponses: [
      {
        code: 400,
        message: "Organization not found",
        response: { Status: "org not found" },
      },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "Refresh Access Token",
    method: "POST",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/refresh",
    description:
      "This endpoint refreshes the access token using a valid refresh token.",
    parameters: [
      {
        name: "refresh",
        description: "The refresh token provided by the client.",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/padlockAdmin/refresh' -H 'Content-Type: application/json' -d '{ \"refresh\": \"<refresh_token>\" }'",
    responseExample: {
      token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9...",
    },
    errorResponses: [
      {
        code: 401,
        message: "Token Not Provided",
        response: { error: "Token Not Provided" },
      },
      {
        code: 401,
        message: "The token got expired or invalid token",
        response: { error: "The token got expired or invalid token" },
      },
      {
        code: 401,
        message: "Not Authorized",
        response: { error: "Not Authorized" },
      },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "Get Price History",
    method: "GET",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/history",
    description:
      "Fetches the price history for a specific application under a specific organization.",
    parameters: [
      {
        name: "app_id",
        description: "The ID of the application.",
        type: "string",
        required: true,
      },
      {
        name: "org_id",
        description: "The ID of the organization.",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/padlockAdmin/history?app_id=<app_id>&org_id=<org_id>' -H 'Authorization: Bearer <access_token>'",
    responseExample: {
      price_history: [
        {
          id: "1",
          totalprice: "500",
          payment_status: "Paid",
          created: "2023-01-01T00:00:00Z",
        },
        {
          id: "2",
          totalprice: "300",
          payment_status: "Pending",
          created: "2023-06-01T00:00:00Z",
        },
      ],
    },
    errorResponses: [
      {
        code: 404,
        message: "No Applications Found",
        response: { price_history: [] },
      },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "Get All Cards",
    method: "GET",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/cardsall",
    description:
      "Fetches all saved cards for a specific user based on their email.",
    parameters: [
      {
        name: "email",
        description: "The email address of the user.",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/padlockAdmin/cardsall?email=<user_email>' -H 'Authorization: Bearer <access_token>'",
    responseExample: [
      {
        last: "4242",
        brand: "Visa",
        exp_month: "12",
        exp_year: "2024",
        card_id: "card_12345",
      },
      {
        last: "1234",
        brand: "MasterCard",
        exp_month: "11",
        exp_year: "2023",
        card_id: "card_67890",
      },
    ],
    errorResponses: [
      {
        code: 404,
        message: "User Not Found",
        response: { user: "user is not found" },
      },
      {
        code: 404,
        message: "No Customer Found",
        response: { customer: "no customer found" },
      },
      { code: 500, message: "Internal Server Error" },
    ],
  },
  {
    name: "Get Default Card",
    method: "GET",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/defaultcard",
    parameters: [
      {
        name: "email",
        description: "The user's email address.",
        type: "string",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/padlockAdmin/defaultcard?email=user@example.com' -H 'Content-Type: application/json'",
    responseExample: {
      card: "card_1GqIC8Ly0qyl6rEd9T1Pb1sv",
    },
    errorResponses: [
      {
        code: 400,
        message: "user did not found",
      },
      {
        code: 400,
        message: "no customer found",
      },
      {
        code: 400,
        message: "no default card",
      },
      {
        code: 500,
        message: "Error occurred while retrieving customer information",
      },
    ],
  },
  {
    name: "Get Current Plan",
    method: "GET",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/current_plan",
    parameters: [
      {
        name: "email",
        description: "The user's email address.",
        type: "string",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/padlockAdmin/current_plan?email=user@example.com' -H 'Content-Type: application/json'",
    responseExample: {
      payment_type: {
        status: "true",
        plan: "premium",
      },
    },
    errorResponses: [
      {
        code: 400,
        message: "user not available",
      },
      {
        code: 400,
        message: "user details doesnot exist",
      },
      {
        code: 400,
        message: "user is not manager",
      },
      {
        code: 400,
        message: "user is not active",
      },
    ],
  },
  {
    name: "Login to Padlock Admin",
    method: "POST",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/login",
    parameters: [
      {
        name: "email",
        description: "The user's email address.",
        type: "string",
      },
      {
        name: "org_name",
        description: "The organization name.",
        type: "string",
      },
      {
        name: "password",
        description: "The user's password.",
        type: "string",
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/padlockAdmin/login\' -H \'Content-Type: application/json\' -d \'{"email": "user@example.com", "password": "userpassword", "org_name": "organization"}\'',
    responseExample: {
      token: "string",
      refresh_token: "string",
    },
    errorResponses: [
      { code: 400, message: "Invalid credentials found" },
      { code: 400, message: "User is not active" },
      { code: 400, message: "Please verify your email" },
      { code: 400, message: "User not found for organization" },
      { code: 400, message: "Application does not exist" },
    ],
  },
  {
    name: "Register User",
    method: "POST",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/register",
    parameters: [
      {
        name: "token",
        description: "The JWT token for verifying the registration link.",
        type: "string",
      },
      {
        name: "languages",
        description: "Preferred language(s) for the user. Defaults to 'en'.",
        type: "string",
      },
      {
        name: "subcription",
        description: "The subscription plan chosen by the user.",
        type: "string",
      },
      {
        name: "first_name",
        description: "The user's first name.",
        type: "string",
      },
      {
        name: "last_name",
        description: "The user's last name.",
        type: "string",
      },
      {
        name: "ip_address",
        description: "The IP address of the user, used for geolocation.",
        type: "string",
      },
      {
        name: "org_name",
        description: "The organization name where the user is registering.",
        type: "string",
      },
      {
        name: "username",
        description: "The desired username for the user.",
        type: "string",
      },
      {
        name: "password",
        description: "The user's password.",
        type: "string",
      },
      {
        name: "phone",
        description: "The user's phone number.",
        type: "string",
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/padlockAdmin/register\' -H \'Content-Type: application/json\' -d \'{"token": "your_jwt_token", "languages": "en", "subcription": "monthly", "first_name": "John", "last_name": "Doe", "ip_address": "192.168.1.1", "org_name": "YourOrg", "username": "johndoe", "password": "securepassword", "phone": "1234567890"}\'',
    responseExample: {
      status: "User registered successfully",
    },
    errorResponses: [
      { code: 400, message: "Link expired! Please register again" },
      { code: 400, message: "User email or username already exists" },
      { code: 400, message: "Organization already exists" },
      { code: 400, message: "Subscription does not exist" },
      { code: 400, message: "Application does not exist" },
      { code: 400, message: "Referral does not exist" },
    ],
  },
  {
    name: "Invite user to new Organization",
    method: "POST",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/invite_org",
    parameters: [
      {
        name: "email",
        description: "The email address of the organization contact.",
        type: "string",
      },
      {
        name: "org_name",
        description: "The name of the organization.",
        type: "string",
      },
      {
        name: "user_name",
        description: "The name of the user inviting the organization.",
        type: "string",
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/padlockAdmin/invite_org\' -H \'Content-Type: application/json\' -H \'Authorization: Bearer YOUR_ACCESS_TOKEN\' -d \'{"email": "contact@organization.com", "org_name": "Example Org", "user_name": "John Doe"}\'',
    responseExample: {
      Status: "Organization Invitation Sent Successfully.",
    },
    errorResponses: [
      { code: 400, message: "Organization already registered" },
      { code: 400, message: "Email not sent" },
    ],
  },
  {
    name: "Padlock Admin",
    method: "POST",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/invite_observer",
    parameters: [
      {
        name: "useremail",
        description: "The email address of the observer being invited.",
        type: "string",
      },
      {
        name: "type",
        description: "The type of invitation (e.g., observer).",
        type: "string",
      },
      {
        name: "teams",
        description:
          "Comma-separated list of team UUIDs to which the observer will be added.",
        type: "string",
      },
      {
        name: "role",
        description: "The role assigned to the observer.",
        type: "string",
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/padlockAdmin/invite_observer\' -H \'Content-Type: application/json\' -H \'Authorization: Bearer YOUR_ACCESS_TOKEN\' -d \'{"useremail": "observer@example.com", "type": "observer", "teams": "team1,team2", "role": "viewer"}\'',
    responseExample: {
      Status: "Observer Invitation Sent Successfully",
    },
    errorResponses: [
      { code: 400, message: "User already exists" },
      { code: 400, message: "Email not sent" },
    ],
  },
  {
    name: "Register Observer",
    method: "POST",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/observer_register",
    parameters: [
      {
        name: "token",
        description: "The registration token sent to the observer.",
        type: "string",
      },
      {
        name: "first_name",
        description: "The first name of the observer.",
        type: "string",
      },
      {
        name: "last_name",
        description: "The last name of the observer.",
        type: "string",
      },
      {
        name: "username",
        description: "The username for the observer.",
        type: "string",
      },
      {
        name: "password",
        description: "The password chosen by the observer.",
        type: "string",
      },
      {
        name: "ip_address",
        description:
          "The IP address of the observer (optional, used for timezone detection).",
        type: "string",
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/padlockAdmin/observer_register\' -H \'Content-Type: application/json\' -d \'{"token": "YOUR_REGISTRATION_TOKEN", "first_name": "John", "last_name": "Doe", "username": "johndoe", "password": "password123", "ip_address": "192.168.1.1"}\'',
    responseExample: {
      status: "User Registered Successfully",
    },
    errorResponses: [
      { code: 400, message: "Link Expired! Please contact to your admin." },
      { code: 400, message: "Token Not Found" },
      { code: 302, message: "User already exists in this org" },
      { code: 400, message: "Application not exists" },
    ],
  },
  {
    name: "Invite User by Email",
    method: "POST",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/invite_email",
    parameters: [
      {
        name: "useremail",
        description: "The email address of the user to be invited.",
        type: "string",
      },
      {
        name: "type",
        description: "The type of invitation (e.g., 'observer').",
        type: "string",
      },
      {
        name: "role",
        description:
          "The role assigned to the user upon registration (e.g., 'admin').",
        type: "string",
      },
    ],
    authentication: "Requires authentication",
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/padlockAdmin/invite_email\' -H \'Content-Type: application/json\' -H \'Authorization: Bearer YOUR_ACCESS_TOKEN\' -d \'{"useremail": "newuser@example.com", "type": "observer", "role": "admin"}\'',
    responseExample: {
      Status: "Email Sent Successfully",
    },
    errorResponses: [{ code: 400, message: "email not sent" }],
  },
  {
    name: "Employee Registration",
    method: "POST",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/employee_register",
    parameters: [
      {
        name: "token",
        description: "JWT token for verifying the invitation.",
        type: "string",
      },
      {
        name: "first_name",
        description: "First name of the employee.",
        type: "string",
      },
      {
        name: "last_name",
        description: "Last name of the employee.",
        type: "string",
      },
      {
        name: "username",
        description: "Username for the employee account.",
        type: "string",
      },
      {
        name: "password",
        description: "Password for the employee account.",
        type: "string",
      },
      {
        name: "languages",
        description: "Preferred languages for the employee account.",
        type: "string",
        default: "en",
      },
      {
        name: "ip_address",
        description: "IP address of the employee, used for timezone detection.",
        type: "string",
        optional: true,
      },
    ],
    authentication: "No authentication required for this endpoint.",
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/padlockAdmin/employee_register\' -H \'Content-Type: application/json\' -d \'{"token": "YOUR_JWT_TOKEN", "first_name": "John", "last_name": "Doe", "username": "johndoe", "password": "password123", "languages": "en", "ip_address": "192.168.1.1"}\'',
    responseExample: {
      status: "User Registered Successfully",
    },
    errorResponses: [
      { code: 400, message: "Token Not Found" },
      { code: 302, message: "User already exists in this org" },
      { code: 400, message: "Application not exists" },
      { code: 400, message: "Link Expired! Please contact to your admin." },
    ],
  },
  {
    name: "Manager Invitation",
    method: "POST",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/manager_invite",
    parameters: [
      {
        name: "useremail",
        description: "Email address of the user to be invited.",
        type: "string",
      },
      {
        name: "teams",
        description:
          "Comma-separated list of team UUIDs to which the manager will be added.",
        type: "string",
      },
      {
        name: "role",
        description: "Role of the user being invited (e.g., manager).",
        type: "string",
      },
    ],
    authentication: "Required (User must be authenticated).",
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/padlockAdmin/manager_invite\' -H \'Authorization: Bearer YOUR_ACCESS_TOKEN\' -H \'Content-Type: application/json\' -d \'{"useremail": "john.doe@example.com", "teams": "team1,team2", "role": "manager"}\'',
    responseExample: {
      status: "Email Sent Successfully",
    },
    errorResponses: [
      { code: 400, message: "User already exist" },
      { code: 500, message: "email not sent", error: "Error message" },
    ],
  },
  {
    name: "Manager Registration",
    method: "POST",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/manager_register",
    parameters: [
      {
        name: "token",
        description: "JWT token provided in the invitation email.",
        type: "string",
      },
      {
        name: "first_name",
        description: "First name of the user registering.",
        type: "string",
      },
      {
        name: "last_name",
        description: "Last name of the user registering.",
        type: "string",
      },
      {
        name: "username",
        description: "Username for the new user.",
        type: "string",
      },
      {
        name: "password",
        description: "Password for the new user.",
        type: "string",
      },
      {
        name: "ip_address",
        description: "IP address of the user (for timezone detection).",
        type: "string",
        optional: true,
      },
    ],
    authentication:
      "No authentication required; token is validated internally.",
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/padlockAdmin/manager_register\' -H \'Content-Type: application/json\' -d \'{"token": "YOUR_JWT_TOKEN", "first_name": "John", "last_name": "Doe", "username": "johndoe", "password": "password123"}\'',
    responseExample: {
      status: "User Registered Successfully",
    },
    errorResponses: [
      {
        code: 400,
        message: "Link Expired! Please contact to your admin.",
        condition: "If the JWT token is invalid or expired.",
      },
      {
        code: 400,
        message: "Token Not Found",
        condition: "If no token is provided in the request.",
      },
      {
        code: 400,
        message: "Application not exists",
        condition:
          "If the application associated with the token does not exist.",
      },
      {
        code: 302,
        message: "User already exists in this org",
        condition: "If the user already exists in the organization.",
      },
      {
        code: 500,
        message: "Internal Server Error",
        condition: "For any other unexpected errors.",
      },
    ],
  },
  {
    name: "Get Observer List",
    method: "GET",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/observerList",
    parameters: [
      {
        name: "Authorization",
        description: "Bearer token to authenticate the request.",
        type: "string",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/padlockAdmin/observerList' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",
    responseExample: {
      users: [
        {
          email: "observer@example.com",
          org_uuid: "organization-uuid",
          username: "observerUser",
          user_uuid: "user-uuid",
          team_name: "Team A",
          team_uuid: "team-uuid",
          userId: 123,
          roles: "observer",
          user_data: {
            status: true,
          },
        },
      ],
    },
    errorResponses: [
      {
        code: 500,
        message: "Internal Server Error",
      },
    ],
  },
  {
    name: "Get Manager List",
    method: "GET",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/managerList",
    parameters: [
      {
        name: "Authorization",
        description: "Bearer token to authenticate the request.",
        type: "string",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/padlockAdmin/managerList' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",
    responseExample: {
      users: [
        {
          email: "manager@example.com",
          org_uuid: "organization-uuid",
          username: "managerUser",
          user_uuid: "user-uuid",
          team_name: "Team B",
          team_uuid: "team-uuid",
          userId: 456,
          roles: "manager",
          user_data: {
            status: true,
          },
        },
      ],
    },
    errorResponses: [
      {
        code: 500,
        message: "Internal Server Error",
      },
    ],
  },
  {
    name: "Deactivate User",
    method: "POST",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/userdeactivate",
    parameters: [
      {
        name: "email",
        description: "The email address of the user to be deactivated.",
        type: "string",
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/padlockAdmin/userdeactivate' -H 'Content-Type: application/json' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN' -d '{\"email\": \"user@example.com\"}'",
    responseExample: {
      Status: "User deactivated successfully",
    },
    errorResponses: [
      {
        code: 400,
        message: "No user app pricing found",
      },
      {
        code: 400,
        message: "User is already deactivated",
      },
      {
        code: 400,
        message: "User doesn't exist",
      },
      {
        code: 500,
        message: "Internal Server Error",
      },
    ],
  },
  {
    name: "Create Card",
    method: "POST",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/card_create",
    parameters: [
      {
        name: "email",
        description: "The email address associated with the user.",
        type: "string",
      },
      {
        name: "stripeToken",
        description: "The Stripe token representing the card details.",
        type: "object",
        properties: {
          id: "string",
          card: {
            id: "string",
            last4: "string",
            brand: "string",
            exp_month: "number",
            exp_year: "number",
          },
        },
      },
      {
        name: "org_uuid",
        description: "The UUID of the organization.",
        type: "string",
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/padlockAdmin/card_create\' -H \'Content-Type: application/json\' -H \'Authorization: Bearer YOUR_ACCESS_TOKEN\' -d \'{"email": "user@example.com", "stripeToken": {"id": "tok_visa", "card": {"id": "card_1HhQ2b2eZvKYlo2CQ7XZc0hu", "last4": "4242", "brand": "Visa", "exp_month": 12, "exp_year": 2024}}, "org_uuid": "org-uuid"}\'',
    responseExample: {
      cards: "card got created",
    },
    errorResponses: [
      {
        code: 400,
        message: "User is not available",
      },
      {
        code: 400,
        message: "User detail is not available",
      },
      {
        code: 400,
        message: "Customer is not created",
      },
      {
        code: 400,
        message: "No card created",
      },
      {
        code: 500,
        message: "Internal Server Error",
      },
    ],
  },
  {
    name: "Update Card",
    method: "POST",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/updatecard",
    parameters: [
      {
        name: "email",
        description:
          "The email address associated with the user whose card is being updated.",
        type: "string",
      },
      {
        name: "card",
        description: "The ID of the new default card to be set.",
        type: "string",
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/padlockAdmin/updatecard' -H 'Content-Type: application/json' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN' -d '{\"email\": \"user@example.com\", \"card\": \"card_1HhQ2b2eZvKYlo2CQ7XZc0hu\"}'",
    responseExample: {
      custo: "successfully card changed",
    },
    errorResponses: [
      {
        code: 400,
        message: "User not found",
      },
      {
        code: 400,
        message: "Card does not exist",
      },
      {
        code: 400,
        message: "No default card set",
      },
      {
        code: 500,
        message: "Internal Server Error",
      },
    ],
  },
  {
    name: "Check Organization",
    method: "POST",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/org",
    parameters: [
      {
        name: "organization",
        description: "The name of the organization to check for existence.",
        type: "string",
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/padlockAdmin/org' -H 'Content-Type: application/json' -d '{\"organization\": \"ExampleOrg\"}'",
    responseExample: {
      Status: "Organization already exists",
    },
    errorResponses: [
      {
        code: 500,
        message: "Internal Server Error",
      },
    ],
  },
  {
    name: "Get Organization list",
    method: "POST",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/organization_require",
    parameters: [
      {
        name: "email",
        description: "The email address used to search for organizations.",
        type: "string",
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/padlockAdmin/organization_require' -H 'Content-Type: application/json' -d '{\"email\": \"user@example.com\"}'",
    responseExample: {
      list: ["Organization1", "Organization2"],
      details: [
        {
          org_uuid: "org-uuid-1",
          name: "Organization1",
        },
        {
          org_uuid: "org-uuid-2",
          name: "Organization2",
        },
      ],
    },
    errorResponses: [
      {
        code: 400,
        message: "No Org Found",
      },
      {
        code: 500,
        message: "Internal Server Error",
      },
    ],
  },

  {
    name: "Update Profile",
    method: "PUT",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/profile",
    parameters: [
      {
        name: "user_uuid",
        description: "The UUID of the user whose profile is being updated.",
        type: "string",
      },
      {
        name: "first_name",
        description: "The user's first name.",
        type: "string",
      },
      {
        name: "last_name",
        description: "The user's last name.",
        type: "string",
      },
      {
        name: "avatar",
        description: "The user's profile image file (optional).",
        type: "file",
      },
    ],
    requestExample:
      "curl -X PUT 'https://api.trypadlock.com/padlockAdmin/profile' -H 'Content-Type: multipart/form-data' -F 'user_uuid=uuid' -F 'first_name=John' -F 'last_name=Doe' -F 'avatar=@/path/to/image.jpg'",
    responseExample: {
      status: "File uploaded successfully",
    },
    errorResponses: [
      {
        code: 404,
        message: "User does not exist",
      },
      {
        code: 500,
        message: "Internal Server Error",
      },
    ],
  },
  {
    name: "Forgot Password",
    method: "POST",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/forgot_password",
    parameters: [
      {
        name: "password",
        description: "The current password of the user.",
        type: "string",
      },
      {
        name: "new_password",
        description: "The new password for the user.",
        type: "string",
      },
      {
        name: "uu_id",
        description: "The UUID of the user whose password is being changed.",
        type: "string",
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/padlockAdmin/forgot_password\' -H \'Content-Type: application/json\' -d \'{"password":"currentpassword","new_password":"newpassword","uu_id":"user-uuid"}\'',
    responseExample: {
      status: "Successfully changed the password",
    },
    errorResponses: [
      {
        code: 400,
        message: "Password entered is wrong",
      },
      {
        code: 404,
        message: "User not found",
      },
      {
        code: 500,
        message: "Internal Server Error",
      },
    ],
  },
  {
    name: "Reset Password",
    method: "PUT",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/reset_password",
    parameters: [
      {
        name: "password",
        description: "The new password for the user.",
        type: "string",
      },
      {
        name: "confirm_password",
        description: "Confirmation of the new password.",
        type: "string",
      },
      {
        name: "email",
        description:
          "The email address of the user whose password is being reset.",
        type: "string",
      },
    ],
    requestExample:
      'curl -X PUT \'https://api.trypadlock.com/padlockAdmin/reset_password\' -H \'Content-Type: application/json\' -d \'{"password":"newpassword","confirm_password":"newpassword","email":"user@example.com"}\'',
    responseExample: {
      status: "Password reset successfully",
    },
    errorResponses: [
      {
        code: 400,
        message: "Password and confirm password didn't match",
      },
      {
        code: 404,
        message: "Invalid User",
      },
      {
        code: 500,
        message: "Internal Server Error",
      },
    ],
  },
  {
    name: "Add Team Member",
    method: "PUT",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/AddTeamMember/:org/:teamid",
    parameters: [
      {
        name: "org",
        description: "The UUID of the organization.",
        type: "string",
        required: true,
        in: "path",
      },
      {
        name: "teamid",
        description: "The UUID of the team to which members are being added.",
        type: "string",
        required: true,
        in: "path",
      },
      {
        name: "team",
        description: "The new name for the team.",
        type: "string",
        required: false,
        in: "query",
      },
      {
        name: "userid",
        description:
          "Comma-separated list of user UUIDs to be added to the team.",
        type: "string",
        required: true,
        in: "query",
      },
    ],
    requestExample:
      "curl -X PUT 'https://api.trypadlock.com/padlockAdmin/AddTeamMember/org-uuid/team-id?team=New%20Team%20Name&userid=user1,user2,user3' -H 'Authorization: Bearer YOUR_ACCESS_TOKEN'",
    responseExample: {
      Status: "Successfully added the user to team",
    },
    errorResponses: [
      {
        code: 400,
        message: "No users are found",
      },
      {
        code: 404,
        message: "No Team is found",
      },
      {
        code: 500,
        message: "Internal Server Error",
      },
    ],
  },
  {
    name: "Add Team",
    method: "POST",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/addTeam",
    parameters: [
      {
        name: "name",
        description: "The name of the team to be added.",
        type: "string",
      },
      {
        name: "org_uuid",
        description: "The UUID of the organization to which the team belongs.",
        type: "string",
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/padlockAdmin/addTeam\' -H \'Content-Type: application/json\' -d \'{"name": "TeamName", "org_uuid": "org-uuid-here"}\'',
    responseExample: {
      status: "Team created Successfully",
      uu_id: "string",
    },
    errorResponses: [
      {
        code: 400,
        message: "Team is already found",
      },
    ],
  },
  {
    name: "List Teams",
    method: "GET",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/listTeam/:org",
    parameters: [
      {
        name: "org",
        description:
          "The UUID of the organization whose teams are to be listed.",
        type: "string",
      },
      {
        name: "observer",
        description:
          "Optional query parameter to filter teams based on observer status.",
        type: "boolean",
        optional: true,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/listTeam/padlockAdmin/org-uuid-here?observer=true' -H 'Authorization: Bearer your_token_here'",
    responseExample: {
      info: [
        {
          team: {
            id: 1,
            name: "Team Name",
            org_uuid: "org-uuid-here",
            status: true,
            team_uuid: "team-uuid-here",
          },
          count: 5,
        },
      ],
    },
    errorResponses: [
      {
        code: 404,
        message: "No team is found for org",
      },
    ],
  },
  {
    name: "List Team Members",
    method: "GET",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/listMember/:team",
    parameters: [
      {
        name: "team",
        description: "The ID of the team whose members are to be listed.",
        type: "string",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/padlockAdmin/listMember/team-id-here' -H 'Authorization: Bearer your_token_here'",
    responseExample: {
      info: [
        {
          role: "Team Role",
          user_uuid: "user-uuid-here",
          username: "username",
          email: "user@example.com",
        },
      ],
    },
    errorResponses: [
      {
        code: 404,
        message: "No team is found for the given ID",
      },
    ],
  },
  {
    name: "Delete Team",
    method: "DELETE",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/teamDelete",
    parameters: [
      {
        name: "org",
        description:
          "The UUID of the organization from which the team is to be deleted.",
        type: "string",
      },
      {
        name: "teamid",
        description: "The UUID of the team to be deleted.",
        type: "string",
      },
    ],
    requestExample:
      "curl -X DELETE 'https://api.trypadlock.com/padlockAdmin/teamDelete?org=org-uuid-here&teamid=team-uuid-here' -H 'Authorization: Bearer your_token_here'",
    responseExample: "Successfully deleted team",
    errorResponses: [
      {
        code: 400,
        message: "Team is already inactive",
      },
      {
        code: 404,
        message: "No team is found for the given organization",
      },
    ],
  },
  {
    name: "Delete Team Members",
    method: "DELETE",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/DeleteTeamMembers",
    parameters: [
      {
        name: "userid",
        description:
          "Comma-separated list of user UUIDs to be removed from the team.",
        type: "string",
      },
      {
        name: "teamid",
        description:
          "The UUID of the team from which members are to be removed.",
        type: "string",
      },
    ],
    requestExample:
      "curl -X DELETE 'https://api.trypadlock.com/padlockAdmin/DeleteTeamMembers' -H 'Content-Type: application/json' -H 'Authorization: Bearer your_token_here' -d '{\"userid\": \"user-uuid-1,user-uuid-2\", \"teamid\": \"team-uuid-here\"}'",
    responseExample: {
      Status: "User Removed successfully",
    },
    errorResponses: [
      {
        code: 404,
        message: "No team is found for the given organization",
      },
    ],
  },
  {
    name: "Update Member Role",
    method: "PUT",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/updateMember",
    parameters: [
      {
        name: "userId",
        description: "The ID of the user whose role is to be updated.",
        type: "string",
      },
      {
        name: "email",
        description: "The email of the user whose role is to be updated.",
        type: "string",
      },
      {
        name: "role",
        description: "The new role to assign to the user.",
        type: "string",
      },
    ],
    requestExample:
      'curl -X PUT \'https://api.trypadlock.com/padlockAdmin/updateMember\' -H \'Content-Type: application/json\' -H \'Authorization: Bearer your_token_here\' -d \'{"userId": "user-id-here", "email": "user@example.com", "role": "new-role"}\'',
    responseExample: {
      message: "User role updated successfully",
    },
    errorResponses: [
      {
        code: 404,
        message: "User not found",
      },
      {
        code: 404,
        message: "User details not found",
      },
      {
        code: 500,
        message: "Internal Server Error",
      },
    ],
  },
  {
    name: "Update Phone Number",
    method: "PUT",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/phone_update",
    parameters: [
      {
        name: "user_uuid",
        description:
          "The UUID of the user whose phone number is to be updated.",
        type: "string",
      },
      {
        name: "phone",
        description: "The new phone number for the user.",
        type: "string",
      },
    ],
    requestExample:
      "curl -X PUT 'https://api.trypadlock.com/padlockAdmin/phone_update' -H 'Content-Type: application/json' -H 'Authorization: Bearer your_token_here' -d '{\"user_uuid\": \"user-uuid-here\", \"phone\": \"new-phone-number\"}'",
    responseExample: {
      status: "phone number updated",
    },
    errorResponses: [
      {
        code: 404,
        message: "No user found with the provided UUID",
      },
    ],
  },
  {
    name: "Get Login Feature",
    method: "GET",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/get_login_feature",
    parameters: [
      {
        name: "org_uuid",
        description:
          "The UUID of the organization for which the login feature is to be retrieved.",
        type: "string",
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/padlockAdmin/get_login_feature?org_uuid=org-uuid-here' -H 'Authorization: Bearer your_token_here'",
    responseExample: {
      features: [
        {
          featureid: 15,
          id: 1,
          feature: "Feature Name",
          description: "Feature Description",
          status: true,
          data: true,
          userexcluded: ["username1", "username2"],
        },
      ],
    },
    errorResponses: [
      {
        code: 200,
        message: "No features found for the provided organization UUID",
      },
    ],
  },
  {
    name: "Add Observer to Team",
    method: "POST",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/addObserverToTeam",
    parameters: [
      {
        name: "email",
        description: "The email of the observer to be added to the team.",
        type: "string",
      },
      {
        name: "team_uuid",
        description:
          "The UUID of the team to which the observer is to be added.",
        type: "string",
      },
      {
        name: "org_uuid",
        description: "The UUID of the organization to which the team belongs.",
        type: "string",
      },
      {
        name: "user_uuid",
        description: "The UUID of the user who is being added as an observer.",
        type: "string",
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/padlockAdmin/addObserverToTeam\' -H \'Content-Type: application/json\' -H \'Authorization: Bearer your_token_here\' -d \'{"email": "observer@example.com", "team_uuid": "team-uuid-here", "org_uuid": "org-uuid-here", "user_uuid": "user-uuid-here"}\'',
    responseExample: {
      message: "Observer added to the team successfully",
    },
    errorResponses: [
      {
        code: 404,
        message: "User not found",
      },
      {
        code: 500,
        message: "Internal Server Error",
      },
    ],
  },
  {
    name: "Remove Observer from Team",
    method: "POST",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/removeObserverFromTeam",
    parameters: [
      {
        name: "email",
        description: "The email of the observer to be removed from the team.",
        type: "string",
      },
      {
        name: "team_uuid",
        description:
          "The UUID of the team from which the observer is to be removed.",
        type: "string",
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/padlockAdmin/removeObserverFromTeam' -H 'Content-Type: application/json' -H 'Authorization: Bearer your_token_here' -d '{\"email\": \"observer@example.com\", \"team_uuid\": \"team-uuid-here\"}'",
    responseExample: {
      message: "Observer removed from the team successfully",
    },
    errorResponses: [
      {
        code: 404,
        message: "Observer not found in the team",
      },
      {
        code: 500,
        message: "Internal Server Error",
      },
    ],
  },
  {
    name: "Add Manager to Team",
    method: "POST",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/addManagerToTeam",
    parameters: [
      {
        name: "email",
        description: "The email of the manager to be added to the team.",
        type: "string",
      },
      {
        name: "team_uuid",
        description:
          "The UUID of the team to which the manager is to be added.",
        type: "string",
      },
      {
        name: "org_uuid",
        description: "The UUID of the organization to which the team belongs.",
        type: "string",
      },
      {
        name: "user_uuid",
        description: "The UUID of the user who is being added as a manager.",
        type: "string",
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/padlockAdmin/addManagerToTeam\' -H \'Content-Type: application/json\' -H \'Authorization: Bearer your_token_here\' -d \'{"email": "manager@example.com", "team_uuid": "team-uuid-here", "org_uuid": "org-uuid-here", "user_uuid": "user-uuid-here"}\'',
    responseExample: {
      message: "Manager added to the team successfully",
    },
    errorResponses: [
      {
        code: 404,
        message: "User not found",
      },
      {
        code: 500,
        message: "Internal Server Error",
      },
    ],
  },
  {
    name: "Remove Manager from Team",
    method: "POST",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/removeManagerFromTeam",
    parameters: [
      {
        name: "email",
        description: "The email of the manager to be removed from the team.",
        type: "string",
      },
      {
        name: "team_uuid",
        description:
          "The UUID of the team from which the manager is to be removed.",
        type: "string",
      },
    ],
    requestExample:
      "curl -X POST 'https://api.trypadlock.com/padlockAdmin/removeManagerFromTeam' -H 'Content-Type: application/json' -H 'Authorization: Bearer your_token_here' -d '{\"email\": \"manager@example.com\", \"team_uuid\": \"team-uuid-here\"}'",
    responseExample: {
      message: "Manager removed from the team successfully",
    },
    errorResponses: [
      {
        code: 404,
        message: "Manager not found for the team",
      },
      {
        code: 500,
        message: "Internal Server Error",
      },
    ],
  },
  {
    name: "Get Feature Market",
    method: "GET",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/featureMarket",
    parameters: [
      {
        name: "type",
        description: "Type of the feature market (e.g., 'Org', 'Team').",
        type: "string",
        required: true,
      },
      {
        name: "userid",
        description: "User ID to filter the features if applicable.",
        type: "string",
        required: false,
      },
      {
        name: "teamid",
        description: "Team ID to filter the features if applicable.",
        type: "string",
        required: false,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/padlockAdmin/featureMarket?type=Org&userid=user-uuid-here' -H 'Authorization: Bearer your_token_here'",
    responseExample: {
      feature: [
        {
          id: 1,
          name: "Feature Name",
          desciption: "Feature Description",
          status: true,
        },
        {
          id: 2,
          name: "Another Feature",
          desciption: "Another Feature Description",
          status: false,
        },
      ],
    },
    errorResponses: [
      {
        code: 404,
        message: "No data found",
      },
      {
        code: 500,
        message: "Internal Server Error",
      },
    ],
  },
  {
    name: "Add or Update Feature Market",
    method: "POST",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/featureMarket",
    parameters: [
      {
        name: "featureid",
        description: "ID of the feature to be added or updated.",
        type: "integer",
        required: true,
      },
      {
        name: "type",
        description: "Type of the feature market (e.g., 'Org', 'Team').",
        type: "string",
        required: true,
      },
      {
        name: "userid",
        description:
          "User ID to associate with the feature market if applicable.",
        type: "string",
        required: false,
      },
      {
        name: "teamid",
        description:
          "Team ID to associate with the feature market if applicable.",
        type: "string",
        required: false,
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/padlockAdmin/featureMarket\' -H \'Authorization: Bearer your_token_here\' -H \'Content-Type: application/json\' -d \'{"featureid": 1, "type": "Org", "userid": "user-uuid-here"}\'',
    responseExample: {
      Status: "Data saved Successfully",
    },
    errorResponses: [
      {
        code: 400,
        message: "Data already exists",
      },
      {
        code: 404,
        message: "Feature not found",
      },
      {
        code: 500,
        message: "Internal Server Error",
      },
    ],
  },
  {
    name: "Remove Feature Market Data",
    method: "DELETE",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/featureMarket",
    parameters: [
      {
        name: "featureid",
        description: "ID of the feature to be removed.",
        type: "integer",
        required: true,
      },
    ],
    requestExample:
      "curl -X DELETE 'https://api.trypadlock.com/padlockAdmin/featureMarket' -H 'Authorization: Bearer your_token_here' -H 'Content-Type: application/json' -d '{\"featureid\": 1}'",
    responseExample: {
      status: "Data removed Successfully",
    },
    errorResponses: [
      {
        code: 404,
        message: "No Data Found",
      },
      {
        code: 500,
        message: "Internal Server Error",
      },
    ],
  },
  {
    name: "Get Feature List",
    method: "GET",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/featureList",
    parameters: [
      {
        name: "type",
        description:
          "Type of the feature list to retrieve. Can be 'Org', 'User', or 'Team'.",
        type: "string",
        required: true,
      },
      {
        name: "userid",
        description: "User ID for filtering features when type is 'User'.",
        type: "string",
        required: false,
      },
      {
        name: "teamid",
        description: "Team ID for filtering features when type is 'Team'.",
        type: "string",
        required: false,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/padlockAdmin/featureList?type=User&userid=example_user_id' -H 'Authorization: Bearer your_token_here'",
    responseExample: {
      features: [
        {
          featureid: 1,
          id: 123,
          feature: "Feature Name",
          description: "Feature Description",
          status: true,
          data: true,
          userexcluded: ["user1", "user2"],
        },
      ],
    },
    errorResponses: [
      {
        code: 404,
        message: "No data found for the specified criteria",
      },
      {
        code: 500,
        message: "Internal Server Error",
      },
    ],
  },
  {
    name: "Get Unavailable Features",
    method: "GET",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/featureUnavailable",
    parameters: [
      {
        name: "type",
        description:
          "Type of the feature list to retrieve. Used to filter the features.",
        type: "string",
        required: true,
      },
      {
        name: "userid",
        description:
          "User ID for filtering unavailable features when a user-specific check is needed.",
        type: "string",
        required: false,
      },
      {
        name: "teamid",
        description:
          "Team ID for filtering unavailable features when a team-specific check is needed.",
        type: "string",
        required: false,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/padlockAdmin/featureUnavailable?type=User&userid=example_user_id' -H 'Authorization: Bearer your_token_here'",
    responseExample: {
      feature: [
        {
          id: 1,
          name: "Feature Name",
          description: "Feature Description",
        },
      ],
    },
    errorResponses: [
      {
        code: 404,
        message: "No data found for the specified criteria",
      },
      {
        code: 500,
        message: "Internal Server Error",
      },
    ],
  },
  {
    name: "Get Feature Override Information",
    method: "GET",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/featureOveride",
    parameters: [
      {
        name: "featureid",
        description: "Comma-separated list of feature IDs to check.",
        type: "string",
        required: true,
      },
      {
        name: "type",
        description:
          "Type of the feature list to retrieve ('Org', 'Team', or 'User').",
        type: "string",
        required: true,
      },
      {
        name: "status",
        description:
          "Comma-separated list of statuses corresponding to the features.",
        type: "string",
        required: true,
      },
      {
        name: "team_uuid",
        description: "Team UUID for filtering when the type is 'Team'.",
        type: "string",
        required: false,
      },
      {
        name: "user_uuid",
        description: "User UUID for filtering when the type is 'User'.",
        type: "string",
        required: false,
      },
    ],
    requestExample:
      "curl -X GET 'https://api.trypadlock.com/padlockAdmin/featureOveride?featureid=1,2&type=Team&status=active,inactive&team_uuid=example_team_uuid' -H 'Authorization: Bearer your_token_here'",
    responseExample: {
      Status: [
        {
          id: 1,
          users: ["username1", "username2"],
          teams: ["team1", "team2"],
          org: ["organization_name"],
        },
      ],
    },
    errorResponses: [
      {
        code: 400,
        message: "Bad Request - Missing or invalid parameters",
      },
      {
        code: 404,
        message: "No features found for the specified criteria",
      },
      {
        code: 500,
        message: "Internal Server Error",
      },
    ],
  },
  {
    name: "Start Feature",
    method: "POST",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/featureStart",
    parameters: [
      {
        name: "feature",
        description: "The ID of the feature to start.",
        type: "string",
        required: true,
      },
      {
        name: "type",
        description:
          "The type of entity ('Org', 'Team', or 'User') for which the feature is being started.",
        type: "string",
        required: true,
      },
      {
        name: "userid",
        description:
          "Comma-separated list of user IDs to exclude (only applicable if type is 'Org' or 'Team').",
        type: "string",
        required: false,
      },
      {
        name: "teamid",
        description: "The ID of the team (only applicable if type is 'Team').",
        type: "string",
        required: false,
      },
      {
        name: "usersid",
        description: "The ID of the user (only applicable if type is 'User').",
        type: "string",
        required: false,
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/padlockAdmin/featureStart\' -H \'Content-Type: application/json\' -H \'Authorization: Bearer your_token_here\' -d \'{"feature": "1", "type": "Org", "userid": "2,3"}\'',
    responseExample: {
      status: "Data saved Successfully",
    },
    errorResponses: [
      {
        code: 400,
        message: "Bad Request - Feature already exists or missing parameters",
      },
      {
        code: 500,
        message: "Internal Server Error",
      },
    ],
  },
  {
    name: "Delete Feature Start",
    method: "DELETE",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/featureStart",
    parameters: [
      {
        name: "featureid",
        description: "The ID of the feature to be removed.",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      "curl -X DELETE 'https://api.trypadlock.com/padlockAdmin/featureStart?featureid=1' -H 'Authorization: Bearer your_token_here'",
    responseExample: {
      status: "Data removed Successfully",
    },
    errorResponses: [
      {
        code: 404,
        message:
          "No Data Found - No feature with the given ID exists for the organization",
      },
      {
        code: 500,
        message: "Internal Server Error",
      },
    ],
  },
  {
    name: "Enable Feature",
    method: "PUT",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/featureEnable",
    parameters: [
      {
        name: "id",
        description: "The ID of the feature to be enabled.",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      "curl -X PUT 'https://api.trypadlock.com/padlockAdmin/featureEnable?id=1' -H 'Authorization: Bearer your_token_here'",
    responseExample: {
      status: "Enabled Successfully",
    },
    errorResponses: [
      {
        code: 404,
        message: "Data not found - No feature with the given ID exists",
      },
      {
        code: 500,
        message: "Internal Server Error",
      },
    ],
  },
  {
    name: "Disable Feature",
    method: "PUT",
    category: "Padlock Admin",
    endpoint: "/padlockAdmin/featureStop",
    parameters: [
      {
        name: "id",
        description: "The ID of the feature to be disabled.",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      "curl -X PUT 'https://api.trypadlock.com/padlockAdmin/featureStop?id=1' -H 'Authorization: Bearer your_token_here'",
    responseExample: {
      status: "Disabled Successfully",
    },
    errorResponses: [
      {
        code: 404,
        message: "Data not found - No feature with the given ID exists",
      },
      {
        code: 500,
        message: "Internal Server Error",
      },
    ],
  },
  {
    name: "Signup",
    method: "POST",
    category: "Strived",
    endpoint: "/strived/signup",
    parameters: [
      {
        name: "email",
        description:
          "The email address of the user. This will be used to find if the user is already registered.",
        type: "string",
        required: true,
      },
      {
        name: "org_name",
        description:
          "The name of the organization the user is associated with.",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      'curl -X POST \'https://api.trypadlock.com/strived/signup\' -H \'Content-Type: application/json\' -d \'{"email": "user@example.com", "org_name": "ExampleOrg"}\'',
    responseExample: {
      Status: "Email sent successfully",
    },
    errorResponses: [
      {
        code: 403,
        message:
          "Please contact your admin to be invited to Strived - User is not registered in the application",
      },
      {
        code: 500,
        message: "Internal Server Error - Error details will be provided",
      },
    ],
  },
  {
    name: "Login Verification",
    method: "PUT",
    category: "Strived",
    endpoint: "/strived/strived_login_verification",
    parameters: [
      {
        name: "email",
        description: "The email address of the user trying to log in.",
        type: "string",
        required: true,
      },
      {
        name: "otp",
        description:
          "The OTP (One Time Password) entered by the user for verification.",
        type: "string",
        required: true,
      },
      {
        name: "org_name",
        description:
          "The name of the organization the user is associated with.",
        type: "string",
        required: true,
      },
    ],
    requestExample:
      'curl -X PUT \'https://api.trypadlock.com/strived/strived_login_verification\' -H \'Content-Type: application/json\' -d \'{"email": "user@example.com", "otp": "123456", "org_name": "ExampleOrg"}\'',
    responseExample: {
      token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9",
      refresh_token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9",
    },
    errorResponses: [
      {
        code: 400,
        message:
          "No OTP is found - The user does not have a valid OTP for verification.",
      },
      {
        code: 401,
        message: "Incorrect OTP - The OTP provided is not valid or expired.",
      },
      {
        code: 404,
        message:
          "User not found - The user with the given email or organization name does not exist.",
      },
      {
        code: 404,
        message:
          "No Organization found - The user is not associated with any organization.",
      },
    ],
  },
];

export default documentationData;
