import React from 'react'
import Modal from 'react-responsive-modal'
import { ArrowNarrowLeftIcon } from '@heroicons/react/solid'
import './selectingFeature.css'
export default function SelectingFeature({open, onCloseModal}) {

  return (
    <Modal open={open} onClose={onCloseModal} center classNames={{ overlay: 'customOverlay', modal: 'customModal2', }} >
                <div className='ml-4' >
                    <div className=" border-gray-200 sm:flex sm:items-center -mt-2">
                        <div className="flex flex-row justify-center items-end align-middle text-center mr-10">
                            <ArrowNarrowLeftIcon className="sm:inline self-center flex-shrink-0 h-5 w-5 hidden cursor-pointer " onClick={onCloseModal} />
                        </div>
                        <h1 className=' 2xl:text-xl' >Custom Plan Two</h1>
                       
                    </div>
                   
                   <div>
                    <h1 className='text-gray-400  xl:text-lg'>Price per user</h1>
                    <div className='w-[90%] border-[1px] border-gray-400 rounded-md h-10 flex items-center'>
                        <h1 className='ml-6 text-gray-400'>$12</h1>
                    </div>
                    <p className='mt-4 text-gray-500  2xl:text-lg'>Features</p>
                    <div >
                        {
                             [...Array(8)].map((feature,index)=>{
                                return (
                                    <div className='flex items-center mb-4 2xl:mb-8 mt-1'>
                                        <input type='checkbox' className='mr-2' />
                                        <p className='text-sm 2xl:text-2xl '>Feature {index}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                   </div>
                   <div className=" flex flex-row justify-end items-end text-center ">
                            <h3 className="text-sm font-medium bg-black text-white inline-flex items-baseline px-5 py-2 rounded-md cursor-pointer " onClick={() => onCloseModal()}>Finish</h3>
                        </div>
                </div>
            </Modal>
  )
}
