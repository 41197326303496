import React, { useState, useEffect } from 'react'
import { addMembersTeam, getTeamMemebers, getUserListofORG } from '../../services/loggedInApp'
import GTable from '../GTable';
import NPagination from '../Pagination/NPagination';
import ProfileNav from '../Profile/ProfileNav';
import { useHistory, useLocation } from 'react-router-dom';
import Modal from 'react-responsive-modal'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';


export default function TeamPage() {
    const history = useHistory();
    const location = useLocation();
    const locState = location.state;

    // console.log('locasadsdaTeamPage', locState)

    const [team, setTeam] = useState([]);
    const [orguserList, setOrgUserList] = useState([]);
    const [member, setMember] = useState('');
    const [pageOrgUserNumber,setPageOrgUserNumber] = useState(0);

    const [showModel, setShowModel] = useState(false);

    const fetch_team_members = () => {
        getTeamMemebers(locState.teamId).then((res) => {
            if(res.data.info == ""){
                setTeam(undefined)
            } else {
                setTeam(res.data.info)
            }
        })
    }

    useEffect(() => {
        fetch_team_members();
         getUserlistOrg();
    },[])

    const handleClose = () => {
        setShowModel(false);
    }

    const teamMember = (e, userid) =>{
        e.preventDefault();
        console.log('delete id num',userid)
        console.log('teamUuid',locState.teamUuid);
        console.log('orguuid',locState.orgUuid)
    }

    const handleDeleteTeam = (e, userid) =>{
        e.preventDefault();
        fetch(
            `https://api.trypadlock.com/team/DeleteTeamMember/${locState.orgUuid}/${locState.teamUuid}/${userid}`,
            {
                method: "DELETE",
                headers: {
                    authorization: "Jwt " + localStorage.getItem("token"),
                    "Content-Type": "application/json",
                },
            }
        )
            .then((res) => res.json())
            .then((data) => {
                if (data.status === 200) {
                    DeletingTeamMember();
                }
            })
            .catch((err) => console.log("err in delete team"));
        
    }

    const teamperPage = 8;
    const pagesOrgUserVisited = pageOrgUserNumber * teamperPage ;
    const pageUserOrgCount = Math.ceil(team ==  undefined ? "" : team.length / teamperPage );

    const changeOrgTeamPage = ({ selected }) => {
        setPageOrgUserNumber(selected);
    }

    const displayOrgsTeamsMembers = team == undefined ?  <h3 className="text-lg m-10">No Teams Found </h3> : (team.slice(pagesOrgUserVisited,
        pagesOrgUserVisited + teamperPage).map((team, teamIdx) => {
          return (
            <tr key={teamIdx} className={teamIdx % 2 === 0 ? "bg-white":"bg-gray-50"}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 ">
                {team.username}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{team.email}</td>
              <td onClick={(e) => {teamMember(e,team.user_uuid)}} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{team.role}</td>
              <td onClick={(e) => {handleDeleteTeam(e,team.user_uuid)}} className="hover:underline cursor-pointer">Delete</td>
            </tr>
          )
        })
    );

    const getUserlistOrg = () => {
        getUserListofORG(locState.appId, locState.orgId).then((res) => {
          if(res.data.data == ""){
            setOrgUserList(undefined)
          } else {
            setOrgUserList(res.data.data);
          }
        })
    }

    const addMembersToTeam = () => {
        addMembersTeam(locState.org_uuid, locState.teamUuid, member.user_id, locState.teamName).then((res) => {
            if(res.status == 200){
                fetch_team_members();
            } else {
                console.log('addTeam', res)
            }
        })
    }

    const handleAutoComplete = (event, values) => {
        // if(member == ""){
        //     setMember("")
        // } else {
            setMember(values)
        // }
    }

    console.log('ORGuselist', orguserList)
    console.log('member', member)
    console.log('team', team);

    return (
        <>
            <ProfileNav />
            <div className='p-2'>
                <div className='mt-10'>
                    <p className='flex left-0 top-2 bg-gray-700 rounded-md w-16 h-8 text-white font-bold justify-center items-center shadow-lg cursor-pointer' onClick={() => {history.goBack()}}>back</p>
                </div>
                <div className='mt-10 p-4'>
                    <p className='mt-2 mb-8 text-3xl font-semibold'>Team Members</p>

                    <div className='flex justify-end items-center'>
                        <button className='bg-gray-900 text-white p-1 px-2 rounded-md' onClick={() => {setShowModel(true)}}>Add Members</button>
                    </div>
                    {GTable(displayOrgsTeamsMembers,['Username', "Email", "Role", ""])}
                    <NPagination pageOrgCount={pageUserOrgCount} onPageChange={changeOrgTeamPage} />
                </div>
            </div>
            <Modal open={showModel} onClose={handleClose} classNames={{overlay: 'customOverlay',modal: 'customModal'}}>
                <div>
                    <Autocomplete
                        id="search-emp"
                        onChange={handleAutoComplete}
                        options={orguserList}
                        value={orguserList}
                        style={{margin: 0, padding: 0, width: 200}}
                        getOptionLabel={option => `${option.username}`}
                        renderInput={(params) => <TextField style={{margin: 0, padding: 0}} {...params} label="Search Employee" />}
                    />
                </div>
                <div className='mt-4'>
                    <button onClick={addMembersToTeam} className="bg-gray-900 text-white rounded-md p-2">Add Member</button>
                </div>
            </Modal>
        </>
    )
}
