import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router";
import ProfileNav from "../Profile/ProfileNav";
import { Link } from "react-router-dom";
import { DocumentTextIcon } from "@heroicons/react/solid";
import { toast, ToastContainer } from "react-toastify";
import {
  SearchIcon,
  ArrowSmDownIcon,
  ArrowSmUpIcon,
  TrashIcon,
  PencilIcon,
} from "@heroicons/react/solid";
import NPagination from "../Pagination/NPagination";
import { refreshTokenn } from "../../services/auth";
import {
  ErrorRedirecting,
  FillAllFields,
  OrgAlreadyExists,
  Created_app_CV,
  Delete_app_CV,
  Edit_app_CV,
  OrgCreatedSuccessfully,
  OrgDeletedSuccessfully,
  ServerError,
  Org_added_to_Cv,
  ErrorinCV,
  no_space_allowed,
  Created_app_Feature,
} from "../NotificationToast";
import {
  deleteORG,
  reactivateORG,
  getClintId,
  getOrgs,
  getDeactiveOrgs,
  getUserDataRevenueApp,
} from "../../services/loggedInApp";
import GTable from "../GTable";
import Modal from "react-responsive-modal";
import Dropdown from "react-dropdown";
// import DateRangeFilter from "../DatePicker";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import moment from "moment";
import buisness from "../../assets/office.jpg";
// import twix from "twix";
import "../../styles/modal.css";

import ReactHighcharts from "react-highcharts";
import { Context } from "../../services/context";
import { transport } from "../../services/api";

export default function EachAppPage() {
  const history = useHistory();
  let location = useLocation();
  let locState = location.state;
  const context = useContext(Context);
  // console.log('locState', locState);

  const [orgsList, setOrgsList] = useState([]);
  const [deactiveOrgsList, setDeactiveOrgsList] = useState([]);
  const [uData, setuData] = useState({});
  const [pageOrgNumber, setPageOrgNumber] = useState(0);
  const [pageDeactiveOrgNumber, setPageDeactiveOrgNumber] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [deactiveSearchTerm, setDeactiveSearchTerm] = useState("");
  const [orgName, setOrgName] = useState("");
  const [orgDisplayName, setOrgDisplayName] = useState("");
  const [orgIDtobeDel, setOrgIdtobeDel] = useState("");
  const [orgIDtobeRea, setOrgIdtobeRe] = useState("");
  const [selectSortBy, setSelectSortBy] = useState("");

  const [deleteModal, setDeleteMOdal] = useState(false);
  const [reactiveModal, setReactivateModal] = useState(false);
  const [isOpenModel, setIsOpenModal] = useState(false);
  const [isOpenReactiveModal, setIsOpenReactiveModal] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [features, setFeatures] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [appdataCV_info, setAppDataCV_info] = useState([]);

  const [cvOpen, setCvOpen] = useState(false);

  const [appKeyName, setAppKeyName] = useState("");
  const [appValueName, setAppValueName] = useState("");
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");

  const [deleteAppCv_key, setDeleteAppCV_key] = useState("");
  const [showDelAppcv, setShowDelAppcv] = useState(false);
  const [showCreateAppcvModal, setShowCreateAppcvModal] = useState(false);
  const [showCreateFeatureModal, setShowCreateFeatureModal] = useState(false);
  const [showClientIdModal, setShowClientIdModal] = useState(false);
  const [isClientIdAvailable, setIsClientIdAvailable] = useState(false);
  const [showEditAppcvModal, setShowEditAppcvModal] = useState(false);
  const [placeHolderValue_appCV_edit, setPlaceHolderValue_appCV_edit] =
    useState({});
  const [isNameOpen, setIsNameOpen] = useState(false);
  const [isDeactiveNameOpen, setIsDeactiveNameOpen] = useState(false);
  const [isDeactiveDateOpen, setIsDeactiveDateOpen] = useState(false);
  const [isDateOpen, setIsDateOpen] = useState(false);
  const [newFeatureName, setNewFeatureName] = useState("");
  const [newFeatureDesc, setNewFeatureDesc] = useState("");
  const [userLogin, setUserLogin] = useState([]);

  const sortByOption = ["All", "Organization", "Joined Date"];

  const onCloseModel = () => {
    setIsOpenModal(false);
    setDeleteMOdal(false);
    setShowDatePicker(false);
    setCvOpen(false);
    setShowDelAppcv(false);
    setShowCreateAppcvModal(false);
    setShowCreateFeatureModal(false);
    setShowEditAppcvModal(false);
    setDeleteAppCV_key("");
    setOrgIdtobeDel("");
    setShowClientIdModal(false);
    setClientId("");
    setIsOpenReactiveModal(false);
    setReactivateModal(false);
    setOrgIdtobeRe("");
  };

  const userDataReveneue = () => {
    getUserDataRevenueApp(locState.appid).then((res) => {
      setuData(res.data);
    });
  };

  const handleDropDown = (sort) => {
    setSelectSortBy(sort.value);
  };

  const getClientIdDetails = () => {
    getClintId(locState.appid).then((resp) => {
      if (resp?.status === 200) {
        let respStatus = resp?.data?.status;
        setIsClientIdAvailable(respStatus);
      } else {
        setIsClientIdAvailable(false);
      }
    });
  };

  const getAllOrgs = () => {
    getOrgs(locState.appid).then((res) => {
      if (res.data.data == undefined || res.data.data == "") {
        setOrgsList(undefined);
      } else {
        let orglist = res.data?.data;
        if (orglist && orglist.length > 0) {
          orglist
            .sort((a, b) => new Date(a.created) - new Date(b.created))
            .reverse();
        }
        setOrgsList(orglist);
        console.log(orglist);
        context.setOrgs(orglist);
      }
    });
  };
  const getDeactiveOrgsList = () => {
    getDeactiveOrgs(locState.appid).then((res) => {
      if (res.data.data == undefined || res.data.data == "") {
        setDeactiveOrgsList(undefined);
      } else {
        let orglist = res.data?.data;
        if (orglist && orglist.length > 0) {
          orglist
            .sort((a, b) => new Date(a.created) - new Date(b.created))
            .reverse();
        }
        setDeactiveOrgsList(orglist);
        // context.setDeactiveOrgs(orglist);
      }
    });
  };
  const getFeatures = () => {
    transport(
      `users/list_features?appname=${
        locState.appName === "workahub" ? "Workahub" : locState.appName
      }`
    ).then((res) => {
      if (res.status === 200 && res?.data?.features?.length > 0) {
        setFeatures(res.data.features);
      }
    });
  };
  useEffect(() => {
    userDataReveneue();
    getAllOrgs();
    getDeactiveOrgsList();
    getAppsCV();
    userLoginChartData();
    getFeatures();
    getClientIdDetails();
  }, []);

  const stats = [
    {
      id: 1,
      name: "This month total revenue",
      stat:
        uData.thismonthRevune == undefined ? (
          <p className="loader"></p>
        ) : (
          uData.thismonthRevune
        ),
      change: "122",
      changeType: "increase",
    },
    {
      id: 2,
      name: "Today active users",
      stat:
        uData.activeToday == undefined ? (
          <p className="loader"></p>
        ) : (
          uData.activeToday
        ),
      change: "5.4%",
      changeType: "increase",
    },
    {
      id: 3,
      name: "Today new signups",
      stat:
        uData.createdToday == undefined ? (
          <p className="loader"></p>
        ) : (
          uData.createdToday
        ),
      change: "3.2%",
      changeType: "decrease",
    },
    {
      id: 4,
      name: "Last month total revenue",
      stat:
        uData.lastmonthRevune == undefined ? (
          <p className="loader"></p>
        ) : (
          uData.lastmonthRevune
        ),
      change: "3.2%",
      changeType: "decrease",
    },
    {
      id: 5,
      name: "Yesterday active users",
      stat:
        uData.activeYesterday == undefined ? (
          <p className="loader"></p>
        ) : (
          uData.activeYesterday
        ),
      change: "3.2%",
      changeType: "decrease",
    },
    {
      id: 6,
      name: "Yesterday new signups",
      stat:
        uData.createdYesterday == undefined ? (
          <p className="loader"></p>
        ) : (
          uData.createdYesterday
        ),
      change: "3.2%",
      changeType: "decrease",
    },
    {
      id: 7,
      name: "Weekly active users",
      stat:
        uData.activeWeekly == undefined ? (
          <p className="loader"></p>
        ) : (
          uData.activeWeekly
        ),
      change: "3.2%",
      changeType: "decrease",
    },
  ];

  //sorting the table using Name of organisation

  const sortNameAscending = () => {
    orgsList.sort((a, b) => a.name.localeCompare(b.name)).reverse();
  };

  const sortDeactiveNameAscending = () => {
    deactiveOrgsList.sort((a, b) => a.name.localeCompare(b.name)).reverse();
  };

  const sortNameDescending = () => {
    orgsList.sort((a, b) => a.name.localeCompare(b.name));
  };

  const sortDeactiveNameDescending = () => {
    deactiveOrgsList.sort((a, b) => a.name.localeCompare(b.name));
  };

  const nameArrowSort = () => {
    if (isNameOpen === false) {
      sortNameDescending();
    } else {
      sortNameAscending();
    }
  };

  const nameSortOpen = () => {
    if (isNameOpen === false) {
      setIsNameOpen(true);
    } else {
      setIsNameOpen(false);
    }
  };

  const deactiveNameArrowSort = () => {
    if (isDeactiveNameOpen === false) {
      sortDeactiveNameDescending();
    } else {
      sortDeactiveNameAscending();
    }
  };

  const deactiveNameSortOpen = () => {
    if (isDeactiveNameOpen === false) {
      setIsDeactiveNameOpen(true);
    } else {
      setIsDeactiveNameOpen(false);
    }
  };

  // sorting the table using createdDate of organisation

  const sortDateAscending = () => {
    orgsList
      .sort((a, b) => new Date(a.created) - new Date(b.created))
      .reverse();
    setOrgsList(orgsList);
  };

  const sortDateDescending = () => {
    orgsList.sort((a, b) => new Date(a.created) - new Date(b.created));
    setOrgsList(orgsList);
  };

  const sortDeactiveDateAscending = () => {
    deactiveOrgsList
      .sort((a, b) => new Date(a.created) - new Date(b.created))
      .reverse();
    setDeactiveOrgsList(deactiveOrgsList);
  };

  const sortDeactiveDateDescending = () => {
    deactiveOrgsList.sort((a, b) => new Date(a.created) - new Date(b.created));
    setDeactiveOrgsList(deactiveOrgsList);
  };

  const dateArrowSort = () => {
    if (isDateOpen == false) {
      sortDateDescending();
    } else {
      sortDateAscending();
    }
  };

  const dateSortOpen = () => {
    if (isDateOpen == false) {
      setIsDateOpen(true);
    } else {
      setIsDateOpen(false);
    }
  };

  const deactiveDateArrowSort = () => {
    if (isDeactiveDateOpen === false) {
      sortDeactiveDateDescending();
    } else {
      sortDeactiveDateAscending();
    }
  };

  const deactiveDateSortOpen = () => {
    if (isDeactiveDateOpen === false) {
      setIsDeactiveDateOpen(true);
    } else {
      setIsDeactiveDateOpen(false);
    }
  };

  //sorting the table using totalnumber of user
  const [isTotalNoUsers, setIsTotalNoUsers] = useState(false);

  const sortTotalUsersAscending = () => {
    orgsList.sort((a, b) => a.count - b.count).reverse();
  };

  const sortTotalUsersDescending = () => {
    orgsList.sort((a, b) => a.count - b.count);
  };

  const totalUsersArrowSort = () => {
    if (isTotalNoUsers === false) {
      sortTotalUsersDescending();
    } else {
      sortTotalUsersAscending();
    }
  };

  const totalUserSortOpen = () => {
    if (isTotalNoUsers === false) {
      setIsTotalNoUsers(true);
    } else {
      setIsTotalNoUsers(false);
    }
  };

  const createOrgSubmit = (e) => {
    e.preventDefault();
    if (orgName == "" || orgDisplayName == "") {
      FillAllFields();
      return;
    } else {
      if (orgName && orgName.length > 20) {
        toast.dark(" organisation name should be less than 20 characters");
        return;
      }

      if (orgName && orgName.indexOf(" ") >= 0) {
        toast.dark("Organisation name should not contain spaces");
        return;
      }
      if (orgName && orgName.length < 20 && orgName.indexOf(" ") < 0) {
        fetch("https://api.trypadlock.com/org/", {
          method: "POST",
          headers: {
            authorization: "Jwt " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: orgName,
            org_display_name: orgDisplayName,
            appid: locState.appid,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.staus == "org already exists") {
              OrgAlreadyExists();
            } else if (data.status == "org cannot have spaces") {
              no_space_allowed();
            } else {
              setIsOpenModal(false);
              OrgCreatedSuccessfully();
              getAllOrgs();
            }
          });
      }
    }
  };

  const openDeleteModel = (id) => {
    setOrgIdtobeDel(id);
    setDeleteMOdal(true);
    setIsOpenModal(true);
  };

  const openReactivateModel = (id) => {
    setOrgIdtobeRe(id);
    setReactivateModal(true);
    setIsOpenReactiveModal(true);
  };

  const deleteOrg = () => {
    deleteORG(orgIDtobeDel).then((res) => {
      console.log("deleteEORG", res);
      setIsOpenModal(false);
      OrgDeletedSuccessfully();
      getAllOrgs();
      getDeactiveOrgsList();
      setOrgIdtobeDel("");
      setDeleteMOdal(false);
    });
  };

  const reactivateOrg = () => {
    reactivateORG(orgIDtobeRea).then((res) => {
      setIsOpenReactiveModal(false);
      toast.dark("Organization Reactivated Successfully!");
      getAllOrgs();
      getDeactiveOrgsList();
      setOrgIdtobeRe("");
      setReactivateModal(false);
    });
  };

  const handleDateChange = (ranges) => {
    setStartDate(ranges.selection.startDate);
    setEndDate(ranges.selection.endDate);
  };

  const usersperPage = 8;
  const pagesOrgsVisited = pageOrgNumber * usersperPage;
  let pageOrgCount = Math.ceil(
    orgsList == undefined ? "" : orgsList.length / usersperPage
  );

  const deactiveOrgPerPage = 8;
  const deactiveOrgsVisited = pageDeactiveOrgNumber * deactiveOrgPerPage;
  let pageDeactiveOrgsCount = Math.ceil(
    deactiveOrgsList == undefined
      ? ""
      : deactiveOrgsList.length / deactiveOrgPerPage
  );

  const changeOrgPage = ({ selected }) => {
    setPageOrgNumber(selected);
  };

  const changeDeactiveOrgPage = ({ selected }) => {
    setPageDeactiveOrgNumber(selected);
  };

  const handleNavigation = (
    e,
    appName,
    type,
    orgId = null,
    orgName = null,
    uuid,
    url,
    org_display_name = null
  ) => {
    e.preventDefault();
    switch (type) {
      case "plans":
        history.push({
          pathname: `/${appName}/region`,
          state: { appName, appId: locState.appid },
        });
        break;
      case "orgPage":
        context.setSelectedOrg({
          org_uuid: orgId,
          name: orgName,
        });
        history.push({
          pathname: `/${appName}/${orgName}`,
          state: {
            appName,
            orgId: orgId,
            orgName: orgName,
            appId: locState.appid,
            uuidOrg: uuid,
            url: url,
            org_display_name: org_display_name,
          },
        });
        break;
      case "activityLogPage":
        history.push({
          pathname: `/${appName}/activitylog`,
          state: {
            appName,
            appId: locState.appid,
            url: url,
          },
        });
        break;
      default:
        ErrorRedirecting();
    }
  };

  const editAppCVModal = (app_info) => {
    console.log("edit App CV appInfo==", app_info);
    setPlaceHolderValue_appCV_edit(app_info);
    setShowEditAppcvModal(true);
    setShowCreateAppcvModal(true);
  };

  const deleteAppCVModal = (app_info) => {
    console.log("delete app cv modal --appinfo===", app_info);
    setDeleteAppCV_key(app_info);
    setShowDelAppcv(true);
  };
  const getAppsCV = () => {
    fetch(
      `https://api.trypadlock.com/app/app_data?app_uuid=${
        locState.appuuid == "" || locState.appuuid == null
          ? ""
          : locState.appuuid
      }`,
      {
        method: "GET",
        headers: {
          authorization: "Jwt " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("getappdat", data);
        if (data == null || data == undefined) {
          console.log("Error fetching app CV");
        } else if (data.status == "key and value not found") {
          console.log("key and value not found");
        } else {
          setAppDataCV_info(data);
        }
      })
      .catch((err) => {
        console.log("getAppsCV error in ", err);
      });
  };

  const createCVapp = (e) => {
    e.preventDefault();
    if (appKeyName == "" || appValueName == "") {
      FillAllFields();
    } else {
      fetch("https://api.trypadlock.com/app/app_data", {
        method: "POST",
        headers: {
          authorization: "Jwt " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          app_uuid: locState.appuuid,
          name: appKeyName,
          main_key: appValueName,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status == "app got added with the value and key") {
            setShowCreateAppcvModal(false);
            getAppsCV();
            Created_app_CV();
          } else {
            ServerError();
          }
        });
    }
  };
  const createClientId = (e) => {
    e.preventDefault();
    try {
      transport("loginapp/add-clientId", {
        method: "POST",
        data: {
          appId: locState.appid,
          clientId: clientId,
          clientSecret: clientSecret,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            setShowClientIdModal(false);
            setClientId("");
            setIsClientIdAvailable(true);
            toast.success("client id created successfully");
          } else {
            ServerError();
          }
        })
        .catch((err) => {
          toast.error("Error in createClientId");
          console.log("Error in createClientId", err?.message);
          setShowClientIdModal(false);
        });
    } catch (e) {
      toast.error("Error in createClientId");
      console.log("Error in createClientId", e?.message);
    }
  };
  const createNewFeature = (e) => {
    e.preventDefault();
    if (!newFeatureName || !newFeatureDesc) {
      FillAllFields();
    } else {
      transport("users/create_feature", {
        method: "POST",
        data: {
          app: locState.appName,
          name: newFeatureName,
          description: newFeatureDesc,
        },
      }).then((res) => {
        if (res.status === 200) {
          setShowCreateFeatureModal(false);
          getFeatures();
          Created_app_Feature();
        } else {
          ServerError();
        }
      });
    }
  };

  const editCVapp = (e) => {
    e.preventDefault();
    if (appValueName == "") {
      FillAllFields();
    } else {
      fetch("https://api.trypadlock.com/app/app_data", {
        method: "POST",
        headers: {
          authorization: "Jwt " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          app_uuid: locState.appuuid,
          name: placeHolderValue_appCV_edit.key,
          main_key: appValueName,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status == "app got updated with the value and key") {
            setShowCreateAppcvModal(false);
            getAppsCV();
            setShowEditAppcvModal(false);
            Edit_app_CV();
          } else {
            ServerError();
          }
        });
    }
  };

  const deleteAppCV = () => {
    fetch("https://api.trypadlock.com/app/app_data", {
      method: "DELETE",
      headers: {
        authorization: "Jwt " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        app_uuid: locState.appuuid,
        name: deleteAppCv_key,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == "key and value of app got deleted") {
          setShowDelAppcv(false);
          getAppsCV();
          Delete_app_CV();
        } else {
          ServerError();
        }
      });
  };

  const userLoginChartData = () => {
    fetch(
      `https://api.trypadlock.com/graph/client_graph?start_date=${"2022-07-01"}&end_date=${"2022-07-01"}`,
      {
        method: "GET",
        headers: {
          authorization: "Jwt " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status == 200) {
          return res.json();
        } else if (res.status == 401) {
          refreshTokenn();
          userLoginChartData();
        }
      })
      .then((data) => {
        setUserLogin(data?.data);
      });
  };

  let arr = [];

  const org =
    orgsList == undefined ? (
      <tr>
        <td colSpan={6} className="text-lg m-10">
          No Organizations Found
        </td>
      </tr>
    ) : (
      orgsList.filter((val) => {
        if (selectSortBy == "Organization") {
          if (searchTerm == "") {
            arr.push(val);
            // console.log('arr in organisation ===',arr)
            // setOrgsList(arr)
            return val;
          } else if (
            val.name.toLowerCase().includes(searchTerm.toLowerCase())
          ) {
            arr.push(val);
            // console.log("arr in organisation ==",arr)
            pageOrgCount = Math.ceil(
              arr == undefined ? "" : arr.length / usersperPage
            );
          }
        } else if (selectSortBy == "Joined Date") {
          if (searchTerm == "") {
            arr.push(val);
            // console.log('arr in joined date==',arr)
            // setOrgsList(arr)
            return val;
          } else if (
            val.created == null
              ? ""
              : moment(val.created).format("MMM Do YY").includes(searchTerm) > 0
          ) {
            arr.push(val);
            // console.log('arr in joined date else if == ',arr)
            // setOrgsList(arr)
            pageOrgCount = Math.ceil(
              arr == undefined ? "" : arr.length / usersperPage
            );
          }
        } else if (selectSortBy == "All") {
          if (searchTerm == "") {
            arr.push(val);
            // console.log('sort by log All in if ==',arr)
            // setOrgsList(arr)
            return val;
          } else if (
            val.name.toLowerCase().includes(searchTerm.toLowerCase())
          ) {
            arr.push(val);
            // console.log('sort by log All in else ===',arr)
            // setOrgsList(arr)
            pageOrgCount = Math.ceil(
              arr == undefined ? "" : arr.length / usersperPage
            );
          }
        } else {
          if (searchTerm == "") {
            arr.push(val);
            // console.log('sort by "" in if ==',arr)
            // setOrgsList(arr)
            return val;
          } else if (
            val.name.toLowerCase().includes(searchTerm.toLowerCase())
          ) {
            arr.push(val);
            // console.log('sort by "" in else ==',arr)
            // setOrgsList(arr)
            pageOrgCount = Math.ceil(
              arr == undefined ? "" : arr.length / usersperPage
            );
          }
        }
      })
    );

  // useEffect(()=>{
  //   org();
  // },[searchTerm])

  //org display data

  const featuresTable =
    !features || features.length === 0 ? (
      <tr>
        <td colSpan={3} className="text-lg m-10">
          No Feature Found
        </td>
      </tr>
    ) : (
      features.map((d, idx) => (
        <tr
          key={idx + "appdatacvinfo"}
          className={idx % 2 == 0 ? "bg-white" : "bg-gray-50"}>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
            {idx + 1}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
            {d["name"]}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
            {d["description"]}
          </td>
        </tr>
      ))
    );
  const customVariableTable =
    appdataCV_info.status == undefined ||
    appdataCV_info.status == "" ||
    appdataCV_info.status == null ? (
      <tr>
        <td colSpan={3} className="text-lg m-10">
          No Custom Variable Found
        </td>
      </tr>
    ) : (
      appdataCV_info.status.map((d, idx) => (
        <tr
          key={idx + "appdatacvinfo"}
          className={idx % 2 == 0 ? "bg-white" : "bg-gray-50"}>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
            {Object.keys(d)}
          </td>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
            {Object.values(d)}
          </td>
          <td className="flex gap-4 items-center">
            <p className="px-6 py-4 whitespace-nowrap text-gray-600 text-xs">
              <PencilIcon
                className="w-5 h-5 cursor-pointer"
                onClick={() => {
                  editAppCVModal({
                    key: Object.keys(d).toString(),
                    value: Object.values(d).toString(),
                  });
                }}
              />
            </p>
            <p className="px-6 py-4 whitespace-nowrap text-gray-600 text-xs">
              <TrashIcon
                className="w-5 h-5 text-red-500 cursor-pointer"
                onClick={() => {
                  deleteAppCVModal(Object.keys(d).toString());
                }}
              />
            </p>
          </td>
        </tr>
      ))
    );

  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const userLoginDate = userLogin && userLogin.map((el) => el.date);
  const userLoginData = userLogin && userLogin.map((el) => el.number_of_users);

  const userLoginChart = {
    chart: {
      type: "column",
      height: 300,
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.y}</b>",
    },
    plotOptions: {},
    credits: {
      enabled: false,
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: userLoginDate,
      crosshair: true,
      title: {
        text: "",
      },
    },
    yAxis: {
      title: {
        text: "",
      },
    },
    series: [
      {
        name: "Employees",
        color: "#000",
        data: userLoginData && userLoginData,
      },
    ],
  };

  return (
    <>
      <ProfileNav />

      <div className="p-20 smt:p-2">
        <p
          className="flex left-0 top-2 bg-gray-700 rounded-md w-16 h-8 text-white font-bold justify-center items-center shadow-lg cursor-pointer"
          onClick={() => {
            history.goBack();
          }}>
          back
        </p>

        {/* APP NAME AT TOP */}
        <div className=" flex justify-center items-center flex-col">
          <h3 className="text-5xl underline capitalize font-bold text-center flex justify-center">
            {locState?.appName ? locState?.appName : ""}
          </h3>
        </div>

        {/* REVENUE BOXED */}
        <div className="mb-10">
          <dl className="mt-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-2">
            {stats.map((item, idx) => (
              <div
                key={idx + "_stats"}
                className="relative bg-white border border-gray-100 pt-5 px-4 pb-1 sm:pt-6 sm:px-6 shadow overflow-hidden">
                <dt>
                  <p className="text-sm font-medium text-gray-500 truncate">
                    {item.name}
                  </p>
                </dt>
                <dd className="pb-6 flex justify-between items-baseline sm:pb-7">
                  <span className="text-2xl font-semibold text-gray-900">
                    {" "}
                    {item.stat}{" "}
                  </span>
                  {/* <p
                                      className={classNames(
                                          item.changeType === "increase"
                                              ? "bg-green-100 text-green-600"
                                              : "bg-red-100 text-red-600",
                                          "inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0"
                                      )}>
                                      {item.changeType === "increase" ? (
                                          <ArrowSmUpIcon
                                              className="self-center flex-shrink-0 h-5 w-5 text-green-500"
                                              aria-hidden="true"
                                          />
                                      ) : (
                                          <ArrowSmDownIcon
                                              className="self-center flex-shrink-0 h-5 w-5 text-red-500"
                                              aria-hidden="true"
                                          />
                                      )}

                                      <span className="sr-only">
                                          {" "}
                                          {item.changeType === "increase"
                                              ? "Increased"
                                              : "Decreased"}{" "}
                                          by{" "}
                                      </span>
                                      {item.change}
                                  </p> */}
                </dd>
              </div>
            ))}
          </dl>
        </div>

        {userLoginData?.length > 0 && (
          <div className="mt-4">
            <ReactHighcharts config={userLoginChart}> </ReactHighcharts>{" "}
          </div>
        )}

        <div className="my-14">
          <div className="flex">
            <h3 className="text-lg leading-6 font-medium text-gray-900 mr-4">
              Google OAuth Client
            </h3>
            <p
              className="w-9 h-6 rounded-full bg-gray-800 text-white flex justify-center items-center cursor-pointer font-bold"
              onClick={() => {
                setShowClientIdModal(true);
              }}>
              +
            </p>
          </div>
          <div className="flex justify-center mt-4 bg-gray-50 p-4">
            {isClientIdAvailable ? (
              "Your Client ID is available for this app."
            ) : (
              <div>
                <p className="mb-2">
                  It looks like you don't have a Client ID for this app yet. To
                  create one, please follow the instructions in our
                  documentation.
                </p>
                <Link to="/googleClientDoc">
                  <DocumentTextIcon className={classNames("2-6 h-6 navIcon")} />
                  <span className={classNames("px-3  text-blue-600")}>
                    View Documentation
                  </span>
                </Link>
              </div>
            )}
          </div>
        </div>

        {/* Search INPUT */}
        <div className="pb-5  border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <div className="flex">
            <h3 className="text-lg leading-6 font-medium text-gray-900 mr-4">
              Organizations
            </h3>
            <p
              className="w-9 h-6 rounded-full bg-gray-800 text-white flex justify-center items-center cursor-pointer font-bold"
              onClick={() => {
                setIsOpenModal(true);
              }}>
              +
            </p>
          </div>
          <div className="mt-3 sm:mt-0 sm:ml-4">
            <div className="flex">
              <div className="relative flex-grow focus-within:z-10">
                <div className="mb-4">
                  <label>Filter By</label>
                  <Dropdown
                    options={sortByOption}
                    onChange={(e) => {
                      handleDropDown(e);
                    }}
                    placeholder="All"
                  />
                </div>

                <div className="flex ">
                  {/* {selectSortBy == 'Joined Date' ? */}
                  {/* <p onClick={() => {setShowDatePicker(true)}} className="bg-gray-800 text-white rounded-xl px-3 h-7 flex items-center cursor-pointer">Pick Date</p> */}
                  {/* : */}
                  <>
                    <div className=" inset-y-0 border p-2 z-10 rounded-l-md flex items-center pointer-events-none">
                      <SearchIcon
                        className="h-5 w-5 z-10 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      type="text"
                      name="desktop-search-candidate"
                      id="desktop-search-candidate"
                      className=" w-full rounded-r-md  sm:block sm:text-sm border-gray-300"
                      placeholder="Search Organizations"
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* orgs table */}
        <div className="flex flex-col">
          <div className="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        className="flex px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                        onClick={nameArrowSort}>
                        <div className="flex gap-2">
                          <p className="cursor-pointer">Name</p>
                          <span onClick={nameSortOpen}>
                            {isNameOpen === true ? (
                              <ArrowSmDownIcon className="w-5 h-5 hover:cursor-pointer" />
                            ) : (
                              <ArrowSmUpIcon className="w-5 h-5 hover:cursor-pointer" />
                            )}
                          </span>
                        </div>
                      </th>
                      <th className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        Display Name
                      </th>
                      <th className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        Fixed
                      </th>
                      <th
                        className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                        onClick={dateArrowSort}>
                        <div className="flex gap-2">
                          <p className="cursor-pointer">Joined Date</p>
                          <span onClick={dateSortOpen}>
                            {isDateOpen === true ? (
                              <ArrowSmDownIcon className="w-5 h-5 hover:cursor-pointer" />
                            ) : (
                              <ArrowSmUpIcon className="w-5 h-5 hover:cursor-pointer" />
                            )}
                          </span>
                        </div>
                      </th>
                      <th
                        className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                        onClick={dateArrowSort}>
                        <div className="flex gap-2">
                          <p className="cursor-pointer">Created Date</p>
                          <span onClick={dateSortOpen}>
                            {isDateOpen === true ? (
                              <ArrowSmDownIcon className="w-5 h-5 hover:cursor-pointer" />
                            ) : (
                              <ArrowSmUpIcon className="w-5 h-5 hover:cursor-pointer" />
                            )}
                          </span>
                        </div>
                      </th>
                      <th
                        className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                        onClick={totalUsersArrowSort}>
                        <div className="flex gap-2">
                          <p>Total Number of Users</p>
                          <span onClick={totalUserSortOpen}>
                            {isTotalNoUsers ? (
                              <ArrowSmDownIcon className="w-5 h-5 hover:cursor-pointer" />
                            ) : (
                              <ArrowSmUpIcon className="w-5 h-5 hover:cursor-pointer" />
                            )}
                          </span>
                        </div>
                      </th>
                      <th className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        Org uuid
                      </th>
                      <th className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        Org Id
                      </th>
                      <th className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {orgsList == undefined ? (
                      <tr>
                        <td colSpan={6} className="text-lg m-10">
                          No Organizations Found
                        </td>
                      </tr>
                    ) : (
                      arr
                        .slice(
                          pagesOrgsVisited,
                          pagesOrgsVisited + usersperPage
                        )
                        .map((orgs, orgsIdx) => {
                          return (
                            <tr
                              key={orgsIdx + "_orgsinapp" + "_" + orgs.org_uuid}
                              className={
                                orgsIdx % 2 === 0 ? "bg-white" : "bg-gray-50"
                              }>
                              <td
                                className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 cursor-pointer hover:underline"
                                onClick={(e) =>
                                  handleNavigation(
                                    e,
                                    locState.appName,
                                    "orgPage",
                                    orgs.id,
                                    orgs.name,
                                    orgs.org_uuid,
                                    orgs.url,
                                    orgs?.org_display_name
                                  )
                                }>
                                <p className="flex items-center">
                                  <span className="mr-2 hidden lg:block">
                                    <img
                                      src={
                                        orgs.url == "None" ? buisness : orgs.url
                                      }
                                      alt="org"
                                      className="rounded-full border object-cover w-8 h-8"
                                    />
                                  </span>
                                  {orgs.name}
                                </p>
                              </td>
                              {/* <td className="flex justify-start items-center pl-0 sm:pl-6"><span className="bg-gray-800 inline-block text-white font-bold rounded-md p-1 mt-2 px-5 cursor-pointer" onClick={(e) => openCvModal(orgs.org_uuid)}>CV</span></td> */}
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{`${
                                orgs?.org_display_name
                                  ? orgs?.org_display_name
                                  : orgs?.name
                              }`}</td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{`${orgs.fixed}`}</td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                {orgs.created == null
                                  ? ""
                                  : moment(orgs.created).format("MMM Do YY")}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                {orgs.created == null
                                  ? ""
                                  : moment(orgs.created).format("MMM Do YY")}
                              </td>
                              <td className="px-10 py-4 whitespace-nowrap text-sm text-gray-600">
                                {orgs.count}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600 cursor-pointer">
                                {" "}
                                {orgs.org_uuid}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600 cursor-pointer">
                                {" "}
                                {orgs.id}
                              </td>
                              <td
                                className="px-6 py-4 whitespace-nowrap text-sm text-gray-600 cursor-pointer"
                                onClick={() => {
                                  openDeleteModel(orgs.org_uuid);
                                }}>{`Deactivate`}</td>
                            </tr>
                          );
                        })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* {GTable(displayOrgs, ['Name','Fixed', 'Joined Date', 'Total Number of Users', ''])} */}
        <NPagination pageCount={pageOrgCount} onPageChange={changeOrgPage} />

        <hr className="mt-14" />

        {/* Search INPUT */}
        <div className="pb-5  border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
          <div className="flex">
            <h3 className="text-lg leading-6 font-medium text-gray-900 mr-4">
              Deactive Organization List
            </h3>
          </div>
          <div className="mt-3 sm:mt-0 sm:ml-4">
            <div className="flex">
              <div className="relative flex-grow focus-within:z-10">
                <div className="flex ">
                  <>
                    <div className=" inset-y-0 border p-2 z-10 rounded-l-md flex items-center pointer-events-none">
                      <SearchIcon
                        className="h-5 w-5 z-10 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      type="text"
                      name="desktop-search-candidate"
                      id="desktop-search-candidate"
                      className=" w-full rounded-r-md  sm:block sm:text-sm border-gray-300"
                      placeholder="Search Organizations"
                      onChange={(e) => setDeactiveSearchTerm(e.target.value)}
                    />
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* orgs table */}
        <div className="flex flex-col">
          <div className="my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        className="flex px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                        onClick={deactiveNameArrowSort}>
                        <div className="flex gap-2">
                          <p className="cursor-pointer">Name</p>
                          <span onClick={deactiveNameSortOpen}>
                            {isDeactiveNameOpen === true ? (
                              <ArrowSmDownIcon className="w-5 h-5 hover:cursor-pointer" />
                            ) : (
                              <ArrowSmUpIcon className="w-5 h-5 hover:cursor-pointer" />
                            )}
                          </span>
                        </div>
                      </th>
                      <th className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        Fixed
                      </th>
                      <th className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        <div className="flex gap-2">
                          <p>Joined Date</p>
                        </div>
                      </th>
                      <th
                        className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                        onClick={deactiveDateArrowSort}>
                        <div className="flex gap-2">
                          <p className="cursor-pointer">Created Date</p>
                          <span onClick={deactiveDateSortOpen}>
                            {isDeactiveDateOpen === true ? (
                              <ArrowSmDownIcon className="w-5 h-5 hover:cursor-pointer" />
                            ) : (
                              <ArrowSmUpIcon className="w-5 h-5 hover:cursor-pointer" />
                            )}
                          </span>
                        </div>
                      </th>
                      <th className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        <div className="flex gap-2">
                          <p>Total Number of Users</p>
                        </div>
                      </th>
                      <th className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        Org uuid
                      </th>
                      <th className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        Org Id
                      </th>
                      <th className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {deactiveOrgsList == undefined ? (
                      <tr>
                        <td colSpan={6} className="text-lg m-10">
                          No Organizations Found
                        </td>
                      </tr>
                    ) : (
                      deactiveOrgsList
                        .filter((deactiveOrg) =>
                          deactiveOrg.name
                            .toLowerCase()
                            .includes(deactiveSearchTerm.toLowerCase())
                        )
                        .slice(
                          deactiveOrgsVisited,
                          deactiveOrgsVisited + deactiveOrgPerPage
                        )
                        .map((orgs, orgsIdx) => {
                          return (
                            <tr
                              key={orgsIdx + "_orgsinapp" + "_" + orgs.org_uuid}
                              className={
                                orgsIdx % 2 === 0 ? "bg-white" : "bg-gray-50"
                              }>
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 ">
                                <p className="flex items-center">
                                  <span className="mr-2 hidden lg:block">
                                    <img
                                      src={
                                        orgs.url == "None" ? buisness : orgs.url
                                      }
                                      alt="org"
                                      className="rounded-full border object-cover w-8 h-8"
                                    />
                                  </span>
                                  {orgs.name}
                                </p>
                              </td>
                              {/* <td className="flex justify-start items-center pl-0 sm:pl-6"><span className="bg-gray-800 inline-block text-white font-bold rounded-md p-1 mt-2 px-5 cursor-pointer" onClick={(e) => openCvModal(orgs.org_uuid)}>CV</span></td> */}
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{`${orgs.fixed}`}</td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                {orgs.created == null
                                  ? ""
                                  : moment(orgs.created).format("MMM Do YY")}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                                {orgs.created == null
                                  ? ""
                                  : moment(orgs.created).format("MMM Do YY")}
                              </td>
                              <td className="px-10 py-4 whitespace-nowrap text-sm text-gray-600">
                                {orgs.count}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600 ">
                                {" "}
                                {orgs.org_uuid}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600 ">
                                {" "}
                                {orgs.id}
                              </td>
                              <td
                                className="px-6 py-4 whitespace-nowrap text-sm text-gray-600 cursor-pointer"
                                onClick={() => {
                                  openReactivateModel(orgs.org_uuid);
                                }}>{`Reactive`}</td>
                            </tr>
                          );
                        })
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* {GTable(displayOrgs, ['Name','Fixed', 'Joined Date', 'Total Number of Users', ''])} */}
        <NPagination
          pageCount={pageDeactiveOrgsCount}
          onPageChange={changeDeactiveOrgPage}
        />

        <hr className="mt-14" />

        <div className="my-14">
          <div className="flex">
            <h3 className="text-lg leading-6 font-medium text-gray-900 mr-4">
              Features
            </h3>
            <p
              className="w-9 h-6 rounded-full bg-gray-800 text-white flex justify-center items-center cursor-pointer font-bold"
              onClick={() => {
                setShowCreateFeatureModal(true);
              }}>
              +
            </p>
          </div>
          <div>{GTable(featuresTable, ["S.no", "Name", "Description"])}</div>
        </div>
        <div className="my-14">
          <div className="flex">
            <h3 className="text-lg leading-6 font-medium text-gray-900 mr-4">
              Custom Variables
            </h3>
            <p
              className="w-9 h-6 rounded-full bg-gray-800 text-white flex justify-center items-center cursor-pointer font-bold"
              onClick={() => {
                setShowCreateAppcvModal(true);
              }}>
              +
            </p>
          </div>
          <div>{GTable(customVariableTable, ["Key", "Value", "Actions"])}</div>
        </div>
        <div className="flex gap-8 mb-5 flex-wrap">
          <div className="mt-10">
            <p className="text-2xl mb-8">Plans and Payment</p>
            <p
              className="w-36 box-content px-4 h-10 text-white flex items-center justify-center bg-gray-400 border-b-2 border-gray-500 rounded cursor-pointer shadow-md hover:border-gray-600"
              onClick={(e) => handleNavigation(e, locState.appName, "plans")}>
              Plans and Payment
            </p>
          </div>
          <div className="mt-10">
            <p className="text-2xl mb-8">Activity Log</p>
            <p
              className="w-36 h-10 px-4 text-white flex items-center justify-center bg-gray-400 border-b-2 border-gray-500 rounded cursor-pointer shadow-md hover:border-gray-600"
              onClick={(e) =>
                handleNavigation(e, locState.appName, "activityLogPage")
              }>
              Logs
            </p>
          </div>
        </div>
      </div>
      <Modal
        open={isOpenModel}
        onClose={onCloseModel}
        classNames={{ overlay: "customOverlay", modal: "customModal" }}>
        {deleteModal ? (
          <div>
            <label>Are you sure you want to Deactivate?</label>
            <div className="mt-8 flex justify-evenly items-center">
              <button
                onClick={() => {
                  onCloseModel();
                  setOrgIdtobeDel("");
                }}
                className="bg-gray-700 text-white font-bold rounded-md w-24 h-8 mr-4 ">
                Cancel
              </button>
              <button
                onClick={() => {
                  deleteOrg();
                }}
                className="bg-red-600 text-white font-bold rounded-md w-24 h-8">
                Yes
              </button>
            </div>
          </div>
        ) : (
          <div className="flex flex-col">
            <label className="text-lg font-bold">Organization Name</label>
            <input
              type="text"
              onChange={(e) => {
                setOrgName(e.target.value);
              }}
              placeholder="Enter Organization Name"
              className="mt-2 mb-4 rounded-md"
            />
            <input
              type="text"
              onChange={(e) => {
                setOrgDisplayName(e.target.value);
              }}
              placeholder="Enter Organization Display Name"
              className="mt-2 mb-4 rounded-md"
            />

            <button
              className="bg-gray-800 text-white rounded-md p-2.5 font-semibold"
              onClick={(e) => {
                createOrgSubmit(e);
              }}>
              Submit
            </button>
          </div>
        )}
      </Modal>

      <Modal
        open={isOpenReactiveModal}
        onClose={onCloseModel}
        classNames={{ overlay: "customOverlay", modal: "customModal" }}>
        {reactiveModal && (
          <div>
            <label>Are you sure you want to Reactivate?</label>
            <div className="mt-8 flex justify-evenly items-center">
              <button
                onClick={() => {
                  onCloseModel();
                  setOrgIdtobeRe("");
                }}
                className="bg-gray-700 text-white font-bold rounded-md w-24 h-8 mr-4 ">
                Cancel
              </button>
              <button
                onClick={() => {
                  reactivateOrg();
                }}
                className="bg-red-600 text-white font-bold rounded-md w-24 h-8">
                Yes
              </button>
            </div>
          </div>
        )}
      </Modal>
      <Modal open={showDatePicker} onClose={onCloseModel}>
        {" "}
        <DateRangePicker
          ranges={[selectionRange]}
          onChange={handleDateChange}
        />
      </Modal>
      <Modal
        open={showCreateFeatureModal}
        onClose={onCloseModel}
        classNames={{ overlay: "customOverlay", modal: "customModal" }}>
        {showEditAppcvModal ? (
          <div>
            <p className="text-2xl my-5 font-bold">
              Edit Value for Custom Variable
            </p>
            <div className="flex flex-col">
              <label className="text-md font-bold">Key Name</label>
              <input
                type="text"
                disabled
                name="appKey"
                placeholder="Key"
                value={placeHolderValue_appCV_edit.key}
                className="mt-2 mb-4 rounded-md"
              />
              <label className="text-md font-bold">Value</label>
              <input
                type="text"
                onChange={(e) => {
                  setAppValueName(e.target.value);
                }}
                placeholder="Value"
                className="mt-2 mb-4 rounded-md"
              />
              <button
                className="bg-gray-900 text-white rounded-md p-2.5 font-semibold"
                onClick={(e) => {
                  editCVapp(e);
                }}>
                Submit
              </button>
            </div>
          </div>
        ) : (
          <div>
            <p className="text-2xl my-5 font-bold">Create Feature</p>
            <div className="flex flex-col">
              <label className="text-md font-bold">Feature Name</label>
              <input
                type="text"
                onChange={(e) => {
                  setNewFeatureName(e.target.value);
                }}
                placeholder="Enter Name"
                className="mt-2 mb-4 rounded-md"
              />
              <label className="text-md font-bold">Description</label>
              <input
                type="text"
                onChange={(e) => {
                  setNewFeatureDesc(e.target.value);
                }}
                placeholder="Enter Description"
                className="mt-2 mb-4 rounded-md"
              />
              <button
                className="bg-gray-900 text-white rounded-md p-2.5 font-semibold"
                onClick={(e) => {
                  createNewFeature(e);
                }}>
                Submit
              </button>
            </div>
          </div>
        )}
      </Modal>
      <Modal
        open={showClientIdModal}
        onClose={onCloseModel}
        classNames={{ overlay: "customOverlay", modal: "customModal" }}>
        <div>
          <p className="text-2xl my-5 font-bold">
            Create Google OAuth ClientId
          </p>
          <div className="flex flex-col">
            <label className="text-md font-bold">ClientId</label>
            <input
              type="text"
              onChange={(e) => {
                setClientId(e.target.value);
              }}
              placeholder="Enter ClientId"
              className="mt-2 mb-4 rounded-md"
            />
            <label className="text-md font-bold">Client Secret</label>
            <input
              type="text"
              onChange={(e) => {
                setClientSecret(e.target.value);
              }}
              placeholder="Enter Client Secret"
              className="mt-2 mb-4 rounded-md"
            />
            <button
              className="bg-gray-900 text-white rounded-md p-2.5 font-semibold"
              onClick={(e) => {
                createClientId(e);
              }}>
              Submit
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        open={showCreateAppcvModal}
        onClose={onCloseModel}
        classNames={{ overlay: "customOverlay", modal: "customModal" }}>
        {showEditAppcvModal ? (
          <div>
            <p className="text-2xl my-5 font-bold">
              Edit Value for Custom Variable
            </p>
            <div className="flex flex-col">
              <label className="text-md font-bold">Key Name</label>
              <input
                type="text"
                disabled
                name="appKey"
                placeholder="Key"
                value={placeHolderValue_appCV_edit.key}
                className="mt-2 mb-4 rounded-md"
              />
              <label className="text-md font-bold">Value</label>
              <input
                type="text"
                onChange={(e) => {
                  setAppValueName(e.target.value);
                }}
                placeholder="Value"
                className="mt-2 mb-4 rounded-md"
              />
              <button
                className="bg-gray-900 text-white rounded-md p-2.5 font-semibold"
                onClick={(e) => {
                  editCVapp(e);
                }}>
                Submit
              </button>
            </div>
          </div>
        ) : (
          <div>
            <p className="text-2xl my-5 font-bold">Create Custom Variable</p>
            <div className="flex flex-col">
              <label className="text-md font-bold">Key Name</label>
              <input
                type="text"
                onChange={(e) => {
                  setAppKeyName(e.target.value);
                }}
                placeholder="Enter Key Name"
                className="mt-2 mb-4 rounded-md"
              />
              <label className="text-md font-bold">Value</label>
              <input
                type="text"
                onChange={(e) => {
                  setAppValueName(e.target.value);
                }}
                placeholder="Enter Value"
                className="mt-2 mb-4 rounded-md"
              />
              <button
                className="bg-gray-900 text-white rounded-md p-2.5 font-semibold"
                onClick={(e) => {
                  createCVapp(e);
                }}>
                Submit
              </button>
            </div>
          </div>
        )}
      </Modal>
      <Modal
        open={showDelAppcv}
        onClose={onCloseModel}
        classNames={{ overlay: "customOverlay", modal: "customModal" }}>
        <div>
          <label>Are you sure you want to Delete?</label>
          <div className="mt-8 flex justify-center items-center">
            <button
              onClick={() => {
                onCloseModel();
                setDeleteAppCV_key("");
              }}
              className="bg-gray-700 text-white font-bold rounded-md w-24 h-8 mr-4 ">
              Cancel
            </button>
            <button
              onClick={() => {
                deleteAppCV();
              }}
              className="bg-red-600 text-white font-bold rounded-md w-24 h-8">
              Yes
            </button>
          </div>
        </div>
      </Modal>
      <ToastContainer className="mt-15" />
    </>
  );
}
