import React, { useState } from "react";
import "../../styles/Profile.css";
import { SearchIcon } from "@heroicons/react/solid";
import CreateAppTable from "./CreateAppTable";
import CreateAppPopup from "../CreateAppPopup";
import PostLoggedInApps from '../../services/loggedInApp'

function ProfileAppLists() {
  const [popupValue, setPopUpValue] = useState(false);

  function toggleTrigger() {
    if (popupValue === true) {
      setPopUpValue(false);
    } else {
      setPopUpValue(true);
    }
  }

  const [apps, setApps] = useState([]);
  return (
    <div className="w-full p-24 smt:p-2">
      <div className="block sm:flex justify-between py-3">
        <h1 className="text-center sm:text-justify text-2xl font-bold">
          Your Apps
        </h1>
        <div className="flex justify-around gap-x-4 mx-auto my-4 sm:mx-0 sm:my-0 sm:w-auto">
          <span className="hidden sm:flex">
            <input type="text" placeholder="Search" />
            <SearchIcon className="w-8 bg-black text-white border_radius_right" />
          </span>
          <button
            className="bg-black text-base text-white py-2 px-8 font-bold rounded"
            onClick={() => toggleTrigger()}
          >
            Create App
          </button>
        </div>
      </div>

      <CreateAppPopup
        trigger={popupValue}
        setTrigger={setPopUpValue}
        apps={apps}
        setApps={setApps}
      />
      <CreateAppTable appss={apps} setApps={setApps} />
      {/* <Pagination /> */}
    </div>
  );
}

export default ProfileAppLists;
