import Navbar from '../Navbar/Navbar';
import React,{useState} from "react";
import './PlanPricingSelection.css';
import Select from 'react-select';
import ExpandedFeature from './ExpandedFeatures/BasicFeaturesExpanded';
import Standard from './ExpandedFeatures/StandardFeaturesExpanded';
import Custom from './ExpandedFeatures/CustomFeaturesExpanded';
import Premium from './ExpandedFeatures/PremiumFeaturesExpanded';
import CreateCustom from './CreateCustomPlan';
import { CheckIcon } from '@heroicons/react/solid';
import globe from "../../assets/globe.svg";

// import Popup from 'reactjs-popup';
export default function PlanPricingSelectionPage(){
   const [isOpen,setIsOpen]=useState(false);
    const plans=[
      {
        id:1,
        plan:"Custom Plan",
        price:12,
        feature:[
          'Pariatur quod similque',
          'Itaque cupiditate adipisci quibusdam',
          'Sapiente libero dolorios modi nostrn',
          'Itaque cupiditate adipisci quibusdam',
          'Sapiente libero dolorios modi nostrn',
        ]
      },
    ]
   const colourStyles = {
      control: (styles) => ({ ...styles, backgroundColor: "white" ,border:"1px solid black",color:"black"}),
     
    };
    const regions=[{value:'region1',label:<div>
      <img className="inline self-center flex-shrink-0 h-5 w-5" src={globe} height="10px" width="10px"/>&nbsp;
      region1</div>},
    {value:'region2',label:<div>
      <img className="inline self-center flex-shrink-0 h-5 w-5" src={globe} height="10px" width="10px"/>&nbsp;
    region2</div>},
    {value:'region3',label:<div>
    <img className="inline self-center flex-shrink-0 h-5 w-5" src={globe} height="10px" width="10px"/>&nbsp;
  region2</div>}];
    const usd=[{value:'USD $',label:'USD $'},
    {value:'CRC ₡',label:'CRC ₡'},
    {value:'EUR €',label:'EUR €'}];
return (
   <div>
    <Navbar />
    <div className="mainContainer">
    <h1 className="Plan">Plan and Pricing</h1>   
    <div className="container1">
        <div className="dropdown">
          <Select options={regions}  styles={colourStyles} />
          </div>
       {/* <img className="inline self-center flex-shrink-0 h-5 w-5 text-green-500" src={globe} alt=""/></div> */}
        <div className="dropdown"><Select options={usd} placeholder='USD $' styles={colourStyles}/></div>
        <div className="customs">
        <CreateCustom/>
        </div>
        </div> 

    <br/>
    <div className="container">
        <div className="box">
        <div className="heading"><span className="Basic">BASIC</span><span className="user"><span className="rate">$12</span>/user</span></div>
         <button type="submit" className="button" onClick={""}>
            Choose  Plan
         </button>
         <div className="Feature">Features</div>
         <div className="feature">
         {/* <span><img className="image" src={tick} alt="" />
</span>&nbsp;&nbsp;&nbsp;
        <span className="text">Pariatur quod similque</span> */}
                 <span class="text ">
    <CheckIcon className="inline self-center flex-shrink-0 h-5 w-5 text-green-500" /> &nbsp;Pariatur quod similque
           </span> 
         </div>
         <div className="feature">
         <span class="text ">
    <CheckIcon className="inline self-center flex-shrink-0 h-5 w-5 text-green-500" />
     &nbsp;Sapiente libero dolorios modi nostrn
           </span> 
         </div>
         <div className="feature">
         <span class="text ">
    <CheckIcon className="inline self-center flex-shrink-0 h-5 w-5 text-green-500" />
     &nbsp;Itaque cupiditate adipisci quibusdam
           </span> 
         </div>
         <div className="feature">
         <span class="text ">
    <CheckIcon className="inline self-center flex-shrink-0 h-5 w-5 text-green-500" />
     &nbsp; Pariatur quod similque heogfse anmaon
           </span> 
         </div>
         <div className="feature">
         <span class="text ">
    <CheckIcon className="inline self-center flex-shrink-0 h-5 w-5 text-green-500" />
     &nbsp;Sapiente libero dolorios modi nostrn
           </span> 
         </div>
         <span className="link">
            <ExpandedFeature/>
         </span>
        </div>
{/* standard */}
        <div className="box">
        <div className="heading"><span className="Basic">STANDARD</span><span className="user"><span className="rate">$12</span>/user</span></div>
         <button type="submit" className="button" onClick={" "}>
            Choose  Plan
         </button>
         <div className="Feature">Features</div>
         <div className="feature">
         {/* <span><img className="image" src={tick} alt="" />
</span>&nbsp;&nbsp;&nbsp;
        <span className="text">Pariatur quod similque</span> */}
                 <span class="text ">
    <CheckIcon className="inline self-center flex-shrink-0 h-5 w-5 text-green-500" /> &nbsp;Pariatur quod similque
           </span> 
         </div>
         <div className="feature">
         <span class="text ">
    <CheckIcon className="inline self-center flex-shrink-0 h-5 w-5 text-green-500" />
     &nbsp;Sapiente libero dolorios modi nostrn
           </span> 
         </div>
         <div className="feature">
         <span class="text ">
    <CheckIcon className="inline self-center flex-shrink-0 h-5 w-5 text-green-500" />
     &nbsp;Itaque cupiditate adipisci quibusdam
           </span> 
         </div>
         <div className="feature">
         <span class="text ">
    <CheckIcon className="inline self-center flex-shrink-0 h-5 w-5 text-green-500" />
     &nbsp; Pariatur quod similque heogfse anmaon
           </span> 
         </div>
         <div className="feature">
         <span class="text ">
    <CheckIcon className="inline self-center flex-shrink-0 h-5 w-5 text-green-500" />
     &nbsp;Sapiente libero dolorios modi nostrn
           </span> 
         </div>
         <span className="link">
          <Standard/>
         </span>
        </div>

        
{/* 
Premium */}
        <div className="box">
        <div className="heading"><span className="Basic">PREMIUM</span><span className="user"><span className="rate">$12</span>/user</span></div>
         <button type="submit" className="button" onClick={" "}>
            Choose  Plan
         </button>
         <div className="Feature">Features</div>
         <div className="feature">
         {/* <span><img className="image" src={tick} alt="" />
</span>&nbsp;&nbsp;&nbsp;
        <span className="text">Pariatur quod similque</span> */}
                 <span class="text ">
    <CheckIcon className="inline self-center flex-shrink-0 h-5 w-5 text-green-500" /> &nbsp;Pariatur quod similque
           </span> 
         </div>
         <div className="feature">
         <span class="text ">
    <CheckIcon className="inline self-center flex-shrink-0 h-5 w-5 text-green-500" />
     &nbsp;Sapiente libero dolorios modi nostrn
           </span> 
         </div>
         <div className="feature">
         <span class="text ">
    <CheckIcon className="inline self-center flex-shrink-0 h-5 w-5 text-green-500" />
     &nbsp;Itaque cupiditate adipisci quibusdam
           </span> 
         </div>
         <div className="feature">
         <span class="text ">
    <CheckIcon className="inline self-center flex-shrink-0 h-5 w-5 text-green-500" />
     &nbsp; Pariatur quod similque heogfse anmaon
           </span> 
         </div>
         <div className="feature">
         <span class="text ">
    <CheckIcon className="inline self-center flex-shrink-0 h-5 w-5 text-green-500" />
     &nbsp;Sapiente libero dolorios modi nostrn
           </span> 
         </div>
         <span className="link">
            <Premium/>
         </span>
        </div>

        {/* custom plans */}
        <div className="container2">
        {
            plans.map((plans)=>(

         <div className='box1'>

        <div className="heading"><span className="Basic">{plans.plan}</span><span className="user">
          <span className="rate">${plans.price}</span>/user</span></div>
         <button type="submit" className="button" onClick={" "}>
            Choose  Plan
         </button>
         <div className="Feature">Features</div>
         {
                 plans.feature.map((feature) => (
                          <div className="feature">
                            <span className="text">
          <CheckIcon className="inline self-center flex-shrink-0 h-5 w-5 text-green-500" />&nbsp;{feature}
          </span>
           </div>                                 ))
                                        }
         
         
         <span className="link">
         <Custom/>
         </span>        
        </div>
        ))
      }

      </div>
    </div>
   
</div>
</div>

);
};
 {/* <span><img className="image" src={tick} alt="" />
</span>&nbsp;&nbsp;&nbsp;
        <span className="text">Pariatur quod similque</span> */}