import React, { useContext, useEffect, useState } from "react";
import PaymentHistorySection from "../components/BillsAndPayments/PaymentHistorySection";
import { getPlans, getUserApps } from "../services/loggedInApp";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { Context } from "../services/context";

const OrgPayments = () => {
    let location = useLocation();
    let locState = location.state;
    const history = useHistory();
    const context = useContext(Context);
    const [priceData, setPriceData] = useState([]);
    const get_plans = () => {
        getPlans(locState.appId).then((res) => {
            if (res.error) {
                console.log(res.error);
            } else {
                setPriceData(res.data.data);
            }
        });
    };

    useEffect(() => {
        get_plans();
        getUserApps().then((res) => {
            if (res == "" || res == undefined) {
                console.log("Error Fetching Apps");
            } else {
                context.setApps(res.data.data);
            }
        });
    }, []);

    return (
        <div className="my-6  max-w-4xl mx-auto">
            <div className="flex justify-between items-center mb-12">
                <button
                    className="bg-gray-800 text-white rounded-md p-1 px-4"
                    onClick={() => {
                        history.goBack();
                    }}>
                    Back
                </button>
                <div className="py-6 -ml-16 text-3xl font-bold text-center self-center">
                    Payment History
                </div>
                <div></div>
            </div>
            <PaymentHistorySection plans={priceData} org_uuid={locState.orgUuid} />
        </div>
    );
};

export default OrgPayments;
