import React from "react";
import './Featurespop.css';

function Popup(props){
    return((props.trigger)?(
        <div className="popup">
            <div className="popup-inner">
                <span className="close-btn" onClick={()=>props.setTrigger(false)}>X</span>
                {props.children}
            </div>
            
        </div>
    )
    :"");
};
export default Popup;