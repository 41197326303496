import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import loginHeroImg from "../../assets/loginHero.svg";
import micorsoftLogo from "../../assets/microsoftlogo.png";
import { getOrganization, loginFun } from "../../services/auth";
import { injectStyle } from "react-toastify/dist/inject-style";
import { ToastContainer, toast } from "react-toastify";
import { FillAllFields } from "../NotificationToast";
import Navbar from "../Navbar/Navbar";
import { CircularProgress } from "@mui/material";

if (typeof window !== "undefined") {
  injectStyle();
}

export default function LoginPageScreen() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [microLoginLoading, setMicroLoginLoading] = useState(false);
  const history = useHistory();
  const [selectedOrg, setSelectedOrg] = useState("");
  const [step, setStep] = useState(1);
  const [orgOptions, setOrgOptions] = useState([]);

  const handleContinue = async (e) => {
    e.preventDefault();
    if (email == "") {
      toast.dark("Please Enter Email Address");
    } else {
      const resp = await getOrganization(email);
      if (resp?.status === 200) {
        const orgList = resp?.data?.list;
        const options = orgList.map((org) => ({
          value: org,
          label: org,
        }));

        setOrgOptions(options);
        if (orgList.length === 1) {
          setSelectedOrg(orgList[0]);
          setStep(3);
        } else {
          setStep(2);
        }
      } else {
        toast.error(resp?.data?.error);
      }
    }
  };

  const handleOrgChange = (e) => {
    setSelectedOrg(e.target.value);
  };

  const handleOrgContinue = (e) => {
    e.preventDefault();
    if (selectedOrg === "") {
      toast.dark("Please select an organization");
    } else {
      setStep(3);
    }
  };

  const submit = async (e) => {
    e.preventDefault();
    console.log(email, password, selectedOrg);
    if (password == "") {
      toast.dark("Please Enter Password");
    } else {
      loginFun(email, password, selectedOrg);
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
    // setPassword({...password, showPassword: !password.showPassword });
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    let qloc = window.location.search;
    let name = new URLSearchParams(qloc).get("code");
    if (name) {
      setMicroLoginLoading(true);
      fetch(
        `https://api.trypadlock.com/padlockMicro/redirect?code=${name}&domain_name=trypadlock`,
        {
          method: "GET",
        }
      )
        .then((resposne) => resposne.json())
        .then((data) => {
          // console.log('2nddata', data);
          if (data.error) {
            toast.error("User Not Found", {
              position: "top-center",
            });
            setMicroLoginLoading(false);
          } else if (data.token) {
            localStorage.setItem("token", data["token"]);
            localStorage.setItem("refresh_token", data["refresh_token"]);
            localStorage.setItem("user_email", data["emails"]);
            sessionStorage.setItem("loggin", "loggin");
            setTimeout(() => {
              const redirectHref = "/apps";
              window.location.href = redirectHref;
            }, 1000);
            // const redirectHref = `https://app.joinworkahub.com/redirect?token=${token}`;
            // window.location.href = redirectHref;
          } else {
            setMicroLoginLoading(false);
            console.log("tokenERR");
            console.log("tokeneroordataaccess", data.access_token);
          }
        })
        .catch((err) => {
          setTimeout(() => {
            toast.error("Something went wrong!", {
              position: "top-center",
            });
            setMicroLoginLoading(false);
          }, 500);
          console.log(err);
        });
    }
  }, []);
  return (
    <>
      <Navbar />
      <div className="min-h-full flex pl-12 pr-2 mww:pr-0 mww:pl-0">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          {microLoginLoading ? (
            <div className="w-full mx-auto max-w-sm lg:w-96 h-[50vh] flex flex-col items-center justify-center">
              <CircularProgress color="inherit" />
              <div className="text-gray-500 pt-4 pb-2 text-lg font-medium pl-3">
                Logging you in...
              </div>
            </div>
          ) : (
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                  Log In
                </h2>
                <p className="mt-2 text-sm text-gray-600">
                  Or{" "}
                  <a href="#" className="font-medium text-black">
                    start your free trial
                  </a>
                </p>
              </div>
              <div className="mt-8">
                <div className="mt-6">
                  <form action="#" method="POST" className="space-y-6">
                    {step === 1 && (
                      <div>
                        <div>
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700">
                            Email address
                          </label>
                          <div className="mt-1">
                            <input
                              id="email"
                              name="email"
                              type="text"
                              autoComplete="email"
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                              required
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                          </div>
                        </div>
                        <div className="mt-8">
                          <button
                            onClick={(e) => handleContinue(e)}
                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black">
                            Continue
                          </button>
                        </div>
                      </div>
                    )}

                    {step === 2 && (
                      <div>
                        <div>
                          <label
                            htmlFor="organization"
                            className="block text-sm font-medium text-gray-700">
                            Organization
                          </label>
                          <select
                            id="organization"
                            name="organization"
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            defaultValue=""
                            onChange={handleOrgChange}>
                            <option disabled className="text-gray-300" value="">
                              -- Select org Name --
                            </option>
                            {orgOptions.map((org) => (
                              <option key={org.value} value={org.value}>
                                {org.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="mt-8">
                          <button
                            onClick={(e) => handleOrgContinue(e)}
                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black">
                            Continue
                          </button>
                        </div>
                      </div>
                    )}

                    {step === 3 && (
                      <div>
                        <div className="space-y-1">
                          <label
                            htmlFor="password"
                            className="block text-sm font-medium text-gray-700">
                            Password
                          </label>
                          <div className="flex mt-1 items-center">
                            <input
                              id="password"
                              name="password"
                              type={showPassword ? "text" : "password"}
                              autoComplete="current-password"
                              onChange={(e) => {
                                setPassword(e.target.value);
                              }}
                              required
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                            <span
                              className="material-icons"
                              onClick={handleShowPassword}>
                              <i
                                className={
                                  showPassword
                                    ? "fa fa-eye-slash fa-lg position-absolute"
                                    : "fa fa-eye fa-lg position-absolute"
                                }
                                style={{
                                  marginLeft: "-30px",
                                  cursor: "pointer",
                                }}></i>
                            </span>
                          </div>
                        </div>

                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <input
                              id="remember-me"
                              name="remember-me"
                              type="checkbox"
                              className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                            />
                            <label
                              htmlFor="remember-me"
                              className="ml-2 block text-sm text-gray-900">
                              Remember me
                            </label>
                          </div>

                          <div className="text-sm">
                            <a href="#" className="font-medium text-black">
                              Forgot your password?
                            </a>
                          </div>
                        </div>

                        <div className="mt-8">
                          <button
                            onClick={(e) => submit(e)}
                            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black">
                            Sign in
                          </button>
                        </div>
                      </div>
                    )}

                    <button
                      type="button"
                      onClick={() => {
                        const redirectMicroLoginURL =
                          // "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=dac3b4ce-fa35-45e1-bfb6-944e48b89ee4&scope=user.read%20openid%20profile%20offline_access&redirect_uri=http%3A%2F%2Flocalhost:3001%2Floginpage&client-request-id=55cd6ce9-6a0b-4e86-b3f2-1908a6f7548b&response_mode=query&response_type=code&x-client-SKU=msal.js.node&x-client-VER=1.3.3&x-client-OS=linux&x-client-CPU=x64&client_info=1";
                          "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=dac3b4ce-fa35-45e1-bfb6-944e48b89ee4&scope=user.read%20openid%20profile%20offline_access&redirect_uri=https%3A%2F%2Ftrypadlock.com%2Floginpage&client-request-id=55cd6ce9-6a0b-4e86-b3f2-1908a6f7548b&response_mode=query&response_type=code&x-client-SKU=msal.js.node&x-client-VER=1.3.3&x-client-OS=linux&x-client-CPU=x64&client_info=1";
                        window.location.href = redirectMicroLoginURL;
                      }}
                      // name='microbtn'
                      className="flex gap-2 w-full justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black">
                      <img
                        src={micorsoftLogo}
                        alt="microsoft logo"
                        className="mb-0.5"
                      />
                      <p>Log in with Microsoft</p>
                    </button>
                    <div className="mt-6 relative">
                      <div
                        className="absolute inset-0 flex items-center"
                        aria-hidden="true">
                        <div className="w-full border-t border-gray-300" />
                      </div>
                      <div className="relative flex justify-center text-sm">
                        <span className="px-2 bg-white text-gray-500"></span>
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <button
                        className="w-full flex justify-center py-2 px-4 border  rounded-md shadow-sm text-sm font-medium text-black bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                        onClick={() => history.push("/signup")}>
                        Create an account
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="hidden lg:block relative w-0 flex-1 mt-20">
          <img
            className="absolute inset-0 h-full w-full object-contain"
            src={loginHeroImg}
            alt=""
          />
        </div>
        <ToastContainer className="mt-24" />
      </div>
    </>
  );
}
