import React from "react";
import ProfileNav from "./ProfileNav";
import ProfileAppLists from "./ProfileAppLists";

export default function Profile() {
  return (
    <div>
      <ProfileNav />
      <ProfileAppLists />
    </div>
  )
}
