import React, { useState } from "react";
// import "../styles/Plans&Payments.css";
import "../../styles/Plans&Payments.css";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/solid";
import moment from "moment";
import { transport } from "../../services/api";
import { CircularProgress } from "@mui/material";
import { ContentCopyRounded } from "@mui/icons-material";
import { IconButton } from "@material-ui/core";
import { toast } from "react-toastify";

function PlanHistoryRow({
    amount,
    status,
    date,
    due_date,
    username,
    email,
    invoice,
    currency,
    users_count,
    card_details,
    features,
    org_name,
    plan,
    org_uuid,
}) {
    const [hide, setHide] = useState(false);
    const [linkGenerating, setLinkGenerating] = useState(false);
    const [paymentLink, setPaymentLink] = useState("");
    const toggleHide = (event) => {
        event.preventDefault();
        if (hide) {
            setHide(false);
        } else {
            setHide(true);
        }
    };
    const getPaymentLink = () => {
        setLinkGenerating(true);
        transport(`stripe/payment_link/${invoice}`, {
            method: "GET",
        })
            .then((res) => {
                if (res.status === 200) {
                    setPaymentLink(res.data.payment_link);
                }
            })
            .finally(() => {
                setLinkGenerating(false);
            });
    };
    const payNow = () => {
        window.open(paymentLink, "_blank");
    };
    return (
        <div className="my-2">
            <div
                className={
                    hide
                        ? "grid grid-cols-5 border-2 py-3 rounded-t-lg bg-blue-50"
                        : "grid grid-cols-5 border-2 py-3 rounded-lg"
                }>
                <h1 className="text-left pl-8 text-lg">{`${
                    currency === "inr" ? "₹" : "$"
                }${amount}`}</h1>
                <h1
                    className={`text-center text-lg ${
                        status ? "text-green-500" : "text-yellow-500"
                    }`}>
                    {status ? "Success" : "Pending"}
                </h1>
                <h1 className="text-center text-lg">{moment(date).format("MMM DD, YYYY")}</h1>
                <div className="flex col-span-2 items-center justify-between w-full pr-8">
                    <h1 className="text-center text-lg flex-1 max-w-xs overflow-ellipsis overflow-hidden">
                        {org_uuid ? email : org_name}
                    </h1>
                    <button className="transition delay-200 ease-in" onClick={toggleHide}>
                        {hide ? (
                            <ChevronUpIcon className="w-7 h-7 bg-blue-100 rounded-lg" />
                        ) : (
                            <ChevronDownIcon className="w-7 h-7 bg-blue-100 rounded-lg" />
                        )}
                    </button>
                </div>
            </div>
            <div
                className={
                    hide
                        ? "py-3 px-5 grid grid-cols-1 sm:grid-cols-3 gap-x-8 justify-around border-2 border-t-0 rounded-b-lg ease-in duration-200"
                        : "hidden"
                }>
                <div>
                    <h5 className="text-sm text-gray-500 ml-5">Plan</h5>
                    <div className="bg-blue-100 p-3 my-1 rounded-lg">
                        <h1 className="text-3xl my-2">
                            {features?.length > 0 ? "Custom Plan" : plan?.name}
                        </h1>
                        <h2 className="my-3">
                            <span className="text-4xl">{`${currency === "inr" ? "₹" : "$"}${
                                features?.length > 0 ? amount / (users_count ?? 1) : plan.price
                            }`}</span>
                            /month
                        </h2>
                        <ul>
                            {features?.length > 0
                                ? features.map((feature, idx) =>
                                      feature?.name?.trim() ? (
                                          <li
                                              className="flex text-sm"
                                              key={invoice + "_feature_" + idx}>
                                              <span className="mr-2">
                                                  <CheckIcon className="w-5 h-5" />
                                              </span>
                                              {feature.name.trim()}
                                          </li>
                                      ) : null
                                  )
                                : plan?.info?.map((feature, idx) =>
                                      feature?.trim() ? (
                                          <li
                                              className="flex text-sm"
                                              key={plan.id + "_feature_" + idx}>
                                              <span className="mr-2">
                                                  <CheckIcon className="w-5 h-5" />
                                              </span>
                                              {feature.trim()}
                                          </li>
                                      ) : null
                                  )}
                            <li className="flex text-sm">
                                <span className="mr-2">
                                    <CheckIcon className="w-5 h-5" />
                                </span>
                                FREE for 15 days
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="sm:border-r-2 sm:border-black">
                    <div className="my-10 text-center">
                        <div className="my-5">
                            <h5 className="text-sm text-gray-500">Status</h5>
                            <p className={status ? "text-green-500" : "text-yellow-500"}>
                                {status ? "Success" : "Pending"}
                            </p>
                        </div>
                        <div className="my-5">
                            <h5 className="text-sm text-gray-500">
                                {status ? "Date Paid" : "Due Date"}
                            </h5>
                            <p>{moment(due_date).format("MMM DD, YYYY")}</p>
                        </div>
                    </div>
                </div>
                <div className="mx-auto py-6 px-4">
                    <h5 className="my-3">
                        <span className="mr-4 text-gray-500 text-sm">Users: </span>
                        {users_count ?? 1}
                    </h5>
                    <h5 className="my-3">
                        <span className="mr-4 text-gray-500 text-sm">Amount Per User: </span>
                        {`${currency === "inr" ? "₹" : "$"}${
                            features?.length > 0 ? amount / (users_count ?? 1) : plan.price
                        }`}
                    </h5>
                    <div className="my-3 border-t-2 border-black" />
                    <h5 className="my-3 text-4xl">
                        <span className="mr-4 text-gray-500 text-sm">Total: </span>
                        {`${currency === "inr" ? "₹" : "$"}${amount}.00`}
                    </h5>
                    {!status && (
                        <div className="pt-6 flex justify-center">
                            <button
                                onClick={() => (paymentLink ? payNow() : getPaymentLink())}
                                className="rounded flex flex-1 items-center justify-center gap-2 px-5 py-2 bg-green-500 text-white">
                                {paymentLink && !linkGenerating
                                    ? "Pay Now"
                                    : linkGenerating
                                    ? "Generating"
                                    : "Get Link"}
                                {linkGenerating && <CircularProgress color="inherit" size="16px" />}
                            </button>
                            {paymentLink && !linkGenerating && (
                                <IconButton
                                    className=""
                                    onClick={() => {
                                        window.navigator.clipboard
                                            .writeText(paymentLink)
                                            .then(() => {
                                                toast.success("Copied", {
                                                    position: "bottom-center",
                                                    hideProgressBar: true,
                                                    autoClose: 150,
                                                });
                                            });
                                    }}>
                                    <ContentCopyRounded />
                                </IconButton>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default PlanHistoryRow;
