import axios from "axios";
import React, { useState, useEffect } from "react";
import "../styles/EditScreen.css";
import ProfileNav from "./Profile/ProfileNav";
import { useHistory, useLocation } from "react-router";
import { getRegionList } from "../services/loggedInApp";


function RegionTable() {
  const history = useHistory();
  let location = useLocation();
  let locState = location.state;

  const [regions, setRegion] = useState([]);
  const [regionID, setRegionId] = useState('');
  const [regionName, setRegionName] = useState('');

  const getRegions = () => {
    getRegionList().then((res) => {
      setRegion(res.data)
    })
  }

  useEffect(() => {
    getRegions();
  }, []);

  const onChangeValue = (e, region, id) => {
    e.preventDefault();
    setRegionId(id)
    setRegionName(region)
  };

  const handleNavigation = () => {
    localStorage.setItem(
        "loc",
        JSON.stringify({
            appName: locState.appName,
            appId: locState.appId,
            regionId: regionID,
            regionname: regionName,
        })
    );
    history.push({
      pathname: `/${locState.appName}/plans`,
      state: {appName: locState.appName, appId: locState.appId, regionId : regionID, regionname: regionName},
    })
  }

  return (
      <>
          <ProfileNav />
          <div className="p-10 flex justify-center items-center flex-col mx-auto max-w-6xl my-10">
              <div className="flex justify-between items-center mb-12 w-full">
                  <button
                      className="bg-gray-800 text-white rounded-md p-1 px-4 ml-2"
                      onClick={() => {
                          history.goBack();
                      }}>
                      Back
                  </button>
                  <p className="text-center text-4xl font-semibold">{locState.appName}</p>
                  <div></div>
              </div>

              <div className="flex justify-center items-center flex-col mb-12">
                  <div className="border">
                      <div className="px-10 mt-4 mb-4 flex flex-col">
                          <label className="my-2 mb-2 font-medium text-2xl">
                              Search your prefered Region
                          </label>
                          <input
                              type="text"
                              placeholder="Search Regions"
                              className="w-64 rounded-md"
                          />
                      </div>
                      <div className="text-justify grid grid-cols-1 md:grid-cols-3 gap-2 py-4 px-10">
                          {regions.map((region, idx) => (
                              <div
                                  key={idx}
                                  className="p-2 rounded-md border-2 m-1 flex justify-start items-center">
                                  <input
                                      type="radio"
                                      name="region"
                                      id={region.region}
                                      value={region.region}
                                      onChange={(e) => onChangeValue(e, region.region, region.id)}
                                  />
                                  <label
                                      htmlFor={region.region}
                                      className="text-sm font-medium py-3 pl-2">
                                      {region.region}
                                  </label>
                              </div>
                          ))}
                      </div>
                      <div className="bg-[#eaebec] w-full h-20 flex justify-end items-center">
                          <button
                              className="text-white bg-black p-2 px-5 rounded-md mr-4"
                              onClick={handleNavigation}>
                              Continue
                          </button>
                      </div>
                  </div>
              </div>
          </div>
      </>
  );
}

export default RegionTable;