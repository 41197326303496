import React, { useState } from "react";
import { useHistory } from "react-router";
import loginHeroImg from "../../assets/loginHero.svg";
import Navbar from "../Navbar/Navbar";
import './SignUp.css'
import { ToastContainer, toast } from "react-toastify";
import { EnterVaildEmail, no_space_allowed, UserAlreadyExists } from "../NotificationToast";
import { FillAllFields } from "../NotificationToast";
import { signUp } from "../../services/auth";


export default function SignUpPage() {
  const validEmailRegex = RegExp(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
  );

  const [username, setuserName] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [appName, setAppName] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const history = useHistory();

  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const submitForm = (e) => {
    e.preventDefault();
    let emailtest = validEmailRegex.test(email);

    if(firstname == "" || lastname == "" || username == "" || password == ""){
      FillAllFields();
    } else {
      if(emailtest == true){
        if(username && username.length < 15 && username.indexOf(" ") < 0){
          signUp(firstname, lastname, username, password, email, appName)
        } else {
          no_space_allowed();
        }
      } else {
        EnterVaildEmail();
      }
    }
  }

  return (
    <>
    <Navbar />
    <div className="min-h-full flex pl-12 pr-2 mww:pr-0 mww:pl-0">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
              Sign Up
            </h2>
            <p className="mt-2 text-sm text-gray-600">
              Or{" "}
              <a href="#" className="font-medium text-black">
              start your free trial
              </a>
            </p>
          </div>
          <div className="mt-8">
            <div className="mt-6">
              <form action= '#' className="space-y-6">
              <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                    >
                    User Name
                  </label>
                  <div className="mt-1">
                    <input
                      id="username"
                      name="username"
                      type="text"
                      autoComplete="username"
                      onChange={(e) => {
                        setuserName(e.target.value);
                      }}
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                    >
                    First Name
                  </label>
                  <div className="mt-1">
                    <input
                      id="firstname"
                      name="firstname"
                      type="text"
                      autoComplete="name"
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                    >
                    Last Name
                  </label>
                  <div className="mt-1">
                    <input
                      id="lastname"
                      name="lastname"
                      type="text"
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                    >
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                  </div>
                </div>

                <div className="space-y-1">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Password
                  </label>
                  <div className="flex mt-1 items-center">
                    <input
                      id="password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      autoComplete="current-password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                    <span className="material-icons" onClick={handleShowPassword}>
                    <i className={showPassword ? "fa fa-eye-slash fa-lg position-absolute" : "fa fa-eye fa-lg position-absolute" } style={{marginLeft: "-30px", cursor: 'pointer'}}></i>
                    </span>
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                    >
                    App Name
                  </label>
                  <div className="mt-1">
                    <input
                      id="appname"
                      name="appname"
                      type="text"
                      autoComplete="appname"
                      onChange={(e) => {
                        setAppName(e.target.value);
                      }}
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                    />
                    <label
                      htmlFor="remember-me"
                      className="ml-2 block text-sm text-gray-900"
                      >
                      Remember me
                    </label>
                  </div>

                  <div className="text-sm">
                    <a href="#" className="font-medium text-black">
                      Forgot your password?
                    </a>
                  </div>
                </div>

                <div>
                  <button
                  // type="submit"
                    onClick={(e)=> {submitForm(e)}}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                    >
                    Sign Up
                  </button>
                </div>

                <div className="mt-6 relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                    >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500"></span>
                  </div>
                </div>

                <div className="flex flex-col">
                  <p className='mb-2'>Already have an account ?</p>
                  <button
                    className="w-full flex justify-center py-2 px-4 border  rounded-md shadow-sm text-sm font-medium text-black bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                    onClick={()=>history.push('/loginpage')}
                    >
                    Sign In
                    </button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block relative w-0 flex-1 mt-20">
        <img
          className="absolute inset-0 h-full w-full object-contain"
          src={loginHeroImg}
          alt="background image"
          />
      </div>     
        <ToastContainer className='mt-28'/>
    </div>
  </>
  );
}
