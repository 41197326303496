import React, { useEffect, useState } from "react";
// import PlanHistoryRow from "./PlanHistoryRow";
import PlanHistoryRow from "../Plans/PlanHistoryRow";
import { transport } from "../../services/api";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import CreateInvoiceModal from "./CreateInvoiceModal";

function PaymentHistorySection({ plans, org_uuid }) {
    let location = useLocation();
    let locState = location.state;
    const [paymentsHistory, setPaymentsHistory] = useState([]);
    const [showCreateInvoiceModal, setShowCreateInvoiceModal] = useState(false);
    useEffect(() => {
        if (paymentsHistory.length === 0) {
            transport(
                `org/org_payments?appid=${locState.appId}&offset=0${
                    org_uuid ? "&org_uuid=" + org_uuid : ""
                }`,
                {
                    method: "GET",
                }
            ).then((res) => {
                if (res.data.orgs) {
                    setPaymentsHistory(res.data.orgs);
                }
            });
        }
    }, []);
    return (
        <div>
            <div className="flex items-center justify-end px-4">
                <button
                    className="px-4 py-2 bg-black text-white rounded-md"
                    onClick={() => setShowCreateInvoiceModal(true)}>
                    Create Invoice
                </button>
            </div>
            <div className="overflow-x-auto">
                <div className="grid grid-cols-5 border-2 mt-2 mb-3 py-3 text-left bg-black rounded-t-lg">
                    <h1 className="text-left pl-8 text-lg text-white font-semibold">Amount</h1>
                    <h1 className="text-center text-lg text-white font-semibold">Status</h1>
                    <h1 className="text-center text-lg text-white font-semibold">Date</h1>
                    <h1 className="text-center col-span-2 text-lg text-white font-semibold">
                        Payment {org_uuid ? "Email" : "Organization"}
                    </h1>
                </div>
                {paymentsHistory.map((payment, idx) => {
                    const planid = payment.plan ?? 1;
                    const plan = plans.find((val) => val.id === planid);
                    return (
                        <PlanHistoryRow
                            key={"payment_" + idx}
                            amount={payment.price}
                            date={payment.date}
                            due_date={payment.due_date}
                            status={payment.status}
                            username={payment.username}
                            email={payment.email}
                            invoice={payment.invoce}
                            currency={payment.currency}
                            users_count={payment.users}
                            card_details={payment.username}
                            org_name={payment.org_name}
                            org_uuid={org_uuid}
                            features={payment.features}
                            plan={plan}
                        />
                    );
                })}
            </div>
            {/* </div>
          </div>
        </div>
      </div> */}
            <CreateInvoiceModal
                open={showCreateInvoiceModal}
                onClose={() => setShowCreateInvoiceModal(false)}
            />
        </div>
    );
}

export default PaymentHistorySection;
