import React from "react";
import earnedCreditImg from '../../assets/earnedCredits.svg';

import downloadIcon from '../../assets/downloadIcon.svg';
import printerIcon from "../../assets/printIcon.svg";

import ProfileNav from '../Profile/ProfileNav';

export default function UserEarnedInfo() {
  return (
    <>
    <ProfileNav />
    <div className="p-10 grid justify-items-center grid-flow-row smt:p-2 smt:pt-4">
      <div className="shadow-lg rounded-lg w-4/5 sww:w-full">

        <div className="text-white h-12 rounded-t-md align-middle justify-center p-3 bg-black">
          <h3>Earned</h3>
        </div>
        <div className="grid grid-cols-2 justify-between p-8">
        <div className="grid grid-cols-2 gap-12 sww:grid-cols-1 sww:w-full">
          <div>
            <h3 className="text-lg leading-6 font-medium  text-gray-500">
              Created Date
            </h3>
            <dl className="mt-2 text-base text-gray-900">
              <div className="mt-1">
                {/* <dt className="sr-only">Phone number</dt> */}
                <dd>16/01/2021</dd>
              </div>
            </dl>
          </div>

          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-500">
              Account
            </h3>
            <dl className="mt-2 text-base text-gray-900">
   
              <div className="mt-1">
                {/* <dt className="sr-only">Phone number</dt> */}
                <dd>Buisness</dd>
              </div>
            </dl>
          </div>

          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-500">
              User Name
            </h3>
            <dl className="mt-2 text-base text-gray-900">
            
              
              <div className="mt-1">
                {/* <dt className="sr-only">Phone number</dt> */}
                <dd>Will Smith</dd>
              </div>
            </dl>
          </div>

          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-500">
              Region
            </h3>
            <dl className="mt-2 text-base text-gray-900">
             
              <div className="mt-1">
                {/* <dt className="sr-only">Phone number</dt> */}
                <dd>US West (Oregon) us-west-2</dd>
              </div>
            </dl>
          </div>

          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-500">
              Address
            </h3>
            <dl className="mt-2 text-base text-gray-900">
              
              <div className="mt-1">
                {/* <dt className="sr-only">Phone number</dt> */}
                <dd>4-34/1, Bangalore,Pin code: 560064, Kanlinagar, India</dd>
              </div>
            </dl>
          </div>

          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-500">
            Plan Details
            </h3>
            <dl className="mt-2 text-base text-gray-900">
              
              <div className="mt-1">
                {/* <dt className="sr-only">Phone number</dt> */}
                <dd>Starter ($15/month)</dd>
              </div>
            </dl>
          </div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-500">
            Plan Expiry
            </h3>
            <dl className="mt-2 text-base text-gray-900">
             
              <div className="mt-1">
                {/* <dt className="sr-only">Phone number</dt> */}
                <dd>16/01/2022</dd>
              </div>
            </dl>
          </div>
          </div>

          <div className="flex justify-end ">
              <img src={earnedCreditImg} alt="#" className="w-1/2 h-1/2 mww:hidden"/>
          </div>
        </div>
      </div>
      <br/>
      <div className="flex justify-end w-4/5">
          <button className="group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 mr-2">
              <img src={downloadIcon} alt="#" className="mr-2"/>
              Download
          </button>
          <button className="group relative flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 ">
            <img src={printerIcon} alt="#" className="mr-2 mt-1"/>
              Print
          </button>
      </div>
    </div>
  </>
  );
}
