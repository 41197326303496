import { toast } from "react-toastify";

export const FillAllFields = () => {
    toast.dark('Please Fill The Fields!')
}

export const DeletedApp = () => {
    toast.dark("App Deleted Successfully!")
}

export const AppAlreadyExists = () => {
    toast.dark('App Already Exists!')
}

export const AppCreated = () => {
    toast.dark("App Created Successfully!")
}

export const ValidURL = () => {
    toast.dark("Enter Valid URL!")
}

export const MagicLCreated = () => {
    toast.dark('Magic Link Created Successfully!')
}

export const EnterVaildEmail = () => {
    toast.dark('Enter Valid Email!')
}

export const UpdatedSuccessfully = () => {
    toast.dark("Updated Successfully")
}

export const UserAlreadyExists = () => {
    toast.dark("User Already Exists");
}

export const ServerError = () => {
    toast.dark("Something Went Wrong, Try Again!")
}

export const InvalidCredentials = () => {
    toast.dark("Invalid Credentials!")
}

export const ErrorRedirecting = () => {
    toast.dark("Error Redirecting!");
}

export const OrgCreatedSuccessfully = () => {
    toast.dark("Organization Created Successfully!")
}

export const OrgAlreadyExists = () => {
    toast.dark("Organization Already Exsits!")
}

export const OrgDeletedSuccessfully = () => {
    toast.dark('Organization Deleted Successfully!')
}

export const OrgUpdatedSuccessfully = () => {
    toast.dark('Organization Updated Successfully!')
}

export const PlanUpdatedSuccessfully = () => {
    toast.dark("Plan Updated Successfully!")
}

export const PlanDeletedSuccessfully = () => {
    toast.dark("Plan Deleted Successfully!")
}

export const UpdatedOrgPic = () => {
    toast.dark("Organization Picture Updated!")
}

export const ErrorUploadingPicture = () => {
    toast.dark('Error Uploading Picture!')
}

export const UserCreatedSuccessfully = () => {
    toast.dark('User Created Successfully!')
}

export const ErrorCreatingUser = () => {
    toast.dark('Something Went Wrong Creating User')
}

export const ChoosePicture = () => {
    toast.dark('Choose a Picture!')
}

export const PlanSavedSuccess = () =>{
    toast.success('Added plan Successfully')
}

export const TeamCreatedSuccessfully = () => {
    toast.dark('Team Created Successfully!')
}

export const TeamAlreadyExists = () => {
    toast.dark("Team Already Exists!")
}

export const TeamDeletedSuccessfully = () => {
    toast.dark('Team Deleted Successfully!')
}

export const ErrorDeletingTeam = () => {
    toast.dark('Error Deleting Team, Try Again!')
}


export const DeletingTeamMember = () =>{
    toast.success('deleted team member');
}

export const AppAddedinCV = () => {
    toast.dark('App added in Custom variable Successfully!');
}

export const ErrorinCV = () => {
    toast.dark('Error Adding to Custom Variable!')
}

export const User_added_to_Cv = () => {
    toast.dark('User Added to Custom Variable!')
}

export const User_Notadded_to_Cv = () => {
    toast.dark('User was not  Added to Custom Variable!')
}



export const no_space_allowed = () => {
    toast.dark('No Spaces Allowed!');
}

export const Created_app_CV = () =>{
    toast.dark("Created Custom Variable Successfully!");
}

export const Delete_app_CV = () => {
    toast.dark('Delete Custom Variable Successfully!');
}

export const Edit_app_CV = () => {
    toast.dark("Updated Custom Variable Successfully!");
}

export const Org_added_to_Cv = () =>{
    toast.dark('Org Added to Custom Variable!')
}

export const Edit_Org_cv = () =>{
    toast.dark("Updated Custom Variable Successfully!")
}

export const Delete_Org_cv = () =>{
    toast.dark('Delete Custom Variable Successfully!')
}

export const Created_app_Feature = () => {
    toast.dark("Created Feature Successfully!");
};

export const TrialExendSuccessfully = () => {
    toast.dark('Trial Extended Successfully!');
}