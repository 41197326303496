import React from "react";
import SettingNotificationCard from "../Settings/SettingNotificationCard";

function NotificationSettingScreen() {
  const notifications = [
    {
      title: "Message Notifications",
      desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cum placeat labore, reprehenderit officiis saepe voluptatem adipisci.",
    },
    {
      title: "Private Messages",
      desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cum placeat labore, reprehenderit officiis saepe voluptatem adipisci.",
    },
    {
      title: "Delete Old Messages",
      desc: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cum placeat labore, reprehenderit officiis saepe voluptatem adipisci.",
    },
  
  ];
  return (
    <div className="mx-10">
      {notifications.map((e, idx) => (
        <SettingNotificationCard title={e.title} desc={e.desc} />
      ))}
    </div>
  );
}

export default NotificationSettingScreen;
