import React from "react";
// import "../styles/SettingScreen.css";
import "../../styles/SettingScreen.css";

function SettingSlider({ active, setActive }) {
  return (
    <div className="relative my-6 justify-around max-w-2xl mx-auto sm:px-6 lg:px-8">
      <ul className="flex justify-evenly border-2 rounded-full bg-black text-white p-1">
        {active === 0 ? (
          <li
            className="bg-white text-black font-bold hover:bg-white hover:text-gray-900 px-3 py-2 sm:px-5  cursor-pointer rounded-full text-sm sm:text-lg"
            onClick={() => setActive(0)}
          >
            Profile
          </li>
        ) : (
          <li
            className="font-bold hover:bg-white hover:text-gray-900 px-3 py-2 sm:px-5  cursor-pointer rounded-full text-sm sm:text-lg"
            onClick={() => setActive(0)}
          >
            Profile
          </li>
        )}
        {active === 1 ? (
          <li
            className="bg-white text-black
              font-bold hover:bg-white hover:text-gray-900 px-3 py-2 sm:px-5  cursor-pointer rounded-full text-sm sm:text-lg"
            onClick={() => setActive(1)}
          >
            Billing Info
          </li>
        ) : (
          <li
            className="font-bold hover:bg-white hover:text-gray-900 px-3 py-2 sm:px-5  cursor-pointer rounded-full text-sm sm:text-lg"
            onClick={() => setActive(1)}
          >
            Billing Info
          </li>
        )}
        {active === 2 ? (
          <li
            className="bg-white text-black
              font-bold hover:bg-white hover:text-gray-900 px-3 py-2 sm:px-5  cursor-pointer rounded-full text-sm sm:text-lg"
            onClick={() => setActive(2)}
          >
            Notifications
          </li>
        ) : (
          <li
            className="font-bold hover:bg-white hover:text-gray-900 px-3 py-2 sm:px-5  cursor-pointer rounded-full text-sm sm:text-lg"
            onClick={() => setActive(2)}
          >
            Notifications
          </li>
        )}
        {active === 3 ? (
          <li
            className="bg-white text-black
              font-bold hover:bg-white hover:text-gray-900 px-3 py-2 sm:px-5  cursor-pointer rounded-full text-sm sm:text-lg"
            onClick={() => setActive(3)}
          >
            Deactivate Account
          </li>
        ) : (
          <li
            className="font-bold hover:bg-white hover:text-gray-900 px-3 py-2 sm:px-5  cursor-pointer rounded-full text-sm sm:text-lg"
            onClick={() => setActive(3)}
          >
            Deactivate Account
          </li>
        )}
      </ul>
    </div>
  );
}

export default SettingSlider;
