import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import CreateApp from '../services/loggedInApp';
import {FillAllFields, ValidURL} from './NotificationToast';

function CreateAppPopup({ trigger, setTrigger, apps, setApps }) {
  const [appName, setAppName] = useState("");
  const [appURL, setAppURL] = useState("");

  function continueBtn() {
    let date = new Date(Date.now());
    let day = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear();
    const regexURLTest = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');    
    const URLtest =  regexURLTest.test(appURL);

    setApps([...apps, { name: appName, date: `${day}/${month}/${year}` }]);
    
    if(appName == "" || appURL == "") {
      FillAllFields();
    } else {
        setTrigger(false);
          if(URLtest == true){
            CreateApp(appName, appURL)
            setAppName("");
            setAppURL("")
          } else {
            // alert('Invalid URL')
            ValidURL();
          }
      }
  }

  return trigger ? (
    <div className="inset-x-0 z-10 absolute border-2 border-gray-300 shadow-lg bg-white p-5 text-center rounded-lg  md:px-8 lg:px-16 popUp md:inset-x-1/3">
      <h1 className="my-9 text-4xl font-semibold text-gray-400">Create App</h1>
      <input
        type="text"
        required
        className="border-1 border-gray-100 shadow bg-blue-100 h-14 w-full px-6 rounded text-lg bg-opacity-75 text-gray-700 font-bold"
        placeholder="Enter Your App Name"
        value={appName}
        onChange={(e) => setAppName(e.target.value)}
      />

      <h1 className="my-9 text-4xl font-semibold text-gray-400">Enter App URL </h1>
      <input
        type="text"
        required
        className="border-1 border-gray-100 shadow bg-blue-100 h-14 w-full px-6 rounded text-lg bg-opacity-75 text-gray-700 font-bold"
        placeholder="Enter Your App URL"
        value={appURL}
        onChange={(e) => setAppURL(e.target.value)}
      />
      
      <div className="flex justify-between my-6">
        <button
          className="w-1/3 h-10 border-2 border-black rounded font-semibold hover:bg-black hover:text-white"
          onClick={() => setTrigger(false)}
        >
          Cancel
        </button>
        <button
          className="w-1/3 h-10 bg-gray-400 rounded text-white font-semibold hover:bg-black"
          onClick={continueBtn}
        >
          Continue
        </button>
      </div>
      <ToastContainer className='-mt-12'/>
    </div>
  ) : (
    ""
  );
}

export default CreateAppPopup;
