import React, {useState, useRef, useEffect} from 'react'
import copy from "copy-to-clipboard";  
import copyIcon from '../../assets/copyIcon.svg';
import { useHistory, useLocation } from "react-router";
import { getOrgs } from '../../services/loggedInApp'

import './mgl.css'
import { EnterVaildEmail, FillAllFields, MagicLCreated } from '../NotificationToast';
import { ToastContainer } from 'react-toastify';
import ProfileNav from '../Profile/ProfileNav';
import { Alert, Snackbar } from '@mui/material';

export default function MagicLinkPage() {
    let location = useLocation();
    let locState = location.state
    // console.log('locState magic ---', locState)

    const validEmailRegex = RegExp(
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
    );
    
    const[magicLink, setMagicLink] = useState('');
    const[userURL, setUserURl] = useState();
    const[email, setEmail] = useState();
    const[avatar, setAvatar] = useState([]);
    const[orgAvatar, setOrgAvatar] = useState([]);
    const[orgsList,setOrgsList] = useState([])
    const[open,setOpen] = useState(false);

    const getAllOrgs = () => {
        getOrgs(locState.appId).then((res)=>{
          console.log('res', res)
          if(res.data.data == undefined ||res.data.data == ""  ) {
            setOrgsList(undefined)
          } else {
            setOrgsList(res.data.data)
          }
        })  
    }

    useEffect(()=>{
        getAllOrgs();
        setEmail(locState.userEmail)
    },[])

    const selectedOrg = orgsList.find((orgs)=> orgs.id === locState.orgId)
    // console.log('selectedOrg==',selectedOrg)
    
    const handleSubmit = (e) => {
        e.preventDefault();
        
        let emailtest = validEmailRegex.test(email);
        if(email == ""){
            FillAllFields();
        } else {
            if(emailtest == true){
                fetch("https://api.trypadlock.com/generator", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        org_name: selectedOrg.name,
                        email: email,
                        application: locState.appName,
                    }),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        let token = data.access_token;
                        setMagicLink(token);
                        MagicLCreated();
                    })
                    .catch(function (err) {
                        console.log(err);
                    });
            } else {
                EnterVaildEmail();
            }
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        setOpen(false);
    };

    const copyText = () => {
      navigator.clipboard.writeText(magicLink)
      setOpen(true)
    }

    return (
      <>
      <ProfileNav />
        <div className='flex justify-center'>
            <div className='flex flex-col justify-center w-1/2 smt:w-full smt:p-2'>
                <div className='mb-10 text-center'>
                    <h3 className='text-4xl font-bold mt-10'>Link Generator</h3>
                </div>

                <div className="inset-0 flex mb-8 items-center" aria-hidden="true">
                <span className='text-gray-400'>•</span><div className="w-full border-t border-gray-300" /><span className='text-gray-400'>•</span>
                </div>

                <div className='w-full'>
                    <div className='w-full'>
                        <div className='flex justify-center'>
                        <button
                            type="submit"
                            onClick={handleSubmit}
                            className="inline-flex justify-center mb-8 py-3 w-full border border-transparent shadow-sm text-base font-medium rounded-xl text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                            >Generate Link
                        </button>
                        </div>
                    </div>
                </div>


                {/* MAGIC LINK */}
                <div>
                    <label className='text-2xl  sww:text-base font-normal'>Magic Link</label>
                    <div className='flex mb-10 content-center'>
                        <input type='text' name='magic-link' value={magicLink} id='magic-link' placeholder='Copy Link' className='py-4 px-4 mr-2 mt-4 block w-full shadow-sm focus:ring-black focus:border-black border-gray-300 rounded-xl'/>
                        <button onClick={copyText} className='mt-2 ml-2'><img src={copyIcon} alt="copy" width={25} height={25}/></button>
                        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                                        Link is copied
                                </Alert>
                        </Snackbar>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
      </>
    )
}