import { FaTrash } from "react-icons/fa";
import { ChevronRightIcon } from "@heroicons/react/outline";
import React, { useState, useEffect } from "react";
import "../../styles/Profile.css";
import { Link } from "react-router-dom";
import '../../styles/Loader.css';
import '../../styles/modal.css'
import Modal from 'react-responsive-modal'
import { ToastContainer, toast } from "react-toastify";
import { DeletedApp } from "../NotificationToast";
import { getUserApps } from "../../services/loggedInApp";

function CreateAppTable({ appss, setAppss }) {

  const token = localStorage.getItem('token')

  const [apps, setapps] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [appid, setAppID] = useState('');

  const userApps = () => {
    getUserApps().then((res) => {
      setapps(res.data.data);
    })
  }

  useEffect(() => {
    userApps();
      return () => {
        userApps();
      }
  },[appss]);

  function deleteApps(element) {
    const filtered = apps.filter((app) => app.id !== element);
    fetch(`https://api.trypadlock.com/loginapp?id=${element}&status=false`, {
        method: "DELETE",
        headers: {
            authorization: "Jwt " + token,
        },
    })
        .then((response) => response.json())
        .then((data) => {
            console.log("dataDELEte", data);
            setapps(filtered);
            onCloseModal();
            DeletedApp();
        })
        .catch((err) => console.log(err));
  }

  const onOpenModal = (element) =>{
    setAppID(element)
    setIsOpen(true);
  }

  const onCloseModal = () =>{
    setIsOpen(false)
  }

  const NoUsers = () => {
    return <tr className="flex m-10"><td className="loader"></td><td className="text-lg ml-2">Loading...</td></tr>
  }

  const displayApps = apps == '' ? <h3 className="text-lg m-10">No Apps Found</h3> : apps.map((app) => {
    return (
      <tr key={app.name} className="border-2">
        <td className="px-6 py-2 whitespace-nowrap text-xl text-black-600  text-center">
          {app.name}
        </td>
        <td className="px-6 py-2 whitespace-nowrap text-md text-black-600 text-center">
          {new Date(app.created).toDateString()}
        </td>
        <td className="px-6 py-2 whitespace-nowrap text-xl text-black-600 text-center">
          <FaTrash
            className="w-6 h-6 text-red-600 align_icon cursor-pointer"
            onClick={() => onOpenModal(app.id) }
          />
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-xl font-medium text-center">
          <Link to="#">
            <ChevronRightIcon className="w-6 h-6" />
          </Link>
        </td>
      </tr>
    )
  })

  return (
    <div className="my-8 w-full border">
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto">
          <div className="py-2 align-middle inline-block min-w-full">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-black py-6">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-1 text-md font-bold text-white tracking-wider text-center"
                    >
                      App Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3  text-md font-bold text-white tracking-wider text-center"
                    >
                      Created Date
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-md font-bold text-white tracking-wider text-center"
                    >
                      Delete
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-md font-bold text-white tracking-wider text-center"
                    ></th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {apps == "" ? <NoUsers /> : displayApps}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Modal 
      open={isOpen}
      onClose={onCloseModal} 
      // className='flex absolute justify-center items-center h-full max-w-sm h-30 p-3'
      classNames={{
          overlay: 'customOverlay',
          modal: 'customModal',
        }}
    >
          <p>Do you want delete it?</p>
          <div className="flex justify-center items-center mt-4">
            <button 
            className="px-2 py-1 m-2 bg-gray-300 hover:bg-gray-400 text-black hover:text-white rounded-md border-b-2 border-gray-400"
               onClick={onCloseModal}>
               Cancel
            </button>
            <button 
              className="px-2 py-1 m-2 bg-red-400 hover:bg-red-500 text-black hover:text-white rounded-md border-b-2 border-red-700"
              onClick={(e)=>deleteApps(appid)}
            >
              Delete
            </button>
          </div>
      </Modal>
      <ToastContainer className='mt-24'/>
    </div>
  );
}

export default CreateAppTable;
