import React from "react";

function ReferralsWayCards({ title, desc, planDetails, plan }) {
  return (
    <div className="border-t-2 border-b-2 border-gray-400 flex justify-between py-4 pr-6">
      <div>
        <h1 className="text-3xl font-bold">{title}</h1>
        <p className="font-light my-2">{desc}</p>
      </div>
      <div className="flex gap-x-5">
        {planDetails === 1 ? <h2>{plan}</h2> : ""}
        <h1 className="text-4xl font-bold my-auto">$5</h1>
        <input
          type="checkbox"
          name="check"
          id="check"
          className="w-9 h-9 block my-auto"
        />
      </div>
    </div>
  );
}

export default ReferralsWayCards;
