import React, { useState } from "react";
import { Switch } from "@headlessui/react";

function SettingNotificationCard({ title, desc }) {
  const [enabled, setEnabled] = useState(false);
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <div className="flex justify-between border-2 py-6 px-10 pb-8 bg-white shadow-md my-2">
      <div>
        <h1 className="text-xl font-semibold">{title}</h1>
        <p>{desc}</p>
      </div>
      <div className="align-middle">
        <Switch
          checked={enabled}
          onChange={setEnabled}
          className={classNames(
            enabled ? "bg-black" : "bg-gray-200",
            "relative inline-flex flex-shrink-0 h-8 w-14 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={classNames(
              enabled ? "translate-x-6" : "translate-x-0",
              "pointer-events-none inline-block h-7 w-7 rounded-full bg-gray-400 shadow transform ring-0 transition ease-in-out duration-200"
            )}
          />
        </Switch>
      </div>
    </div>
  );
}

export default SettingNotificationCard;
