import React from 'react'
import ReactPaginate from "react-paginate";


export default function NPagination({ pageCount, onPageChange}) {
    return (
        <div>
            <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={pageCount}
                onPageChange={onPageChange}
                containerClassName={"paginationContainer"}
                previousLinkClassName={"previousBtn text-lg sww:text-sm"}
                nextLinkClassName={"nextLinkBtn text-lg sww:text-sm"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
                renderOnZeroPageCount={null}
            />
        </div>
    );
}
