import React from "react";
import "../styles/SettingScreen.css";

function DeactivateAccSettingScreen() {
  return (
    <div className="max-w-5xl mx-auto border-2 py-7 px-12 rounded-xl shadow-md">
      <div className="max-w-lg mx-auto">
        <h1 className="uppercase text-center mb-4 font-bold text-lg">
          Are you sure want to permanently delete ?
        </h1>
        <p className="text-center mb-2">
          This account will no longer be available and all data in the account
          will be permanently deleted.
        </p>
        <p className="text-center mb-2">
          To permanently deleted your account please enter your password and
          confirm
        </p>
        <div className="grid">
          <label htmlFor="enter-password">Enter Password</label>
          <input
            type="password"
            className="shadow rounded-lg my-4 p-3"
            placeholder="Password"
          />
          <div className="flex justify-between my-5">
            <button className="border-2 border-black w-1/3 h-11 rounded-md hover:bg-black hover:text-white font-semibold shadow-lg">
              Back
            </button>
            <button className="w-1/3 h-11 rounded-md bg-red-600 font-semibold text-white shadow-lg">
              Delete Account
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeactivateAccSettingScreen;
