import React,{useState} from "react";
import Popups from './CustomCreatePop';
// import './PlanPricingSelection.css';
import './CustomCreatePop.css';
import Select from 'react-select';
import  {CheckIcon} from '@heroicons/react/solid';
import Custom from './ExpandedFeatures/CustomFeaturesExpanded';
import Dropdown from "./DropDown";
import SelectingFeature from "./SelectingFeature";

function CreateCustom(){
    const [isOpen,setIsOpen]=useState(false);
    const [customFeature, setCustomFeature] = useState(false);

    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () =>{ 
      setOpen(false)};

    const features=[
        {value:'create as new',label:'create as new'},
    {value:'Basic',label:'Basic'},
    {value:'Standard',label:'Standard'},
    {value:'Premium',label:'Premium'},
    {value:'Custom Plan1',label:'Custom Plan1'},
];
    const colourStyles = {
        control: (styles) => ({ ...styles, backgroundColor: "white" ,border:"1px solid black",color:"black"}),
       
      };
    return(
        <div>
   
            <button onClick={()=> setIsOpen(true)}>Custom Plan</button>
            <Popups trigger={isOpen} setTrigger={setIsOpen}>
   <div className="customcontainer">
    <div className="title">Create new plan</div><br/>
    <div>
        <form>
            <div className="label">
            <label className="label">Name of the new Plan<br/></label></div>
            <div className="drop">
            <input type="text" name="name" placeholder="Eg:Growth Plan" className="textfield" req/>
            </div>
            <div className="label">
            <label>Copy features from<br/></label></div>
            <div className="textfield1">
                <select placeholder="select" className="dropdownmenu" >
                    <option value="create a new" >&#10004;create a new</option>
                    <option  value="Basic">&#10004;Basic</option>
                    <option  value="Standard">&#10004;Standard</option>
                    <option  value="Premium">&#10004;Premium</option>
                    <option value="Custom plan 1">&#10004;Custom Plan 1</option>
                </select>
                {/* <Dropdown/> */}
            </div>
            </form>
            <div className="but">
                <button type="button" className="newbutton" onClick={()=> onOpenModal()}>Next</button>
                </div>
            </div>
   </div>
                 <SelectingFeature open={open} onCloseModal={onCloseModal} />
   </Popups>
   </div>

    );
}
export default CreateCustom;