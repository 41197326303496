import React from "react";
import ProfileSettingScreen from "../Profile/ProfileSettingScreen";
import BillingInfoScreen from "../BillsAndPayments/BillingInfoScreen";
// import NotificationSettingScreen from "./NotificationSettingScreen";
import NotificationSettingScreen from "../Notifications/NotificationSettingScreen";
import DeactivateAccSettingScreen from "../DeactivateAccSettingScreen";
import {Elements, ElementsConsumer} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_live_8TSDRYUEWcKegk80c0R96LEb00IeVwJBMg')

function SettingBody({ active }) {
  return (
    <div className="max-w-6xl my-10 mx-auto sm:px-6 lg:px-8">
      {active === 0 ? <ProfileSettingScreen /> : ""}
      {active === 1 ? <Elements stripe={stripePromise}> <BillingInfoScreen /> </Elements> : ""}
      {active === 2 ? <NotificationSettingScreen /> : ""}
      {active === 3 ? <DeactivateAccSettingScreen /> : ""}
    </div>
  );
}

export default SettingBody;
