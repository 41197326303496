import React from "react";
// import "../styles/NotificationScreen.css";
import "../../styles/NotificationScreen.css"
import { XIcon } from "@heroicons/react/solid";

function NotificationCard({ price, title }) {
  return (
    <div className="card border-2 shadow-lg border-gray-100 rounded-lg p-5 flex justify-around items-center">
      <span className="text-2xl p-4 rounded-full bg-black text-white font-semibold">
        {price}
      </span>
      <div className="w-1/2">
        <h2 className="sm:text-base md:text-lg font-semibold">{title}</h2>
        <p className="sm:text-base">14-Jan-2021</p>
      </div>
      <span className="p-3 bg-blue-200 rounded-full">
        <XIcon className="text-black h-6 w-6" />
      </span>
    </div>
  );
}

export default NotificationCard;
