import { useState } from "react";
import {
  EnterVaildEmail,
  InvalidCredentials,
  UserAlreadyExists,
} from "../components/NotificationToast";

export const validEmailRegex = RegExp(
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

export const api_url = "https://api.trypadlock.com";

export const signUp = (
  firstname,
  lastname,
  username,
  password,
  email,
  appName
) => {
  fetch("https://api.trypadlock.com/loginapp/signup", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
      username: username,
      password: password,
      first_name: firstname,
      last_name: lastname,
      appname: appName,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log("reftokne", data.token);
      if (data.status == "user already exists") {
        UserAlreadyExists();
      } else {
        console.log(data);
        const token = data.token;
        console.log(token, "token ");
        localStorage.setItem("token", token);
        localStorage.setItem("user_email", email);
        sessionStorage.setItem("loggin", "loggin");
        const redirectHref = "/apps";
        window.location.href = redirectHref;
      }
    })
    .catch((err) => console.log("err", err));
};

export const loginFun = (email, password, orgName) => {
  let emailtest = validEmailRegex.test(email);
  console.log("testRmail", emailtest);

  if (emailtest) {
    fetch(`${api_url}/loginapp/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: password,
        org_name: orgName,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("logindata", data);
        if (data.status == "invalid credntails") {
          InvalidCredentials();
        } else if (data.status == "email doesnot exists") {
          EnterVaildEmail();
        } else {
          let token = data.token;
          let refreshToken = data.refresh_token;
          localStorage.setItem("token", token);
          localStorage.setItem("refresh_token", refreshToken);
          localStorage.setItem("user_email", email);
          sessionStorage.setItem("loggin", "loggin");
          // refreshTokenn();
          const redirectHref = "/apps";
          window.location.href = redirectHref;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  } else {
    console.log("error in email");
    EnterVaildEmail();
  }
};

export const refreshTokenn = () => {
  fetch("https://api.trypadlock.com/refresh/", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      refresh: localStorage.getItem("refresh_token"),
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.error) {
        console.log("R Token Expired");
      } else {
        if (data !== null || undefined) {
          localStorage.setItem("token", data.token);
        }
      }
    })
    .catch((err) => console.log("refreshError", err));
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refresh_token");
  sessionStorage.removeItem("loggin");
  window.location.href = "/";
};

export const getToken = () => {
  // if (localStorage.getItem("token")) {
  //   return true;
  // }
  return false;
};

export const getOrganization = async (email) => {
  try {
    const response = await fetch(
      "https://api.trypadlock.com/users/organization_require",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
        }),
      }
    );

    const updatedData = await response.json();
    return {
      status: response?.status,
      data: updatedData,
    };
  } catch (err) {
    console.log("err", err);
  }
};
