import React, { createContext, useState } from "react";
import { useEffect } from "react";
import { getUserApps } from "./loggedInApp";
import context from "react-bootstrap/esm/AccordionContext";

export const Context = createContext({
    orgs: [],
    apps: [],
    selectedOrg: null,
    setOrgs: () => {},
    setSelectedOrg: () => {},
    setApps: () => {},
});

export const ContextProvider = ({ children }) => {
    const [orgs, setOrgs] = useState([]);
    const [apps, setApps] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState(null);
    return (
        <Context.Provider
            value={{
                orgs,
                setOrgs,
                setApps,
                apps,
                selectedOrg,
                setSelectedOrg,
            }}>
            {children}
        </Context.Provider>
    );
};
