import React from "react";
import { useState } from "react";
// import "../styles/SettingScreen.css";
import "../../styles/SettingScreen.css"
import { useStripe, useElements, CardElement, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
// import SavedCards from "../PaymentsCardsDetails/SavedCards";


function ProfileBillingInfoScreen() {

  const stripe = useStripe();
  const elements = useElements();
  const [result, setResult] = useState([]);

  const[card, setCard] = useState();

  function stripeTokenHandler(token){
    console.log("StripeToken", token);

    const response = fetch("https://api.trypadlock.com/users/card_create", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-type": "application/json",
        },
        body: JSON.stringify({
            email: localStorage.getItem("user_email"),
            stripeToken: token,
        }),
    })
        .then((response) => {
            console.log("response", response);
            if (response.status == 200) {
                alert("succesfully created");
            } else {
                alert("not created");
            }
        })
        .catch((error) => {
            console.log("error list", error.message);
        });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if(!stripe || !elements) {
      return;
    }

    const card = elements.getElement(
      CardNumberElement,
      CardExpiryElement,
      CardCvcElement
    );

    const result = await stripe.createToken(card);

    if(result.error){
      console.log(result.error.message);
      setResult(result.error.message)
    } else {
      stripeTokenHandler(result.token)
    }
  }

  const handleSubmit1 = async (event) => {
    event.preventDefault();
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(
        CardNumberElement,
        CardExpiryElement,
        CardCvcElement
      ),
    });
    console.log(card);
    if (result.error) {
      // Show error to your customer.
      console.log(result.error.message);
      setResult(result.error.message);
    } else {
      // Send the token to your server.
      // This function does not exist yet; we will define it in the next step.
      stripeTokenHandler(result.token);
    }
  };


  // const years = [];
  // for (var i = 2000; i < 2070; i++) {
  //   years.push(i);
  // }
  // const months = [];
  // for (var j = 1; j <= 12; j++) {
  //   months.push(j);
  // }

  // const [cardData, setCardData] = useState([]);
  // const [name, setName] = useState("");
  // const [cardNo, setCardNo] = useState("");
  // const [cvv, setCVV] = useState("");
  // const [expMonth, setExpMonth] = useState("1");
  // const [expYear, setExpYear] = useState("2000");

  // const saveData = () => {
  //   setCardData([
  //     ...cardData,
  //     {
  //       nameOfCard: name,
  //       cardNumber: cardNo,
  //       CVVNo: cvv,
  //       dateOfExpiry: `${expMonth}/${expYear}`,
  //     },
  //   ]);
  //   console.log(cardData);
  // };


  return (
    <div className="mx-10">
      <div className="mt-20 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div className="sm:col-span-3">
          <label
            htmlFor="name-of-card"
            className="uppercase block text-sm font-medium text-gray-500"
          >
            Name of Card
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="name-of-card"
              id="name-of-card"
              autoComplete="given-name"
              value={name}
              onChange={(event) => setName(event.target.value)}
              className="uppercase shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md bg-blue-100 h-10 text-gray-600 font-semibold tracking-wider"
            />
          </div>
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="user-name"
            className="uppercase block text-sm font-medium text-gray-500"
          >
            Card Number
          </label>
          <div className="mt-1">
          <CardNumberElement className="block uppercase shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-full sm:text-sm border-gray-300 rounded-md bg-blue-100 h-10 text-gray-600 font-semibold tracking-wider"/>
          </div>
        </div>
        <div>
        <label>Expiry Date</label>
        <CardExpiryElement className="uppercase shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md bg-blue-100 h-10 text-gray-600 font-semibold tracking-wider"/>
      </div>
        <div className="sm:col-span-2">
          <label
            htmlFor="cvv-no"
            className="uppercase block text-sm font-medium text-gray-500"
          >
            cvv number
          </label>
          <div className="mt-1">
          <CardCvcElement className="uppercase shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md bg-blue-100 h-10 text-gray-600 font-semibold tracking-wider"/>
          </div>
        </div>
        {/* <div className="sm:col-span-2">
          <label
            htmlFor="months"
            className="uppercase block text-sm font-medium text-gray-500"
          >
            exp.month
          </label>
          <div className="mt-1">
            <select
              id="months"
              name="months"
              onChange={(event) => setExpMonth(event.target.value)}
              autoComplete="months"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md h-10 bg-blue-100 text-gray-600 font-semibold"
            >
              {months.map((month) => (
                <option>{month}</option>
              ))}
            </select>
          </div>
        </div> */}
        {/* <div className="sm:col-span-2">
          <label
            htmlFor="year"
            className="uppercase block text-sm font-medium text-gray-500"
          >
            exp.year
          </label>
          <div className="mt-1">
            <select
              id="year"
              name="year"
              onChange={(event) => setExpYear(event.target.value)}
              autoComplete="year"
              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md bg-blue-100 h-10 text-gray-600 font-semibold"
            >
              {years.map((year) => (
                <option>{year}</option>
              ))}
            </select>
          </div>
        </div> */}
      </div>
      <div className="m-auto sm:w-96 my-16">
        <button
          className="bg-black text-white font-bold text-xl w-full sm:w-96 h-14 rounded-md border-0 tracking-wider"
          onClick={(e)=> {handleSubmit(e)}}
        >
          Save Data
        </button>
      </div>

      {/* <SavedCards /> */}
    </div>
  );
}

export default ProfileBillingInfoScreen;
