import React from "react";
// import './Featurespop.css';
import './CustomCreatePop.css';
function Popups(props){
    return((props.trigger)?(
        <div className="popups">
            <div className="popup-inners">
                <span className="close-btns" onClick={()=>props.setTrigger(false)}>X</span>
                {props.children}
            </div>
            
        </div>
    )
    :"");
};
export default Popups;