import React from "react";

function PlansSlider({ active, setActive, history }) {
    return (
        <div className="relative justify-around max-w-lg mx-auto sm:px-6 lg:px-8">
            <ul className="flex justify-evenly border-2 rounded-full bg-black text-white p-1">
                <li
                    className={
                        active === 0
                            ? "bg-white text-black font-bold hover:bg-white hover:text-gray-900 px-3 py-2 sm:px-5  cursor-pointer rounded-full text-sm sm:text-lg"
                            : "font-bold hover:bg-white hover:text-gray-900 px-3 py-2 sm:px-5  cursor-pointer rounded-full text-sm sm:text-lg"
                    }
                    onClick={() => {
                        if (active !== 0) {
                            const state = history.location.state;
                            history.replace(`/${state.appName}/payment-history`, state);
                            setActive(0);
                        }
                    }}>
                    My subscription plan
                </li>
                <li
                    className={
                        active === 1
                            ? "bg-white text-black font-bold hover:bg-white hover:text-gray-900 px-3 py-2 sm:px-5  cursor-pointer rounded-full text-sm sm:text-lg"
                            : "font-bold hover:bg-white hover:text-gray-900 px-3 py-2 sm:px-5  cursor-pointer rounded-full text-sm sm:text-lg"
                    }
                    onClick={() => {
                        if (active !== 1) {
                            const state = history.location.state;
                            history.replace(`/${state.appName}/payment-history`, state);
                            setActive(1);
                        }
                    }}>
                    Payment History
                </li>
            </ul>
        </div>
    );
}

export default PlansSlider;
