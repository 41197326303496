import { XIcon } from "@heroicons/react/solid";
import { Button, Dialog, DialogTitle, Input, Select } from "@material-ui/core";
import {
    Checkbox,
    DialogContent,
    FormControlLabel,
    FormGroup,
    IconButton,
    MenuItem,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../services/context";
import { getOrgs, getRegionList } from "../../services/loggedInApp";
import { transport } from "../../services/api";
import { toast } from "react-toastify";

const CreateInvoiceModal = ({ open, onClose }) => {
    const context = useContext(Context);
    const [selectedOrg, setSelectedOrg] = useState(
        context.selectedOrg ?? context.orgs?.at(0) ?? {}
    );
    const [regions, setRegions] = useState([]);
    const [selectedApp, setSelectedApp] = useState(null);
    const [price, setPrice] = useState(0);
    const [currency, setCurrency] = useState("inr");
    const [features, setFeatures] = useState([]);
    const [selFeatures, setSelFeatures] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState({});
    const appName = window.location.pathname.split("/")[1];
    const orgName = window.location.pathname.split("/")[2];
    const initialize = async () => {
        if (appName && context.apps?.length > 0) {
            const app = context.apps.find((val) => val.name === appName);
            setSelectedApp(app);
            let orgs = context.orgs;
            if (!orgs?.length > 0) {
                const res = await getOrgs(app.id);
                if (res.data.data == undefined || res.data.data == "") {
                    context.setOrgs([]);
                } else {
                    let orglist = res.data?.data;
                    if (orglist && orglist.length > 0) {
                        orglist.sort((a, b) => new Date(a.created) - new Date(b.created)).reverse();
                    }
                    orgs = orglist;
                    context.setOrgs(orglist);
                }
            }
            if (orgs?.length > 0 && orgName !== "payment-history") {
                const org = orgs.find((val) => val.name === orgName);
                if (org) {
                    setSelectedOrg(org);
                    context.setSelectedOrg(org);
                } else {
                    setSelectedOrg(orgs[0]);
                }
            } else if (orgs?.length > 0) {
                setSelectedOrg(orgs[0]);
            }
        }
    };
    useEffect(() => {
        initialize();
        getRegionList().then((res) => {
            if (res.data) {
                setRegions(res.data);

                setSelectedRegion(res.data[0]);
            }
        });
        if (appName === "workahub") {
            transport("users/list_features").then((res) => {
                setFeatures(res?.data?.features);
            });
        }
    }, [context.apps]);
    const submitHandler = async () => {
        if (
            selectedOrg?.org_uuid &&
            selectedApp &&
            selectedRegion?.id &&
            price > 0 &&
            selFeatures.length > 0
        ) {
            const featuresNames = selFeatures.map((feature) => feature.name);
            const res = await transport("users/create_invoice", {
                method: "POST",
                data: {
                    org_uuid: selectedOrg.org_uuid,
                    currency: currency,
                    price: price,
                    regionid: selectedRegion.id,
                    features: featuresNames,
                    appname: selectedApp.name,
                },
            });
            if (res.status == 200) {
                await transport(`stripe/payment_link/${res.data.id}`, {
                    method: "GET",
                });
                window.location.reload();
            }
            onClose();
        } else {
            toast.warning("Please fill all the fields");
        }
    };
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle className="font-semibold">Create Invoice</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                }}>
                <XIcon color="black" fontSize="18px" className="text-black w-6 h-6" />
            </IconButton>

            <DialogContent dividers className="w-[480px] flex flex-col gap-5 max-w-[90vw]">
                <div className="flex gap-4 items-end justify-between">
                    <span className="font-semibold">Organization: </span>
                    <Select
                        label="Organization"
                        value={selectedOrg?.org_uuid ?? ""}
                        className="flex-1 pl-0.5"
                        disabled={context.orgs?.length === 1 || orgName !== "payment-history"}
                        onChange={(ev) => {
                            const org = context.orgs.find(
                                (val) => val.org_uuid === ev.target.value
                            );
                            setSelectedOrg(org);
                        }}>
                        {context.orgs?.map((org) => (
                            <MenuItem key={org.org_uuid} value={org.org_uuid}>
                                {org.name}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                <div className="flex gap-10 items-center w-full">
                    <div className="flex gap-4 items-end justify-between">
                        <span className="font-semibold">Curency: </span>
                        <Select
                            label="Currency"
                            value={currency}
                            className="w-16 pl-0.5"
                            disabled={context.orgs?.length === 1}
                            onChange={(ev) => {
                                setCurrency(ev.target.value);
                            }}>
                            <MenuItem key={"inr"} value={"inr"}>
                                INR
                            </MenuItem>
                            <MenuItem key={"usd"} value={"usd"}>
                                USD
                            </MenuItem>
                        </Select>
                    </div>
                    <div className="flex gap-4 flex-1 items-end justify-between">
                        <span className="font-semibold">Price per user: </span>
                        <Input
                            type="number"
                            className="flex-1 pl-0.5"
                            value={price}
                            onChange={(ev) => setPrice(ev.target.value)}
                        />
                    </div>
                </div>
                <div className="flex gap-4 items-end justify-between">
                    <span className="font-semibold">Region: </span>
                    <Select
                        label="Region"
                        value={selectedRegion?.id ?? ""}
                        className="flex-1 pl-0.5"
                        disabled={regions?.length <= 1}
                        onChange={(ev) => {
                            const regionsel = regions?.find((val) => val.id === ev.target.value);
                            setSelectedRegion(regionsel);
                        }}>
                        {regions?.map((region) => (
                            <MenuItem key={region.id + region.region} value={region.id}>
                                {region.region}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                {selectedApp?.id === 2 && (
                    <div className="">
                        <label htmlFor="features" className="font-semibold">
                            Features
                        </label>
                        <FormGroup id="features" className="max-h-[40vh] overflow-y-auto">
                            {features?.map((feature) => (
                                <FormControlLabel
                                    key={"feature_" + feature.id}
                                    control={
                                        <Checkbox
                                            onChange={(event) => {
                                                if (event.target.checked) {
                                                    setSelFeatures([...selFeatures, feature]);
                                                } else {
                                                    setSelFeatures(
                                                        selFeatures.filter(
                                                            (val) => val.id !== feature.id
                                                        )
                                                    );
                                                }
                                            }}
                                            color="default"
                                            checked={selFeatures.includes(feature)}
                                        />
                                    }
                                    label={feature.name}
                                />
                            ))}
                        </FormGroup>
                    </div>
                )}
                <button
                    className="rounded-md bg-green-500 px-4 py-2 text-white"
                    onClick={submitHandler}>
                    Create
                </button>
            </DialogContent>
        </Dialog>
    );
};

export default CreateInvoiceModal;
