import React from "react";
import { useState } from "react";
import { useHistory, useLocation } from "react-router";
import ProfileNav from "../Profile/ProfileNav";
import ReferralsBody from "./ReferralsBody";
import ReferralsSlider from "./ReferralsSlider";

function ReferralsAndCredits() {
  const [active, setActive] = useState(0);

  let location = useLocation();
  let locState = location.state;

  console.log('asdasdlocstate', locState)

  return (
    <div>
      <ProfileNav />
      <div className="my-10 max-w-4xl mx-auto">
        <h1 className="text-center font-extrabold text-4xl tracking-wider">
          Refer {`&`} Earn.. To get credits{" "}
        </h1>
        <ReferralsSlider active={active} setActive={setActive} />
        <ReferralsBody active={active} />
      </div>
    </div>
  );
}

export default ReferralsAndCredits;
