import React from "react";
import ProfileNav from '../Profile/ProfileNav';
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";

export default function AdminCreditPage() {
  return (
    <>
    <ProfileNav />
    <div className="justify-center text-center">
      <div>
        <h3 className="mt-20 mb-5 text-lg text-black font-semibold">
          Add Credits
        </h3>
        <p className="text-gray-500 mb-4">
          Use a permanent address where you can receive mail.
        </p>
      </div>

      <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl border border-b-4 transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full justify-center ">
        <div className="text-left overflow-hidden transform transition-all sm:align-middle sm:max-w-sm sm:w-full sm:p-6 justify-center ">
          <div>
            <div>
              <label
                htmlFor="location"
                className="block text-sm font-medium text-gray-700"
              >
                User Name
              </label>
              <select
                id="location"
                name="UserName"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                defaultValue="--Select User Name--"
              >
                <option className="text-gray-300">
                  -- Select User Name --
                </option>
                <option>United States</option>
                <option>Canada</option>
                <option>Mexico</option>
              </select>
            </div>

            <div className="mt-6 mb-6">
              <label
                htmlFor="location"
                className="block text-sm font-medium text-gray-700"
              >
                No of Accepted Invitee's
              </label>
              <select
                id="location"
                name="location"
                className="mt-1 block w-full pl-3  pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                defaultValue="--Select No. of Accepted Invitee's--"
              >
                <option className="text-gray-300">
                  -- Select No. of Accepted Invitee's --
                </option>
                <option>United States</option>
                <option>Canada</option>
                <option>Mexico</option>
              </select>
            </div>

            <div>
              <label
                htmlFor="location"
                className="block text-lg font-medium text-gray-700"
              >
                Add Credits
              </label>
              <select
                id="location"
                name="location"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                defaultValue="--Select $--"
              >
                <option className="text-gray-300">-- Select $ --</option>
                <option>United States</option>
                <option>Canada</option>
                <option>Mexico</option>
              </select>
            </div>
          </div>
         
        </div>
        <div className="h-14 justify-center text-center border bg-gray-200 w-full">
            <button
              type="button"
              className="inline-flex mt-2 justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
              onClick={() =>{}}
            >
              Add Credits
            </button>
          </div>
      </div>
    </div>
    </>
  );
}
