import React, { useState } from 'react'
import '../../styles/additionaInfoModal.css'
import Modal from 'react-responsive-modal';
import { PlusIcon, MinusIcon, CheckIcon } from '@heroicons/react/solid';
import { useHistory } from 'react-router-dom';


export default function AdditionInfoModal({setAdditionalInformation,price,planName,renewed,setRenewPlan, id}) {
    const [open, setOpen] = useState(true);
    const [count, setCount] = useState(1)
    const [billingPeriod, setBillingPeriod] = useState(1)

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () =>{
        setAdditionalInformation(false)
        setRenewPlan(false)
         setOpen(false)
        };
        let history = useHistory();

    const someEventHandler = () => {
       history.push({
           pathname: '/payment',
           state: { count:count, month: billingPeriod, price: price, planName: planName, id:id }
       });
    };
    console.log(renewed)
   
  return (
    <Modal open={open} onClose={onCloseModal} center classNames={{ overlay: 'customOverlay', modal: 'customModalForInfo', }}>
            <div >
              <div >
                <h1 className='font-bold'>
                  {
                    renewed == 'tr' ? 
                    'Renew your plan'
                    :
                    'Additional Information'
                  }
                   </h1>
              </div>
              <div className='flex items-baseline lg:mb-8 mt-2'>
            <div className='flex border-[1px] border-gray-300 w-[25%] h-9 items-center justify-between  mr-2'>
             <MinusIcon   className="bg-gray-200 h-[100%] w-[30%] text-gray-500 border-r-[1px] cursor-pointer" onClick={()=>setCount(count- 1)}/>
             <h1>{count}</h1>
             <PlusIcon  className="bg-gray-200 h-[100%]  w-[30%] text-gray-500 border-r-[1px] cursor-pointer"  onClick={()=>setCount(count + 1)}/>
            </div>
            <p className='text-xs font-bold '> ${price} * {count} user</p>
              </div>
              <hr className='mt-2 '></hr>
              <div className='lg:mb-8 mt-2'>
              <h1 className='text-sm'>Billing period</h1>
              <div className='flex items-center '>

              <select
              
             onfocus='this.size=5' onblur='this.size=1'
               className='h-10 rounded-sm w-[50%] mr-10 font-bold outline-none' 
               defaultValue={billingPeriod}
               onChange={(e)=>setBillingPeriod(e.target.value)}
               >
              <option value="1"> 1 month</option>
              <option value="2" > 2 month</option>
              <option value="3">  3 month</option>
              <option value="4">  4 month</option>
              <option value="5">  5 month</option>
              <option value="6">  6 month</option>
              <option value="7">  7 month</option>
              <option value="8">  8 month</option>
              <option value="9">  9 month</option>
              <option value="10">  10 month</option>
              <option value="11">  11 month</option>
              <option value="12">  12 month</option>
            </select>
            
            <div className='flex'>
                <input type='checkbox'
                className='mr-4' />
                <p className='text-blue-700 text-xs sm:text-sm'>recurring-payment</p>
            </div>
                </div>
              </div>
            <hr className='mt-2'></hr>
            <div className='flex items-center justify-between mt-4' onClick={()=>setCount(count + 1)}>
                <p className='text-sm font-bold'>${price} * {count} * {billingPeriod} = ${price*count*billingPeriod}</p>
                <h3 className="text-sm font-medium bg-black text-white inline-flex items-baseline px-5 py-2 rounded-md sm:mb-2 cursor-pointer" onClick={someEventHandler}>Next</h3>

            </div>
            </div>
          </Modal>
  )
}
