import { CheckIcon } from '@heroicons/react/solid';
import React, { useEffect, useState } from 'react'
import Modal from 'react-responsive-modal';
import { useLocation } from 'react-router-dom';


export default function SeeAllTheFeature({ setSeeFeature, sendTopay, price, planName, id }) {
    const [open, setOpen] = useState(true);
    const [plansDetail, setPlansDetail] = useState([])

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => {
        setSeeFeature(false)
        setOpen(false)
    };
    const location = useLocation();

    const feature = [
        'Time tracking every 1 min, 2 min, 5 min',
        'Attendance',
        'Tasks and projects',
        'Upto 5 Integrations',
        'Audio recording',
        'White glove technical support Teams',
        'Unlimited screenshots',
        'Unlimited screenshots',
        'Global, Team, & User permissions',
        'Observer/Client Login',
        'Observer/Client Login',
        'Unlimited data storage',
        'Idle Time Subtraction',
        'Idle Time Subtraction',
        'Track apps and URLs',
        'Video screen captures',
        'Unlimited Teams'
    ]
    const getPlanDetails = ( ) => {
        fetch(`https://api.trypadlock.com/plans/features_plan/?planid=${id}`, {
            method: "GET",
            headers: {
                authorization: "Jwt " + localStorage.getItem("token"),
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                console.log(data.data);
                setPlansDetail(data.data);
            })
            .catch((err) => console.log("err", err));
      };

      useEffect(()=>{
         getPlanDetails()
      },[])
    return (
        <>
            {
                sendTopay ?
                    <Modal open={open} onClose={onCloseModal} center classNames={{ overlay: 'customOverlay', modal: 'customModal2', }} >
                        <div className=' md:w-[65vw]  flex flex-col items-center  '>
                            <div className=''>
                                <h1 className='font-bold text-2xl text-center 2xl:text-5xl'>{planName}</h1>
                                <div className='w-[100%]  flex flex-col items-center'>
                                    <p className='text-gray-800 font-normal text-xs text-center 2xl:text-xl'>${price}/user/month</p>
                                    <p className=' mt-4'>What you'll get</p>
                                </div>
                            </div>

                            <div className='w-[100%] '>
                                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-6 gap-x-4 px-4 
                        text-gray-600 mt-4  2xl:gap-y-[100px] ">
                                    {
                                        plansDetail.length > 0 ?
                                        plansDetail?.map((data) => (
                                                
                                                <div className="space-y-4
                                    text-sm text-gray-800 font-bold  2xl:text-2xl">
                                                    <p className='overflow-ellipsis whitespace-nowrap hover:whitespace-pre-wrap overflow-hidden hover:overflow-visible cursor-pointer'>
                                                    <CheckIcon className="inline self-center flex-shrink-1 h- w-5 text-green-500 -translate-x-1 " />
                                                        {data.description}
                                                    </p>

                                                </div>
                                        ))
                                        :
                                        feature?.map((data) => (
                                                
                                                <div className="space-y-4
                                    text-sm text-gray-800 font-bold  2xl:text-2xl">
                                                    <p className='overflow-ellipsis whitespace-nowrap hover:whitespace-pre-wrap overflow-hidden hover:overflow-visible cursor-pointer'>
                                                    <CheckIcon className="inline self-center flex-shrink-1 h- w-5 text-green-500 -translate-x-1 " />
                                                        {data}
                                                    </p>

                                                </div>
                                        ))

                                    }

                                </div>
                            </div>
                        </div>
                    </Modal>
                    :
                    <div className=' md:w-[65vw]  flex flex-col items-center sm:border-r-[1px] border-gray-300 '>
                        <div className=''>
                            <h1 className='font-bold text-2xl mt-2 text-center 2xl:text-5xl'>{location.state.planName}</h1>
                            <div className='w-[100%]  flex flex-col items-center'>
                                <p className='text-gray-400 font-normal text-sm text-center 2xl:text-xl'>${location.state.price}/user/month</p>
                                <p className=' mt-8 2xl:text-2xl'>What you'll get</p>
                            </div>
                        </div>

                        <div className='w-[100%] sm:h-full sm:overflow-none h-[70vh] overflow-auto'>
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-10 2xl:gap-y-[100px] gap-x-2 px-6 py-14  text-gray-600 ">
                                
                            {
                                        plansDetail.length > 0 ?
                                        plansDetail?.map((data) => (
                                                
                                                <div className="space-y-4
                                    text-sm text-gray-800 font-bold  2xl:text-2xl">
                                                    <p className='overflow-ellipsis whitespace-nowrap hover:whitespace-pre-wrap overflow-hidden hover:overflow-visible cursor-pointer'>
                                                    <CheckIcon className="inline self-center flex-shrink-1 h- w-5 text-green-500 -translate-x-1 " />
                                                        {data.description}
                                                    </p>

                                                </div>
                                        ))
                                        :
                                        feature?.map((data) => (
                                                
                                                <div className="space-y-4
                                    text-sm text-gray-800 font-bold  2xl:text-2xl">
                                                    <p className='overflow-ellipsis whitespace-nowrap hover:whitespace-pre-wrap overflow-hidden hover:overflow-visible cursor-pointer'>
                                                    <CheckIcon className="inline self-center flex-shrink-1 h- w-5 text-green-500 -translate-x-1 " />
                                                        {data}
                                                    </p>

                                                </div>
                                        ))

                                    }
                            </div>
                        </div>
                    </div>

            }
        </>
    )
}

// {
//     feature.map((data) => (

//         <div className="space-y-4 text-sm 2xl:text-xl text-gray-800 font-semibold w-[100%]  ">
//             <p> <CheckIcon className="inline self-center flex-shrink-0 h-5 w-5 text-green-500" /> {data}
//             </p>

//         </div>
//     ))
// }