import React from "react";
import ReferralSteps from "./ReferralSteps";
import { FaWallet } from "react-icons/fa";
import ReferralsWayCards from "./ReferralsWayCards";

function Referrals() {
  return (
    <div className="my-8">
      <h3 className="my-5 text-2xl text-center font-light">
        Get Free credits every time, when a refered friend uses padlock to
        upload apps
      </h3>
      <ReferralSteps />
      <div className="border-2 block sm:flex  justify-between rounded-xl py-6 px-8 shadow-lg my-8">
        <h1 className="text-3xl">Your total credit balance is $15</h1>
        <button className="flex my-5 sm:my-0 py-2 px-5 text-lg font-semibold text-white bg-black rounded-lg">
          <FaWallet className="w-6 h-6 mr-3" />
          Apply credit
        </button>
      </div>
      <div className="my-12">
        <h1 className="text-3xl mb-9">Ways to earn credits</h1>
        <ReferralsWayCards />
      </div>
    </div>
  );
}

export default Referrals;
