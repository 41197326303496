import React, { useRef } from "react";
import Navbar from "../Navbar/Navbar";
import ProfileNav from "../Profile/ProfileNav";
import { Link } from "react-router-dom";
import addClientId from "../../assets/addClientId.png";
import appDetails from "../../assets/appDetails.png";

import padlockHome from "../../assets/padlockHome.png";

const GoogleClientDocumentation = ({ isLoggedin }) => {
  const step1Ref = useRef(null);
  const step2Ref = useRef(null);
  const step3Ref = useRef(null);
  const step4Ref = useRef(null);
  const step5Ref = useRef(null);
  const step6Ref = useRef(null);
  const step7Ref = useRef(null);
  const step8Ref = useRef(null);

  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      {isLoggedin ? <ProfileNav /> : <Navbar />}

      <div className='flex'>
        <div className='w-1/5 bg-gray-200 p-4 rounded sticky top-0 h-screen overflow-y-auto'>
          <ul>
            <li>
              <button
                className='text-blue-500 hover:underline'
                onClick={() => scrollToSection(step1Ref)}>
                Step 1: Google Cloud Console
              </button>
            </li>
            <li>
              <button
                className='text-blue-500 hover:underline'
                onClick={() => scrollToSection(step2Ref)}>
                Step 2: Create a Project
              </button>
            </li>
            <li>
              <button
                className='text-blue-500 hover:underline'
                onClick={() => scrollToSection(step3Ref)}>
                Step 3: OAuth Consent Screen
              </button>
            </li>
            <li>
              <button
                className='text-blue-500 hover:underline'
                onClick={() => scrollToSection(step4Ref)}>
                Step 4: Create OAuth Credentials
              </button>
            </li>
            <li>
              <button
                className='text-blue-500 hover:underline'
                onClick={() => scrollToSection(step5Ref)}>
                Step 5: Obtain Client ID & Secret
              </button>
            </li>
            <li>
              <button
                className='text-blue-500 hover:underline'
                onClick={() => scrollToSection(step6Ref)}>
                Step 6: Go to trypadlock
              </button>
            </li>
            <li>
              <button
                className='text-blue-500 hover:underline'
                onClick={() => scrollToSection(step7Ref)}>
                Step 7: Click on an App to View Details
              </button>
            </li>
            <li>
              <button
                className='text-blue-500 hover:underline'
                onClick={() => scrollToSection(step8Ref)}>
                Step 8: Add Client ID and Secret
              </button>
            </li>
          </ul>
        </div>

        <div className='w-4/5 p-10 bg-[#f5f5f5] rounded'>
          <h2 className='text-xl font-bold mb-4'>
            How to Generate Google Client ID and Secret Key
          </h2>

          <div className='mb-20' ref={step1Ref}>
            <h3 className='text-lg font-semibold'>
              Step 1: Go to the Google Developers Console
            </h3>
            <p>
              Visit the{" "}
              <a
                href='https://console.cloud.google.com/'
                className='text-blue-500 underline'>
                Google Cloud Console
              </a>{" "}
              and log in with your Google account.
            </p>
            <p>If you don’t have a project yet, you’ll need to create one.</p>
            <img
              src='https://www.balbooa.com/images/docs/gridbox/integrations/google-client-id-project.webp'
              alt='Google Cloud Console Homepage'
              className='mt-4 rounded shadow-lg h-auto'
            />
          </div>

          <div className='mb-20' ref={step2Ref}>
            <h3 className='text-lg font-semibold'>
              Step 2: Create a New Project
            </h3>
            <p>
              In the Cloud Console, click{" "}
              <strong>Select a project ➝ New Project ➝</strong> then click the{" "}
              <strong>Create</strong> button.
            </p>
            <img
              src='https://www.balbooa.com/images/docs/gridbox/integrations/google-client-id-new-project.webp'
              alt='Create a new project in Google Cloud'
              className='mt-4 rounded shadow-lg  h-auto'
            />
          </div>

          <div className='mb-20' ref={step3Ref}>
            <h3 className='text-lg font-semibold'>
              Step 3: Configure the OAuth Consent Screen
            </h3>
            <p>
              Click <strong>OAuth consent screen</strong> in the left side menu{" "}
              <strong>➝</strong> choose <strong>User Type ➝</strong> click the{" "}
              <strong>Create</strong> button.
            </p>
            <img
              src='https://www.balbooa.com/images/docs/gridbox/integrations/google-client-id-choose-user-type.webp'
              alt='OAuth Consent Screen'
              className='mt-4 rounded shadow-lg  h-auto'
            />
          </div>

          <div className='mb-20' ref={step4Ref}>
            <h3 className='text-lg font-semibold'>
              Step 4: Create OAuth 2.0 Credentials
            </h3>
            <p>
              Go to <strong>Credentials ➝</strong> click{" "}
              <strong>Create Credentials ➝</strong> select{" "}
              <strong>OAuth client ID</strong> from the dropdown list.
            </p>
            <img
              src='https://www.balbooa.com/images/docs/gridbox/integrations/google-client-id-creating.webp'
              alt='Create OAuth 2.0 Credentials'
              className='mt-4 rounded shadow-lg  h-auto'
            />
          </div>

          <div className='mb-20' ref={step5Ref}>
            <h3 className='text-lg font-semibold'>
              Step 5: Obtain Client ID and Secret
            </h3>
            <p>
              Fill out the required details such as the application type (e.g.,
              Web Application) and the authorized redirect URIs. Once submitted,
              you will receive a Client ID and Client Secret.
            </p>
            <img
              src='https://www.balbooa.com/images/docs/gridbox/integrations/copy-google-client-id-and-client-secret.webp'
              alt='Client ID and Secret'
              className='mt-4 rounded shadow-lg h-auto'
            />
          </div>

          <div className='mb-20' ref={step6Ref}>
            <h3 className='text-lg font-semibold'>
              Step 6: Go to trypadlock and View the App List
            </h3>
            <p>
              Navigate to the Home page of{" "}
              <Link to='/apps'>
                <span className={"px-3 text-blue-600"}>trypadlock</span>
              </Link>
              . On this page, you will see a list of all your applications.
            </p>

            <img src={padlockHome} className='mt-4 rounded shadow-lg h-auto' />
          </div>

          <div className='mb-20' ref={step7Ref}>
            <h3 className='text-lg font-semibold'>
              Step 7: Click on an App to View Details
            </h3>
            <p>
              From the app list, click on any application to navigate to the app
              details page. On this page, you can see Google OAuth Client
            </p>

            <img src={appDetails} className='mt-4 rounded shadow-lg h-auto' />
          </div>

          <div className='mb-20' ref={step8Ref}>
            <h3 className='text-lg font-semibold'>
              Step 8: Add Client ID and Secret
            </h3>
            <p>
              In the app details page, you will see Google OAuth Client with +
              button Click on the <strong>+</strong> button, then enter your
              Client ID and Client Secret. Once entered, click on{" "}
              <strong>Submit</strong> to save the changes.
            </p>
            <img src={addClientId} className='mt-4 rounded shadow-lg h-auto' />
          </div>
        </div>
      </div>
    </>
  );
};

export default GoogleClientDocumentation;
