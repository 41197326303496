import React, {useState, useEffect} from "react";
// import "../styles/SettingScreen.css";
import "../../styles/SettingScreen.css";
import { CameraIcon } from "@heroicons/react/solid";
import user from '../../assets/user.png';
import { FillAllFields, UpdatedSuccessfully } from "../NotificationToast";
import { ToastContainer } from "react-toastify";


function ProfileSetting() {
  const [profileImg, setProfileImg] = useState(user);
  const validEmailRegex = RegExp(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
);

  // const [userInfo, setUserInfo] = useState([]);
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showCurrentPassword, setshowCurrentPassword] = useState(false);

  const ImageHandler = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setProfileImg(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleShowCurrentPassword = () => {
    setshowCurrentPassword(!showCurrentPassword)
  }

  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  useEffect(() => {
      const profile = fetch("https://api.trypadlock.com/loginapp/profile/", {
          method: "GET",
          headers: {
              authorization: "Jwt " + localStorage.getItem("token"),
          },
      })
          .then((response) => response.json())
          .then((data) => {
              console.log("userProfile", data);
              // setUserInfo(data)
              setFirstName(data.first_name);
              setLastName(data.last_name);
              setUserName(data.username);
              setEmail(data.email);
          })
          .catch((err) => console.log(err));
  }, []);

  const handleSubmit = () => {
      if (firstName == "" || lastName == "" || newPassword == "" || password == "") {
          FillAllFields();
      } else {
          // if(emailtest == true){
          const edit = fetch("https://api.trypadlock.com/loginapp/profile", {
              method: "PUT",
              headers: {
                  authorization: "Jwt " + localStorage.getItem("token"),
                  "Content-Type": "application/json",
              },
              body: JSON.stringify({
                  first_name: firstName,
                  last_name: lastName,
                  password: newPassword,
                  avatar: profileImg,
              }),
          })
              .then((response) => response.json())
              .then((data) => {
                  console.log("data", data);
                  setPassword("");
                  setNewPassword("");
                  setshowCurrentPassword(false);
                  setShowPassword(false);
                  UpdatedSuccessfully();
              })
              .catch((err) => {
                  console.log(err);
              });
      }
  };

 
  return (
    <div className="border shadow-md">
      <div className="m-auto w-24 smt:pt-2">
        <img
          className="rounded-full border mt-4 w-24 h-24 contain text-center z-0"
          src={profileImg}
          alt="profile_pic"
        />
        <input
          type="file"
          className="hidden"
          id="image-change"
          name="image-change"
          accept="image/*"
          onChange={ImageHandler}
        />
        <label htmlFor="image-change">
          <CameraIcon className="w-7 h-7 ml-16 -mt-6 cursor-pointer text-white p-1 bg-black z-10 rounded-full absolute" />
        </label>
      </div>
      <div className="my-6">
        <div className="py-6 px-14 justify-center">
          <h1 className="text-xl font-medium">Edit Profile</h1>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label
                htmlFor="user-name"
                className="block text-sm font-medium text-gray-700"
              >
                User Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  value={userName}
                  onChange={(event) => {
                    setUserName(event.target.value);
                  }}
                  name="user-name"
                  id="user-name"
                  disabled
                  autoComplete="given-name"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md h-9"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium text-gray-700"
              >
                First name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  value={firstName}
                  onChange={(event) => {
                    setFirstName(event.target.value);
                  }}
                  name="first-name"
                  id="first-name"
                  required
                  autoComplete="given-name"
                  className="h-9 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium text-gray-700"
              >
                Last name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  value={lastName}
                  onChange={(event) => {
                    setLastName(event.target.value);
                  }}
                  name="last-name"
                  id="last-name"
                  required
                  autoComplete="family-name"
                  className="h-9 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  value={email}
                  // onChange={(event) => {
                  //   setEmail(event.target.value);
                  // }}
                  disabled
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="h-9 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="py-6 px-14 justify-center">
          <h1 className="text-xl font-medium">Change Password</h1>
          <h2 className="text-lg font-medium mt-3">
            Your new password must be different from previous used passwords.
          </h2>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label
                htmlFor="current-password"
                className="block text-sm font-medium text-gray-700"
              >
                Current Password
              </label>
              <div className="mt-1 flex">
                <input
                  type={showCurrentPassword ? "text" : "password"}
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                  name="current-password"
                  id="current-password"
                  autoComplete="given-name"
                  className="h-9 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
                <span className="material-icons" onClick={handleShowCurrentPassword}>
                  <i className={showCurrentPassword ? "fa fa-eye-slash fa-lg position-absolute" : "fa fa-eye fa-lg position-absolute" } style={{marginLeft: "-30px", marginTop: "10px", cursor: 'pointer'}}></i>
                </span>
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="new-password"
                className="block text-sm font-medium text-gray-700"
              >
                New Password
              </label>
              <div className="mt-1 flex">
                <input
                  type={showPassword ? "text" : "password"}
                  value={newPassword}
                  onChange={(event) => setNewPassword(event.target.value)}
                  name="new-password"
                  id="new-password"
                  autoComplete="given-name"
                  className="h-9 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
                <span className="material-icons" onClick={handleShowPassword}>
                  <i className={showPassword ? "fa fa-eye-slash fa-lg position-absolute" : "fa fa-eye fa-lg position-absolute" } style={{marginLeft: "-30px", marginTop: "10px", cursor: 'pointer'}}></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="border bg-gray-300 flex">
          <div className="m-auto mt-5 mb-2 w-full flex justify-end items-end">
            <button
              className="bg-black text-white font-bold text-xl w-52 h-14 rounded-md border-0 mr-4"
              onClick={handleSubmit}
            >
              Save Changes
            </button>
          </div>
        </div>
      <ToastContainer />
    </div>
  );
}

export default ProfileSetting;
